import { useTranslation } from "react-i18next"
import { TiStarburst } from "react-icons/ti";

export default function SelectInput({ title, arr, state, setState, arrProps }) {
    const { t } = useTranslation();
    return (
        <div className="radioButton__grid__twoCollumns">
            <label htmlFor={`${title}Select`}>{t(title)}:</label>
            <select name={`${title}Select`} id="diagnoseSelect" value={state} onChange={e => setState(e.target.value)}>
                <option value="">--{t('choose' + title.substring(0, 1).toUpperCase() + title.substring(1))}--</option>
                {arr.map((obj, i) => <option key={i} value={obj[arrProps[0]]}>{obj[arrProps[1]]} {obj[arrProps[2]]}</option>)}
            </select>
            <div className="red__star"><TiStarburst /></div>
        </div>
    )
}