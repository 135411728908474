import "./cariescheker.css"

export default function CariesChecker({handler, checkedArr}) {

  
  return (
    <div id="filling-surface-div" className="mb-2">		
      <svg id="upper-side" width="102" height="102">
        
          <g id="facial" className="group" onClick={(e)=>handler(e.target.id)}>
            <polygon points="67 29, 97 3, 3 3, 33 29" className={`rect tooth-surface-rect-${checkedArr[0]===true?"":"un"}selected`} id="facial" > </polygon>
            <text x="43" y="21" className="odontogram-tooth-surface-text" id="facial" > VE </text>
          </g>	


          <g id="distal" className="group" onClick={(e)=>handler(e.target.id)}> 
            <polygon points="31 70, 31 31, 2 6, 2 95" className={`rect tooth-surface-rect-${checkedArr[1]===true?"":"un"}selected`} id="distal" > </polygon>		
            <text x="9" y="56" className="odontogram-tooth-surface-text" id="distal" > D </text>
          </g>

          <g id="occlusal" className="group" onClick={(e)=>handler(e.target.id)}>
            <rect x="34" y="32" rx="2" ry="2" width="33" height="37" className={`rect tooth-surface-rect-${checkedArr[2]===true?"":"un"}selected`} id="occlusal" ></rect>	
            <text x="45" y="56" className="odontogram-tooth-surface-text" id="occlusal" > O </text>
          </g>

          <g id="mesial" className="group" onClick={(e)=>handler(e.target.id)}>
            <polygon points="98 94, 98 6, 70 31, 70 70" className={`rect tooth-surface-rect-${checkedArr[3]===true?"":"un"}selected`} id="mesial" > </polygon>
            <text x="81" y="56" className="odontogram-tooth-surface-text" id="mesial" > M </text>
          </g>

          <g id="oral" className="group" onClick={(e)=>handler(e.target.id)}>
            <polygon points="97 97, 67 72, 33 72, 4 97" className={`rect tooth-surface-rect-${checkedArr[4]===true?"":"un"}selected`} id="oral" > </polygon>
            <text x="41" y="91" className="odontogram-tooth-surface-text" id="oral" > OR </text>												 	
          </g>														
      </svg>
    </div>
  )
}