import { TiStarburst } from "react-icons/ti";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./rtgaddedit.css";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import DatePicker from "react-datepicker";

export default function RTGEdit() {
  const id = useParams().id;

  const { editRtgRecords, rtgRecords, message, setMessage } = useContext(DataContext);
  const { rtgDate, rtgNote, toothNumber } = rtgRecords.find(obj => obj.rtgRecordsId === id);

  const { t } = useTranslation();
  const [date, setDate] = useState(new Date(rtgDate));
  const [document, setDocument] = useState("");
  const [note, setNote] = useState(rtgNote);

  useEffect(() => { setMessage("") }, [])

  const rtgAddHandle = (e) => {
    e.preventDefault();
    editRtgRecords(id, date, document, note, toothNumber);
  }

  const handleDate = (date) => {
    setDate(date)
  }

  return (
    <section className="container rtgadd__container">
      <div className="rtgadd__top">
        <h3>{t('editRTGRecord')}</h3>
      </div>
      <form className="rtgAdd__form" onSubmit={rtgAddHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input36">{t('date')} </label>
          <DatePicker
            selected={date}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>

        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input37">{t('document')} </label>
          <input type="file" name="document" id="input37" value={document} onChange={e => setDocument(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>

        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="textarea1">{t('note')} </label>
          <textarea name="textarea1" id="textarea1" cols="40" rows="6" value={note} onChange={e => setNote(e.target.value)} />
        </div>

        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>

      </form>
    </section>
  )
}