import { useTranslation } from "react-i18next"
import { FaUser } from "react-icons/fa"
import { AgePieChart } from "../../../charts/AgePieChart";
import { DemographicHorizontalBarChart} from "../../../charts/DemographicHorizontalBarChart";
import { Top10VerticalBarChart } from "../../../charts/Top10VerticalBarChart";
import { useContext } from "react";
import { DataContext } from "../../../DataContext";

export default function StatisticCompleteValues(){
  const {t} = useTranslation();
  const {numberOfInterventions, numberOfPatients} = useContext(DataContext);
  return(
    <div className="statistic__card__container">

      <div className="statistic__top__container">

        <div className="patient__number__left">
          <div className="statistic__title__top">
            <FaUser />
             <h3>{t('numberOfPatients')}</h3>
          </div>
          <p>{numberOfPatients}</p>
        </div>

        <div className="birth_demography_chart">
          <div className="statistic__title__top">
            <FaUser /> 
            <h3>{t('genderDemography')}</h3>
          </div>
          <DemographicHorizontalBarChart />
        </div>

        <div className="age_demography_chart">
        <div className="statistic__title__top">
          <FaUser />
          <h3>{t('ageDemography')}</h3>
        </div>
        <div className="age__pie__chart__container">
          <AgePieChart />
        </div>
        
        </div>
      </div>


      <div className="statistic__bottom__container">
        <div className="intervention__number__left">
          <div className="statistic__title__top">
            <FaUser />
            <h3>{t('numberInterventions')}</h3>
          </div>
          <p>{numberOfInterventions}</p>
        </div>
        <div className="top10__interventions__chart">
          <div className="statistic__title__top">
            <FaUser />
            <h3>{t('top10Interventions')}</h3>
          </div>
          <Top10VerticalBarChart />
        </div>
      </div>
    </div>
  )
}