import { useContext, useEffect, useState } from "react";
import "./appointmentAddEdit.css"
import { BsCalendar3 } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../DataContext";
import { useParams } from "react-router-dom";

export default function AppointmentEdit() {
  const id = +useParams().id;
  const { t } = useTranslation();

  const {
    interventions,
    fetchInterventions,
    fetchAppointment,
    editAppointment,
    appointment,
    setAppointment,
    message
  } = useContext(DataContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAppointment(id);
    fetchInterventions();
    setLoading(false);
  }, []);



  const handleSubmit = (event) => {
    event.preventDefault();
    editAppointment(id, appointment);
  }

  if (!loading && Object.keys(appointment).length > 0) {
    return (
      <section className="container  newReservation__container">
        <div className="newReservation__top">
          <BsCalendar3 />
          <h3>{t('create/edit_appointment')}</h3>
        </div>

        <form className="newReservation__form" onSubmit={handleSubmit}>

          <div className="radioButton__flex">
            <label htmlFor="input17">{t('intervention')}: </label>
            <select type="text" name="intervention" id="input17" value={appointment.service} onChange={e => setAppointment(old => ({ ...old, service: e.target.value }))}>
              {interventions.map(({ interventionTitle }) => <option value={interventionTitle}>
                {interventionTitle}
              </option>)}
            </select>
          </div>

          <div className="radioButton__flex">
            <label htmlFor="input19">{t('note')}: </label>
            <textarea id="input19" rows="4" cols="50" name="note" value={appointment.note} onChange={e => setAppointment(old => ({ ...old, note: e.target.value }))}></textarea>
          </div>

          <div className="newReservationFormFooter">
            <input className="btn save" type="submit" value={t('save')} />
            <div className="message__div">{message}</div>
          </div>
        </form>
      </section>
    )
  } else return <div>Loading</div>
}