import Image from "../../images/logo.png"
import { useTranslation } from "react-i18next"

export default function MainHeader(){
    const {t} = useTranslation();
    return (
        <header className="main__header">
            <div className="container main__header-container">
                <div className="main__header-left">
                    <h1>{t('dentalOffice')}</h1>
                </div>
                <div className="main__header-right">
                    <div className="main__header-img">
                        <img src={Image} alt="Main Header Image" className="hammer-pic"/>
                    </div>
                </div>
            </div>
        </header>
    )
}