import { useTranslation } from "react-i18next"

export default function CariesTypeForm({setState}) {
  const {t} = useTranslation();
  return(
    <div className="cariesType__form">

      <div className="radioButton__flex">
        <input type="radio" name="caries" id="amalgamRadio" value={t('amalgam')} onChange={e => setState(e.target.value)}/>
        <label htmlFor="amalgamRadio">{t('amalgam')}</label>
      </div>

      <div className="radioButton__flex">
        <input type="radio" name="caries" id="compositeRadio" value={t('composite')} onChange={e => setState(e.target.value)}/>
        <label htmlFor="compositeRadio">{t('composite')}</label>
      </div>

      <div className="radioButton__flex">
        <input type="radio" name="caries" id="glasIonomerRadio" value={t('glassIonomer')} onChange={e => setState(e.target.value)}/>
        <label htmlFor="glasIonomerRadio">{t('glassIonomer')}</label>
      </div>

      <div className="radioButton__flex">
        <input type="radio" name="caries" id="otherRadio" value={t('other')} onChange={e => setState(e.target.value)}/>
        <label htmlFor="otherRadio">{t('other')}</label>
      </div>

    </div>
  )
}