import React, { useContext } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../DataContext';

ChartJS.register(CategoryScale,LinearScale,BarElement,Tooltip,Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export function NewPatientsIn3LastYearVerticalBarChart() {
  const {t} = useTranslation();
  const {lastThreeYearsPatients} = useContext(DataContext);
  const {y1,y2,y3} = lastThreeYearsPatients;
  const labels = [t('january'),t('february'),t('march'),t('april'),t('may'),t('june'),t('july'),t('august'),t('september'),t('october'),t('november'),t('december')];
  const date = new Date();


  const data = {
  labels,
  datasets: [
    {
      label: (date.getFullYear()-2).toString(),
      data: y3,
      backgroundColor: 'rgba(83, 162, 235, 0.5)',
    },
    {
      label: (date.getFullYear()-1).toString(),
      data: y2,
      backgroundColor: 'rgba(53, 162, 35, 0.5)',
    },
    {
      label: date.getFullYear().toString(),
      data: y1,
      backgroundColor: 'rgba(23, 62, 235, 0.5)',
    },
  ],
};


  return <Bar options={options} data={data} />;
}
