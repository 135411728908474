import "./patients.css";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DataContext } from "../../DataContext";
import dayjs from "dayjs";
import DeleteModal from "../../modals/DeleteModal";


export default function Patients() {
  const { t } = useTranslation();
  const [nameQuery, setNameQuery] = useState('');
  const [lastNameQuery, setLastNameQuery] = useState('');
  const [page, setPage] = useState(1);
  const [amount, setAmount] = useState(10);
  const [idForDelete, setIdForDelete] = useState(null);
  const [deleteModelOpen, setDeleteModalOpen] = useState(false);
  const { patients, fetchPatients, deletePatient, searchPatients } = useContext(DataContext);

  useEffect(() => {
    fetchPatients(page, amount);
  }, [page, amount]);

  const searchSubmit = (e) => {
    e.preventDefault();
    searchPatients(nameQuery, lastNameQuery);
  }

  const handleDeletePatient = () => {
    try {
      deletePatient(idForDelete);
      setDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <section className="container patients__container">
      <DeleteModal open={deleteModelOpen} setOpen={setDeleteModalOpen} handleDelete={handleDeletePatient} />
      <div className="patients__table__top">
        <h3>{t('allPatientsView')}: </h3>
        <Link className="btn sm" to="/patientAdd">+ {t('addPatient')}</Link>
      </div>

      <div className="patients__table__navigation">
        <div className="patients__table__navigation__page">
          <p>{t('pageNumber')}: </p>
          <button className="btn sm" onClick={() => page > 1 ? setPage(old => old - 1) : () => null}>-</button>
          <p>{page}</p>
          <button className="btn sm" onClick={() => setPage(old => old + 1)}>+</button>
          <p>{t('numberOfPatientsByPage')}: </p>
          <select name="paientAmount" id="patientAmount" value={amount} onChange={e => setAmount(e.target.value)}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <form className="patients__table__navigation__search" onSubmit={searchSubmit}>
          <AiOutlineSearch />
          <input type="text" name="searchQuery" placeholder={t('name')} id="nameQuery" value={nameQuery} onChange={e => setNameQuery(e.target.value)} />
          <input type="text" name="lastNameQuery" placeholder={t('lastName')} id="lastNameQuery" value={lastNameQuery} onChange={e => setLastNameQuery(e.target.value)} />
          <input className="btn sm" type="submit" value={t('search')} />
        </form>
      </div>

      <div className="patients__table__body">
        <div className="patients__table__body__top">
          <p>#</p>
          <p>{t('name')}:</p>
          <p>{t('lastName')}:</p>
          <p>{t('dateOfBirth')}:</p>
          <p>{t('parentName')}:</p>
          <p>{t('address')}:</p>
          <p>{t('edit')}</p>
          <p>{t('open')}</p>
          <p>{t('delete')}</p>
        </div>

        {patients.map(({ patientId, name, lastName, birthday, parentName, adress }) => {
          return <div className="patients__table__body__top data" key={patientId}>
            <p></p>
            <p>{name}</p>
            <p>{lastName}</p>
            <p>{dayjs(birthday).format("DD.MM.YYYY")}</p>
            <p>{parentName}</p>
            <p>{adress}</p>
            <Link className="btn sm" to={`/patientEdit/${patientId}`}>{t('edit')}</Link>
            <Link className="btn sm" to={`/patient/${patientId}`}>{t('open')}</Link>
            <button className="btn sm" onClick={(e) => {
              e.stopPropagation();
              setIdForDelete(patientId);
              setDeleteModalOpen(true);
            }
            }>
              {t('delete')}
            </button>
          </div>
        })}
      </div>
    </section>
  )
}