import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./filesandopinionsadd.css";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import DatePicker from "react-datepicker";

export default function FilesAndOpinionsEdit() {
  const id = +useParams().id;
  const { t } = useTranslation();

  const { editFilesAndOpinions, filesAndOpinions, doctors, message, setMessage } = useContext(DataContext);
  const { date, diagnose, therapy, doctor } = filesAndOpinions.find(obj => obj.fileId === id);


  const [dateState, setDateState] = useState(date ? new Date(date) : new Date());
  const [diagnoseState, setDiagnoseState] = useState(diagnose);
  const [therapyState, setTherapyState] = useState(therapy);
  const [doctorState, setDoctorState] = useState(doctor);

  useEffect(() => { setMessage("") }, [])

  const filesAndOpinionsAddEditHandle = (e) => {
    e.preventDefault();
    editFilesAndOpinions(id, dateState, diagnoseState, therapyState, doctorState);
  }

  const handleDate = (date) => {
    setDateState(date)
  }

  return (
    <section className="container filesAndOpinionsAddEdit__container">
      <div className="filesAndOpinionsAddEdit__top">
        <h3>{t('filesAndOpinionsAdd/Edit')}</h3>
      </div>
      <form className="filesAndOpinionsForm" onSubmit={filesAndOpinionsAddEditHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input15">{t('date')}: </label>
          <DatePicker
            selected={dateState}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>

        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="diagnoseInput">{t('diagnose')}: </label>
          <textarea rows="8" type="text" name="diagnose" id="diagnoseInput" value={diagnoseState} onChange={e => setDiagnoseState(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>

        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="therapyInput">{t('therapy')}: </label>
          <textarea rows="8" type="text" name="therapy" id="therapyInput" value={therapyState} onChange={e => setTherapyState(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>

        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="doctorInput">{t('doctor')}: </label>
          <select name="doctor" id="doctorInput" value={doctorState} onChange={e => setDoctorState(e.target.value)}>
            <option value="placeholder">-- {t('chooseDoctor')} --</option>
            {doctors.map(({ doctorId, doctorName, doctorLastName }, i) => <option key={i} value={doctorId}>{doctorName} {doctorLastName}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>
      </form>
    </section>
  )
}