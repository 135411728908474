import "./odontogramdetails.css";
import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTooth } from "react-icons/fa";
import CariesChecker from "../../../../components/toothStatusFormParts/CariesChecker"
import CariesTypeForm from "../../../../components/toothStatusFormParts/CariesTypeForm";
import { lengthCodes } from "../../../../data";
import LengthInputs from "../../../../components/odontogramDetailsParts/LengthInputs";
import { DataContext } from "../../../../DataContext";
import SelectInput from "../../../../components/SelectInput";
import RootCanalChecker from "../../../../components/odontogramDetailsParts/rootCanalChecker";
import DatePicker from "react-datepicker";

export default function OdontogramDetails({ selectedTooth, selectedToothsIds, patientId, resetTooths }) {
  const { t } = useTranslation();
  const { fetchDoctors, fetchInterventions, interventions, doctors, createSingleToothIntervention, message, setMessage } = useContext(DataContext);

  const [date, setDate] = useState(new Date());
  const [doctorId, setDoctorId] = useState('');
  const [checkedDiagnose, setCheckedDiagnose] = useState('');
  const [checkedMiddleIntervention, setCheckedMiddleIntervention] = useState('');
  const [checkedIntervention, setCheckedIntervention] = useState('');
  const [checkedToothParts, setCheckedToothParts] = useState([false, false, false, false, false]);
  const [checkedLengthCodes, setCheckedLengthCodes] = useState(["", "", "", "", "", "", "", "", "", ""]);
  const [checkedImplantType, setCheckedImplantType] = useState('');
  const [checkedRootCanal, setCheckedRootCanal] = useState([false, false, false]);
  const [diagnose, setDiagnose] = useState('');
  const [note, setNote] = useState('');
  const [price, setPrice] = useState('0');

  useEffect(() => {
    setMessage("");
    fetchInterventions();
    fetchDoctors();
  }, [])

  const handleDate = (date) => {
    setDate(date);
  }

  const checkDiagnose = (interventionId) => {
    setCheckedMiddleIntervention('');
    setCheckedIntervention('');
    setCheckedDiagnose(interventionId);
  }

  const checkInterventionHandle = (interventionId) => {
    const subInterventionCheck = interventions.find(el => el.interventionId === +checkedDiagnose).Children.find(el => el.interventionId === +interventionId)?.Children.length > 0;
    if (subInterventionCheck) {
      setCheckedIntervention('');
      setCheckedMiddleIntervention(interventionId);
    }
    else {
      setCheckedIntervention(interventionId);
    }
  }

  const submitHandler = (event) => {
    event.preventDefault();
    createSingleToothIntervention(patientId, doctorId, selectedToothsIds, checkedIntervention, date, diagnose, note, price, checkedToothParts, checkedImplantType, checkedLengthCodes, checkedRootCanal);
  }

  const toothPartHandler = (id) => {
    setCheckedToothParts(old => {
      let compare = old.slice();
      if (id === "facial") compare[0] = !compare[0];
      if (id === "distal") compare[1] = !compare[1];
      if (id === "occlusal") compare[2] = !compare[2];
      if (id === "mesial") compare[3] = !compare[3];
      if (id === "oral") compare[4] = !compare[4];
      return compare;
    })
  }


  const lengthCodeHandler = (id, value) => {
    setCheckedLengthCodes(old => {
      let compare = old.slice();
      if (id === "O/P") compare[0] = value;
      if (id === "B/V") compare[1] = value;
      if (id === "MB1") compare[2] = value;
      if (id === "MB2") compare[3] = value;
      if (id === "DB") compare[4] = value;
      if (id === "D") compare[5] = value;
      if (id === "MB") compare[6] = value;
      if (id === "ML") compare[7] = value;
      if (id === "1") compare[8] = value;
      if (id === "L") compare[9] = value;
      return compare;
    })
  }

  const rootCanalHandler = (id) => {
    setCheckedRootCanal((old) => {
      let compare = old.slice();
      if (id === 'canal_1') compare[0] = !compare[0];
      if (id === 'canal_2') compare[1] = !compare[1];
      if (id === 'canal_3') compare[2] = !compare[2];
      return compare;
    })
  }


  return (
    <div className="odontogramDetails__container">
      <div className="odontogramDetails__left">
        <div className="left__upper__left">
          <div className="left__upper__left__left">
            <FaTooth />
            <h3>{t('toothStatus')} {selectedTooth.length !== 32 ? selectedTooth.toString() : t('all')}</h3>
            <button className="btn sm" onClick={() => resetTooths()}>{t('reset')}</button>
          </div>
        </div>
        <hr />

        <div className="left__body">
          <h3>{t('newConditionInput')}:</h3>
        </div>

        <form onSubmit={submitHandler} className="interventionForm">
          <div className="textInputPart__container">

            <div className="textInputPart__flex">
              <label htmlFor="dateInput">{t('date')}</label>
              <DatePicker
                selected={date}
                onChange={handleDate}
                dateFormat="dd.MM.yyyy"
                id="input26"
                placeholderText="DD.MM.YYYY"
                className="date__input"
              />
              <div className="red__star"><TiStarburst /></div>
            </div>

            <SelectInput title="doctor" arr={doctors} state={doctorId} setState={setDoctorId} arrProps={['doctorId', 'doctorName', 'doctorLastName']} />

            <SelectInput title="intervention" arr={interventions} state={checkedDiagnose} setState={checkDiagnose} arrProps={['interventionId', 'interventionTitle']} />

            {checkedDiagnose && <SelectInput title="intervention" arr={interventions.find(el => el.interventionId === +checkedDiagnose).Children} state={checkedMiddleIntervention ? checkedMiddleIntervention : checkedIntervention} setState={checkInterventionHandle} arrProps={['interventionId', 'interventionTitle']} />}

            {checkedMiddleIntervention && <SelectInput title="intervention" arr={interventions.find(el => el.interventionId === +checkedDiagnose).Children.find(el => el.interventionId === +checkedMiddleIntervention).Children} state={checkedIntervention} setState={checkInterventionHandle} arrProps={['interventionId', 'interventionTitle']} />}


            <div className="textInputPart__flex">
              <label htmlFor="diagnoseInput">{t('diagnose')}: </label>
              <input type="text" id="diagnoseInput" value={diagnose} onChange={e => setDiagnose(e.target.value)} />
            </div>

            <div className="textInputPart__flex">
              <label htmlFor="zabeleska">{t('note')}</label>
              <textarea name="zabeleska" id="zabeleska" cols="40" rows="4" value={note} onChange={e => setNote(e.target.value)}></textarea>
            </div>

            <div className="textInputPart__flex">
              <label htmlFor="priceInput">{t('price')}(rsd):</label>
              <input type="text" id="priceInput" value={price} onChange={e => setPrice(e.target.value)} />
              <div className="red__star"><TiStarburst /></div>
            </div>

          </div>

          <div className="interventionFormRight">
            {checkedIntervention === "5" && <div><CariesChecker checkedArr={checkedToothParts} handler={toothPartHandler} />  <CariesTypeForm setState={setCheckedImplantType} /></div>}
            {checkedIntervention === "66" && <CariesChecker checkedArr={checkedToothParts} handler={toothPartHandler} />}
            {['71', '72'].includes(checkedIntervention) && <div className="length__input__div">{lengthCodes.map((title, i) => <LengthInputs title={title} handler={lengthCodeHandler} key={i} />)}</div>}
            {['12', '20', '35', '36'].includes(checkedIntervention) && <RootCanalChecker checkedArr={checkedRootCanal} rootHandler={rootCanalHandler} selectedTooth={selectedTooth} />}
          </div>


          <div className="anamnezaFormFooter">
            <input className="btn save" type="submit" value={t('save')} />
            <button className="btn sm">{t('discard')}</button>
            <div className="message__div">{message}</div>
          </div>
        </form>
      </div>
    </div>
  )
}