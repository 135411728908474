import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { MdCalendarViewMonth } from "react-icons/md";
import { PiRadioactiveFill } from "react-icons/pi";
import InterventionModal from "../../../../modals/interventionModal";
import { GrClose } from "react-icons/gr";
import "./odontogramTables.css";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../../../DataContext";

export default function OdontogramTables({ singleToothInterventions, deleteSingleToothIntervention, plannedInterventionsForTooth, deletePlannedInterventions, rtgRecordsForToothPage, setRtgRecordsForToothPage, plannedInterventionsForToothPage, setPlannedInterventionsForToothPage, singleToothOnInterventionsPage, setSingleToothOnInterventionsPage }) {
  const { t } = useTranslation();
  const { rtgRecordsForTooth, deleteRtgRecords, model, setModel, tempImgSrc, handleImageModal, requestConfig } = useContext(DataContext);
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interventionData, setInterventionData] = useState({});


  const modalHandle = (data) => {
    setIsModalOpen(true);
    setInterventionData(data);
  }


  useEffect(() => {
    const fetchData = async () => {
      if (rtgRecordsForTooth.length === 0) return;

      const imagesArray = await Promise.all(
        rtgRecordsForTooth.map(async ({ uploadedFile }) => {
          const response = await fetch(process.env.REACT_APP_LOCAL_URL + "fileUpload/getFile/" + uploadedFile.upoloadedFileId, requestConfig);
          const buffer = await response.arrayBuffer();
          const blob = new Blob([buffer], { type: uploadedFile.mimeType });
          return { url: URL.createObjectURL(blob), id: uploadedFile.upoloadedFileId };
        })
      );
      setImages(imagesArray);
    };

    fetchData();
  }, [rtgRecordsForTooth, requestConfig]);

  return (
    <div className="odontogram__tables__container">
      <InterventionModal open={isModalOpen} setOpen={() => setIsModalOpen(false)} data={interventionData} />
      <div className={model ? "model open" : "model"}>
        <img src={tempImgSrc} alt="Not Found"></img>
        <GrClose onClick={() => setModel(false)} />
      </div>

      <div className="table__tooth__history">
        <div className="doned__treatments__top">
          <div className="radioButton__flex">
            <FaUser />
            <h3>{t('teethHistory')}</h3>
          </div>

          <div className="page__selector__div">
            <p>{t('page')}</p>
            <button className="btn sm" onClick={() => setSingleToothOnInterventionsPage(old => old - 1)}>-</button>
            {singleToothOnInterventionsPage}
            <button className="btn sm" onClick={() => setSingleToothOnInterventionsPage(old => old + 1)}>+</button>
          </div>

        </div>

        <div className="table__data">
          <div className="table__tooth__history__top">
            <p>{t('date').toUpperCase()}</p>
            <p>{t('service').toUpperCase()}</p>
            <p>{t('price').toUpperCase()}</p>
            <p>{t('edit').toUpperCase()}</p>
            <p>{t('delete').toUpperCase()}</p>
          </div>{singleToothInterventions.length > 0 ?
            singleToothInterventions.map((obj, i) => {
              return <div className="table__tooth__history__top data" key={i} onClick={() => modalHandle(obj)}>
                <p>{obj.date.substring(0, 10)}</p>
                <p>{obj.intervention.interventionTitle}</p>
                <p>{obj.price}</p>
                <Link to={`/donedTreatmentsEdit/${obj.fk_toothId}/${obj.fk_interventionId}`} className="btn edit">{t('edit')}</Link>
                <button className="btn delete" onClick={() => deleteSingleToothIntervention(obj.toothOnInterventionId)}>{t('delete')}</button>
              </div>
            }) : <div className="table__future__exams__top no__data">{t('noInterventionsYet')}</div>}

        </div>
      </div>

      <div className="table__planed__interventions">
        <div className="doned__treatments__top">
          <div className="radioButton__flex">
            <MdCalendarViewMonth />
            <h3>{t('plannedInterventions')}</h3>
          </div>

          <div className="page__selector__div">
            <p>{t('page')}</p>
            <button className="btn sm" onClick={() => setPlannedInterventionsForToothPage(old => old - 1)}>-</button>
            <p>{plannedInterventionsForToothPage}</p>
            <button className="btn sm" onClick={() => setPlannedInterventionsForToothPage(old => old + 1)}>+</button>
          </div>

        </div>
        <div className="table__data">
          <div className="table__planed__interventions__top">
            <p>{t('service').toUpperCase()}</p>
            <p>{t('priority').toUpperCase()}</p>
            <p>{t('edit').toUpperCase()}</p>
            <p>{t('delete').toUpperCase()}</p>
          </div>
          {plannedInterventionsForTooth.length > 0 ? plannedInterventionsForTooth.map(({ plannedInterventionsId, interventionService, isPriority }, i) => {
            return <div className="table__planed__interventions__top  data" key={i}>
              <p>{interventionService}</p>
              <p>{isPriority ? t('yes') : t('no')}</p>
              <Link to={`/plannedInterventionsEdit/${plannedInterventionsId}`} className="btn edit">{t('edit')}</Link>
              <button className="btn delete" onClick={() => deletePlannedInterventions(plannedInterventionsId)}>{t('delete')}</button>
            </div>
          }) : <div className="table__future__exams__top no__data">{t('noInterventionsYet')}</div>}
        </div>

      </div>


      <div className="table__rtg">
        <div className="doned__treatments__top">
          <div className="radioButton__flex">
            <PiRadioactiveFill />
            <h3>RTG</h3>
          </div>

          <div className="page__selector__div">
            <p>{t('page')}</p>
            <button className="btn sm" onClick={() => setRtgRecordsForToothPage(old => old - 1)}>-</button>
            {rtgRecordsForToothPage}
            <button className="btn sm" onClick={() => setRtgRecordsForToothPage(old => old + 1)}>+</button>
          </div>

        </div>

        <div className="table__data">
          <div className="table__rtg__top">
            <p>{t('date').toUpperCase()}</p>
            <p>{t('document').toUpperCase()}</p>
            <p>{t('note').toUpperCase()}</p>
            <p>{t('edit').toUpperCase()}</p>
            <p>{t('delete').toUpperCase()}</p>
          </div>
          {rtgRecordsForTooth.length > 0 ?
            rtgRecordsForTooth.map(({ rtgRecordsId, rtgDate, rtgNote, uploadedFile }, i) => {
              const index = images ? images.findIndex(e => e.id === uploadedFile.upoloadedFileId) : -1;
              return <div className="table__rtg__top data" key={i}>
                <p>{rtgDate.substring(0, 10)}</p>
                <div className="rtg__image__div" onClick={() => handleImageModal(images[index] ? images[index].url : "")}>
                  <img src={images[index] ? images[index].url : ""} alt="no image" />
                </div>
                <p>{rtgNote}</p>
                <Link to={`/rtgEdit/${rtgRecordsId}`} className="btn edit">{t('edit')}</Link>
                <button className="btn delete" onClick={() => deleteRtgRecords(rtgRecordsId)}>{t('delete')}</button>
              </div>
            })
            : <div className="table__future__exams__top no__data">{t('noRTGRecordsYet')}</div>}
        </div>
      </div>
    </div>
  )
}