import "./rtg.css";
import {PiRadioactiveFill} from "react-icons/pi";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../DataContext";
import { GrClose } from "react-icons/gr";


export default function RTG({id, rtgRecordsPage, setRtgRecordsPage}){
  const {t} = useTranslation();
  const {rtgRecords, deleteRtgRecords, requestConfig, model, setModel, tempImgSrc, handleImageModal} = useContext(DataContext);
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    rtgRecords.map(({uploadedFile}) => {
      fetch(process.env.REACT_APP_LOCAL_URL+"fileUpload/getFile/"+uploadedFile.upoloadedFileId, requestConfig)
      .then(response => response.arrayBuffer())
      .then(response => new Blob([response], {type: uploadedFile.mimeType})).then(response => setImages(old => [...old, {url: URL.createObjectURL(response), id: uploadedFile.upoloadedFileId}]))
    })
  }, []);

  return (
    <div className="rtg__container">

      <div className={model ? "model open" : "model"}>
          <img src={tempImgSrc} alt="Not Found"></img>
          <GrClose onClick={() => setModel(false)}/>
      </div>

      <div className="rtg__table">
        <div className="rtg__table__top">
          <div className="rtg__table__top__left">
            <PiRadioactiveFill />
            <h3>{t('rtgRecords')}</h3>
          </div>

          <div className="page__selector__div">
            <p>{t('page')}</p>
            <button className="btn sm" onClick={() => setRtgRecordsPage(old => old-1)}>-</button>
            <p>{rtgRecordsPage}</p>
            <button className="btn sm" onClick={() => setRtgRecordsPage(old => old+1)}>+</button>
          </div>


          <Link to={`/rtgadd/${id}`} className="btn sm">+ {t('addRTGRecord')}</Link>
        </div>
        <div className="rtg__table__body">
          <div className="rtg__table__body__top">
            <p>{t('date').toUpperCase()}</p>
            <p>{t('document').toUpperCase()}</p>
            <p>{t('note').toUpperCase()}</p>
            <p>{t('toothNumber').toUpperCase()}</p>
            <p>{t('edit').toUpperCase()}</p>
            <p>{t('delete').toUpperCase()}</p>
          </div>{rtgRecords.length > 0 ?
          
          rtgRecords.map(({rtgRecordsId, rtgDate, rtgNote, tooth, uploadedFile},i)=>{
                  const index = images ? images.findIndex(e => e.id === uploadedFile.upoloadedFileId) : -1;
                  return <div className="rtg__table__body__top data" key={i}>
                  <p>{rtgDate ? rtgDate.substring(0,10): ""}</p>
                  <div className="rtg__image__div" onClick={() => handleImageModal(images[index] ? images[index].url : "")}>
                    <img src={images[index] ? images[index].url : ""} alt="no image" />
                  </div>
                  <p>{rtgNote}</p>
                  <p>{tooth.toothNum}</p>
                  <Link to={`/rtgEdit/${rtgRecordsId}`} className="btn edit">{t('edit')}</Link>
                  <button className="btn delete" onClick={()=>deleteRtgRecords(rtgRecordsId)}>{t('delete')}</button>
                </div>
              }) : <div className="table__future__exams__top no__data">{t('noRTGRecordsYet')}</div>}
        </div>
      </div>
    </div>
  )
}