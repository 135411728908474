import React, { useContext } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../DataContext';

ChartJS.register(CategoryScale,LinearScale,BarElement,Tooltip,Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export function InterventionThisYearVerticalBarChart() {
  const {t} = useTranslation();
  const {partialInterventionsSelectedYear} = useContext(DataContext);
  const labels = [t('january'),t('february'),t('march'),t('april'),t('may'),t('june'),t('july'),t('august'),t('september'),t('october'),t('november'),t('december')];

   const data = {
    labels,
    datasets: [
      {
        label: 'Intervencije',
        data: partialInterventionsSelectedYear,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  return <Bar options={options} data={data} />;
}