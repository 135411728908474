import { useTranslation } from "react-i18next"

export default function DeleteModal({ open, setOpen, handleDelete }) {
    const { t } = useTranslation();

    return (
        <div className={open ? "modal-overlay" : "modal-dispose"}>
            <div className="modal">
                <div className="modal__top">
                    <p>{t('delete')}</p>
                    <button className='btn sm' onClick={() => setOpen()}>X</button>
                </div>
                <div className="modal__body">
                    <div className="modal__body__items">
                        <div>{t('delete_modal_message')}</div>
                    </div>

                    <div>
                        <button className="btn sm" onClick={() => setOpen(false)}>{t('cancel')}</button>
                        <button className="btn delete" onClick={() => handleDelete()}>{t('delete')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}