import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./appointmentModal.css";
import { useContext } from "react";
import { DataContext } from "../../DataContext";


export default function AppointmentModal({open, setOpen, appointmentId}) {
  const {t} = useTranslation();
  const {deleteAppointment} = useContext(DataContext);
  return (
    <div className={open ? "modal-overlay" : "modal-dispose"}>
      <div className="modal">
        <div className="modal__top">
          <p>{t('edit')+"/"+t('delete')}</p>
          <button onClick={() => setOpen()} className="btn__close">X</button>
        </div>
        <div className="modal__body">
          <Link to={`/appointmentEdit/${appointmentId}`} className="btn btn edit">{t('edit')}</Link>
          <button onClick={() => {
            deleteAppointment(appointmentId);
            setOpen(false);
          }} className="btn btn delete">{t('delete')}</button>      
        </div>  
      </div>
    </div>
  );
}
