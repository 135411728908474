import { createContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axiosInstance from "./axios/axios.instance";

export const DataContext = createContext({
  ordinationId: "",

  doctors: [],
  fetchDoctors: () => { },
  doctor: {},
  setDoctor: () => { },
  createDoctor: () => { },
  editDoctor: () => { },
  deleteDoctor: () => { },
  fetchDoctor: () => { },

  chairs: [],
  fetchChairs: () => { },
  chair: {},
  setChair: () => { },
  fetchChair: () => { },
  createChair: () => { },
  editChair: () => { },
  deleteChair: () => { },

  workers: [],
  fetchWorkers: () => { },
  worker: {},
  setWorker: () => { },
  setWorkers: () => { },
  createWorker: () => { },
  editWorker: () => { },
  deleteWorker: () => { },
  fetchWorker: () => { },

  patients: [],
  fetchPatients: () => { },
  searchPatients: () => { },

  patient: {},
  fetchPatient: () => { },

  interventions: [],
  fetchInterventions: () => { },

  tooths: [],
  fetchTooths: () => { },
  createPatient: () => { },
  deletePatient: () => { },
  editPatient: () => { },

  fetchPdf: () => { },
  fetchPdfFiles: () => { },

  appointments: [],
  appointment: {},
  fetchAppointments: () => { },
  fetchAppointment: () => { },
  setAppointment: () => { },
  createAppointment: () => { },
  editAppointment: () => { },
  deleteAppointment: () => { },
  appointmentsForCalendar: [],
  fetchAppointmentsForCalendar: () => { },
  filterAppointmentsForCalendar: () => { },


  plannedInterventions: [],
  fetchPlannedInterventions: () => { },
  createPlannedInterventions: () => { },
  editPlannedInterventions: () => { },
  deletePlannedInterventions: () => { },
  fetchPlannedInterventionsForTooth: () => { },
  plannedInterventionsForTooth: [],

  notes: [],
  fetchNotes: () => { },
  createNotes: () => { },
  editNotes: () => { },
  deleteNotes: () => { },
  fetchNoteDocument: () => { },

  filesAndOpinions: [],
  fetchFilesAndOpinions: () => { },
  createFilesAndOpinions: () => { },
  editFilesAndOpinions: () => { },
  deleteFilesAndOpinions: () => { },

  anamnesis: {},
  fetchAnamnesis: () => { },
  editAnamnesis: () => { },

  rtgRecords: [],
  setRtgRecords: () => { },
  fetchRtgRecords: () => { },
  createRtgRecords: () => { },
  editRtgRecords: () => { },
  deleteRtgRecords: () => { },
  rtgRecordsForTooth: [],
  setRtgRecordsForTooth: () => { },
  fetchRtgRecordsForTooth: () => { },

  toothInterventions: [],
  fetchToothInterventions: () => { },

  singleToothInterventions: [],
  fetchSingleToothsInterventions: () => { },
  createSingleToothIntervention: () => { },
  editSingleToothIntervention: () => { },
  deleteSingleToothIntervention: () => { },

  implantSurface: [],
  implantType: "",
  fetchImplantSurface: () => { },

  cariesSurface: [],
  fetchCariesSurface: () => { },

  lengthCodes: [],
  fetchLengthCodes: () => { },

  endoRoot: [],
  fetchEndoRoot: () => { },

  malePatients: 0,
  fetchMalePatients: () => { },
  femalePatients: 0,
  fetchFemalePatients: () => { },
  agesData: [],
  fetchAgesData: () => { },

  top10Interventions: {},
  fetchTop10Interventions: () => { },

  numberOfInterventions: 0,
  fetchNumberOfInterventions: () => { },

  numberOfInterventionsYearMonth: 0,
  fetchNumberOfInterventionsYearMonth: () => { },

  numberOfInterventionsYear: 0,
  fetchNumberOfInterventionsYear: () => { },

  partialInterventionsSelectedMonth: [],
  fetchPartialInterventionsSelectedMonth: () => { },

  partialInterventionsSelectedYear: [],
  fetchPartialInterventionsSelectedYear: () => { },

  interventionsByDoctor: [],
  fetchInterventionsByDoctor: () => { },

  appointmentsYearMonth: 0,
  fetchAppointmentsYearMonth: () => { },

  numberOfPatients: 0,
  fetchNumberOfPatients: () => { },

  newPatients: 0,
  fetchNewPatients: () => { },

  patientsYearMonth: [],
  fetchPatientsYearMonth: () => { },

  treatedPatientsYearMonth: [],
  fetchTreatedPatients: () => { },

  treatedPatientsYear: 0,
  fetchTreatedPatientsYear: () => { },

  lastThreeYearsPatients: {},
  fetchLastThreeYearsPatients: () => { },

  image: "",
  fetchImage: () => { },
  handleImageModal: () => { },
  tempImgSrc: "",
  setTempImgSrc: () => { },
  model: false,
  setModel: () => { },

  message: '',
  setMessage: () => { },

  requestConfig: ""

})

export default function DataProvider({ children }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const registerUrl = process.env.REACT_APP_LOCAL_URL + "auth/signup/";
  const fetchDoctorsURL = process.env.REACT_APP_LOCAL_URL + "doctor/";
  const fetchChairsURL = process.env.REACT_APP_LOCAL_URL + "chair/";
  const fetchWorkersURL = process.env.REACT_APP_LOCAL_URL + "medicalWorker/";
  const fetchPatientsURL = process.env.REACT_APP_LOCAL_URL + 'patient/';
  const fetchInterventionsURL = process.env.REACT_APP_LOCAL_URL + 'intervention';
  const fetchToothsURL = process.env.REACT_APP_LOCAL_URL + 'tooth';
  const fetchPdfURL = process.env.REACT_APP_LOCAL_URL + 'patient/pdf/download';
  const fetchPdfFilesURL = process.env.REACT_APP_LOCAL_URL + "patient/pdf-files/download";
  const fetchAppointmentsURL = process.env.REACT_APP_LOCAL_URL + 'appointment';
  const plannedInterventionsURL = process.env.REACT_APP_LOCAL_URL + 'plannedInterventions';
  const notesURL = process.env.REACT_APP_LOCAL_URL + 'notes';
  const anamnesisURL = process.env.REACT_APP_LOCAL_URL + 'anamnesis';
  const filesAndOpinionsURL = process.env.REACT_APP_LOCAL_URL + 'filesAndOpinions';
  const rtgRecordsURL = process.env.REACT_APP_LOCAL_URL + 'rtgRecords';
  const toothInterventionsURL = process.env.REACT_APP_LOCAL_URL + 'toothOnInterventions';
  const implantSurfaceURL = process.env.REACT_APP_LOCAL_URL + "implantSurface";
  const cariesSurfaceURL = process.env.REACT_APP_LOCAL_URL + "cariesSurface";
  const lengthCodesURL = process.env.REACT_APP_LOCAL_URL + 'lengthCodes';
  const endoRootURL = process.env.REACT_APP_LOCAL_URL + 'endoRootCanal';
  const fileUploadURL = process.env.REACT_APP_LOCAL_URL + 'fileUpload';

  const ordinationId = localStorage.getItem('ordinationId');
  const accessToken = localStorage.getItem('access_token');

  const [doctors, setDoctors] = useState([]);
  const [doctor, setDoctor] = useState({});
  const [chairs, setChairs] = useState([]);
  const [chair, setChair] = useState({});
  const [workers, setWorkers] = useState([]);
  const [worker, setWorker] = useState({});
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [interventions, setInterventions] = useState([]);
  const [tooths, setTooths] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [appointmentsForCalendar, setAppointmentsForCalendar] = useState([]);
  const [plannedInterventions, setPlannedInterventions] = useState([]);
  const [plannedInterventionsForTooth, setPlannedInterventionsForTooth] = useState([]);
  const [notes, setNotes] = useState([]);
  const [filesAndOpinions, setFilesAndOpinions] = useState([]);
  const [anamnesis, setAnamnesis] = useState({});
  const [rtgRecords, setRtgRecords] = useState([]);
  const [rtgRecordsForTooth, setRtgRecordsForTooth] = useState([]);
  const [toothInterventions, setToothInterventions] = useState([]);
  const [singleToothInterventions, setSingleToothInterventions] = useState([]);
  const [implantSurface, setImplantSurface] = useState([]);
  const [implantType, setImplantType] = useState("");
  const [cariesSurface, setCariesSurface] = useState([]);
  const [lengthCodes, setLengthCodes] = useState([]);
  const [endoRoot, setEndoRoot] = useState([]);
  const [malePatients, setMalePatients] = useState(0);
  const [femalePatients, setFemalePatients] = useState(0);
  const [agesData, setAgesData] = useState([]);
  const [top10Interventions, setTop10Interventions] = useState({});
  const [numberOfInterventions, setNumberOfInterventions] = useState(0);
  const [numberOfInterventionsYearMonth, setNumberOfInterventionsYearMonth] = useState(0);
  const [numberOfInterventionsYear, setNumberOfInterventionsYear] = useState(0);
  const [partialInterventionsSelectedMonth, setPartialInterventionsSelectedMonth] = useState([]);
  const [partialInterventionsSelectedYear, setPartialInterventionsSelectedYear] = useState([]);
  const [interventionsByDoctor, setInterventionsByDoctor] = useState([]);
  const [appointmentsYearMonth, setAppointmentsYearMonth] = useState(0);
  const [numberOfPatients, setNumberOfPatients] = useState(0);
  const [newPatients, setNewPatients] = useState(0);
  const [patientsYearMonth, setPatientsYearMonth] = useState([]);
  const [treatedPatientsYear, setTreatedPatientsYear] = useState([]);
  const [treatedPatientsYearMonth, setTreatedPatientsYearMonth] = useState([]);
  const [lastThreeYearsPatients, setLastThreeYearsPatients] = useState({});
  const [image, setImage] = useState({});
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  const [message, setMessage] = useState('');

  const requestConfig = { headers: { 'Authorization': "Bearer " + accessToken } };
  const imageRequestConfig = { headers: { 'Authorization': "Bearer " + accessToken, 'Content-Type': 'multipart/form-data' } };


  const fetchDoctors = () => {
    axiosInstance.get(fetchDoctorsURL + "ordination/" + ordinationId, requestConfig).then(response => {
      setDoctors(response.data.result);
    }).catch(error => console.log(error));
  }

  const fetchDoctor = (doctorId) => {
    axiosInstance.get(fetchDoctorsURL + doctorId, requestConfig).then(response => {
      setDoctor(response.data)
    }).catch(error => console.log(error))
  }

  const editDoctor = (doctorId, doctorObj) => {
    if (doctorObj.doctorName === "" || doctorObj.doctorLastName === "") {
      setMessage(t('allFieldsRequired'))
    }
    axiosInstance.put(fetchDoctorsURL + doctorId, doctorObj, requestConfig).then(response => {
      setDoctors(old => old.map(obj => obj.doctorId === doctorId ? response.data : obj));
      setMessage("");
      alert('Doctor EDITED');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'));
    });
  }

  const createDoctor = (username, password, passwordConfirm, name, lastName) => {
    if (username === "" || password === "" || passwordConfirm === "" || name === "" || lastName === "") {
      setMessage(t('allFieldsRequired'));
      return;
    }
    if (password !== passwordConfirm) {
      setMessage(t('passwordMissmatch'))
      return;
    }
    const data = {
      username: username,
      password: password,
      name: name,
      lastName: lastName,
      ordinationId: ordinationId
    }
    axiosInstance.post(registerUrl + "doctor", data, requestConfig).then(response => {
      setDoctors(old => [...old, response.data]);
      setMessage("");
      alert('Doctor CREATED')
    }).catch(error => {
      if (error.response.status === 403) {
        alert(t('403Message'));
        setMessage("");
      }
    });
  }

  const deleteDoctor = (doctorId) => {
    axiosInstance.delete(fetchDoctorsURL + doctorId, requestConfig).then(response => {
      setDoctors(old => old.filter(obj => obj.doctorId !== doctorId));
      alert('Doctor DELETED')
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    })
  }

  const fetchChairs = () => {
    axiosInstance.get(fetchChairsURL + "ordination/" + ordinationId, requestConfig).then(response => {
      setChairs(response.data.result);
    }).catch(error => console.log(error));
  }

  const fetchChair = (chairId) => {
    axiosInstance.get(fetchChairsURL + chairId, requestConfig).then(response => {
      setChair(response.data);
    }).catch(error => console.log(error));
  }

  const createChair = (name) => {
    if (name === "") {
      setMessage(t('nameIsRequired'));
      return;
    }
    const data = {
      chairName: name,
      fk_ordinationId: ordinationId
    }
    axiosInstance.post(fetchChairsURL, data, requestConfig, requestConfig).then(response => {
      setChairs(old => [...old, response.data]);
      setMessage("");
      alert('chair CREATED');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editChair = (chairId, chairObj) => {
    if (chairObj.chairName === "") {
      setMessage(t('nameIsRequired'));
      return;
    }
    axiosInstance.put(fetchChairsURL + chairId, chairObj, requestConfig).then(response => {
      setChairs(old => old.map(obj => obj.chairId === chairId ? response.data : obj));
      setMessage("");
      alert("chair EDITED");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteChair = (chairId) => {
    axiosInstance.delete(fetchChairsURL + chairId, requestConfig).then(response => {
      setChairs(old => old.filter(obj => obj.chairId !== chairId));
      alert("chair DELETED");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchWorkers = () => {
    axiosInstance.get(fetchWorkersURL + "ordination/" + ordinationId, requestConfig).then(response => {
      setWorkers(response.data);
    }).catch(error => console.log(error));
  }

  const fetchWorker = (workerId) => {
    axiosInstance.get(fetchWorkersURL + workerId, requestConfig).then(response => {
      setWorker(response.data)
    }).catch(error => console.log(error))
  }

  const editWorker = (workerId, workerObj) => {
    if (workerObj.medicalWorkerName === "" || workerObj.medicalWorkerLastName === "") {
      setMessage(t('allFieldsRequired'))
    }
    axiosInstance.put(fetchWorkersURL + workerId, workerObj, requestConfig).then(response => {
      setWorkers(old => old.map(obj => obj.medicalWorkerId === workerId ? response.data : obj));
      setMessage("");
      alert('Worker EDITED');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'));
    });
  }

  const createWorker = (username, password, passwordConfirm, name, lastName) => {
    if (username === "" || password === "" || passwordConfirm === "" || name === "" || lastName === "") {
      setMessage(t('allFieldsRequired'));
      return;
    }
    if (password !== passwordConfirm) {
      setMessage(t('passwordMissmatch'))
      return;
    }
    const data = {
      username: username,
      password: password,
      name: name,
      lastName: lastName,
      ordinationId: ordinationId
    }
    axiosInstance.post(registerUrl + "worker", data, requestConfig).then(response => {
      setWorkers(old => [...old, response.data]);
      setMessage("");
      alert('Worker CREATED')
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteWorker = (workerId) => {
    axiosInstance.delete(fetchWorkersURL + workerId, requestConfig).then(() => {
      setWorkers(old => old.filter(obj => obj.medicalWorkerId !== workerId));
      alert("worker deleted")
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchPatients = (page, amount) => {
    axiosInstance.get(fetchPatientsURL + "limit/" + page + "/" + amount + "/" + ordinationId, requestConfig).then((response) => {
      setPatients(response.data.result);
    }).catch((error) => {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) console.log('failed');
      else console.log('failed');
    });
  }

  const searchPatients = (name, lastName) => {
    const data = {
      fk_ordinationId: ordinationId,
      nameQuery: name,
      lastNameQuery: lastName
    }

    axiosInstance.post(fetchPatientsURL + "search", data, requestConfig).then(response => {
      setPatients(response.data)
    }).catch(error => console.log(error))
  }

  const fetchPatient = (id) => {
    const idPatientURL = fetchPatientsURL + id;

    axiosInstance.get(idPatientURL, requestConfig).then((response) => {
      setPatient(response.data);
    }).catch((error) => {
      console.log(error);
    })
  }

  const createPatient = (name, lastName, birthday, gender, jmbg, parentName, adress, verbalAgreement, notedAgreement, mobilePhone, housePhone, email, image) => {
    if (name === "" || lastName === "" || birthday === "" || gender === "" || adress === "") {
      setMessage(t('notNullFields'));
      return;
    }

    const requestBody = {
      fk_ordinationId: ordinationId,
      name: name,
      lastName: lastName,
      birthday: new Date(birthday),
      gender: gender,
      jmbg: jmbg,
      parentName: parentName,
      adress: adress,
      verbalAgreement: verbalAgreement,
      notedAgreement: notedAgreement,
      mobilePhone: mobilePhone,
      housePhone: housePhone,
      email: email,
      image: image.name
    }

    axiosInstance.post(fetchPatientsURL, requestBody, requestConfig).then(response => {
      setPatients(old => [...old, response.data]);
      setMessage("");

      const imageRequestConfig = { headers: { 'Authorization': "Bearer " + accessToken, 'Content-Type': 'multipart/form-data' } };
      axiosInstance.post(fileUploadURL + "/upload/" + response.data.patientId, { "document": image }, imageRequestConfig);
      navigate('/patients');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deletePatient = (id) => {
    const idPatientURL = fetchPatientsURL + id;

    axiosInstance.delete(idPatientURL, requestConfig).then((response) => {
      setPatients(old => old.filter(obj => obj.patientId !== id));
      alert(`Pacijent ${response.data.name} ${response.data.lastName} je obrisan.`);
      navigate('/patients');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editPatient = (id, name, lastName, birthday, gender, jmbg, parentName, adress, verbalAgreement, notedAgreement, mobilePhone, housePhone, email, image) => {
    if (name === "" || lastName === "" || birthday === "" || gender === "" || adress === "") {
      setMessage(t('notNullFields'));
      return;
    }

    const idPatientURL = fetchPatientsURL + id;

    const requestBody = {
      name: name,
      lastName: lastName,
      birthday: new Date(birthday),
      gender: gender,
      jmbg: jmbg,
      parentName: parentName,
      adress: adress,
      verbalAgreement: verbalAgreement,
      notedAgreement: notedAgreement,
      mobilePhone: mobilePhone,
      housePhone: housePhone,
      email: email,
      image: image
    }

    axiosInstance.put(idPatientURL, requestBody, requestConfig).then((response) => {
      setPatients(old => old.map(obj => obj.patientId === id ? response.data : obj));
      setMessage("");
      navigate(`/patient/${id}`);
      alert(`Pacijent ${response.data.name} ${response.data.lastName} izmenjen.`);
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchAppointments = (patientId, page) => {
    axiosInstance.get(fetchAppointmentsURL + "/" + patientId + "/" + page, requestConfig).then(response => {
      setAppointments(response.data);
    }).catch(error => console.log(error))
  }

  const fetchAppointment = (appointmentId) => {
    axiosInstance.get(fetchAppointmentsURL + "/unique/" + appointmentId, requestConfig).then(response => {
      setAppointment(response.data)
    }).catch(error => console.log(error));
  }

  const createAppointment = (id, doctorId, chairId, service, startDate, note, endDate) => {
    if (id === "" || doctorId === "" || chairId === "" || service === "" || startDate === "" || endDate === "") {
      setMessage(t('notNullFields'));
      return;
    }

    const data = {
      fk_ordinationId: ordinationId,
      fk_patientId: id,
      fk_doctorId: doctorId,
      fk_chairId: Number(chairId),
      service: service,
      startDate: new Date(startDate),
      note: note,
      endDate: new Date(endDate)
    }

    axiosInstance.post(fetchAppointmentsURL, data, requestConfig).then(response => {
      setAppointmentsForCalendar(old => [...old, { title: response.data.service + " - " + response.data.service, start: new Date(response.data.startDate), end: new Date(response.data.endDate) }])
      alert(response.data);
      setMessage('')
      navigate('/ordination');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editAppointment = (appointmentId, appointmentObj) => {
    const { fk_ordinationId, fk_patientId, fk_doctorId, fk_chairId, service, startDate, endDate } = appointmentObj;
    if (fk_ordinationId === "" || fk_patientId === "" || fk_doctorId === "" || fk_chairId === "" || service === "" || startDate === "" || endDate === "") {
      setMessage('notNullFields');
      return;
    }
    const data = { ...appointmentObj, startDate: new Date(appointmentObj.startDate), endDate: new Date(appointmentObj.endDate) }

    axiosInstance.put(fetchAppointmentsURL + "/" + appointmentId, data, requestConfig).then(response => {
      setAppointmentsForCalendar(old => old.map(obj => obj.appointmentId === appointmentId ? response.data : obj));
      alert("Appointment edited");
      navigate('/ordination');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteAppointment = (appointmentId) => {
    axiosInstance.delete(fetchAppointmentsURL + "/" + appointmentId, requestConfig).then(response => {
      setAppointmentsForCalendar(old => old.filter(obj => obj.appointmentId !== appointmentId));
      alert("Appointment deleted");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchAppointmentsForCalendar = (startDate, endDate) => {
    axiosInstance.get(fetchAppointmentsURL + "/calendar/" + startDate + "/" + endDate + "/" + ordinationId, requestConfig).then(response => {
      setAppointmentsForCalendar(response.data.map(date => {
        return {
          appointmentId: date.appointmentId,
          chair: date.fk_chairId,
          title: date.doctor.doctorName + " - " + date.service,
          start: new Date(date.startDate),
          end: new Date(date.endDate)
        }
      }));
    }).catch(error => console.log(error));
  }

  const filterAppointmentsForCalendar = (doctorId, chairId, startDate, endDate) => {
    axiosInstance.get(fetchAppointmentsURL + "/filter/" + doctorId + "/" + chairId + "/" + startDate + "/" + endDate + "/" + ordinationId, requestConfig).then(response => {
      setAppointmentsForCalendar(response.data.map(date => {
        return {
          appointmentId: date.appointmentId,
          chair: date.fk_chairId,
          title: date.doctor.doctorName + " - " + date.service,
          start: new Date(date.startDate),
          end: new Date(date.endDate)
        }
      }))
    }).catch(error => console.log(error));
  }

  const fetchPlannedInterventions = (patientId, page) => {
    axiosInstance.get(plannedInterventionsURL + "/patient/" + patientId + "/" + page, requestConfig).then(response => {
      setPlannedInterventions(response.data);
    }).catch(error => console.log(error))
  }

  const fetchPlannedInterventionsForTooth = (toothId, page) => {
    axiosInstance.get(plannedInterventionsURL + "/tooth/" + toothId + "/" + page, requestConfig).then(response => {
      setPlannedInterventionsForTooth(response.data);
    }).catch(error => console.log(error));
  }

  const createPlannedInterventions = (patientId, toothId, service, isPriority) => {
    if (patientId === "" || toothId === "" || service === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = {
      fk_patientId: patientId,
      fk_toothId: toothId,
      interventionService: service,
      isPriority: isPriority
    }

    axiosInstance.post(plannedInterventionsURL, data, requestConfig).then(response => {
      setPlannedInterventions(old => [...old, response.data]);
      setMessage("");
      alert('Uspesno dodata planirana intervencija');
      navigate('/patient/' + patientId);
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editPlannedInterventions = (plannedInterventionsId, interventionService, isPriority) => {
    if (plannedInterventionsId === "" || interventionService === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = {
      plannedInterventionsId: plannedInterventionsId,
      interventionService: interventionService,
      isPriority: isPriority
    }
    axiosInstance.put(plannedInterventionsURL + "/" + plannedInterventionsId, data, requestConfig).then(response => {
      setPlannedInterventions(old => old.map(obj => obj.plannedInterventionsId === response.data.plannedInterventionsId ? response.data : obj));
      setMessage("");
      alert("Planned intervention edited");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deletePlannedInterventions = (plannedInterventionsId) => {
    axiosInstance.delete(plannedInterventionsURL + "/" + plannedInterventionsId, requestConfig).then(response => {
      setPlannedInterventions(old => old.filter(obj => obj.plannedInterventionsId !== plannedInterventionsId));
      alert('Planned intervention deleted');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchNotes = (patientId, page) => {
    axiosInstance.get(notesURL + "/" + patientId + "/" + page, requestConfig).then(response => {
      setNotes(response.data)
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const createNotes = (patientId, date, type, text, document) => {
    if (patientId === "" || date === "" || type === "" || text === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = [
      {
        fk_patientId: patientId,
        noteDate: new Date(date),
        noteType: type,
        noteText: text,
        noteDocument: document ? document.name : ""
      },
      {
        name: document.name,
        size: document.size,
        mimeType: document.type
      }
    ]

    axiosInstance.post(notesURL, data, requestConfig).then(response => {
      setNotes(old => [...old, response.data]);
      setMessage("");

      if (document) {
        axiosInstance.post(fileUploadURL + "/upload/" + response.data.fk_uploadedFileId, { "document": document }, imageRequestConfig)
      }
      alert("Uspesno dodata zabeleska");
      navigate('/patient/' + patientId);
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editNotes = (noteId, noteDate, noteType, noteText, noteDocument) => {
    if (noteId === "" || noteDate === "" || noteType === "" || noteText === "") {
      setMessage(t('notNullFields'))
    }
    const data = {
      noteId: noteId,
      noteDate: new Date(noteDate),
      noteType: noteType,
      noteText: noteText,
      noteDocument: noteDocument
    }

    axiosInstance.put(notesURL + "/" + noteId, data, requestConfig).then(response => {
      setNotes(old => old.map(obj => obj.noteId === response.data.noteId ? response.data : obj));
      setMessage("")
      alert('Note changed');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteNotes = (noteId) => {
    axiosInstance.delete(notesURL + "/" + noteId, requestConfig).then(response => {
      setNotes(old => old.filter(obj => obj.noteId !== noteId));
      alert("Note deleted");

      if (response.data.noteDocument) axiosInstance.delete(fileUploadURL + "/" + response.data.noteDocument, imageRequestConfig);

    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchNoteDocument = (documentName, mimeType) => {
    fetch(fileUploadURL + "/getFile/" + documentName, imageRequestConfig)
      .then(response => response.arrayBuffer())
      .then(res => new Blob([res], { type: mimeType }))
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        let tab = window;
        tab.location.href = file;
      });
  }

  const fetchFilesAndOpinions = (patientId, page) => {
    axiosInstance.get(filesAndOpinionsURL + "/" + patientId + "/" + page, requestConfig).then(response => {
      setFilesAndOpinions(response.data)
    }).catch(error => console.log(error));
  }

  const createFilesAndOpinions = (patientId, date, diagnose, therapy, doctor) => {
    if (patientId === "" || date === "" || diagnose === "" || therapy === "" || doctor === "") {
      setMessage(t('allFieldsRequired'));
      return;
    }
    const data = {
      fk_patientId: patientId,
      fk_doctorId: doctor,
      date: new Date(date),
      diagnose: diagnose,
      therapy: therapy,
    }

    axiosInstance.post(filesAndOpinionsURL, data, requestConfig).then(response => {
      setFilesAndOpinions(old => [...old, response.data]);
      setMessage("")
      alert('File created');
      navigate('/patient/' + patientId);
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editFilesAndOpinions = (fileId, date, diagnose, therapy, doctor) => {
    if (fileId === "" || date === "" || diagnose === "" || therapy === "" || doctor === "") {
      setMessage(t('allFieldsRequired'));
      return;
    }
    const data = {
      fileId: fileId,
      date: new Date(date),
      diagnose: diagnose,
      therapy: therapy,
      doctor: doctor
    }

    axiosInstance.put(filesAndOpinionsURL + "/" + fileId, data, requestConfig).then(response => {
      setFilesAndOpinions(old => old.map(obj => obj.fileId === fileId ? response.data : obj));
      setMessage('')
      alert('File Updated');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteFilesAndOpinions = (fileId) => {
    axiosInstance.delete(filesAndOpinionsURL + "/" + fileId, requestConfig).then(response => {
      setFilesAndOpinions(old => old.filter(obj => obj.fileId !== fileId));
      alert("fileDeleted");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchAnamnesis = (patientId) => {
    axiosInstance.get(anamnesisURL + "/" + patientId, requestConfig).then(response => {
      setAnamnesis(response.data)
    }).catch(error => console.log(error))
  }

  const editAnamnesis = (patientId, warning, bloodType, alergicTo, hadPainWeeklyFaceJaw, hadPainWeeklyChewing, jawBlockWeekly, chronicDiseases, infectiveDiseases, otherDiseases, permanentTherapy, hadGumSurgery, hadGumBleed, hadOtherOperations, toothSensitivity, isSmoker, wasInHospital, additionalInfo) => {
    const data = {
      fk_patientId: patientId,
      warning: warning,
      bloodType: bloodType,
      alergicTo: alergicTo,
      hadPainWeeklyFaceJaw: hadPainWeeklyFaceJaw,
      hadPainWeeklyChewing: hadPainWeeklyChewing,
      jawBlockWeekly: jawBlockWeekly,
      chronicDiseases: chronicDiseases,
      infectiveDiseases: infectiveDiseases,
      otherDiseases: otherDiseases,
      permanentTherapy: permanentTherapy,
      hadGumSurgery: hadGumSurgery,
      hadGumBleed: hadGumBleed,
      hadOtherOperations: hadOtherOperations,
      toothSensitivity: toothSensitivity,
      isSmoker: isSmoker,
      wasInHospital: wasInHospital,
      additionalInfo: additionalInfo
    }

    axiosInstance.put(anamnesisURL + "/" + patientId, data, requestConfig).then(response => {
      setAnamnesis(old => response.data);
      alert('Anamnesis Changed');
      navigate('/patient/' + patientId)
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchRtgRecords = (patientId, page) => {
    axiosInstance.get(rtgRecordsURL + "/patient/" + patientId + "/" + page, requestConfig).then(response => {
      setRtgRecords(response.data);
    }).catch(error => console.log(error));
  }

  const fetchRtgRecordsForTooth = (toothId, page) => {
    axiosInstance.get(rtgRecordsURL + "/tooth/" + toothId + "/" + page, requestConfig).then(response => {
      setRtgRecordsForTooth(response.data);
      console.log(response.data);
    }).catch(error => console.log(error));
  }

  const createRtgRecords = (patientId, toothId, date, document, note, toothNumber) => {
    if (patientId === "" || toothId === "" || date === "" || !document || toothNumber === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = [
      {
        fk_patientId: patientId,
        fk_toothId: toothId,
        rtgDate: new Date(date),
        rtgDocument: document.name,
        rtgNote: note
      },
      {
        name: document.name,
        size: document.size,
        mimeType: document.type
      }
    ]
    axiosInstance.post(rtgRecordsURL, data, requestConfig).then(response => {
      setRtgRecords(old => [...old, response.data]);

      axiosInstance.post(fileUploadURL + "/upload/" + response.data.fk_uploadedFileId, { "document": document }, imageRequestConfig)
        .then(() => { }).catch(error => console.log(error));

      setMessage("");
      alert("RTG record added");
      navigate('/patient/' + patientId);
    }).catch(error => {
      console.log(error);
      if (error.response.status === 403) {
        alert(t('403Message'));
      }
    });
  }

  const editRtgRecords = (rtgRecordsId, date, document, note, toothNumber) => {
    if (rtgRecordsId === "" || date === "" || document === "" || toothNumber === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = {
      rtgRecordsId: rtgRecordsId,
      rtgDate: new Date(date),
      rtgDocument: document,
      rtgNote: note,
      toothNumber: toothNumber
    }

    axiosInstance.put(rtgRecordsURL + "/" + rtgRecordsId, data, requestConfig).then(response => {
      setRtgRecords(old => old.map(obj => obj.rtgRecordsId === rtgRecordsId ? response.data : obj));
      setMessage("");
      alert('RTG Record edited')
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteRtgRecords = (rtgRecordsId) => {
    axiosInstance.delete(rtgRecordsURL + "/" + rtgRecordsId, requestConfig).then(response => {
      setRtgRecords(old => old.filter(obj => obj.rtgRecordsId !== rtgRecordsId));
      alert("RTG Record deleted")
      axiosInstance.delete(fileUploadURL + "/" + rtgRecordsId + ".jpg", requestConfig)
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchToothInterventions = (patientId, page) => {
    axiosInstance.get(toothInterventionsURL + "/all/" + patientId + "/" + page, requestConfig).then(response => {
      setToothInterventions(response.data.result);
    }).catch(error => console.log(error))
  }

  const createSingleToothIntervention = (patientId, doctorId, tooths, interventionId, date, diagnose, note, price, checkedToothParts, checkedCariesType, checkedLengthCodes, checkedRootCanal) => {
    if (doctorId === "" || interventionId === "" || date === "" || price === "") {
      setMessage(t('notNullFields'));
      return;
    }
    const data = [{
      fk_ordinationId: ordinationId,
      fk_patientId: patientId,
      fk_doctorId: doctorId,
      fk_interventionId: Number(interventionId),
      tooth: tooths,
      date: new Date(date),
      dignose: diagnose,
      note: note,
      price: price,
    },
    { checkedToothParts: checkedToothParts },
    { checkedCariesType: checkedCariesType },
    { checkedLengthCodes: checkedLengthCodes },
    { checkedRootCanal: checkedRootCanal }
    ]

    axiosInstance.post(toothInterventionsURL, data, requestConfig).then(response => {
      setSingleToothInterventions(old => [...old, response.data]);
      setToothInterventions(old => [...old, response.data])
      if (response.data.tooth) {
        setTooths(old => old.map(obj => obj.toothId === response.data.tooth.toothId ? response.data.tooth : obj));
      }

      if (interventionId === "1") {
        setImplantSurface(checkedToothParts);
        setImplantType(checkedCariesType);
      }
      if (interventionId === "29") setCariesSurface(checkedToothParts);
      if (interventionId === "19") setLengthCodes(checkedLengthCodes);
      if (['4', '5', '12', '20', '35', '36'].includes(interventionId)) setEndoRoot(checkedRootCanal);

      setMessage("");
      alert("Intervencija dodata");

      if (checkedToothParts.toString() === "undefined" && checkedLengthCodes.toString() === "undefined") navigate('/patient/' + patientId);
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const editSingleToothIntervention = (toothOnInterventionId, date, diagnose, note, price) => {
    const data = {
      toothOnInterventionId,
      date: new Date(date),
      dignose: diagnose,
      note: note,
      price: price
    }
    axiosInstance.put(toothInterventionsURL, data, requestConfig).then(response => {
      setSingleToothInterventions(old => old.map(obj => obj.toothOnInterventionId === toothOnInterventionId ? response.data : obj));
      alert("Intervencija izmenjena");
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const deleteSingleToothIntervention = (toothOnInterventionId) => {
    axiosInstance.delete(toothInterventionsURL + "/" + toothOnInterventionId, requestConfig).then((response) => {
      setSingleToothInterventions(old => old.filter(obj => obj.toothOnInterventionId !== toothOnInterventionId));
      setToothInterventions(old => old.filter(obj => obj.toothOnInterventionId !== toothOnInterventionId));
      if (response.data.tooth) {
        setTooths(old => old.map(obj => obj.toothId === response.data.tooth.toothId ? response.data.tooth : obj));
      }
      if (response.data.fk_interventionId === 1) setImplantSurface([false, false, false, false, false]);
      if (response.data.fk_interventionId === 29) setCariesSurface([false, false, false, false, false]);
      if (response.data.fk_interventionId === 19) setLengthCodes(["", "", "", "", "", "", "", "", "", ""]);
      if ([4, 5, 12, 20, 35, 36].includes(response.data.fk_interventionId)) setEndoRoot([false, false, false]);

      alert('Donned treatment deleted');
    }).catch(error => {
      if (error.response.status === 403) alert(t('403Message'))
    });
  }

  const fetchSingleToothsInterventions = (tooths, page) => {
    const data = {
      tooths,
      page
    }
    axiosInstance.post(toothInterventionsURL + "/selected-tooths-interventions", data, requestConfig).then(response => {
      setSingleToothInterventions(response.data);
    }).catch(error => {
      console.log(error);
    });
  }

  const fetchImplantSurface = (toothId) => {
    axiosInstance.get(implantSurfaceURL + "/" + toothId, requestConfig).then(response => {
      setImplantSurface(response.data[0]);
      setImplantType(response.data[1]);
    }).catch(error => console.log(error));
  }

  const fetchCariesSurface = (toothId) => {
    axiosInstance.get(cariesSurfaceURL + "/" + toothId, requestConfig).then(response => {
      setCariesSurface(response.data);
    }).catch(error => console.log(error));
  }

  const fetchLengthCodes = (toothId) => {
    axiosInstance.get(lengthCodesURL + "/" + toothId, requestConfig).then(response => {
      setLengthCodes(response.data);
    }).catch(error => console.log(error));
  }

  const fetchEndoRoot = (toothId) => {
    axiosInstance.get(endoRootURL + "/" + toothId, requestConfig).then(response => {
      setEndoRoot(response.data);
    }).catch(error => console.log(error));
  }

  const fetchInterventions = () => {
    axiosInstance.get(fetchInterventionsURL, requestConfig).then((response) => {
      setInterventions(response.data.result)
    }).catch((error) => console.log(error))
  }

  const fetchTooths = (patientId) => {
    axiosInstance.get(fetchToothsURL + "/" + patientId, requestConfig).then((response) => {
      setTooths(response.data)
    }).catch((error) => {
      console.log(error);
    })
  }

  const fetchPdf = (locale, patientId) => {
    fetch(fetchPdfURL + "/" + locale + "/" + ordinationId + "/" + patientId, requestConfig)
      .then(res => res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        let tab = window.open();
        tab.location.href = file;
      });
  }

  const fetchPdfFiles = (locale, fileId) => {
    fetch(fetchPdfFilesURL + "/" + locale + "/" + fileId + "/" + ordinationId, requestConfig)
      .then(res => res.blob())
      .then(blob => {
        const file = window.URL.createObjectURL(blob);
        let tab = window.open();
        tab.location.href = file;
      });
  }

  const fetchImage = (imageName) => {
    fetch(fileUploadURL + "/getFile/" + imageName + ".jpeg", requestConfig)
      .then(response => response.blob())
      .then(blob => setImage(URL.createObjectURL(blob)))
      .catch(error => console.log(error));
  }

  const handleImageModal = (path) => {
    setTempImgSrc(path);
    setModel(true);
  }

  const fetchMalePatients = (gender) => {
    axiosInstance.get(fetchPatientsURL + "gender/" + gender + "/" + ordinationId, requestConfig).then(response => {
      setMalePatients(response.data);
    }).catch(error => console.log(error));
  }

  const fetchFemalePatients = (gender) => {
    axiosInstance.get(fetchPatientsURL + "gender/" + gender + "/" + ordinationId, requestConfig).then(response => {
      setFemalePatients(response.data);
    }).catch(error => console.log(error));
  }

  const fetchAgesData = () => {
    axiosInstance.get(fetchPatientsURL + "age/" + ordinationId, requestConfig).then(response => {
      setAgesData(response.data);
    }).catch(error => console.log(error))
  }

  const fetchTop10Interventions = () => {
    axiosInstance.get(toothInterventionsURL + "/top-interventions/" + ordinationId, requestConfig).then(response => {
      setTop10Interventions(response.data);
    })
  }

  const fetchNumberOfInterventions = () => {
    axiosInstance.get(toothInterventionsURL + "/all-interventions/" + ordinationId, requestConfig).then(response => {
      setNumberOfInterventions(response.data)
    }).catch(error => console.log(error))
  }

  const fetchNumberOfInterventionsYearMonth = (year, month) => {
    axiosInstance.get(toothInterventionsURL + "/count/" + year + "/" + month + "/" + ordinationId, requestConfig).then(response => {
      setNumberOfInterventionsYearMonth(response.data);
    }).catch(error => console.log(error));
  }

  const fetchNumberOfInterventionsYear = (year) => {
    axiosInstance.get(toothInterventionsURL + "/count-year/" + year + "/" + ordinationId, requestConfig).then(response => {
      setNumberOfInterventionsYear(response.data);
    }).catch(error => console.log(error))
  }

  const fetchPartialInterventionsSelectedMonth = (year, month) => {
    axiosInstance.get(toothInterventionsURL + "/count-partial/" + year + "/" + month + "/" + ordinationId, requestConfig).then(response => {
      setPartialInterventionsSelectedMonth(response.data);
    }).catch(error => console.log(error))
  }

  const fetchPartialInterventionsSelectedYear = (year) => {
    axiosInstance.get(toothInterventionsURL + "/count-year-partial/" + year + "/" + ordinationId, requestConfig).then(response => {
      setPartialInterventionsSelectedYear(response.data);
    }).catch(error => console.log(error));
  }

  const fetchInterventionsByDoctor = (year) => {
    axiosInstance.get(toothInterventionsURL + "/count-by-doctor/" + ordinationId + "/" + year, requestConfig).then(response => {
      setInterventionsByDoctor(response.data);
    }).catch(error => console.log(error));
  }

  const fetchAppointmentsYearMonth = (year, month) => {
    axiosInstance.get(fetchAppointmentsURL + "/count/" + year + "/" + month + "/" + ordinationId, requestConfig).then(response => {
      setAppointmentsYearMonth(response.data);
    }).catch(error => console.log(error));
  }

  const fetchNumberOfPatients = () => {
    axiosInstance.get(fetchPatientsURL + "all/" + ordinationId, requestConfig).then(response => {
      setNumberOfPatients(response.data)
    }).catch(error => console.log(error))
  }

  const fetchNewPatients = () => {
    axiosInstance.get(fetchPatientsURL + "new/" + ordinationId, requestConfig).then(response => {
      setNewPatients(response.data);
    }).catch(error => console.log(error))
  }

  const fetchPatientsYearMonth = (year, month) => {
    axiosInstance.get(fetchPatientsURL + "count/" + ordinationId + "/" + year + "/" + month, requestConfig).then(response => {
      setPatientsYearMonth(response.data);
    }).catch(error => console.log(error));
  }

  const fetchTreatedPatientsYear = (year) => {
    axiosInstance.get(fetchPatientsURL + "count-treated/" + ordinationId + "/" + year, requestConfig).then(response => {
      setTreatedPatientsYear(response.data);
    }).catch(error => console.log(error));
  }

  const fetchTreatedPatients = (year, month) => {
    axiosInstance.get(fetchPatientsURL + "count-treated/" + ordinationId + "/" + year + "/" + month, requestConfig).then(response => {
      setTreatedPatientsYearMonth(response.data);
    }).catch(error => console.log(error));
  }

  const fetchLastThreeYearsPatients = () => {
    axiosInstance.get(fetchPatientsURL + "count-last-three/" + ordinationId, requestConfig).then(response => {
      setLastThreeYearsPatients(response.data);
    }).catch(error => console.log(error));
  }


  const contextValue = {
    ordinationId: ordinationId,

    doctors: doctors,
    fetchDoctors,
    doctor: doctor,
    setDoctor,
    createDoctor,
    editDoctor,
    deleteDoctor,
    fetchDoctor,

    chairs: chairs,
    fetchChairs,
    chair: chair,
    setChair,
    fetchChair,
    createChair,
    editChair,
    deleteChair,

    workers: workers,
    fetchWorkers,
    worker: worker,
    setWorker,
    setWorkers,
    createWorker,
    editWorker,
    fetchWorker,
    deleteWorker,

    patients: patients,
    fetchPatients,
    searchPatients,

    patient: patient,
    fetchPatient,
    createPatient,
    deletePatient,
    editPatient,

    interventions: interventions,
    fetchInterventions,

    tooths: tooths,
    fetchTooths,

    fetchPdf,
    fetchPdfFiles,


    createAppointment,
    appointments: appointments,
    appointment: appointment,
    fetchAppointment,
    setAppointment,
    fetchAppointments,
    editAppointment,
    deleteAppointment,
    appointmentsForCalendar,
    fetchAppointmentsForCalendar,
    filterAppointmentsForCalendar,

    plannedInterventions: plannedInterventions,
    fetchPlannedInterventions,
    createPlannedInterventions,
    editPlannedInterventions,
    deletePlannedInterventions,
    plannedInterventionsForTooth: plannedInterventionsForTooth,
    fetchPlannedInterventionsForTooth,

    notes: notes,
    fetchNotes,
    createNotes,
    editNotes,
    deleteNotes,
    fetchNoteDocument,

    filesAndOpinions: filesAndOpinions,
    fetchFilesAndOpinions,
    createFilesAndOpinions,
    editFilesAndOpinions,
    deleteFilesAndOpinions,

    anamnesis: anamnesis,
    fetchAnamnesis,
    editAnamnesis,

    rtgRecords: rtgRecords,
    setRtgRecords,
    fetchRtgRecords,
    createRtgRecords,
    editRtgRecords,
    deleteRtgRecords,
    rtgRecordsForTooth: rtgRecordsForTooth,
    setRtgRecordsForTooth,
    fetchRtgRecordsForTooth,

    toothInterventions: toothInterventions,
    fetchToothInterventions,


    singleToothInterventions: singleToothInterventions,
    fetchSingleToothsInterventions,
    createSingleToothIntervention,
    editSingleToothIntervention,
    deleteSingleToothIntervention,

    implantSurface: implantSurface,
    implantType: implantType,
    fetchImplantSurface,

    cariesSurface: cariesSurface,
    fetchCariesSurface,

    lengthCodes: lengthCodes,
    fetchLengthCodes,

    endoRoot: endoRoot,
    fetchEndoRoot,

    malePatients: malePatients,
    fetchMalePatients,

    femalePatients: femalePatients,
    fetchFemalePatients,

    agesData,
    fetchAgesData,

    top10Interventions: top10Interventions,
    fetchTop10Interventions,

    numberOfInterventions: numberOfInterventions,
    fetchNumberOfInterventions,

    numberOfInterventionsYearMonth: numberOfInterventionsYearMonth,
    fetchNumberOfInterventionsYearMonth,

    numberOfInterventionsYear: numberOfInterventionsYear,
    fetchNumberOfInterventionsYear,

    partialInterventionsSelectedMonth: partialInterventionsSelectedMonth,
    fetchPartialInterventionsSelectedMonth,

    partialInterventionsSelectedYear: partialInterventionsSelectedYear,
    fetchPartialInterventionsSelectedYear,

    interventionsByDoctor: interventionsByDoctor,
    fetchInterventionsByDoctor,

    appointmentsYearMonth: appointmentsYearMonth,
    fetchAppointmentsYearMonth,

    numberOfPatients: numberOfPatients,
    fetchNumberOfPatients,

    newPatients: newPatients,
    fetchNewPatients,

    patientsYearMonth: patientsYearMonth,
    fetchPatientsYearMonth,

    treatedPatientsYear: treatedPatientsYear,
    fetchTreatedPatientsYear,

    treatedPatientsYearMonth: treatedPatientsYearMonth,
    fetchTreatedPatients,

    lastThreeYearsPatients: lastThreeYearsPatients,
    fetchLastThreeYearsPatients,

    image: image,
    fetchImage,
    handleImageModal,
    tempImgSrc: tempImgSrc,
    setTempImgSrc,
    model: model,
    setModel,

    message: message,
    setMessage,

    requestConfig: requestConfig
  }

  return (
    <DataContext.Provider value={contextValue}>
      {children}
    </DataContext.Provider>
  )
}