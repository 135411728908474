import { useEffect, useState } from "react";
import "./patientaddedit.css";
import DatePicker from 'react-datepicker';
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { TiStarburst } from "react-icons/ti";
import { DataContext } from "../../../DataContext";

export default function PatientAddEdit() {
      const { t } = useTranslation();
      const { createPatient, message, setMessage } = useContext(DataContext);
      const [firstName, setFirstName] = useState('');
      const [lastName, setLastName] = useState('');
      const [bornDate, setBornDate] = useState('');
      const [gender, setGender] = useState("male");
      const [jmbg, setJmbg] = useState("");
      const [parentName, setParentName] = useState("");
      const [address, setAddress] = useState("");
      const [verbalAprooval, setVerbalAprooval] = useState(false);
      const [writtenAprooval, setWrittenAprooval] = useState(false);
      const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
      const [phoneNumber, setPhoneNumber] = useState("");
      const [email, setEmail] = useState("");
      const [image, setImage] = useState("");

      useEffect(() => { setMessage('') })

      const patientAddEditHandle = (e) => {
            e.preventDefault();
            createPatient(firstName, lastName, bornDate, gender, jmbg, parentName, address, verbalAprooval, writtenAprooval, mobilePhoneNumber, phoneNumber, email, image);
      }

      const handleBornDate = (date) => {
            setBornDate(date);
      }

      return (
            <section className="container patientAddEdit__container">
                  <div className="patientAddEdit__top">
                        <h3>{t('create/edit_patients')}</h3>
                  </div>

                  <form className="patientAddEdit__form" onSubmit={patientAddEditHandle}>
                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input24">{t('name')}:</label>
                              <input type="text" name="firstName" id="input24" value={firstName} onChange={e => setFirstName(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input25">{t('lastName')}: </label>
                              <input type="text" name="lastName" id="input25" value={lastName} onChange={e => setLastName(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input26">{t('dateOfBirth')}:</label>
                              <DatePicker
                                    selected={bornDate}
                                    onChange={handleBornDate}
                                    dateFormat="dd.MM.yyyy"
                                    id="input26"
                                    placeholderText="DD.MM.YYYY"
                                    className="date__input"
                              />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="gender1">{t('gender')}:</label>
                              <select name="gender" id="gender1" value={gender} onChange={e => setGender(e.target.value)}>
                                    <option value="placeholder">-- {t('chooseGender')} --</option>
                                    <option value={t('male')}>{t('male')}</option>
                                    <option value={t('female')}>{t('female')}</option>
                              </select>
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input27">JMBG: </label>
                              <input type="text" name="jmbg" id="input27" value={jmbg} onChange={e => setJmbg(e.target.value)} />
                        </div>


                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input28">{t('parentName')}:</label>
                              <input type="text" name="parentName" id="input28" value={parentName} onChange={e => setParentName(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input29">{t('address')}: </label>
                              <input type="text" name="address" id="input29" value={address} onChange={e => setAddress(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input30">{t('verbalAgreement')}: </label>
                              <input className="chk__button" type="checkbox" name="verbalAprooval" id="input30" checked={verbalAprooval} onChange={e => setVerbalAprooval(e.target.checked)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input31">{t('notedAgreement')}:</label>
                              <input className="chk__button" type="checkbox" name="writtenAprooval" id="input31" checked={writtenAprooval} onChange={e => setWrittenAprooval(e.target.checked)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input32">{t('mobilePhone')}:</label>
                              <input type="text" name="mobilePhoneNumber" id="input32" value={mobilePhoneNumber} onChange={e => setMobilePhoneNumber(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input33">{t('housePhone')}:</label>
                              <input type="text" name="phoneNumber" id="input33" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input34">{t('email')}:</label>
                              <input type="text" name="email" id="input34" value={email} onChange={e => setEmail(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input35">{t('image')}: </label>
                              <input type="file" accept="image/png, image/jpeg" name="image" id="input35" onChange={e => setImage(e.target.files[e.target.files.length - 1])} />
                        </div>

                        <div className="anamnezaFormFooter">
                              <input className="btn save" type="submit" value={t('save')} />
                              <button className="btn sm">{t('discard')}</button>
                              <div className="message__div">{message}</div>
                        </div>

                  </form>
            </section>
      )
}