import { useContext, useEffect, useState } from 'react';
import './chairsAddEdit.css';
import { DataContext } from '../../../DataContext';
import { useTranslation } from 'react-i18next';
import ChairsEdit from './ChairsEdit';

export default function ChairsAdd(){
  const {t} = useTranslation();
  const {chairs, createChair, deleteChair, message, setMessage} = useContext(DataContext);

  const [name, setName] = useState('');
  const [chairId, setChairId] = useState('');
  const [editToggle, setEditToggle] = useState(false);

  useEffect(() => {setMessage("")}, []);

  const chairSubmitHandler = (e) => {
    e.preventDefault();
    createChair(name);
  }


  return(
    <section>
        <div className="container chair__container">
          <div className="all__chairs__view">
            <div className="all__chairs__table__top">
              <h2>{t('allChairs')}</h2>
            </div>
            <div className="all__chairs__table__body__top">
              <p>{t('name').toUpperCase()}</p>
            </div>
            <div>
              {chairs.map(({chairId, chairName}, i)=> {
                  return <div className='all__chairs__table__body__top  data' key={i}>
                    <p>{chairName}</p>
                    <button className='btn edit' onClick={() => {
                      setChairId(chairId);
                      setEditToggle(old => !old);
                    }}>{t('edit')}</button>
                    <button className='btn delete' onClick={() => deleteChair(chairId)}>{t('delete')}</button>
            </div>
              })}
            </div>
          </div>
          
          <div className="chair__add__container">
            <form onSubmit={chairSubmitHandler} className="chair__form">
                <div className="chair__form__top">
                  <h3>{t('addChair')}</h3>
                </div>

                <div className="chair__form__body">
                    <div className="radioButton__grid__twoCollumns">
                      <label htmlFor="chairName">{t('name')}: </label>
                      <input type="text" name="chairName" id="chairName" value={name} onChange={e => setName(e.target.value)}/>
                    </div>

                    <div className="newReservationFormFooter">
                        <input className="btn save" type="submit" value={t('add')} />
                        <div className="message__div">{message}</div>
                    </div>
                </div>
            </form>
                {editToggle && <ChairsEdit chairId={chairId}/>}
          </div>
        </div>
    </section>
  )
}