import "./navbar.css"
import { useState, useEffect, useContext } from "react"
import Logo from "../images/logo.png"
import { Link, NavLink } from "react-router-dom"
import { GoBook } from "react-icons/go"
import LocaleContext from "../LocaleContext"
import i18n from "../i18n"
import { useTranslation } from "react-i18next";
import { AuthContext } from "../AuthContext";
import { useNavigate } from "react-router-dom"
import axiosInstance from "../axios/axios.instance"


export default function Navbar() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isNavShowing, setIsNavShowing] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);
    const { locale, setLocale } = useContext(LocaleContext);
    const { logged, setLogged } = useContext(AuthContext);

    i18n.on('languageChanged', () => setLocale(i18n.language));

    const languageChangeHandle = (event) => {
        i18n.changeLanguage(event.target.value);
    }


    useEffect(() => {
        setWidth(window.innerWidth);
    }, [width]);


    const logoutHandle = (e) => {
        e.preventDefault();
        axiosInstance.get(process.env.REACT_APP_LOCAL_URL + "auth/logout", { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') } }).then(response => {
            alert(t('logOut'));
            localStorage.clear();
            setLogged(false);
            navigate('/');
        }).catch(error => console.log(error));
    }

    const unAuthenticatedTitle = (
        <p className="nav__p">
            {" - " + t('dentalOffice')}
        </p>
    );

    const AuthenticatedTitle = (
        <p className="nav__p__link" onClick={() => navigate('/passwordReset')}>
            {`${localStorage.getItem('role')}: ${localStorage.getItem('name')} ${localStorage.getItem('lastName')}`}
        </p>
    );

    return (
        <nav>
            <div className="container nav__container">
                <Link to="/" className="link">
                    <img src={Logo} alt="Nav Logo" className="logo" />
                </Link>

                {logged ? AuthenticatedTitle : unAuthenticatedTitle}

                <ul className={`nav__links ${isNavShowing ? "show__nav" : "hide__nav"}`}>

                    <li><NavLink to="/" onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('home')}</NavLink></li>
                    {logged && <li><NavLink to="/ordination" onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('ordination')}</NavLink></li>}
                    {logged && <li><NavLink to="/patients" onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('patients')}</NavLink></li>}
                    {logged && <li><NavLink to="/appointmentAdd/all" onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('newReservation')}</NavLink></li>}
                    {logged && <li><NavLink to="/statistic" onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('statistic')}</NavLink></li>}

                    {logged ?
                        <form onSubmit={logoutHandle}>
                            <input className="logout_navlink" type="submit" value={t('logout')} />
                        </form> :

                        <li><NavLink to={"/login"} onClick={() => setIsNavShowing(oldVal => false)} className={({ isActive }) => isActive ? "active-nav" : ""}>{t('login')}</NavLink></li>
                    }


                    <select className="lang__select" name="languageSelector" id="languageSelector" value={locale} onChange={languageChangeHandle}>
                        <option value="sr">Srpski</option>
                        <option value="en">English</option>
                    </select>
                </ul>
                <button onClick={() => setIsNavShowing(oldVal => !oldVal)} className="nav__toggle-btn">
                    <GoBook />
                </button>

            </div>
        </nav>
    )
}