import "./medicaldata.css";
import { useContext } from "react";
import {TbReportMedical} from "react-icons/tb";
import {GrDocumentText} from "react-icons/gr";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../../DataContext";
import LocaleContext from "../../../../LocaleContext";

export default function MedicalData({id, filesAndOpinionsPage, setFilesAndOpinionsPage}){
  const {t} = useTranslation();
  const {anamnesis, filesAndOpinions, deleteFilesAndOpinions, fetchPdfFiles} = useContext(DataContext);
  const {locale} = useContext(LocaleContext);
  const {warning, bloodType, alergicTo, hadPainWeeklyFaceJaw, hadPainWeeklyChewing, jawBlockWeekly, chronicDiseases, infectiveDiseases, otherDiseases, permanentTherapy, hadGumSurgery, hadGumBleed, hadOtherOperations, toothSensitivity,  isSmoker, wasInHospital, additionalInfo} = anamnesis;

  return (
    <div className="medicalData__container">
        <div className="medicalData__container__left">

          <div className="medicalRecordsTable">
            <div className="medicalRecordsTableTop">
              <div className="radioButton__flex">
                <TbReportMedical />
                <h3>{t('filesAndOpinions')}</h3>
              </div>

              <div className="page__selector__div">
                <p>{t('page')}</p>
                <button className="btn sm" onClick={() => setFilesAndOpinionsPage(old => old-1)}>-</button>
                <p>{filesAndOpinionsPage}</p>
                <button className="btn sm" onClick={() => setFilesAndOpinionsPage(old => old+1)}>+</button>
              </div>

              <Link to={`/filesAndOpinionsAdd/${id}`} className="btn sm">+ {t('add')}</Link>
            </div>
            <div className="medicalRecordsTableBody">
              <div className="medicalRecordsTableBodyTop">
                 <p>{t('date').toUpperCase()}</p>
                 <p>{t('diagnose').toUpperCase()}</p>
                 <p>{t('therapy').toUpperCase()}</p>
                 <p>DR</p>
                 <p>{t('print').toUpperCase()}</p>
                 <p>{t('edit').toUpperCase()}</p>
                 <p>{t('delete').toUpperCase()}</p>
              </div>{filesAndOpinions.length > 0 ? 
              filesAndOpinions.map(({fileId,date,diagnose,therapy,doctor},i)=>{
                  return <div className="table__future__exams__top data" key={i}>
                  <p>{date.substring(0,10)}</p>
                  <p>{diagnose.substring(0,10)}...</p>
                  <p>{therapy===""?"Nema beleške":therapy.substring(0,10)}...</p>
                  <p>{doctor.doctorName}</p>
                  <button className="btn print" onClick={() => fetchPdfFiles(locale, fileId)}>{t('print')}</button>
                  <Link to={`/filesAndOpinionsEdit/${fileId}`} className="btn edit">{t('edit')}</Link>
                  <button className="btn delete" onClick={()=>deleteFilesAndOpinions(fileId)}>{t('delete')}</button>
                </div>
                }): <div className="table__future__exams__top no__data">{t('noFilesYet')}</div>}
            </div>
          </div>

        </div>



        <div className="medicalData__container__right">
          <div className="medicalRecordsTable">
            <div className="medicalRecordsTableTop">
              <div className="radioButton__flex">
                <GrDocumentText />
                <h3>{t('anamnesis')}</h3>
              </div>
              <Link to={`/anamnezaedit/${id}`} className="btn sm">{t('edit')}</Link>
            </div>
            <div className="medicalRecordsTableBody">
              <p>{t('warning')}: <span>{warning}</span></p>
              <p>{t('bloodGroup')}: <span>{bloodType}</span></p>
              <p>{t('patientAlergicTo')}: <span>{alergicTo}</span></p>
              <p>{t('hadPainWeeklyFaceJaw')} <span>{hadPainWeeklyFaceJaw}</span></p>
              <p>{t('hadPainWeeklyChewing')} <span>{hadPainWeeklyChewing}</span></p>
              <p>{t('jawBlockWeekly')} <span>{jawBlockWeekly}</span></p>
              <p>{t('chronicDiseases')}: <span>{chronicDiseases}</span></p>
              <p>{t('infectiveDiseases')}: <span>{infectiveDiseases}</span></p>
              <p>{t('otherDiseases')}: <span>{otherDiseases}</span></p>
              <p>{t('permanentTherapy')}? <span>{permanentTherapy}</span></p>
              <p>{t('hadGumSurgery')}? <span>{hadGumSurgery?t('yes'):t('no')}</span></p>
              <p>{t('hadGumBleed')}? <span>{hadGumBleed?t('yes'):t('no')}</span></p>
              <p>{t('hadOtherSurgeries')}? <span>{hadOtherOperations}</span></p>
              <p>{t('toothSensitivity')}: <span>{toothSensitivity}</span></p>
              <p>{t('isSmoker')}? <span>{ isSmoker ? t('yes') : t('no')}</span></p>
              <p>{t('lastTwoYearsHospital')}? <span>{wasInHospital}</span></p>
              <p>{t('additionalInfo')}: <span>{additionalInfo}</span></p>
            </div>
          </div>
        </div>

    </div>
  )
}