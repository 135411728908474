import "./login.css";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../DataContext";
import axiosInstance from "../../axios/axios.instance";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const { setLogged } = useContext(AuthContext);
    const { message, setMessage } = useContext(DataContext);

    useEffect(() => setMessage(''), []);

    const loginHandler = (e) => {
        e.preventDefault();

        const loginData = {
            username: username,
            password: password
        }

        axiosInstance.post(process.env.REACT_APP_LOCAL_URL + "auth/signin", loginData).then(response => {

            alert("Logged succsesfully");
            localStorage.setItem('access_token', response.data.tokens.refreshToken);
            setLogged(true);

            localStorage.setItem('userId', response.data.user.userId);
            localStorage.setItem('ordinationId', response.data.user.ordination[0].ordinationId);
            localStorage.setItem('role', response.data.user.role.name)
            localStorage.setItem('name', response.data.user.name);
            localStorage.setItem('lastName', response.data.user.lastName);

            navigate('/ordination');
        }).catch(error => {
            if (error.response.status === 404) return setMessage(t('wrongUsername'));
            if (error.response.status === 412) return setMessage(t('wrongPassword'));
        });
    }


    return (
        <section>
            <div className="container login__container">
                <div className="login__info">
                    <h2 className="login__h2">{t('login')}: </h2>

                    <form onSubmit={loginHandler} className='log__form'>
                        <div className='input__div'><p>{t('username')}:</p><input type="text" name="username" value={username} onChange={e => setUsername(e.target.value)} /></div>
                        <div className='input__div'><p>{t('password')}:</p><input type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} /></div>
                        <div className="log__div">
                            <input className='btn login__btn' type="submit" value={t('login')} />
                        </div>
                    </form>
                    <div className="message__div">{message}</div>
                </div>
            </div>
        </section>
    )
}