import './rootCanalChecker.css';
import { useTranslation } from 'react-i18next';

export default function RootCanalChecker({ checkedArr, rootHandler, selectedTooth }) {
    const { t } = useTranslation();

    let limit;
    [14, 24, 15, 25].includes(selectedTooth) ? limit = 2 : [18, 17, 27, 28, 48, 47, 46, 36, 37, 38].includes(selectedTooth) ? limit = 3 : [16, 26].includes(selectedTooth) ? limit = 4 : limit = 1;
    const rootButtons = [];
    for (let i = 0; i < limit; i++) rootButtons.push(i + 1);

    return (
        <div className="rootCanalCheckContainer">
            <h3>{t('chooseCanal')}</h3>
            {rootButtons.map((el, i) => {
                return <div className="canalCheckDiv" key={el}>
                    <label htmlFor={`canal_${el}`}>{t(`canal${el}`)}</label>
                    <input type="checkbox" id={`canal_${el}`} checked={checkedArr[i]} onChange={e => rootHandler(e.target.id)} />
                </div>
            })}

        </div>
    )
}