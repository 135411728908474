import './modal.css';

export default function InterventionModal({ open, setOpen, data }) {
  if (!data) return <div></div>
  const { date, note, dignose, price, intervention, tooth } = data;
  return (
    <div className={open ? "modal-overlay" : "modal-dispose"}>
      <div className="modal">
        <div className="modal__top">
          <p>Odradjena intervencija: </p>
          <button className='btn sm' onClick={() => setOpen()}>X</button>
        </div>
        <div className="modal__body">
          <div className="modal__body__items">
            <div>Datum: {date ? date.substring(0, 10) : ""}</div>
            <div>Zabeleska: {note ? note : "Nema zabeleške"}</div>
            <div>Dijagnoza: {dignose ? dignose : "Nema dijagnoze"}</div>
            <div>Cena: {price}RSD</div>
            <div>Naziv intervencije: {intervention ? intervention.interventionTitle : ""}</div>
            <div>Kod intervencije: {intervention ? intervention.interventionCode : ""}</div>
            <div>Broj zuba: {tooth ? tooth.map((tooth) => tooth.toothNum).toString() : ""}</div>
          </div>
        </div>
      </div>
    </div>
  );
}