import { FaUser } from "react-icons/fa";
import "./patientinfo.css";
import noImg from "../../../../images/noImg.jpg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { DataContext } from "../../../../DataContext";
import LocaleContext from "../../../../LocaleContext";
import InterventionModal from "../../../../modals/interventionModal";
import dayjs from "dayjs";
import DeleteModal from "../../../../modals/DeleteModal";


export default function PatientInfo({ plannedInterventionsPage, setPlannedInterventionsPage, notesPage, setNotesPage, appointmentsPage, setAppointmentsPage, toothInterventionsPage, setToothInterventionsPage }) {
  const { t } = useTranslation();
  const { patient, image, fetchPdf, fetchNoteDocument, toothInterventions, plannedInterventions, notes, appointments, deleteSingleToothIntervention, deletePlannedInterventions, deleteNotes, deleteAppointment, deletePatient } = useContext(DataContext);
  const { locale } = useContext(LocaleContext);
  const { patientId, name, lastName, birthday, gender, jmbg, parentName, adress, verbalAgreement, notedAgreement, mobilePhone, housePhone, email } = patient;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idForDelete, setIdForDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [interventionData, setInterventionData] = useState({});


  const modalHandle = (data) => {
    setInterventionData(data);
    setIsModalOpen(true);
  }

  const handleDeleteIntervention = () => {
    try {
      deleteSingleToothIntervention(idForDelete);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="patientInfo__container">
      <DeleteModal open={isDeleteModalOpen} setOpen={() => setIsDeleteModalOpen(false)} handleDelete={handleDeleteIntervention} />
      <InterventionModal open={isModalOpen} setOpen={() => setIsModalOpen(false)} data={interventionData} />
      <div className="patientInfo__left">
        <div className="patientInfoCard">
          <div className="patientInfoCard__top">
            <div className="radioButton__flex">
              <FaUser />
              <h3>{t('patientInfo')}</h3>
            </div>

            <Link to={`/patientEdit/${patientId}`} className="btn sm">{t('edit')}</Link>
          </div>
          <div className="infoCard__container">

            <div className="infoCard__left">
              <p>{t('name')}: <span>{name} {lastName}</span></p>
              <p>{t('dateOfBirth')}: <span>{birthday ? dayjs(birthday).format("DD.MM.YYYY") : ""}</span></p>
              <p>{t('gender')}: <span>{gender}</span></p>
              <p>JMBG: <span>{jmbg}</span></p>
              <p>{t('parentName')}: <span>{parentName}</span></p>
              <p>{t('address')}: <span>{adress}</span></p>
              <p>{t('mobilePhone')}: <span>{mobilePhone}</span></p>
              <p>{t('housePhone')}: <span>{housePhone}</span></p>
              <p>{t('email')}: <span>{email}</span></p>
              <p>{t('verbalAgreement')}? <span>{verbalAgreement ? "Da" : "Ne"}</span></p>
              <p>{t('notedAgreement')}? <span>{notedAgreement ? "Da" : "Ne"}</span></p>
            </div>


            <div className="infoCard_right">
              <img src={image ? image : noImg} alt="" />
            </div>
          </div>
        </div>


        <div className="action__card">
          <div className="doned__treatments">

            <div className="doned__treatments__top">

              <div className="radioButton__flex">
                <FaUser />
                <h3>{t('actions')}</h3>
              </div>

            </div>
            <div className="table__first__row__patient__actions">
              <button className="btn" onClick={() => fetchPdf(locale, patientId)}>{t('printPDF')}</button>
              <button className="btn delete" onClick={() => deletePatient(patientId)}>{t('deletePatient')}</button>
            </div>
          </div>
        </div>

        <div className="future__termins">
          <div className="doned__treatments">
            <div className="doned__treatments__top">
              <div className="radioButton__flex">
                <FaUser />
                <h3>{t('futureAppointments')}</h3>
              </div>

              <div className="page__selector__div">
                <p>{t('page')}</p>
                <button className="btn sm" onClick={() => setAppointmentsPage(old => old - 1)}>-</button>
                {appointmentsPage}
                <button className="btn sm" onClick={() => setAppointmentsPage(old => old + 1)}>+</button>
              </div>


              <Link to={`/appointmentAdd/${patientId}`} className="btn sm" >{t('addAppointment')}</Link>
            </div>
            <div className="table__data">
              <div className="table__future__exams__top">
                <p>{t('from').toUpperCase()}:</p>
                <p>{t('till').toUpperCase()}:</p>
                <p>{t('service')}:</p>
                <p>{t('note')}:</p>
                <p>{t('worker')}:</p>
                <p>{t('edit').toUpperCase()}</p>
                <p>{t('delete').toUpperCase()}</p>
              </div>
              {appointments.length > 0 ?
                appointments.map(({ appointmentId, startDate, endDate, service, note, doctor }, i) => {
                  return <div className="table__future__exams__top data" key={i}>
                    <p>{startDate ? startDate.substring(0, 16).replace("T", "/") : ""}</p>
                    <p>{endDate ? endDate.substring(0, 16).replace("T", "/") : ""}</p>
                    <p>{service}</p>
                    <p>{note === "" ? "Nema beleške" : note.substring(0, 10)}...</p>
                    <p>{doctor.doctorName}</p>
                    <Link to={`/appointmentEdit/${appointmentId}`} className="btn edit">{t('edit')}</Link>
                    <button className="btn delete" onClick={() => deleteAppointment(appointmentId)}>{t('delete')}</button>
                  </div>
                }) : <div className="table__future__exams__top no__data">{t('noAppointmentsYet')}</div>}
            </div>
          </div>
        </div>
      </div>


      <div className="patientInfo__right">
        <div className="doned__treatments">

          <div className="doned__treatments__top">
            <div className="radioButton__flex">
              <FaUser />
              <h3>{t('donedTreatments')}</h3>
            </div>

            <div className="page__selector__div">
              <p>{t('page')}</p>
              <button className="btn sm" onClick={() => setToothInterventionsPage(old => old - 1)}>-</button>
              {toothInterventionsPage}
              <button className="btn sm" onClick={() => setToothInterventionsPage(old => old + 1)}>+</button>
            </div>

            <Link to={`/donedTreatmentsAdd/${patientId}`} className="btn sm">+ {t('addTreatment')}</Link>
          </div>
          <div className="table__data">
            <div className="table__donedTreatments__top">
              <p>{t('date').toUpperCase()}:</p>
              <p>{t('service').toUpperCase()}:</p>
              <p>{t('code').toUpperCase()}:</p>
              <p>{t('diagnose').toUpperCase()}:</p>
              <p>{t('tooth').toUpperCase()}:</p>
              <p>{t('price').toUpperCase()}</p>
              <p>{t('edit').toUpperCase()}</p>
              <p>{t('delete').toUpperCase()}</p>
            </div>
            {toothInterventions.length > 0 ?
              toothInterventions.map((obj, i) => {
                return (
                  <>
                    <div className="table__donedTreatments__top data" key={i} onClick={() => modalHandle(obj)}>
                      <p>{obj.date ? obj.date.substring(0, 10) : ""}</p>
                      <p>{obj.intervention.interventionTitle}</p>
                      <p>{obj.intervention.interventionCode}</p>
                      <p>{obj.dignose}</p>
                      <p>{obj.tooth ? obj.tooth.length === 32 ? t('all') : obj.tooth.map((tooth) => tooth.toothNum).toString() : ""}</p>
                      <p>{obj.price}rsd</p>
                      <Link to={`/donedTreatmentsEdit/${obj.toothOnInterventionId}`} className="btn edit">{t('edit')}</Link>
                      <button className="btn delete" onClick={(e) => {
                        e.stopPropagation();
                        setIdForDelete(obj.toothOnInterventionId);
                        setIsDeleteModalOpen(true);
                      }
                      }>
                        {t('delete')}
                      </button>
                    </div>
                  </>
                )
              }) : <div className="table__future__exams__top no__data">{t('noInterventionsYet')}</div>}
          </div>
        </div>

        <div className="working__account">
          <div className="doned__treatments__top">

            <div className="radioButton__flex">
              <FaUser />
              <h3>{t('plannedInterventions')}</h3>
            </div>

            <div className="page__selector__div">
              <p>{t('page')}: </p>
              <button className="btn sm" onClick={() => setPlannedInterventionsPage(old => old - 1)}>-</button>
              <p>{plannedInterventionsPage}</p>
              <button className="btn sm" onClick={() => setPlannedInterventionsPage(old => old + 1)}>+</button>
            </div>

            <Link to={`/plannedInterventionsAdd/${patientId}`} className="btn sm">+ {t('addIntervention')}</Link>
          </div>
          <div className="table__data">
            <div className="table__interventions__top">
              <p>{t('service').toUpperCase()}:</p>
              <p>{t('toothNumber').toUpperCase()}:</p>
              <p>{t('priority').toUpperCase()}:</p>
              <p>{t('edit').toUpperCase()}</p>
              <p>{t('delete').toUpperCase()}</p>
            </div>{plannedInterventions.length > 0 ?
              plannedInterventions.map(({ plannedInterventionsId, interventionService, tooth, isPriority }, i) => {
                return <div className="table__interventions__top data" key={i}>
                  <p>{interventionService}</p>
                  <p>{tooth.toothNum}</p>
                  <p>{isPriority ? "DA" : "Ne"}</p>
                  <Link to={`/plannedInterventionsEdit/${plannedInterventionsId}`} className="btn edit">{t('edit')}</Link>
                  <button className="btn delete" onClick={() => deletePlannedInterventions(plannedInterventionsId)}>{t('delete')}</button>
                </div>
              }) : <div className="table__future__exams__top no__data">{t('noPlannedInterventionsYet')}</div>}
          </div>
        </div>


        <div className="planned__treatments">
          <div className="doned__treatments__top">
            <div className="radioButton__flex">
              <FaUser />
              <h3>{t('notes')}</h3>
            </div>

            <div className="page__selector__div">
              <p>{t('page')}</p>
              <button className="btn sm" onClick={() => setNotesPage(old => old - 1)}>-</button>
              <p>{notesPage}</p>
              <button className="btn sm" onClick={() => setNotesPage(old => old + 1)}>+</button>
            </div>

            <Link to={`/noteAdd/${patientId}`} className="btn sm">+ {t('addNote')}</Link>
          </div>
          <div className="table__data">
            <div className="table__notes__top">
              <p>{t('date').toUpperCase()}:</p>
              <p>{t('type').toUpperCase()}:</p>
              <p>{t('text').toUpperCase()}:</p>
              <p>{t('document').toUpperCase()}:</p>
              <p>{t('edit').toUpperCase()}</p>
              <p>{t('delete').toUpperCase()}</p>
            </div>{notes.length > 0 ?
              notes.map(({ noteId, noteDate, noteType, noteText, noteDocument, uploadedFile }, i) => {
                return <div className="table__notes__top data" key={i}>
                  <p>{noteDate ? noteDate.substring(0, 10) : ""}</p>
                  <p>{noteType}</p>
                  <p>{noteText}</p>
                  {noteDocument ? <button className="btn print" onClick={() => fetchNoteDocument(uploadedFile.upoloadedFileId, uploadedFile.mimeType)}> {t('view')} </button> : <p>/</p>}
                  <Link to={`/noteEdit/${noteId}`} className="btn edit">{t('edit')}</Link>
                  <button className="btn delete" onClick={() => deleteNotes(noteId)}>{t('delete')}</button>
                </div>
              }) : <div className="table__future__exams__top no__data">{t('noNotesYet')}</div>}
          </div>
        </div>

      </div>
    </div>
  )
}