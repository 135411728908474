import { TiStarburst } from "react-icons/ti";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import "./plannedInterventionsAddEdit.css";

export default function PlannedInterventionEdit(){
  const id = +useParams().id;

  const {t} = useTranslation();
  const {editPlannedInterventions, plannedInterventions, message, setMessage} = useContext(DataContext);
  const {interventionService, isPriority} = plannedInterventions.find(obj=> obj.plannedInterventionsId === id);

  const [service, setService] = useState(interventionService);
  const [isPriorityState, setIsPriorityState] = useState(isPriority);

  useEffect(() => {setMessage("")}, []);


  const plannedInterventionAddHandle = (e) => {
    e.preventDefault();
    editPlannedInterventions(id, service, isPriorityState);
  }
  return (
    <section className="container plannedIntervention__container">
      <div className="plannedInterventionAdd__top">
        <h3>{t('editIntervention')}</h3>
      </div>

      <form className="plannedInterventionForm" onSubmit={plannedInterventionAddHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="serviceInput">{t('service')}:</label>
          <textarea name="service" id="serviceInput" cols="50" rows="5" value={service} onChange={e => setService(e.target.value)} />
          <div className="red__star"><TiStarburst/></div>
        </div>


      <div className="radioButton__grid__twoCollumns">
        <label htmlFor="isPriorityInput">{t('priority')}?</label>
        <input className="chk__button" type="checkbox" name="isPriority" id="isPriorityInput" checked={isPriorityState} onChange={e => setIsPriorityState(e.target.checked)}/>
      </div>

      <div className="anamnezaFormFooter">
                <input className="btn save" type="submit" value={t('save')} />
                <button className="btn sm">{t('discard')}</button>
                <div className="message__div">{message}</div>
          </div>

      </form>
    </section>
  )
}