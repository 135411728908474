import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./donedTreatmentsAddEdit.css";
import { DataContext } from "../../../DataContext";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import SelectInput from "../../../components/SelectInput";
import ToothSelctor from "./ToothSelctor";

export default function DonedTreatmentsAdd() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { tooths, fetchTooths, doctors, fetchDoctors, fetchInterventions, interventions, createSingleToothIntervention, message, setMessage } = useContext(DataContext);
  const [toothsIds, setToothsIds] = useState([]);
  const [checkedDiagnose, setCheckedDiagnose] = useState('');
  const [checkedMiddleIntervention, setCheckedMiddleIntervention] = useState('');
  const [checkedIntervention, setCheckedIntervention] = useState('');
  const [date, setDate] = useState(new Date());
  const [diagnose, setDiagnose] = useState('');
  const [note, setNote] = useState('');
  const [price, setPrice] = useState('0');
  const [doctorId, setDoctorId] = useState('');

  useEffect(() => {
    setMessage("");
    fetchInterventions();
    fetchDoctors();
    fetchTooths(id);
  }, [])

  const donedTreatmentsAddHandle = (e) => {
    e.preventDefault();
    createSingleToothIntervention(id, doctorId, toothsIds, checkedIntervention, date, diagnose, note, price);
  }

  const handleDate = (date) => {
    setDate(date);
  }

  const checkDiagnose = (interventionId) => {
    setCheckedMiddleIntervention('');
    setCheckedIntervention('');
    setCheckedDiagnose(interventionId);
  }

  const checkInterventionHandle = (interventionId) => {
    const subInterventionCheck = interventions.find(el => el.interventionId === +checkedDiagnose).Children.find(el => el.interventionId === +interventionId)?.Children.length > 0;
    if (subInterventionCheck) {
      setCheckedIntervention('');
      setCheckedMiddleIntervention(interventionId);
    }
    else {
      setCheckedIntervention(interventionId);
    }
  }

  return (
    <section className="container donedTreatments__container">
      <div className="donedTreatmentsAdd__top">
        <h3>{t('addTreatment')}</h3>
      </div>

      <form className="donedTreatmentsForm" onSubmit={donedTreatmentsAddHandle}>
        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="dateInput">{t('date')}:</label>
          <DatePicker
            selected={date}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <SelectInput title="intervention" arr={interventions} state={checkedDiagnose} setState={checkDiagnose} arrProps={['interventionId', 'interventionTitle']} />

        {checkedDiagnose && <SelectInput title="intervention" arr={interventions.find(el => el.interventionId === +checkedDiagnose).Children} state={checkedMiddleIntervention ? checkedMiddleIntervention : checkedIntervention} setState={checkInterventionHandle} arrProps={['interventionId', 'interventionTitle']} />}

        {checkedMiddleIntervention && <SelectInput title="intervention" arr={interventions.find(el => el.interventionId === +checkedDiagnose).Children.find(el => el.interventionId === +checkedMiddleIntervention).Children} state={checkedIntervention} setState={checkInterventionHandle} arrProps={['interventionId', 'interventionTitle']} />}

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="diagnoseInput">{t('diagnose')}:</label>
          <input type="text" name="diagnose" id="diagnoseInput" value={diagnose} onChange={e => setDiagnose(e.target.value)} />
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="x">{t('toothNumber')}:</label>
          <ToothSelctor tooths={tooths} toothsIds={toothsIds} setToothsIds={setToothsIds} />
          <div className="red__star"><TiStarburst /></div>
        </div>


        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="noteInput">{t('note')}:</label>
          <textarea rows="20" name="note" id="note" value={note} onChange={e => setNote(e.target.value)} />
        </div>


        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="priceInput">{t('price')}:</label>
          <input type="text" name="price" id="priceInput" value={price} onChange={e => setPrice(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="doctorInput">{t('doctor')}:</label>
          <select type="text" name="doctorInput" id="doctorInput" value={doctorId} onChange={e => setDoctorId(e.target.value)}>
            <option value="">-- {t('chooseDoctor')} --</option>
            {doctors.map(({ doctorId, doctorName, doctorLastName }) => <option value={doctorId}>{doctorName} {doctorLastName}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>



        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>
      </form>
    </section>
  )
}