import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import { TiStarburst } from "react-icons/ti";
import "./plannedInterventionsAddEdit.css";

export default function PlannedInterventionAdd() {
  const id = useParams().id;

  const { t } = useTranslation();
  const { createPlannedInterventions, tooths, message, setMessage } = useContext(DataContext);

  const [service, setService] = useState('');
  const [toothId, setToothId] = useState('');
  const [isPriority, setIsPriority] = useState(false);

  useEffect(() => {
    setMessage("");
  }, []);

  const plannedInterventionAddHandle = (e) => {
    e.preventDefault();
    createPlannedInterventions(id, toothId, service, isPriority);
  }
  return (
    <section className="container plannedIntervention__container">
      <div className="plannedInterventionAdd__top">
        <h3>{t('addIntervention')}</h3>
      </div>

      <form className="plannedInterventionForm" onSubmit={plannedInterventionAddHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="serviceInput">{t('service')}:</label>
          <textarea name="service" id="serviceInput" cols="50" rows="5" value={service} onChange={e => setService(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="toothNumberInput">{t('toothNumber')}:</label>
          <select name="toothNUmber" id="toothNumberInput" value={toothId} onChange={e => setToothId(e.target.value)}>
            <option value="">-- Izaberite zub --</option>
            {tooths.map(({ toothId, toothNum }, i) => <option key={i} value={toothId}>{toothNum}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="isPriorityInput">{t('priority')}?</label>
          <input className="chk__button" type="checkbox" name="isPriority" id="isPriorityInput" checked={isPriority} onChange={e => setIsPriority(e.target.checked)} />
        </div>

        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>

      </form>
    </section>
  )
}