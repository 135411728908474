import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./statistic.css";
import StatisticHeader from "./StatisticHeader";
import StatisticCompleteValues from "./statisticCards/StatisticCompleteValues";
import StatisticPatients from "./statisticCards/StatisticPatients";
import StatisticInterventions from "./statisticCards/StatisticInterventions";
import { DataContext } from "../../DataContext";


export default function Statistic(){
  const {t} = useTranslation();
  const {fetchMalePatients, fetchFemalePatients, fetchTop10Interventions, fetchNumberOfPatients, fetchNewPatients, fetchNumberOfInterventions, fetchAgesData} = useContext(DataContext);
  const [selectedTab, setSelectedTab] = useState('completeValues');


  useEffect(() => {
    fetchMalePatients(t('male'));
    fetchFemalePatients(t('female'));
    fetchAgesData();
    fetchTop10Interventions();
    fetchNumberOfPatients();
    fetchNewPatients();
    fetchNumberOfInterventions();
  }, [])




  return(
    <section className="container statistic__section">
      <StatisticHeader handler={setSelectedTab} selectedCard={selectedTab}/>
      {selectedTab === "completeValues" && <StatisticCompleteValues />}
      {selectedTab === "patients" && <StatisticPatients />}
      {selectedTab === "interventions" && <StatisticInterventions />}
    </section>
  )
}