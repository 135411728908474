import "./ordination.css";
import {BsCalendar3} from "react-icons/bs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MyCalendar from "./MyCalendar";
import { useEffect, useContext } from "react";
import { DataContext } from "../../DataContext";

export default function Ordination(){
  const {t} = useTranslation();
  const {fetchChairs, fetchDoctors, fetchWorkers, fetchInterventions} = useContext(DataContext);

  useEffect(()=> {
    fetchDoctors();
    fetchChairs();
    fetchWorkers();
  },[])

  return(
    <section className="container ordination__container">
      <div className="ordination__calendar">

        {/*Navbar kalendara*/}
        <div className="calendar__top">
          <div className="calendar__top__left">
              <BsCalendar3 />
              <h3>{t('scheduledAppointments')}</h3>
          </div>
          <div className="calendar__top__right">

            <Link to="/doctorAddEdit" className="btn sm">{t('allDoctors')}</Link>
            <Link to="/chairsAddEdit" className="btn sm">{t('allChairs')}</Link>
            <Link to="/workersAddEdit" className="btn sm">{t('allWorkers')}</Link>
            <Link to="/appointmentAdd/all" className="btn sm">+ {t('addAppointment')}</Link>
          </div>
        </div>
      </div>

      {/*Kalendar*/}
      <div className="calendar__body">
        <MyCalendar/>
      </div>
    </section>
  )
}