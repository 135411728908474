import "./toothInfo.css";
import { FaTooth } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DataContext } from "../../../../DataContext";

export default function ToothInfo({ selectedTooth }) {
    const { t } = useTranslation();
    const { implantSurface, implantType, cariesSurface, lengthCodes, endoRoot } = useContext(DataContext);

    return (
        <div className="tooth__info__container">
            <div className="tooth__info__container__top">
                <div className="radioButton__flex">
                    <FaTooth />
                    <h3>{t('toothInfo') + ": " + selectedTooth}</h3>
                </div>
            </div>
            <div className="tooth__info__container__body">
                <div className="radioButton__flex">
                    <p>{t('implant')}: </p>
                    <p>{t('facial')}: <span className="red__span">{implantSurface[0] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('distal')}: <span className="red__span">{implantSurface[1] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('occulsal')}: <span className="red__span">{implantSurface[2] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('mesial')}: <span className="red__span">{implantSurface[3] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('oral')}: <span className="red__span">{implantSurface[4] ? t('yes') : t('no')}</span></p>
                    <p>{t('implantType')}: <span className="red__span">{implantType}</span></p>
                </div>

                <div className="radioButton__flex">
                    <p>{t('caries')}:</p>
                    <p>{t('facial')}: <span className="red__span">{cariesSurface[0] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('distal')}: <span className="red__span">{cariesSurface[1] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('occulsal')}: <span className="red__span">{cariesSurface[2] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('mesial')}: <span className="red__span">{cariesSurface[3] ? t('yes') : t('no')}</span>,</p>
                    <p>{t('oral')}: <span className="red__span">{cariesSurface[4] ? t('yes') : t('no')}</span></p>
                </div>

                <div className="radioButton__flex">
                    <p>{t('lenghts')}: </p>
                    <p>O/P: <span className="red__span">{lengthCodes[0] ? lengthCodes[0] : "0"}</span></p>
                    <p>B/V: <span className="red__span">{lengthCodes[1] ? lengthCodes[1] : "0"}</span></p>
                    <p>MB1: <span className="red__span">{lengthCodes[2] ? lengthCodes[2] : "0"}</span></p>
                    <p>MB2: <span className="red__span">{lengthCodes[3] ? lengthCodes[3] : "0"}</span></p>
                    <p>DB: <span className="red__span">{lengthCodes[4] ? lengthCodes[4] : "0"}</span></p>
                    <p>D: <span className="red__span">{lengthCodes[5] ? lengthCodes[5] : "0"}</span></p>
                    <p>MB: <span className="red__span">{lengthCodes[6] ? lengthCodes[6] : "0"}</span></p>
                    <p>ML: <span className="red__span">{lengthCodes[7] ? lengthCodes[7] : "0"}</span></p>
                    <p>1: <span className="red__span">{lengthCodes[8] ? lengthCodes[8] : "0"}</span></p>
                    <p>L: <span className="red__span">{lengthCodes[9] ? lengthCodes[9] : "0"}</span></p>
                </div>

                <div className="radioButton__flex">
                    <p>{t('treatedCanal')}: </p>
                    <p>{t('canal1')}: <span className="red__span">{endoRoot[0] ? t('yes') : t('no')}</span></p>
                    <p>{t('canal2')}: <span className="red__span">{endoRoot[1] ? t('yes') : t('no')}</span></p>
                    <p>{t('canal3')}: <span className="red__span">{endoRoot[2] ? t('yes') : t('no')}</span></p>
                    <p>{t('canal4')}: <span className="red__span">{endoRoot[3] ? t('yes') : t('no')}</span></p>
                </div>
            </div>
        </div>
    )
}