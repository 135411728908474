import { Routes, Route } from "react-router-dom"
import { Suspense, useContext, useEffect, useState } from "react"
import LocaleContext from "./LocaleContext"
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import Login from "./pages/login/Login"
import Patients from "./pages/patients/Patients"
import Patient from "./pages/patient/Patient"
import Home from "./pages/home/Home"
import NotFound from "./pages/notFound/NotFound"
import Ordination from "./pages/ordination/Ordination"
import DoctorAdd from "./pages/forms/doctorAddEdit/DoctorAdd.jsx";
import AppointmentAdd from "./pages/forms/appointmentAddEdit/AppointmentAdd"
import AnamnezaEdit from "./pages/forms/anamnezaEdit/AnamnezaEdit"
import PatientAdd from "./pages/forms/patientAddEdit/PatientAdd"
import PatientEdit from "./pages/forms/patientAddEdit/PatientEdit"
import RTGAdd from "./pages/forms/rtgAddEdit/RTGAdd"
import DonedTreatmentsAdd from "./pages/forms/donedTreatmentsAddEdit/DonedTreatmentsAdd"
import PlannedInterventionAdd from "./pages/forms/plannedInterventionsAddEdit/PlannedInterventionsAdd"
import PlannedInterventionEdit from "./pages/forms/plannedInterventionsAddEdit/PlannedInterventionsEdit"
import FilesAndOpinionsAdd from "./pages/forms/filesAndOpinionsAddEdit/FilesAndOpinionsAdd"
import i18n from "./i18n.js";
import Statistic from "./pages/statistic/Statistic"
import DonedTreatmentsEdit from "./pages/forms/donedTreatmentsAddEdit/DonedTreatmentsEdit"
import FilesAndOpinionsEdit from "./pages/forms/filesAndOpinionsAddEdit/FilesAndOpinionsEdit"
import RTGEdit from "./pages/forms/rtgAddEdit/RTGEdit"
import NoteAdd from "./pages/forms/noteAddEdit/NoteAdd"
import NoteEdit from "./pages/forms/noteAddEdit/NoteEdit"
import AppointmentEdit from "./pages/forms/appointmentAddEdit/AppointmentEdit"
import ScrollToTop from "./components/ScroolOnTop"
import ChairsAdd from "./pages/forms/chairsAddEdit/ChairsAdd.jsx"
import { AuthContext } from "./AuthContext.js"
import WorkerAdd from "./pages/forms/workersAddEdit/WorkersAdd.jsx"
import PasswordReset from "./pages/passwordReset/PasswordReset.jsx"


export default function App() {
    const [locale, setLocale] = useState(i18n.language);
    const { logged, setLogged } = useContext(AuthContext);

    useEffect(() => {
        setLogged(localStorage.getItem('access_token') !== null ? true : false);
    }, []);

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            <Suspense fallback={<div>Loading...</div>}>
                <ScrollToTop />
                <Navbar />
                <Routes>
                    <Route index element={<Home />} />
                    {logged && <Route path="patients" element={<Patients />} />}
                    {logged && <Route path="patient/:id" element={<Patient />} />}
                    {!logged && <Route path="login" element={<Login />} />}
                    {logged && <Route path="ordination" element={<Ordination />} />}
                    {logged && <Route path="doctorAddEdit" element={<DoctorAdd />} />}
                    {logged && <Route path="chairsAddEdit" element={<ChairsAdd />} />}
                    {logged && <Route path="workersAddEdit" element={<WorkerAdd />} />}
                    {logged && <Route path="appointmentAdd/:id" element={<AppointmentAdd />} />}
                    {logged && <Route path="appointmentEdit/:id" element={<AppointmentEdit />} />}
                    {logged && <Route path="statistic" element={<Statistic />} />}
                    {logged && <Route path="anamnezaedit/:id" element={<AnamnezaEdit />} />}
                    {logged && <Route path="patientAdd" element={<PatientAdd />} />}
                    {logged && <Route path="patientEdit/:id" element={<PatientEdit />} />}
                    {logged && <Route path="rtgadd/:id" element={<RTGAdd />} />}
                    {logged && <Route path="rtgEdit/:id" element={<RTGEdit />} />}
                    {logged && <Route path="noteAdd/:id" element={<NoteAdd />} />}
                    {logged && <Route path="noteEdit/:id" element={<NoteEdit />} />}
                    {logged && <Route path="donedTreatmentsAdd/:id" element={<DonedTreatmentsAdd />} />}
                    {logged && <Route path="donedTreatmentsEdit/:toothOnInterventionId" element={<DonedTreatmentsEdit />} />}
                    {logged && <Route path="plannedInterventionsAdd/:id" element={<PlannedInterventionAdd />} />}
                    {logged && <Route path="plannedInterventionsEdit/:id" element={<PlannedInterventionEdit />} />}
                    {logged && <Route path="filesAndOpinionsAdd/:id" element={<FilesAndOpinionsAdd />} />}
                    {logged && <Route path="filesAndOpinionsEdit/:id" element={<FilesAndOpinionsEdit />} />}
                    {logged && <Route path="passwordReset" element={<PasswordReset />} />}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </Suspense>
        </LocaleContext.Provider>
    )
}