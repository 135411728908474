import "./donedTreatmentsAddEdit.css";
import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../DataContext";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

export default function DonedTreatmentsEdit() {
  const toothOnInterventionId = useParams().toothOnInterventionId;

  const { t } = useTranslation();
  const { toothInterventions, editSingleToothIntervention, message, setMessage } = useContext(DataContext);
  const { note, dignose, price, date } = toothInterventions.find(obj => obj.toothOnInterventionId === toothOnInterventionId);

  const [dateState, setDateState] = useState(new Date(date));
  const [noteState, setNoteState] = useState(note);
  const [diagnoseState, setDiagnoseState] = useState(dignose);
  const [priceState, setPriceState] = useState(price);

  useEffect(() => { setMessage('') }, []);

  const donedTreatmentsEditHandle = (e) => {
    e.preventDefault();
    editSingleToothIntervention(toothOnInterventionId, dateState, diagnoseState, noteState, priceState);
  }

  const handleDate = (date) => {
    setDateState(date)
  }

  return (
    <section className="container donedTreatments__container">
      <div className="donedTreatmentsAdd__top">
        <h3>{t('editTreatment')}</h3>
      </div>

      <form className="donedTreatmentsForm" onSubmit={donedTreatmentsEditHandle}>
        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="dateInput">{t('date')}:</label>
          <DatePicker
            selected={dateState}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="note">{t('note')}:</label>
          <textarea name="note" id="note" cols="30" rows="10" value={noteState} onChange={e => setNoteState(e.target.value)}></textarea>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="diagnoseInput">{t('diagnose')}:</label>
          <input type="text" name="diagnose" id="diagnoseInput" value={diagnoseState} onChange={e => setDiagnoseState(e.target.value)} />
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="priceInput">{t('price')}:</label>
          <input type="text" name="price" id="priceInput" value={priceState} onChange={e => setPriceState(e.target.value)} />
        </div>


        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>
      </form>
    </section>
  )
}