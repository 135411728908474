import "./odontogram.css";
import { MdCalendarViewMonth } from "react-icons/md";
import { BsCheckCircle } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import ToothChart from "./ToothChart";
import OdontogramDetails from "./OdontogramDetails";
import OdontogramTables from "./OdontogramTables";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../../DataContext";
import ToothInfo from "./ToothInfo";
import MilkToothChart from "./MilkToothChart";


export default function Odontogram({ id }) {
  const { t } = useTranslation();
  const { tooths, singleToothInterventions, fetchSingleToothsInterventions, deleteSingleToothIntervention, plannedInterventionsForTooth, fetchPlannedInterventionsForTooth, deletePlannedInterventions, fetchRtgRecordsForTooth, fetchImplantSurface, fetchCariesSurface, fetchLengthCodes, fetchEndoRoot } = useContext(DataContext);
  const [selectedToothType, setSelectedToothType] = useState('REGULAR');
  const [selectedTooth, setSelectedTooth] = useState([]);
  const [selectedToothsIds, setSelectedToothsIds] = useState([]);
  const [rtgRecordsForToothPage, setRtgRecordsForToothPage] = useState(1);
  const [plannedInterventionsForToothPage, setPlannedInterventionsForToothPage] = useState(1);
  const [singleToothOnInterventionsPage, setSingleToothOnInterventionsPage] = useState(1);

  const toggleHandler = (toothNumber, toothId) => {
    fetchImplantSurface(toothId);
    fetchCariesSurface(toothId);
    fetchLengthCodes(toothId);
    fetchEndoRoot(toothId);
    setSelectedTooth(old => old.includes(toothNumber) ? old.filter(el => el !== toothNumber) : [...old, toothNumber]);
    setSelectedToothsIds(old => old.includes(toothId) ? old.filter(el => el !== toothId) : [...old, toothId]);
  }


  const selectAllTooth = () => {
    if (selectedToothType === "REGULAR") {
      setSelectedTooth(tooths.filter(tooth => tooth.toothNum < 49).map(tooth => tooth.toothNum));
      setSelectedToothsIds(tooths.filter(tooth => tooth.toothNum < 49).map(tooth => tooth.toothId));
    }
    else {
      setSelectedTooth(tooths.filter(tooth => tooth.toothNum > 48).map(tooth => tooth.toothNum))
      setSelectedToothType(tooths.filter(tooth => tooth.toothNum > 48).map(tooth => tooth.toothId));
    }
  }

  const resetTooths = () => {
    setSelectedTooth('');
    setSelectedToothsIds([]);
  }


  useEffect(() => {
    if (selectedToothsIds.length > 0) {
      fetchSingleToothsInterventions(selectedToothsIds, singleToothOnInterventionsPage);
      fetchPlannedInterventionsForTooth(selectedToothsIds, plannedInterventionsForToothPage);
      fetchRtgRecordsForTooth(selectedToothsIds, rtgRecordsForToothPage);
    }
  }, [selectedTooth, singleToothOnInterventionsPage, plannedInterventionsForToothPage, rtgRecordsForToothPage])

  return (
    <div className="odontogram__container">
      <div className="odontogram__top">

        <div className="odontogram__upper__left">
          <MdCalendarViewMonth />
          <button className="btn sm" onClick={() => setSelectedToothType('REGULAR')}>{t('teeth')}</button>
          <button className="btn sm" onClick={() => setSelectedToothType('MILK')}>{t('milkTeeth')}</button>
        </div>

        <button className="btn sm">
          <BsCheckCircle />
        </button>
      </div>

      <div className="odontogram__body">
        <div className="odontogram__tooth__grid">
          {selectedToothType === "REGULAR" ?
            <ToothChart handler={toggleHandler} tooths={tooths} /> :
            <MilkToothChart handler={toggleHandler} tooths={tooths} />}
        </div>

        <div className="odontogram__details">
          {selectedTooth.length > 0 ?
            <OdontogramDetails selectedTooth={selectedTooth} selectedToothsIds={selectedToothsIds} patientId={id} resetTooths={resetTooths} />
            : <div className="no__selection__div">
              <div>{t('pleaseSelectToothFirst')}</div>
              <div>{t('or')}</div>
              <button className="btn sm" onClick={() => selectAllTooth()}>{t('selectAllTeeth')}</button>
            </div>}
        </div>
        {selectedTooth.length > 0 && <OdontogramTables
          selectedTooth={selectedTooth}
          singleToothInterventions={singleToothInterventions}
          deleteSingleToothIntervention={deleteSingleToothIntervention}
          plannedInterventionsForTooth={plannedInterventionsForTooth}
          deletePlannedInterventions={deletePlannedInterventions}
          rtgRecordsForToothPage={rtgRecordsForToothPage}
          setRtgRecordsForToothPage={setRtgRecordsForToothPage}
          plannedInterventionsForToothPage={plannedInterventionsForToothPage}
          setPlannedInterventionsForToothPage={setPlannedInterventionsForToothPage}
          singleToothOnInterventionsPage={singleToothOnInterventionsPage}
          setSingleToothOnInterventionsPage={setSingleToothOnInterventionsPage}
        />}
        {selectedTooth.length > 0 && <ToothInfo selectedTooth={selectedTooth} />}
      </div>
    </div>
  )
}