import {FaUser} from "react-icons/fa";
import { PiRadioactiveFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import "../../components/header.css";

export default function StatisticHeader({handler}){
  const {t} = useTranslation();
  return (
      <section className="header">
          <div className="header__container">
              <div className="header__content">
                
                  <div className="header__content__left">
                   <p>{t('statistic')}</p>
                  </div>

                  <div className="header__content__right">

                        <button className="btn  patient" onClick={() => handler("completeValues")}>
                          <FaUser />
                          <p>{t('summedValues')}</p>
                        </button>

                        <button className="btn  patient" onClick={() => handler("patients")}>
                          <FaUser />
                          <p>{t('patients')}</p>
                        </button>

                        <button className="btn  patient" onClick={() => handler("interventions")}>
                          <PiRadioactiveFill />
                          <p>{t('interventions')}</p>
                        </button>
                    
                  </div> 
              </div>
          </div>
      </section>
  )
}