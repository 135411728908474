import React, { useContext } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DataContext } from '../DataContext';

ChartJS.register(CategoryScale,LinearScale,BarElement,Tooltip,Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export function InterventionByDoctorYearVerticalBarChart() {
  const {t} = useTranslation();
  const labels = [t('january'),t('february'),t('march'),t('april'),t('may'),t('june'),t('july'),t('august'),t('september'),t('october'),t('november'),t('december')];
  const {interventionsByDoctor} = useContext(DataContext);

  const data = {
    labels,
    datasets: interventionsByDoctor
  };


  return <Bar options={options} data={data} />;
}