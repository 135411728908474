import { FaUser } from "react-icons/fa"
import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InterventionThisMonthVerticalBarChart } from "../../../charts/InterventionThisMonthVerticalBarChart";
import { InterventionThisYearVerticalBarChart } from "../../../charts/InterventionThisYearVerticalBarChart";
import { InterventionByDoctorYearVerticalBarChart } from "../../../charts/InterventionByDoctorVerticalBarChart";
import { DataContext } from "../../../DataContext";

export default function StatisticInterventions(){
  const {t} = useTranslation();
  const months = [t('january'),t('february'),t('march'),t('april'),t('may'),t('june'),t('july'),t('august'),t('september'),t('october'),t('november'),t('december')];
  const {appointmentsYearMonth , numberOfInterventions, numberOfInterventionsYearMonth, numberOfInterventionsYear, fetchAppointmentsYearMonth, fetchNumberOfInterventionsYearMonth, fetchNumberOfInterventionsYear, fetchPartialInterventionsSelectedMonth, fetchPartialInterventionsSelectedYear, fetchInterventionsByDoctor} = useContext(DataContext);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

  useEffect(() => {
    fetchAppointmentsYearMonth(selectedYear, selectedMonth);
    fetchNumberOfInterventionsYearMonth(selectedYear, selectedMonth);
    fetchNumberOfInterventionsYear(selectedYear);
    fetchPartialInterventionsSelectedMonth(selectedYear, selectedMonth);
    fetchPartialInterventionsSelectedYear(selectedYear);
    fetchInterventionsByDoctor(selectedYear);
  }, [selectedYear, selectedMonth])

  return (
    <div className="statistic__card__container">
      <div className="patients__card__top__container">
        <div className="patients__card__top__container__top">
          <div className="patients__card__top__container__top__left">
            <FaUser />
            <h3>{t('scheduledAppointmentsForMonth')} {months[selectedMonth]}: <span className="number__of__patients">{appointmentsYearMonth}</span></h3>
          </div>
          <div className="patients__card__top__container__top__right">

            <div className="month__year__select">
              <FaUser/>
              <h3>{t('year')}</h3>
              <select name="yearSelect" id="yearSelect" value={selectedYear} onChange={e => setSelectedYear(e.target.value)}>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
              </select>
            </div>

            <div className="month__year__select">
              <FaUser/>
              <h3>{t('month')}</h3>
              <select name="monthSelect" id="monthSelect" value={selectedMonth} onChange={e => setSelectedMonth(e.target.value)}>
                <option value={0}>{t('january')}</option>
                <option value={1}>{t('february')}</option>
                <option value={2}>{t('march')}</option>
                <option value={3}>{t('april')}</option>
                <option value={4}>{t('may')}</option>
                <option value={5}>{t('june')}</option>
                <option value={6}>{t('july')}</option>
                <option value={7}>{t('august')}</option>
                <option value={8}>{t('september')}</option>
                <option value={9}>{t('october')}</option>
                <option value={10}>{t('november')}</option>
                <option value={11}>{t('december')}</option>
              </select>
            </div>

          </div>
        </div>

        <div className="interventions__card__top__container__bottom">
        <div className="patient__number__card">
          <div className="statistic__title__top">
             <h3>{t('numberInterventions')}</h3>
          </div>
          <p>{numberOfInterventions}</p>
        </div>

        <div className="patient__number__card">
          <div className="statistic__title__top">
             <h3>{t('numberInterventions')} {months[selectedMonth]} {selectedYear}</h3>
          </div>
          <p>{numberOfInterventionsYearMonth}</p>
        </div>

        <div className="patient__number__card">
          <div className="statistic__title__top">
             <h3>{t('numberInterventions')} {selectedYear}</h3>
          </div>
          <p>{numberOfInterventionsYear}</p>
        </div>

        </div>
      </div>

      <div className="patients__card__mid__container">

        <div className="mid__container__top">

          <div className="new__patients__chart__container">
            <div className="statistic__title__top  mid__charts">
              <FaUser />
              <h3>{t('interventions')} {months[selectedMonth] + " " + selectedYear}:</h3>
            </div>
            <InterventionThisMonthVerticalBarChart />
          </div>

          <div className="treated__patients__chart__container">
            <div className="statistic__title__top  mid__charts">
              <FaUser />
              <h3>{t('interventions')} {selectedYear}:</h3>
            </div>
            <InterventionThisYearVerticalBarChart />
          </div>

        </div>

        <div className="mid__container__bottom">
          <div className="statistic__title__top mid__charts">
            <FaUser/>
            <h3>{t('interventionsByDoctor')} {selectedYear}:</h3>
          </div>
          <InterventionByDoctorYearVerticalBarChart />
        </div>
      </div>
    </div>
  )
}