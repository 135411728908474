import "./doctorAddEdit.css";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { DataContext } from "../../../DataContext";

export default function DoctorEdit({doctorId}) {
  const {t} = useTranslation();
  const {fetchDoctor, editDoctor, doctor, setDoctor} = useContext(DataContext);

  useEffect(() => {
    fetchDoctor(doctorId);
  }, [])
  
  const doctorEditSubmitHandler = (e) => {
    e.preventDefault();
    editDoctor(doctorId, doctor);
  }

  if (doctor) {return (
            <form onSubmit={doctorEditSubmitHandler} className="doctor__form">
                <div className="doctor__form__top">
                  <h3>{t('editDoctor')}</h3>
                </div>

                <div className="doctor__form__body">
                    <div className="radioButton__grid__twoCollumns">
                      <label htmlFor="doctorName">{t('name')}: </label>
                      <input type="text" name="doctorName" id="doctorName" value={doctor.doctorName} onChange={e => setDoctor(old => ({...old, doctorName: e.target.value}))}/>
                    </div>

                    <div className="radioButton__grid__twoCollumns">
                      <label htmlFor="doctorLastName">{t('lastName')}: </label>
                      <input type="text" name="doctorLastName" id="doctorLastName" value={doctor.doctorLastName} onChange={e => setDoctor(old => ({...old, doctorLastName: e.target.value}))}/>
                    </div>

                    <div className="newReservationFormFooter">
                        <input className="btn save" type="submit" value={t('edit')} />
                    </div>
                </div>

            </form>
  )} else return <div>Loading</div>
}