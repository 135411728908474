import "./toothChart.css";
const MilkToothChart = ({ handler, tooths }) => {

    const implantChecker = (num) => tooths.find(obj => obj.toothNum === num).implantSurface;
    const cariesChecker = (num) => tooths.find(obj => obj.toothNum === num).cariesSurface;
    const endoRootCheker = (num) => tooths.find(obj => obj.toothNum === num).endoRootCanal;
    const missingChecker = (num) => tooths.find(obj => obj.toothNum === num).interventions ? tooths.find(obj => obj.toothNum === num).interventions.some(obj => obj.fk_interventionId === 33) : false;

    return (<svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1000 840"

    >
        <g id="Group" onClick={(e) => handler(+e.target.id.substring(0, 2), tooths.find(el => el.toothNum == e.target.id.substring(0, 2)).toothId)}>
            <g id="Group75" className={missingChecker(75) ? "g1-none" : "g1"}>
                <path
                    id="75up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m755 572.4c0.8 6.9 1.3 14.1-0.2 20.9-1.3 5.6-4.2 10.6-8.8 14.1-8 6-22.6 7.3-28.1-2.7-8.1-14.5-8-28.9 1.5-43.1 1.7-2.6 5-4.9 7.7-6.4 1.9-1.1 4-1.8 6.2-2 5.3-0.4 10.5 1.7 14.3 5.3 3.8 3.5 6.8 8.7 7.4 13.9zm-6 18.4c-2.4-3.2-3.2-10-3.3-18-0.5 6.3-0.5 11.4 0.5 14.6-1.7 3.2-4.1 5.3-6.9 6.1-4.1 1.2-9.5 0-16-3.7 0-3.5-0.7-7.2-2.1-11.2 1.2 6.8 1.5 13.6 0.5 20.4q0.1-0.2 0.1-0.3 1.3-3.7 1.5-7.6c5.4 3 10 4.3 13.9 3.9 0.4 0 0.8 0.2 1 0.5q2.2 3.3 2.4 7.8c0.3-3.3-0.1-6.3-1.7-8.6q0.4 0 0.7-0.1c2.9-0.9 5.3-2.9 7.2-5.9 0.5 1 1.2 1.7 2.2 2.1z"
                />
                <path
                    id="75down-fill"
                    className="s0"
                    d="m757 671.2c0.3 4.7 0.4 10.1-0.5 15.1-0.3 2.2-0.9 4.3-1.6 6.2q-0.5 1.5-1.2 2.9c-0.1 0.2-0.3 0.5-0.5 0.8q-0.6 0.9-0.9 1.5c-4.3-1.1-9.2-0.3-12.8 2.3q-0.4 0.2-0.9 0.6c-0.9 0.6-1.7 1.3-2.7 1.5q-1.6 0.5-3.8-0.5c-0.6-0.2-1.3-0.6-1.9-0.9-0.6-0.3-1.2-0.7-1.8-0.9-2.7-1.2-7.5-2.2-10.4-0.4-5.1-7.8-7.7-19-7.9-34.2 0-2.6 0.6-4.6 1.9-5.9 0.7-0.7 1.4-0.8 2.3-0.9q0.3 0 0.7-0.1c1.8-0.3 3.1-0.6 4.3-1.1 1.7-0.7 3.1-1.7 4.6-2.7 0.7-0.5 1.4-1 2.2-1.5 1.8-1.1 3.6-1.6 5.5-1.6 2.7 0 5.4 1 8.9 3.3 4.8 4.6 8.9 7.6 12.5 9.2 1.3 0.6 2.5 1.3 3.1 2.5 0.7 1.5 0.8 3.2 0.9 4.8z"
                />
                <path
                    id="75down-fill2"
                    className="s0"
                    d="m752.1 698.8c0 14.1-1.6 30.5-5.1 50.2-0.2 1.5-0.8 3.1-1.2 4.6q-0.8 1.9-1.2 3.9c-0.6 3-1.1 5.9-1.4 8.8q-0.4 4.3-0.2 8.8c0.1 1.5 0.3 3.1 0.5 4.5 0.2 1.4 0.3 2.8 0.4 4.2 0.1 1 0.1 2.1-0.2 3.2-0.3 1-0.9 1.8-1.7 2.2q-0.5 0.2-1.1 0.2c-0.9 0-1.9-0.4-2.5-0.8-2.3-1.3-3.5-3.3-4.8-5.6l-0.4-0.5c-1.5-2.7-3-5.7-4.4-8.8-2.6-5.9-4.5-12.1-5.8-18.4-1.6-8-2.1-16.4-1.6-25.1 1.4-10.3 0.5-20.3-2.8-29.8 2.5-1.7 7-0.7 9.4 0.4q0.8 0.4 1.7 0.8c0.6 0.4 1.3 0.8 2 1.1q1.7 0.7 3.1 0.7 0.7 0 1.3-0.2c1.2-0.3 2.2-1 3.2-1.8q0.4-0.3 0.8-0.6c3.4-2.4 8-3.1 12-2z"
                />
                <path
                    id="75caries-oral"
                    className={cariesChecker(75) ? cariesChecker(75).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m713 586l15.1-8.4 18.2-0.2 10 1.5-2.8-13-7.3-9.7-5.1-2.1-8.1-1.1-7.9 4.1-7.1 8-3 7.9z"
                />
                <path
                    id="75implant-oral"
                    className={implantChecker(75) ? implantChecker(75).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m713 586l15.1-8.4 18.2-0.2 10 1.5-2.8-13-7.3-9.7-5.1-2.1-8.1-1.1-7.9 4.1-7.1 8-3 7.9z"
                />
                <path
                    id="75caries-vesial"
                    className={cariesChecker(75) ? cariesChecker(75).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m721 656v42l5 1 5 2 3 1h4l6-4 5-1v-36l-5-4-5-4-5-2-5 1-5 2zm35-66l-15 8-9 2-6-2-5-4-7-5 1 6 3 8 4 5 6 3 7 1 7-2 5-2 5-7 4-7z"
                />
                <path
                    id="75implant-vesial"
                    className={implantChecker(75) ? implantChecker(75).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m721 656v42l5 1 5 2 3 1h4l6-4 5-1v-36l-5-4-5-4-5-2-5 1-5 2zm35-66l-15 8-9 2-6-2-5-4-7-5 1 6 3 8 4 5 6 3 7 1 7-2 5-2 5-7 4-7z"
                />
                <path
                    id="75caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(75) ? cariesChecker(75).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m747 558l3 2 3 4 2 7 1 5 1 7-1 7-1 6-3 5-4 5-1 2-2 1 1-14 2-4 1-4-1-5-1-2zm5 106l3 1 2 4 1 8-1 9-2 7-2 4h-5v-36z"
                />
                <path
                    id="75implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(75) ? implantChecker(75).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m747 558l3 2 3 4 2 7 1 5 1 7-1 7-1 6-3 5-4 5-1 2-2 1 1-14 2-4 1-4-1-5-1-2zm5 106l3 1 2 4 1 8-1 9-2 7-2 4h-5v-36z"
                />
                <path
                    id="75caries-distal"
                    fillRule="evenodd"
                    className={cariesChecker(75) ? cariesChecker(75).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m725 579v-21l-4 3-4 7-1 5-2 3v14l1 4 2 6 2 5 4 4 2 1v-12l-3-4-1-6 1-5zm-6 79h-3l-3 1-3 3v7l1 9 2 12 4 7 2 3 3-2v-42z"
                />
                <path
                    id="75implant-distal"
                    fillRule="evenodd"
                    className={implantChecker(75) ? implantChecker(75).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m725 579v-21l-4 3-4 7-1 5-2 3v14l1 4 2 6 2 5 4 4 2 1v-12l-3-4-1-6 1-5zm-6 79h-3l-3 1-3 3v7l1 9 2 12 4 7 2 3 3-2v-42z"
                />
                <path
                    id="75caries-occlusal"
                    className={cariesChecker(75) ? cariesChecker(75).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m747 577l-8-1h-10l-7 5-2 9 3 8 6 5 10-1 7-6 5-7z"
                />
                <path
                    id="75implant-occlusal"
                    className={implantChecker(75) ? implantChecker(75).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m747 577l-8-1h-10l-7 5-2 9 3 8 6 5 10-1 7-6 5-7z"
                />
                <path
                    id="75endo1"
                    className={endoRootCheker(75) ? endoRootCheker(75).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m739 788c0 0-2.4-10-4.6-19.7-1.8-9.5-3.4-19.2-3.4-19.2l-1-13.7 1-25.5 0.4-7.5 3.5-0.4-0.5 26 2.8 36 1.8 19.5-1.5-8.8z"
                />
                <path
                    id="75up-outline"
                    className="s3"
                    d="m724.3 589.8c6.5 3.7 11.9 4.9 16 3.7 2.8-0.8 5.2-2.9 6.9-6.1-1-3.2-1-8.3-0.5-14.6 0.1 8 0.9 14.8 3.3 18-1-0.4-1.7-1.1-2.2-2.1-1.9 3-4.3 5-7.2 5.9q-0.3 0.1-0.7 0.1c1.6 2.3 2 5.3 1.7 8.6q-0.2-4.5-2.4-7.8c-0.2-0.3-0.6-0.5-1-0.5-3.9 0.4-8.5-0.9-13.9-3.9q-0.2 3.9-1.5 7.6 0 0.1-0.1 0.3c1-6.8 0.7-13.6-0.5-20.4 1.4 4 2.1 7.7 2.1 11.2z"
                />
                <path
                    id="75down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m717.6 700.8c-5.5-8-8.4-19.6-8.6-35.6 0-2.9 0.7-5.2 2.3-6.7 0.9-0.9 1.9-1 2.9-1.2q0.3 0 0.6 0c1.7-0.3 3-0.6 4.1-1.1 1.5-0.7 2.9-1.6 4.3-2.6q1.2-0.8 2.3-1.5c2-1.2 4-1.8 6.1-1.8 2.9 0 5.9 1.1 9.5 3.6 4.9 4.5 8.9 7.4 12.3 9 1.5 0.7 3 1.5 3.7 3.1 0.8 1.6 0.9 3.4 1 5.1 0.2 4.8 0.4 10.3-0.5 15.4q-0.5 3.3-1.6 6.4-0.6 1.6-1.3 3-0.2 0.4-0.6 0.9c-0.3 0.4-0.9 1.3-0.9 1.5 0.1 14.3-1.6 30.9-5.1 50.9-0.3 1.6-0.8 3.2-1.3 4.8-0.4 1.2-0.8 2.5-1.1 3.7-0.7 3-1.2 5.9-1.4 8.7-0.3 2.8-0.4 5.7-0.2 8.7 0 1.4 0.2 2.9 0.4 4.4 0.2 1.4 0.4 2.8 0.5 4.2 0.1 1.2 0.1 2.4-0.2 3.6-0.4 1.3-1.3 2.4-2.4 2.9q-0.7 0.3-1.5 0.3c-1.2 0-2.3-0.5-3.1-0.9-2.5-1.5-3.8-3.7-5.2-6l-0.3-0.6c-1.6-2.7-3.1-5.7-4.5-8.9-2.6-5.9-4.6-12.2-5.9-18.6-1.5-8-2.1-16.6-1.6-25.4 1.4-10.1 0.5-20-2.7-29.3zm0.4-1.4c2.9-1.8 7.7-0.8 10.4 0.4q0.9 0.5 1.8 0.9c0.6 0.3 1.3 0.7 1.9 0.9q2.2 1 3.8 0.6c1-0.3 1.8-0.9 2.7-1.6q0.5-0.4 0.9-0.6c3.6-2.6 8.5-3.4 12.8-2.3q0.3-0.6 0.9-1.5c0.2-0.3 0.4-0.6 0.5-0.8q0.7-1.4 1.2-2.9c0.7-1.9 1.3-4 1.6-6.1 0.9-5 0.7-10.5 0.5-15.2-0.1-1.6-0.2-3.3-0.9-4.8-0.6-1.2-1.8-1.9-3.1-2.5-3.6-1.6-7.7-4.6-12.5-9.2-3.5-2.3-6.2-3.3-8.9-3.3-1.9 0-3.7 0.5-5.5 1.6-0.8 0.5-1.5 1-2.2 1.5-1.5 1-2.9 2-4.6 2.7-1.2 0.5-2.5 0.8-4.3 1.1q-0.4 0.1-0.7 0.1c-0.9 0.2-1.6 0.2-2.3 0.9-1.3 1.3-1.9 3.3-1.9 5.9 0.2 15.2 2.8 26.4 7.9 34.2zm10 1.4c-2.4-1.1-6.9-2.1-9.4-0.4 3.3 9.5 4.2 19.5 2.8 29.8-0.5 8.7 0 17.1 1.6 25.1 1.3 6.3 3.2 12.5 5.8 18.4 1.4 3.1 2.9 6.1 4.4 8.8l0.4 0.5c1.3 2.3 2.5 4.3 4.8 5.6 0.6 0.4 1.6 0.8 2.5 0.8q0.6 0 1.1-0.2c0.8-0.4 1.4-1.2 1.7-2.2 0.3-1.1 0.3-2.2 0.2-3.2-0.1-1.4-0.2-2.8-0.4-4.2-0.2-1.4-0.4-3-0.5-4.5q-0.2-4.5 0.2-8.8c0.3-2.9 0.8-5.8 1.4-8.8q0.4-2 1.2-3.9c0.4-1.5 1-3.1 1.2-4.6 3.5-19.7 5.1-36.1 5.1-50.2-4-1.1-8.6-0.4-12 2q-0.4 0.3-0.8 0.6c-0.9 0.8-1.9 1.5-3.2 1.8q-0.6 0.2-1.3 0.2-1.4 0-3.1-0.7c-0.7-0.3-1.4-0.7-2-1.1q-0.9-0.4-1.7-0.8z"
                />
                <path
                    id={75}
                    className="s5"
                    d="m723 516.5v-2h11.2v1l-6.3 16.5h-2.5l6-15.5zm15.9 6.8v-8.8h9.3v2h-7.2v4.3q0.5-0.2 1.1-0.3 0.5-0.1 1-0.1 2.9 0 4.6 1.6 1.7 1.6 1.7 4.4 0 2.8-1.7 4.4-1.8 1.5-5 1.5-1.1 0-2.2-0.1-1.2-0.2-2.4-0.6v-2.4q1.1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-1 0-1.9 0.2-1 0.2-1.9 0.7z"
                />
            </g>
            <g id="Group74" className={missingChecker(74) ? "g1-none" : "g1"}>
                <path
                    id="74down-fill2"
                    className="s0"
                    d="m668.7 700.9c1 0.8 2.1 1.7 3.2 2.5 1.2 0.8 3.5 2 5.9 2q1.1 0 2.2-0.4c2.6-0.8 4.2-3.2 5.8-5.4l0.4-0.6c2.1-3 4.6-4.5 7.6-4.6q-0.5 1.5-1 2.9c-3.9 12.1-6 24.6-6.1 37.2q0 0.8 0 1.7c0 3.6-0.1 7.3-1.1 10.8-1.4 4.5-2.1 9.2-2.6 12.8-0.6 5-1 10.2-1 15.4 0 2.5 0 5.1 0.2 8.1q0 0.9 0.1 1.8c0.2 1.7 0.4 3.5-0.1 4.9-0.6 1.5-2.1 2.5-3.3 2.2-0.7-0.2-1.3-0.8-1.9-1.4-6.3-6.8-10.8-14.1-13.6-21.7-3.1-8.2-3.5-16.4-3.9-25.1-0.8-13.9-1.1-29.3-1.1-47 2.4-0.5 4.8-0.1 7.1 1.4 1.1 0.7 2.2 1.6 3.2 2.5z"
                />
                <path
                    id="74down-fill"
                    className="s0"
                    d="m697.5 681.1c-0.6 4.2-1.9 8.3-3.3 12.3-3.6-0.1-6.5 1.6-8.9 5l-0.4 0.6c-1.5 2.1-3 4.2-5.3 5-2.7 0.9-5.6-0.6-7.1-1.6-1.1-0.7-2.1-1.5-3.1-2.3-1-0.9-2.1-1.8-3.3-2.6-2.6-1.7-5.2-2.2-8-1.5-3.7-8.5-4.9-18.2-3.6-28.8 0.6-4.1 3.7-5.9 7.1-7.9l0.2-0.1c3-1.7 5.9-3.6 8.7-5.6 1.8-1.2 3.2-2 4.7-2q1.4 0 2.9 0.9c0.9 0.6 1.9 1.3 2.9 1.9 1.5 1 3.2 2.1 4.9 3 1 0.5 2.1 0.9 3.2 1.4 3.7 1.5 7.5 3.1 8.5 7.1 0.6 2.3 0.5 4.8 0.3 7.4-0.1 1.2-0.2 2.3-0.2 3.4 0 1.4 0 2.9-0.2 4.4z"
                />
                <path
                    id="74up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m692.3 566.7c1.9 3.4 2.4 7.8 1.3 13.6-1 5.5-2.9 10.5-5.5 15.1l-0.2 0.3c-3.3 5.5-6.7 8.4-10.7 9-4.5 0.8-8.3-1.9-11.2-4.4-12-10.1-14.9-20-9.1-30 1.8-3 4.3-6 7.5-8.9 2.1-1.9 5-3.4 7-4.2 3.4-1.5 6.7-1.8 9.7-1 5.4 1.4 8.7 6.1 11.2 10.5zm-9.2 20.1c0.2-0.3 0.6-0.3 0.8 0q0.9 1.1 2.5 1.4c0 0-1.9-2-2.2-3.2-0.8-2.8 0.8-12.6 0.8-12.6-1.2 4.2-2.5 8.9-2 12 0 0.4 0 0.8-0.2 1.1-3.5 4.8-8.4 6.4-14.9 4.9-0.3-0.1-0.6-0.4-0.7-0.7q-1.1-3.9-5.2-9.3c2.7 4.9 4.5 9.8 5.1 14.9q0.6-1.4 0.6-3.2c0-0.4 0.3-0.6 0.7-0.6 6.2 1.3 11.2-0.3 14.7-4.7z"
                />
                <path
                    id="74caries-oral"
                    className={cariesChecker(74) ? cariesChecker(74).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m656 589l11-8 9-3 8 1 10 3v-13l-6-10-6-4-6 1-8 2-7 5-5 9-2 6z"
                />
                <path
                    id="74implant-oral"
                    className={implantChecker(74) ? implantChecker(74).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m656 589l11-8 9-3 8 1 10 3v-13l-6-10-6-4-6 1-8 2-7 5-5 9-2 6z"
                />
                <path
                    id="74caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(74) ? cariesChecker(74).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m662 696l1-38 7-5 5-2 4 2 7 4 5 2v35l-9 9-5 2-3-1-7-6zm32-110l-2 3-3 5-5 7-5 4h-5l-5-2-5-5-7-6-3-6 6 3 7 4 6 1 7-2c0 0 10.5-4.2 12-5 1.5-0.8 2-1 2-1z"
                />
                <path
                    id="74implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(74) ? implantChecker(74).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m662 696l1-38 7-5 5-2 4 2 7 4 5 2v35l-9 9-5 2-3-1-7-6zm32-110l-2 3-3 5-5 7-5 4h-5l-5-2-5-5-7-6-3-6 6 3 7 4 6 1 7-2c0 0 10.5-4.2 12-5 1.5-0.8 2-1 2-1z"
                />
                <path
                    id="74caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(74) ? cariesChecker(74).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m685 578v-20l7 6 3 7-1 7-1 6-2 6-3 6-3 5-2 1 1-13 2-3v-5zm7 82l3 2 3 3 1 7-2 14-3 8h-4v-35z"
                />
                <path
                    id="74implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(74) ? implantChecker(74).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m685 578v-20l7 6 3 7-1 7-1 6-2 6-3 6-3 5-2 1 1-13 2-3v-5zm7 82l3 2 3 3 1 7-2 14-3 8h-4v-35z"
                />
                <path
                    id="74caries-distal"
                    fillRule="evenodd"
                    className={cariesChecker(74) ? cariesChecker(74).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m665 581v-19l-5 4-5 7-1 6v6l2 5 9 9v-7l-2-5 1-4zm-9 81l-2 5v9l1 10 3 10h5l1-38z"
                />
                <path
                    id="74implant-distal"
                    fillRule="evenodd"
                    className={implantChecker(74) ? implantChecker(74).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m665 581v-19l-5 4-5 7-1 6v6l2 5 9 9v-7l-2-5 1-4zm-9 81l-2 5v9l1 10 3 10h5l1-38z"
                />
                <path
                    id="74caries-occlusal"
                    className={cariesChecker(74) ? cariesChecker(74).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m687 579l-10-3-10 3-5 5v6l3 4 10 2 7-3 6-8z"
                />
                <path
                    id="74caries-occlusal"
                    className={implantChecker(74) ? implantChecker(74).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m687 579l-10-3-10 3-5 5v6l3 4 10 2 7-3 6-8z"
                />
                <path
                    id="74endo1"
                    className={endoRootCheker(74) ? endoRootCheker(74).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m679 790c0 0-2.4-10-4.6-19.7-1.8-9.5-3.4-19.2-3.4-19.2l-1-13.7 1-25.5 0.4-7.5 3.5-0.4-0.5 26 2.8 36 1.8 19.5-1.5-8.8z"
                />
                <path
                    id="74up-outline"
                    className="s3"
                    d="m667.1 595.3c-0.6-5.1-2.4-10-5.1-14.9q4.1 5.4 5.2 9.3c0.1 0.3 0.4 0.6 0.7 0.7 6.5 1.5 11.4-0.1 14.9-4.9 0.2-0.3 0.2-0.7 0.2-1.1-0.5-3.1 0.7-7.8 2-12 0 0-1.6 9.8-0.8 12.6 0.3 1.2 2.2 3.2 2.2 3.2q-1.6-0.3-2.5-1.4c-0.2-0.3-0.6-0.3-0.8 0-3.6 4.4-8.5 6-14.7 4.7-0.4 0-0.8 0.2-0.7 0.6q0 1.8-0.6 3.2z"
                />
                <path
                    id="74down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m653.5 667.1c0.6-4.7 4.1-6.7 7.5-8.7l0.3-0.2c2.9-1.7 5.8-3.5 8.6-5.5 1.6-1.1 3.3-2.2 5.3-2.2 1.1 0 2.2 0.4 3.4 1.1 1 0.6 2 1.2 3 1.9 1.5 1 3.1 2.1 4.8 2.9 1 0.5 2 0.9 3.1 1.3 3.8 1.6 8 3.4 9.2 7.9 0.6 2.5 0.4 5.1 0.2 7.8-0.1 1.1-0.1 2.2-0.1 3.3 0 1.5 0 3-0.2 4.5-0.6 4.5-2.1 8.9-3.6 13.1q-0.6 1.7-1.1 3.4c-4 11.9-6 24.3-6.1 36.8q0 0.9 0 1.7c0 3.7-0.1 7.5-1.1 11.1-1.4 4.4-2.1 9.1-2.6 12.6-0.6 5-1 10.2-1 15.3 0 2.5 0 5.1 0.2 8q0 0.9 0.1 1.8c0.2 1.8 0.4 3.7-0.2 5.4-0.6 1.7-2.2 3-3.8 3q-0.4 0-0.8-0.1c-1-0.3-1.7-1.1-2.4-1.8-6.4-6.9-11-14.3-13.9-22-3.1-8.4-3.5-16.6-3.9-25.4-0.8-14.1-1.1-29.6-1.1-47.4-4-8.7-5.3-18.7-3.8-29.6zm12 31.3c-2.3-1.5-4.7-1.9-7.1-1.4 0 17.7 0.3 33.1 1.1 47 0.4 8.7 0.8 16.9 3.9 25.1 2.8 7.6 7.3 14.9 13.6 21.7 0.6 0.6 1.2 1.2 1.9 1.4 1.2 0.3 2.7-0.7 3.3-2.2 0.5-1.4 0.3-3.2 0.1-4.9q-0.1-0.9-0.1-1.8c-0.2-3-0.2-5.6-0.2-8.1 0-5.2 0.4-10.4 1-15.4 0.5-3.6 1.2-8.3 2.6-12.8 1-3.5 1.1-7.2 1.1-10.8q0-0.9 0-1.7c0.1-12.6 2.2-25.1 6.1-37.2q0.5-1.4 1-2.9c-3 0.1-5.5 1.6-7.6 4.6l-0.4 0.6c-1.6 2.2-3.2 4.5-5.8 5.4q-1.1 0.4-2.2 0.4c-2.4 0-4.7-1.2-5.9-2-1.1-0.8-2.2-1.7-3.2-2.5-1-0.9-2.1-1.8-3.2-2.5zm-11-31.2c-1.3 10.6-0.1 20.3 3.6 28.8 2.8-0.6 5.4-0.2 8 1.5 1.2 0.8 2.3 1.7 3.3 2.6 1 0.8 2 1.6 3.1 2.3 1.5 1 4.4 2.5 7.1 1.6 2.3-0.8 3.8-2.9 5.3-5l0.4-0.6c2.4-3.4 5.3-5.1 8.9-5 1.4-4 2.7-8.1 3.3-12.3 0.2-1.5 0.2-3 0.2-4.4 0-1.1 0.1-2.2 0.2-3.4 0.2-2.6 0.3-5.1-0.3-7.4-1-4-4.8-5.6-8.5-7.1-1.1-0.5-2.2-0.9-3.2-1.4-1.7-0.9-3.4-2-4.9-3-1-0.6-2-1.3-2.9-1.9q-1.5-0.9-2.9-0.9c-1.5 0-2.9 0.8-4.7 2-2.8 2-5.7 3.9-8.7 5.6l-0.2 0.1c-3.3 2-6.5 3.8-7.1 7.9z"
                />
                <path
                    id="74up-outline2"
                    fillRule="evenodd"
                    className="s3"
                    d="m665.4 601.1c-12.3-10.4-15.5-20.9-9.4-31.4 1.8-3 4.4-6.1 7.7-9.1 2.2-2 5.2-3.5 7.3-4.4 3.6-1.5 7.1-1.9 10.4-1 5.7 1.5 9.2 6.4 11.8 11 2.1 3.6 2.6 8.3 1.4 14.3-1 5.6-2.9 10.8-5.6 15.4l-0.2 0.3c-2.2 3.7-5.7 8.7-11.5 9.6-4.8 0.8-8.9-2.1-11.9-4.7zm-1-39.7c-3.2 3-5.8 5.9-7.5 8.9-5.8 10-2.9 19.9 9.1 30 2.9 2.5 6.7 5.2 11.2 4.4 4-0.6 7.4-3.5 10.7-9l0.2-0.3c2.6-4.5 4.5-9.6 5.5-15.1 1.1-5.8 0.6-10.2-1.3-13.6-2.5-4.4-5.8-9.1-11.2-10.5-3-0.8-6.3-0.5-9.7 1-2 0.8-4.9 2.3-7 4.2z"
                />
                <path
                    id={74}
                    fillRule="evenodd"
                    className="s5"
                    d="m662 516.5v-2h11.2v1l-6.3 16.5h-2.5l6-15.5zm14.4 9.1l7.3-11.1h3v11.4h2.5v2h-2.5v4.1h-2.4v-4.1h-7.9zm7.9 0.3v-9.3l-5.9 9.3z"
                />
            </g>
            <g id="Group73" className={missingChecker(73) ? "g1-none" : "g1"}>
                <path
                    id="73up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m643.4 565.8c0.4 2.5 0.3 5.2-0.4 7.3-2.7 8.3-7.7 16.2-14.9 23.4-0.1 0.1-0.3 0.2-0.5 0.3-4.3 2.6-8.4 1.5-12.6-3.6-4.2-5.5-7.4-11.2-9.5-16.8-1.7-3.9-1.5-7.1 0.5-9.9 2.3-3 5.1-5.7 8.3-8 0.8-0.6 1.8-1.3 2.9-2q2.5-1.7 4.8-2.1c3.5-0.8 7-0.4 10.9 1.2q2.5 1.1 5 2.9l0.1 0.1c1.6 1.2 3.3 2.4 4.4 4 0.6 0.8 0.8 2.1 1 3.2zm-34.5 2.4c10-3.2 20.1-3.7 30.6-0.3-8.7-4.6-19-4.5-30.6 0.3z"
                />
                <path
                    id="73down-fill"
                    className="s0"
                    d="m639.7 668.4c0.9 8.6 0.6 15-0.9 20.7-0.5 1.8-1.1 3.5-1.7 5.1q-0.3 0.9-0.6 1.8-2.9 6.4-6.2 9.8-3.3 3.3-7.1 3.7c-6.3 0.5-13.7-5.3-22.2-17.5q0-0.1 0-0.3l-0.2-0.6c-1.2-4.8-2.5-10.4-2.4-16q0-7.5 3-12.9c2.6-4.7 7.2-9.7 11.3-12.1 1.5-0.8 3.1-1.3 4.6-1.3q1.4 0 2.5 0.5c1.4 0.5 2.5 1.6 3.7 2.7q0.4 0.4 0.8 0.8c2.5 2.3 4.6 3.8 6.9 4.9q0.5 0.2 1 0.4c2.1 1 4.2 1.9 5.6 3.8 1.3 1.8 1.7 4.2 1.9 6.5z"
                />
                <path
                    id="73down-fill2"
                    className="s0"
                    d="m601.8 694.9c7.7 10.6 14.4 15.7 20.6 15.7q0.5 0 1 0c4.2-0.4 8.1-3.5 11.5-9.2-1 4.6-1.4 9.3-1.8 13.9-0.1 2.2-0.3 4.5-0.6 6.6q-0.2 2.6-0.5 5.2c-0.3 3.6-0.7 7.2-1.3 10.8-0.4 2.9-1.2 6-2 9-0.5 2.2-1.1 4.4-1.5 6.5-2.3 10.7-3.8 21.7-4.7 32.7 0 0.4 0 1.1-0.1 1.9 0 3.8-0.2 10.7-3.2 10.7q-0.9 0-2-0.6c-4.6-2.7-6.6-8.5-7.9-13.8-3.3-13.2-3.2-26.9-3.2-40.1 0-7.1 0-14.4-0.5-21.6-0.3-5.2-0.9-10.7-1.7-16.7-0.5-3.9-1.3-7.8-2.1-11z"
                />
                <path
                    id="73caries-vesial"
                    className={cariesChecker(73) ? cariesChecker(73).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m610 587v-17h7 21l-2 17-4 6-6 5h-5l-7-6zm-2 115v-42h25v44l-5 5-6 1-7-2-5-5z"
                />
                <path
                    id="73implant-vesial"
                    className={implantChecker(73) ? implantChecker(73).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m610 587v-17h7 21l-2 17-4 6-6 5h-5l-7-6zm-2 115v-42h25v44l-5 5-6 1-7-2-5-5z"
                />
                <path
                    id="73caries-mesial"
                    className={cariesChecker(73) ? cariesChecker(73).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m637 557l-2 32 7-11 2-11-1-5-3-4zm-5 100v48l4-6 3-11 2-11v-11l-3-5-3-3z"
                />
                <path
                    id="73implant-mesial"
                    className={implantChecker(73) ? implantChecker(73).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m637 557l-2 32 7-11 2-11-1-5-3-4zm-5 100v48l4-6 3-11 2-11v-11l-3-5-3-3z"
                />
                <path
                    id="73caries-distal"
                    className={cariesChecker(73) ? cariesChecker(73).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m611 561v27l-5-10-2-6 1-4 3-3zm-2 92v48l-4-3-3-5-2-6-1-8v-7l1-6 2-6 3-4z"
                />
                <path
                    id="73implant-distal"
                    className={implantChecker(73) ? implantChecker(73).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m611 561v27l-5-10-2-6 1-4 3-3zm-2 92v48l-4-3-3-5-2-6-1-8v-7l1-6 2-6 3-4z"
                />
                <path
                    id="73caries-occlusal"
                    fillRule="evenodd"
                    className={cariesChecker(73) ? cariesChecker(73).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m634 657l-6-2-2-3-4-3-4-1-5 1-6 5-5 6h35zm-29-89l6 3 4 1h18l5-1 3-2 4-2v-3l-4-5-5-3-7-3h-6l-12 6-4 4z"
                />
                <path
                    id="73implant-occlusal"
                    fillRule="evenodd"
                    className={implantChecker(73) ? implantChecker(73).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m634 657l-6-2-2-3-4-3-4-1-5 1-6 5-5 6h35zm-29-89l6 3 4 1h18l5-1 3-2 4-2v-3l-4-5-5-3-7-3h-6l-12 6-4 4z"
                />
                <path
                    id="73endo1"
                    className={endoRootCheker(73) ? endoRootCheker(73).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m617.3 795.1c0 0-1.6-10.2-3.2-20-1.2-9.6-2.1-19.4-2.1-19.4v-13.7l2.7-25.4 1-7.5 3.5-0.1-2.3 25.9 0.3 36.1 0.4 19.5-0.8-8.8z"
                />
                <path
                    id="73up-outline2"
                    className="s3"
                    d="m639.5 567.9c-10.5-3.4-20.6-2.9-30.6 0.3 11.6-4.8 21.9-4.9 30.6-0.3z"
                />
                <path
                    id="73up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m604.5 576.8c-1.8-4.2-1.6-7.8 0.7-11 2.3-3.1 5.2-5.8 8.5-8.2 0.8-0.6 1.8-1.3 2.9-2 1.9-1.1 3.5-1.9 5.1-2.2 3.7-0.9 7.6-0.5 11.6 1.3q2.6 1.1 5.2 2.9l0.1 0.1c1.6 1.2 3.5 2.5 4.7 4.3 0.7 1 1 2.4 1.2 3.6 0.4 2.7 0.2 5.5-0.5 7.8-2.7 8.5-7.8 16.5-15.2 23.9-5 3.5-9.9 2.3-14.6-3.4-4.3-5.6-7.6-11.4-9.7-17.1zm1.5-10.3c-2 2.8-2.2 6-0.5 9.9 2.1 5.6 5.3 11.3 9.5 16.8 4.2 5.1 8.3 6.2 12.6 3.6 0.2-0.1 0.4-0.2 0.5-0.3 7.2-7.2 12.2-15.1 14.9-23.4 0.7-2.1 0.8-4.8 0.4-7.3-0.2-1.1-0.4-2.4-1-3.2-1.1-1.6-2.8-2.8-4.4-4l-0.1-0.1q-2.5-1.8-5-2.9c-3.9-1.6-7.4-2-10.9-1.2q-2.3 0.4-4.8 2.1c-1.1 0.7-2.1 1.4-2.9 2-3.2 2.3-6 5-8.3 8z"
                />
                <path
                    id="73down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m597.3 675.1c0-5.2 1.1-9.7 3.1-13.5 2.7-4.8 7.5-9.9 11.8-12.4 2.6-1.6 5.7-1.9 8-1 1.6 0.7 2.8 1.9 4 3q0.4 0.4 0.9 0.8c2.4 2.2 4.4 3.6 6.6 4.7q0.5 0.2 0.9 0.4c2.2 1 4.5 2.1 6.1 4.1 1.5 2 1.8 4.6 2.1 7.1 0.9 8.8 0.6 15.3-1 21.1-0.4 1.8-1.1 3.5-1.7 5.2-0.5 1.5-1.1 3.1-1.5 4.6-1.5 5.2-1.9 10.8-2.4 16.2-0.2 2.2-0.3 4.5-0.6 6.6q-0.3 2.6-0.5 5.2c-0.3 3.6-0.7 7.3-1.3 10.9-0.5 2.9-1.3 6-2 9.1-0.6 2.1-1.1 4.3-1.5 6.4-2.3 10.7-3.9 21.7-4.7 32.6 0 0.4-0.1 1.1-0.1 1.8 0 4.4-0.2 11.8-4.3 11.8q-1.2 0-2.6-0.8c-4.9-2.8-7-8.9-8.3-14.4-3.4-13.4-3.3-27.1-3.3-40.4 0-7.1 0-14.4-0.4-21.5-0.4-5.2-1-10.7-1.7-16.7-0.6-3.9-1.4-7.8-2.2-11.1q-0.4-1.4-0.8-2.9l-0.1-0.6c-1.3-4.9-2.6-10.6-2.5-16.3zm4.1-12.9q-3 5.4-3 12.9c-0.1 5.6 1.2 11.2 2.4 16l0.2 0.6q0 0.2 0.1 0.3c8.4 12.2 15.8 18 22.1 17.5q3.8-0.4 7.1-3.8 3.3-3.3 6.2-9.7 0.3-0.9 0.6-1.8c0.6-1.6 1.2-3.3 1.7-5.1 1.6-5.7 1.8-12.1 0.9-20.7-0.2-2.3-0.6-4.7-1.9-6.5-1.4-1.9-3.5-2.8-5.6-3.8q-0.5-0.2-1-0.4c-2.3-1.1-4.4-2.6-6.9-4.9q-0.4-0.4-0.8-0.8c-1.2-1.1-2.3-2.2-3.7-2.7q-1.1-0.5-2.4-0.5c-1.5 0-3.2 0.5-4.7 1.3-4.1 2.4-8.7 7.4-11.3 12.1zm2.5 43.7c0.8 6 1.4 11.5 1.7 16.7 0.5 7.2 0.5 14.5 0.5 21.6 0 13.2-0.1 26.9 3.2 40.1 1.3 5.3 3.3 11.1 7.9 13.8q1.1 0.6 2 0.6c3 0 3.2-6.9 3.2-10.7 0.1-0.8 0.1-1.5 0.1-1.9 0.9-11 2.4-22 4.7-32.7 0.4-2.1 1-4.3 1.5-6.5 0.8-3 1.6-6.1 2-9 0.6-3.6 1-7.2 1.3-10.8q0.3-2.6 0.5-5.2c0.3-2.1 0.5-4.4 0.6-6.6 0.4-4.6 0.8-9.3 1.8-13.9-3.4 5.7-7.3 8.8-11.5 9.2q-0.5 0-1 0c-6.2 0-12.9-5.1-20.6-15.7 0.8 3.2 1.6 7.1 2.1 11z"
                />
                <path
                    id={73}
                    className="s5"
                    d="m614 516.5v-2h11.2v1l-6.3 16.5h-2.5l6-15.5zm23 6.1q1.7 0.3 2.7 1.5 0.9 1.1 0.9 2.8 0 2.6-1.8 4-1.7 1.4-5 1.4-1.1 0-2.3-0.2-1.2-0.2-2.4-0.6v-2.3q1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2.2 0 3.4-0.8 1.2-0.9 1.2-2.6 0-1.5-1.1-2.4-1.1-0.9-3-0.9h-2.1v-1.9h2.2q1.7 0 2.6-0.7 1-0.7 1-2 0-1.4-1-2.1-1-0.7-2.7-0.7-1 0-2.1 0.2-1.1 0.2-2.5 0.6v-2.1q1.4-0.3 2.5-0.5 1.2-0.2 2.3-0.2 2.7 0 4.2 1.2 1.6 1.2 1.6 3.3 0 1.5-0.8 2.5-0.9 1-2.4 1.4z"
                />
            </g>
            <g id="Group72" className={missingChecker(72) ? "g1-none" : "g1"}>
                <path
                    id="72fill-up"
                    fillRule="evenodd"
                    className="s0"
                    d="m589.6 561.5c1.2 1.9 1.7 4.6 1.5 8q-4.1 12.7-11.1 22.8-0.4 0.4-0.8 0.8-1.7 1.8-3.9 2.5c-2.1 0.7-4.3 0.5-6.5-0.5q-1.1-0.6-2.3-1.9c-0.8-0.9-1.5-1.8-2.2-2.8-1.2-1.8-2.3-3.8-3.3-5.7l-0.1-0.2c-2.2-4.2-4.3-8.7-6.4-13.8-0.8-3.3 0-5.8 2.5-7.4 0.5-0.5 1.1-0.8 1.8-1 10.6-3.6 20.5-4.2 29.4-1.9 0.6 0.2 1.1 0.6 1.4 1.1zm-2.1 4.8c-8.5-2.8-17.4-2.6-26.9 0.5 9-1.4 18-1.5 26.9-0.5zm-27 0.5q0 0 0.1 0-0.1 0-0.2 0 0.1 0 0.1 0z"
                />
                <path
                    id="72down-fill"
                    className="s0"
                    d="m585.6 658.1c0.6 1.5 0.5 3.4 0.4 4.9-0.3 4.9-0.5 8.2-0.8 12.6l-0.2 2.6c-0.4 5.3-1.3 10.1-2.2 15.2q-0.1 0.7-0.3 1.4c-0.9 1.4-2.1 2.4-3.5 3.4q-0.5 0.3-0.9 0.6c-1.9 1.4-3.6 3.1-5.2 4.8q-0.3 0.3-0.5 0.6c-0.6 0.7-1 1.2-1.8 1.5-1 0.4-2 0.5-3.3 0.5-3.1-0.1-5.7-1.9-8-3.5-3.3-2.3-5.9-4.6-8.2-7q0-0.1 0-0.1c-0.3-1.1-0.7-2.2-0.8-3.3-0.4-3.3-0.5-6.7-0.6-10.7 0-5.6 0.2-11.2 0.3-16.6q0.1-3.6 0.2-7.2c0.1-1.4 0.1-3 0.8-4.2 0.7-1.2 1.7-1.5 2.9-1.5q0.7 0 1.3 0.1h0.7q4.5 0.3 9.1 0.4c4.7 0.2 8.9 0.3 12.8 0.4 2.7 0 6.5 2.1 7.8 5.1z"
                />
                <path
                    id="72down-fill2"
                    className="s0"
                    d="m552 699.1q-0.1-0.6-0.3-1.2c2 1.9 4.3 3.8 7 5.7 2.4 1.7 5.1 3.6 8.5 3.7q0.2 0 0.3 0 1.9 0 3.5-0.6c1-0.4 1.6-1.1 2.2-1.8q0.2-0.3 0.5-0.5c1.5-1.8 3.2-3.3 5.1-4.7q0.4-0.3 0.8-0.6c0.9-0.7 1.8-1.3 2.6-2.1-2 11.9-3.2 24.8-3.6 41.2-0.2 6.3-0.7 12.8-1.1 19.1-0.3 3.8-0.6 7.7-0.8 11.5-0.4 6.1-0.5 11-0.5 15.6q0.1 0.9 0.2 2.2c0.4 4.4 0.5 8.3-2.6 8.7-1.3 0.2-2.7-0.3-3.8-1.5-1-1-1.7-2.4-2.4-3.8-5.4-11.6-9.1-24.9-11.2-40.6-1-7.3-1.4-14.9-1.8-22.3l-0.2-2.9c-0.3-5.6-0.9-11.2-1.5-16.6-0.3-2.8-0.7-5.6-0.9-8.5z"
                />
                <path
                    id="72fill-up2"
                    className="s0"
                    d="m569.1 556.2c6.4-2.1 12.4-1.1 18.2 2.9-8.2-1.9-17.3-1.4-26.9 1.6q4.5-3.2 8.7-4.5z"
                />
                <path
                    id="72caries-vesial"
                    className={cariesChecker(72) ? cariesChecker(72).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m562 588v-18l23 1v14l-4 6-5 5-5 1-4-3zm-6 113v-38h24l-1 36-4 3-5 5-7-2z"
                />
                <path
                    id="72implant-vesial"
                    className={implantChecker(72) ? implantChecker(72).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m562 588v-18l23 1v14l-4 6-5 5-5 1-4-3zm-6 113v-38h24l-1 36-4 3-5 5-7-2z"
                />
                <path
                    id="72caries-mesial"
                    className={cariesChecker(72) ? cariesChecker(72).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m584 557v30l5-11 3-7-1-6-3-4zm-5 96l-1 46 5-4 2-14 2-20-2-5-2-2z"
                />
                <path
                    id="72implant-mesial"
                    className={implantChecker(72) ? implantChecker(72).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m584 557v30l5-11 3-7-1-6-3-4zm-5 96l-1 46 5-4 2-14 2-20-2-5-2-2z"
                />
                <path
                    id="72caries-distal"
                    className={cariesChecker(72) ? cariesChecker(72).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m563 558v31l-3-7-4-7-2-6v-3l3-3 3-2zm-6 94v49l-5-4-2-5v-7-9-9-6-4l1-2 1-2h3z"
                />
                <path
                    id="72implant-distal"
                    className={implantChecker(72) ? implantChecker(72).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m563 558v31l-3-7-4-7-2-6v-3l3-3 3-2zm-6 94v49l-5-4-2-5v-7-9-9-6-4l1-2 1-2h3z"
                />
                <path
                    id="72caries-occlusal"
                    className={cariesChecker(72) ? cariesChecker(72).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m585.3 657.6l-2.2 6.8-28-0.1-4.8-7 0.1-2.9 3.1-1.9 11-0.6 9 0.3 7 1.3zm5.2-88.2l-5.1 2.8-9-0.4-8-0.2-7-1.3-5-1.1-2.8-3.1 5.1-3.7 4.1-3.8 7.1-2.7 6.1-0.8 5 0.2 3.9 3.1 4.8 3.1 1.9 4z"
                />
                <path
                    id="72implant-occlusal"
                    className={implantChecker(72) ? implantChecker(72).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m585.3 657.6l-2.2 6.8-28-0.1-4.8-7 0.1-2.9 3.1-1.9 11-0.6 9 0.3 7 1.3zm5.2-88.2l-5.1 2.8-9-0.4-8-0.2-7-1.3-5-1.1-2.8-3.1 5.1-3.7 4.1-3.8 7.1-2.7 6.1-0.8 5 0.2 3.9 3.1 4.8 3.1 1.9 4z"
                />
                <path
                    id="72endo1"
                    className={endoRootCheker(72) ? endoRootCheker(72).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m572 792c0 0-2.4-10-4.6-19.7-1.8-9.5-3.4-19.2-3.4-19.2l-1-13.7 1-25.5 0.4-7.5 3.5-0.4-0.5 26 2.8 36 1.8 19.5-1.5-8.8z"
                />
                <path
                    id="72up-outline2"
                    className="s3"
                    d="m587.5 566.3c-9-1-18-0.9-27.1 0.5q0.1 0 0.1 0c9.5-3.1 18.5-3.3 27-0.5z"
                />
                <path
                    id="72up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m553.5 571c-0.9-3.8 0.1-6.7 2.9-8.5 4.3-3.6 8.3-6 12.4-7.3 7.1-2.3 13.9-1 20.3 3.9 2.3 2.2 3.3 5.8 3 10.6v0.1c-2.7 8.6-6.5 16.3-11.3 23.1q-0.4 0.5-0.8 1c-3.2 3.2-7.6 4-11.6 2.1q-1.4-0.6-2.7-2.1c-0.8-0.9-1.6-1.9-2.2-2.9-1.3-1.9-2.4-3.9-3.4-5.8l-0.1-0.2c-2.2-4.2-4.4-8.8-6.5-14zm1-0.3c2.1 5.1 4.2 9.6 6.4 13.8l0.1 0.2c1 1.9 2.1 3.9 3.3 5.7 0.7 1 1.4 1.9 2.2 2.8q1.2 1.3 2.3 1.9c2.2 1 4.4 1.2 6.5 0.5q2.2-0.7 3.9-2.5 0.4-0.4 0.8-0.8 7-10.1 11.1-22.8c0.2-3.4-0.3-6.1-1.5-8-0.3-0.5-0.8-0.9-1.4-1.1-8.9-2.3-18.8-1.7-29.4 1.9-0.7 0.2-1.3 0.5-1.8 1-2.5 1.6-3.3 4.1-2.5 7.4zm14.6-14.5q-4.2 1.3-8.7 4.5c9.6-3 18.7-3.5 26.9-1.6-5.8-4-11.8-5-18.2-2.9z"
                />
                <path
                    id="72down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m550.9 699.2c-0.1-1-0.5-2.1-0.8-3.3-0.4-1.1-0.8-2.3-0.9-3.5-0.4-3.4-0.5-6.8-0.5-10.8-0.1-5.6 0.1-11.2 0.2-16.6q0.1-3.6 0.2-7.2c0.1-1.5 0.1-3.3 1-4.8 1-1.7 2.6-2 3.8-2q0.7 0 1.4 0.1h0.6q4.6 0.3 9.1 0.4c4.7 0.2 8.9 0.3 12.8 0.4 3.1 0 7.4 2.3 8.8 5.8 0.7 1.8 0.6 3.7 0.5 5.4-0.3 4.9-0.5 8.2-0.8 12.6l-0.2 2.6c-0.4 5.3-1.3 10.1-2.2 15.3-2.4 12.9-3.7 26.6-4.2 44.6-0.2 6.4-0.7 12.9-1.2 19.2-0.2 3.7-0.5 7.6-0.7 11.5-0.4 6-0.5 10.9-0.5 15.5 0 0.5 0.1 1.2 0.2 2.1 0.3 3.7 0.8 9.3-3.5 9.9q-0.4 0.1-0.7 0.1c-1.4 0-2.9-0.7-4.1-1.9-1.1-1.2-1.9-2.6-2.6-4.2-5.4-11.6-9.1-25-11.3-40.9-1-7.3-1.4-14.9-1.8-22.3l-0.2-2.9c-0.3-5.6-0.9-11.2-1.5-16.6q-0.5-4.2-0.9-8.5zm2 8.4c0.6 5.4 1.2 11 1.5 16.6l0.2 2.9c0.4 7.4 0.8 15 1.8 22.3 2.1 15.7 5.8 29 11.2 40.6 0.7 1.4 1.4 2.8 2.4 3.8 1.1 1.2 2.5 1.7 3.8 1.5 3.1-0.4 3-4.4 2.6-8.7q-0.1-1.3-0.2-2.2c0-4.6 0.1-9.5 0.5-15.6 0.2-3.8 0.5-7.7 0.8-11.5 0.4-6.3 0.9-12.8 1.1-19.1 0.4-16.4 1.6-29.3 3.6-41.2-0.8 0.8-1.7 1.4-2.6 2.1q-0.4 0.3-0.8 0.6c-1.9 1.4-3.6 2.9-5.1 4.7q-0.3 0.2-0.5 0.5c-0.6 0.7-1.2 1.4-2.2 1.8q-1.6 0.6-3.5 0.6-0.1 0-0.3 0c-3.4-0.1-6.1-2-8.5-3.7q-4.1-2.9-6.9-5.7 0.1 0.6 0.2 1.2c0.2 2.9 0.6 5.7 0.9 8.5zm-1.9-54c-0.7 1.2-0.7 2.8-0.8 4.2q-0.1 3.6-0.2 7.2c-0.1 5.4-0.3 11-0.2 16.6 0 4 0.1 7.3 0.5 10.7 0.1 1.1 0.5 2.2 0.8 3.3q0 0 0 0.1c2.3 2.4 4.9 4.7 8.2 7 2.3 1.6 4.9 3.4 8 3.5 1.3 0 2.3-0.1 3.3-0.5 0.8-0.3 1.2-0.8 1.8-1.5q0.2-0.3 0.5-0.6c1.6-1.7 3.3-3.4 5.2-4.8q0.4-0.3 0.9-0.6c1.4-1 2.7-2 3.5-3.4q0.2-0.7 0.3-1.4c1-5.1 1.8-9.9 2.2-15.2l0.2-2.6c0.3-4.4 0.5-7.7 0.8-12.6 0.1-1.6 0.2-3.4-0.4-4.9-1.3-3-5.1-5.1-7.8-5.2-3.9 0-8.1-0.1-12.8-0.3q-4.6-0.1-9.1-0.4h-0.7q-0.6-0.1-1.3-0.1c-1.2 0-2.2 0.3-2.9 1.5z"
                />
                <path
                    id={72}
                    className="s5"
                    d="m561 516.5v-2h11.2v1l-6.3 16.5h-2.5l6-15.5zm17.9 13.5h8.2v2h-11.1v-2q1.4-1.4 3.7-3.7 2.3-2.4 2.9-3.1 1.2-1.2 1.6-2.1 0.5-0.9 0.5-1.8 0-1.4-1-2.2-1-0.9-2.6-0.9-1.1 0-2.3 0.4-1.2 0.4-2.7 1.1v-2.4q1.5-0.5 2.7-0.8 1.3-0.3 2.3-0.3 2.7 0 4.3 1.3 1.6 1.4 1.6 3.7 0 1.1-0.4 2-0.4 1-1.4 2.3-0.3 0.3-1.9 2-1.6 1.6-4.4 4.5z"
                />
            </g>
            <g id="Group71" className={missingChecker(71) ? "g1-none" : "g1"}>
                <path
                    id="71down-fill2"
                    className="s0"
                    d="m507.2 695c1.9 0.3 3.5 2.3 4.8 4.5l0.1 0.2c1.3 1.9 2.6 4.2 4.9 5.2q1.2 0.5 2.5 0.6c1.8 0 3.7-0.6 5.5-1.8 1.6-1 2.9-2.3 4.2-3.5q0.9-0.9 1.8-1.7c1.3-1.1 2.4-1.9 3.5-2.5 1.5-0.7 2.4-0.9 2.7-0.9l-0.1 1.8h-0.1q-0.1 0.5-0.2 1.1c-0.2 0.5-0.3 0.8-0.3 1-6.6 26.2-9.7 54.1-9.6 85.3q-1.5 2.7-3.1 2.8-1.7 0-3.8-3.2c-3-7.1-5-15.5-6-24.7-0.4-3.7-0.4-7.4-0.4-11.1 0-3 0-6.1-0.3-9.2-1-14.7-3.1-29.4-6.1-43.8q0-0.1 0-0.1z"
                />
                <path
                    id="71down-fill"
                    className="s0"
                    d="m526.6 653q0.7 0.1 1.4 0.2c2.2 0.3 4.9 0.6 6.4 1.7 1.4 1 1.7 2.7 1.9 4.5q0.1 0.4 0.2 0.8c2.2 13.5 2.5 24.4 0.9 33.8-0.6 0-1.6 0.2-3.4 1.1-1.2 0.6-2.4 1.4-3.7 2.5q-0.9 0.9-1.8 1.8c-1.3 1.2-2.6 2.5-4 3.4-2.5 1.6-5.1 2-7 1.1-2-0.9-3.3-2.9-4.5-4.8v-0.2c-1.1-1.7-3.1-4.9-6.1-5.1q-0.3-1.2-0.7-2.3c-0.5-1.4-0.9-2.7-1.1-4.1-0.5-2.8-0.6-5.7-0.7-8.3-0.2-6.2-0.1-12.4 0-18.5l0.1-2.5c0.2-4.5 3-5.1 7.7-5.5 4.8-0.5 9.6-0.4 14.4 0.4z"
                />
                <path
                    id="71up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m537.5 561.9c0.4 2 0.2 4.6-0.1 6.4q0 0.5-0.2 0.9c-3.8 9.6-7.3 16-11 20.3q-3.3 3.7-6.3 3.7c-2.4 0-4.9-1.8-7.3-5.4-3.3-4.8-5.7-10.9-7.2-18q-0.4-1.7-0.7-3.6c-0.2-1.4-0.2-2.4 0.1-3.6q0.2-1.1 0.7-1.9c0.5-0.9 1.3-1.4 2.2-1.7 8.9-2.2 18-2.2 27.1 0.1 1.3 0.4 2.4 1.4 2.7 2.8zm-5 3.4q-5.2-2.8-11.2-2.8-5.9 0-12.5 2.8c8.1-1.9 16-2 23.7 0z"
                />
                <path
                    id="71up-fill2"
                    className="s0"
                    d="m534.1 557.9q-6.6-1.6-13.1-1.6-5.7 0-11.4 1.2c4-1.8 7.4-2.7 10.7-3 4.2-0.2 8.2 0.7 13.2 3.1q0.3 0.1 0.6 0.3z"
                />
                <path
                    id="71caries-vesial"
                    className={cariesChecker(71) ? cariesChecker(71).cariesfacial ? "s1-blue" : "s1" : "s1"}
                    d="m510 585v-17h10 12l-1 15-4 6-6 4-5-1-5-5zm0 111v-35h22v35l-4 5-7 4-4-1-5-6z"
                />
                <path
                    id="71implant-vesial"
                    className={implantChecker(71) ? implantChecker(71).implantfacial ? "s1-red" : "s1" : "s1"}
                    d="m510 585v-17h10 12l-1 15-4 6-6 4-5-1-5-5zm0 111v-35h22v35l-4 5-7 4-4-1-5-6z"
                />
                <path
                    id="71caries-mesial"
                    className={cariesChecker(71) ? cariesChecker(71).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m531 556l-1 29 5-9 3-9v-6l-3-3zm0 97v45l4-3 3-2 1-13-1-14-2-10-1-2z"
                />
                <path
                    id="71implant-mesial"
                    className={implantChecker(71) ? implantChecker(71).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m531 556l-1 29 5-9 3-9v-6l-3-3zm0 97v45l4-3 3-2 1-13-1-14-2-10-1-2z"
                />
                <path
                    id="71caries-distal"
                    className={cariesChecker(71) ? cariesChecker(71).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m511 557v29l-5-11-1-9v-6l2-2zm0 96v43l-4-2-2-6v-7l-1-11v-7-6l2-3 3-1z"
                />
                <path
                    id="71implant-distal"
                    className={implantChecker(71) ? implantChecker(71).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m511 557v29l-5-11-1-9v-6l2-2zm0 96v43l-4-2-2-6v-7l-1-11v-7-6l2-3 3-1z"
                />
                <path
                    id="71caries-occlusal"
                    className={cariesChecker(71) ? cariesChecker(71).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m506 654l3 8h22l5-7-3-2-5-1h-10-6zm-1-87l6 2 6 1h6l6-1 4-1 4-1 2-1v-4l-4-4-7-3-6-1-6 1-5 1-3 3-3 2z"
                />
                <path
                    id="71caries-occlusal"
                    className={implantChecker(71) ? implantChecker(71).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m506 654l3 8h22l5-7-3-2-5-1h-10-6zm-1-87l6 2 6 1h6l6-1 4-1 4-1 2-1v-4l-4-4-7-3-6-1-6 1-5 1-3 3-3 2z"
                />
                <path
                    id="71endo1"
                    className={endoRootCheker(71) ? endoRootCheker(71).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m522.7 784.7c0 0-1.4-9.1-2.8-16.1-0.9-8-1.7-17.3-1.7-17.3l-0.1-13.7 1.5-25.5 0.5-7.3 3.5-0.1-1.6 24.3 0.8 32.2 0.3 19-0.7-8.9z"
                />
                <path
                    id="71up-outline2"
                    className="s3"
                    d="m521.3 562.5q6 0 11.2 2.8c-7.7-2-15.6-1.9-23.7 0q6.6-2.8 12.5-2.8z"
                />
                <path
                    id="71up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m503.7 566.4c-0.2-1.3-0.3-2.5 0-4 0.7-2.6 2-4.3 4.6-5.5 4.5-2.1 8.2-3.2 11.9-3.4q0.7-0.1 1.4-0.1c3.9 0 7.7 1 12.4 3.2 1 0.5 2.2 1.1 3.1 2.2 1.8 2 1.8 5.1 1.6 7.6q-0.1 1.3-0.4 2.6-0.2 1-0.7 2c-0.7 1.7-1.3 3.3-2.1 5-1.2 2.6-2.5 5.2-3.9 7.7-1.2 2-2.5 3.9-4 5.8q-0.3 0.3-0.6 0.7c-5.2 5.9-10.5 5.3-15.3-1.8-3.4-4.9-5.9-11.1-7.3-18.4q-0.4-1.8-0.7-3.6zm4-7.4c-0.9 0.3-1.7 0.8-2.2 1.7q-0.5 0.8-0.7 1.9c-0.3 1.2-0.3 2.2 0 3.6q0.2 1.9 0.6 3.6c1.5 7.1 3.9 13.2 7.2 18 2.4 3.6 4.9 5.4 7.3 5.4q3 0 6.3-3.7c3.7-4.3 7.2-10.7 11-20.3q0.2-0.4 0.2-0.9c0.3-1.8 0.5-4.4 0.1-6.4-0.3-1.4-1.4-2.4-2.7-2.8-9.1-2.3-18.2-2.3-27.1-0.1zm1.9-1.5q5.7-1.2 11.4-1.2 6.5 0 13.1 1.6-0.3-0.2-0.6-0.3c-5-2.4-9-3.3-13.2-3.1-3.3 0.3-6.7 1.2-10.7 3z"
                />
                <path
                    id="71down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m503.4 660.6v-2.6c0.3-5.7 4.5-6.1 8.7-6.5 4.9-0.5 9.8-0.4 14.6 0.4q0.7 0.1 1.5 0.2c2.3 0.3 5.1 0.7 6.9 1.9 1.8 1.3 2.1 3.4 2.3 5.2q0.1 0.4 0.2 0.8c2.2 13.7 2.5 24.7 0.9 34.3q-0.1 0.5-0.1 1c0 0.7-0.1 1.5-0.5 2.9-6.8 26.5-10.1 54.7-9.9 86.3v0.1l-0.1 0.1c-1.2 2.3-2.5 3.4-4 3.5q-0.1 0-0.2 0c-1.5-0.1-3-1.3-4.6-3.7-3.1-7.4-5.2-15.9-6.2-25.2-0.3-3.7-0.4-7.5-0.4-11.2 0-3 0-6.1-0.2-9.1-1.1-14.6-3.2-29.3-6.2-43.7-0.2-1.2-0.6-2.3-0.9-3.4-0.5-1.4-1-2.9-1.2-4.4-0.4-2.8-0.6-5.7-0.7-8.4-0.2-6.1-0.1-12.4 0.1-18.5zm2.8 30.9q0.4 1.1 0.7 2.3c3 0.2 5 3.4 6.1 5.1v0.2c1.3 1.9 2.5 3.9 4.5 4.8 1.9 0.9 4.5 0.5 7-1.1 1.4-0.9 2.7-2.2 4-3.4q0.9-0.9 1.8-1.8c1.3-1.1 2.5-1.9 3.7-2.5 1.8-0.9 2.9-1.1 3.4-1.1 1.6-9.4 1.3-20.3-0.9-33.8q-0.1-0.4-0.1-0.8c-0.3-1.8-0.5-3.5-2-4.5-1.5-1.1-4.2-1.4-6.4-1.7q-0.7-0.1-1.4-0.2c-4.8-0.8-9.6-0.9-14.4-0.4-4.7 0.4-7.5 1-7.7 5.5v2.5c-0.2 6.1-0.3 12.3-0.1 18.5 0.1 2.6 0.2 5.5 0.7 8.3 0.2 1.4 0.6 2.7 1.1 4.1zm5.8 8c-1.3-2.2-2.9-4.2-4.8-4.5q0 0 0 0.1c3 14.4 5.1 29.1 6.1 43.8 0.3 3.1 0.3 6.2 0.3 9.2 0 3.6 0.1 7.4 0.4 11.1 1 9.2 3 17.5 6 24.7q2.1 3.2 3.8 3.2 1.6-0.1 3.1-2.8c-0.1-31.2 3-59.1 9.6-85.3 0-0.2 0.2-0.5 0.3-1q0.1-0.6 0.2-1.1h0.1l0.1-1.8c-0.3 0-1.2 0.2-2.7 0.9-1.1 0.6-2.2 1.4-3.5 2.5q-0.9 0.8-1.8 1.7c-1.3 1.2-2.6 2.5-4.2 3.5-1.8 1.2-3.7 1.8-5.5 1.8q-1.3-0.1-2.5-0.6c-2.3-1-3.6-3.3-4.9-5.2z"
                />
                <path
                    id={71}
                    className="s5"
                    d="m509 516.5v-2h11.2v1l-6.3 16.5h-2.5l6-15.5zm16.2 15.5v-2h3.9v-13.3l-4.2 0.8v-2.2l4.2-0.8h2.4v15.5h3.8v2z"
                />
            </g>
            <g id="Group81" className={missingChecker(81) ? "g1-none" : "g1"}>
                <path
                    id="81up-fill2"
                    className="s0"
                    d="m488 558.4q-5.6-1.2-11.4-1.2-6.5 0-13.1 1.6 0.3-0.2 0.6-0.3c5-2.4 9-3.3 13.2-3 3.3 0.2 6.8 1.1 10.7 2.9z"
                />
                <path
                    id="81up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m492.9 563.5c0.2 1.2 0.2 2.2 0 3.7q-0.3 1.8-0.7 3.6c-1.4 7-3.9 13.1-7.2 18-2.4 3.5-4.9 5.3-7.3 5.3q-3 0-6.3-3.7c-3.7-4.2-7.2-10.7-11-20.2q-0.1-0.5-0.2-0.9c-0.2-1.9-0.5-4.4 0-6.5 0.3-1.3 1.3-2.4 2.6-2.7 9.1-2.4 18.2-2.4 27.1-0.2 0.9 0.3 1.7 0.9 2.2 1.7q0.5 0.8 0.8 1.9zm-4.1 2.7q-6.6-2.7-12.5-2.7-6 0-11.1 2.8c7.6-2.1 15.5-2 23.6-0.1z"
                />
                <path
                    id="81down-fill2"
                    className="s0"
                    d="m469.3 653.1c4.7-0.8 9.6-0.9 14.4-0.4 4.6 0.4 7.4 1 7.6 5.5l0.1 2.5c0.1 6.1 0.3 12.3 0 18.5 0 2.6-0.2 5.4-0.6 8.3-0.3 1.4-0.7 2.7-1.2 4.1q-0.3 1.1-0.6 2.3c-3.1 0.2-5.1 3.4-6.1 5.1l-0.1 0.2c-1.2 1.9-2.4 3.9-4.4 4.8-2 0.9-4.6 0.5-7-1.1-1.5-0.9-2.8-2.2-4-3.4q-0.9-0.9-1.9-1.8c-1.3-1.1-2.4-2-3.6-2.5-1.9-1-2.9-1.1-3.5-1.1-1.6-9.5-1.3-20.3 1-33.8q0-0.4 0.1-0.8c0.2-1.8 0.5-3.5 1.9-4.5 1.5-1.1 4.2-1.4 6.4-1.7q0.8-0.1 1.5-0.2z"
                />
                <path
                    id="81down-fill"
                    className="s0"
                    d="m458.6 695.2c0.4 0 1.3 0.2 2.8 0.9 1.1 0.6 2.2 1.4 3.4 2.4q0.9 0.9 1.8 1.8c1.3 1.2 2.6 2.5 4.2 3.5 1.8 1.2 3.8 1.8 5.6 1.7q1.3 0 2.4-0.5c2.3-1 3.7-3.3 4.9-5.3l0.1-0.1c1.3-2.2 2.9-4.2 4.9-4.5q-0.1 0-0.1 0.1c-3 14.4-5 29.1-6.1 43.8-0.2 3.1-0.2 6.2-0.3 9.2 0 3.6 0 7.4-0.4 11.1-0.9 9.2-3 17.5-6 24.7q-2.1 3.2-3.8 3.2-1.5-0.1-3-2.8c0.1-31.2-3.1-59.1-9.7-85.3 0-0.2-0.1-0.5-0.2-1q0 0-0.1 0-0.1-0.6-0.2-1.1z"
                />
                <path
                    id="81caries-vesial"
                    className={cariesChecker(81) ? cariesChecker(81).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m463 696v-35h24v33l-4 5-4 6h-5l-7-6zm1-116v-12h24v16l-5 8-6 2-5-2-6-9z"
                />
                <path
                    id="81implant-vesial"
                    className={implantChecker(81) ? implantChecker(81).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m463 696v-35h24v33l-4 5-4 6h-5l-7-6zm1-116v-12h24v16l-5 8-6 2-5-2-6-9z"
                />
                <path
                    id="81caries-mesial"
                    className={cariesChecker(81) ? cariesChecker(81).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m487 557v29l3-6 3-11v-5l-1-4-3-2zm-1 96v42l3-1 2-6 1-11v-11-7l-1-4-2-2z"
                />
                <path
                    id="81implant-mesial"
                    className={implantChecker(81) ? implantChecker(81).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m487 557v29l3-6 3-11v-5l-1-4-3-2zm-1 96v42l3-1 2-6 1-11v-11-7l-1-4-2-2z"
                />
                <path
                    id="81caries-distal"
                    className={cariesChecker(81) ? cariesChecker(81).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m465 557v26l-4.4-9.3-1.9-7.1 0.9-4.9 2-2.1zm-1 97.1c0 0 0 10.5 0 21 0 10.4 0 20.9 0 20.9l-4-1-3-1v-15-14l2-6.8 1.9-4z"
                />
                <path
                    id="81implant-distal"
                    className={implantChecker(81) ? implantChecker(81).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m465 557v26l-4.4-9.3-1.9-7.1 0.9-4.9 2-2.1zm-1 97.1c0 0 0 10.5 0 21 0 10.4 0 20.9 0 20.9l-4-1-3-1v-15-14l2-6.8 1.9-4z"
                />
                <path
                    id="81caries-occlusal"
                    className={cariesChecker(81) ? cariesChecker(81).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m461 654l3 8h22l5-7-3-2-5-1h-10-6zm-1-87l6 2 6 1h6l6-1 4-1 4-1 2-1v-4l-4-4-7-3-6-1-6 1-5 1-3 3-3 2z"
                />
                <path
                    id="81implant-occlusal"
                    className={implantChecker(81) ? implantChecker(81).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m461 654l3 8h22l5-7-3-2-5-1h-10-6zm-1-87l6 2 6 1h6l6-1 4-1 4-1 2-1v-4l-4-4-7-3-6-1-6 1-5 1-3 3-3 2z"
                />
                <path
                    id="81endo1"
                    className={endoRootCheker(81) ? endoRootCheker(81).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m473 784.7c0 0 1.4-9.1 2.8-16.1 0.9-8 1.7-17.3 1.7-17.3l0.1-13.7-1.5-25.5-0.5-7.3-3.5-0.1 1.6 24.3-0.8 32.2-0.3 19 0.7-8.9z"
                />
                <path
                    id="81up-outline2"
                    className="s3"
                    d="m476.3 563.5q5.9 0 12.5 2.7c-8.1-1.9-16-2-23.6 0.1q5.1-2.8 11.1-2.8z"
                />
                <path
                    id="81up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m458.9 567.3c-0.2-2.5-0.2-5.6 1.6-7.6 0.9-1 2.1-1.7 3.1-2.2 4.7-2.1 8.5-3.1 12.4-3.1q0.7 0 1.4 0c3.7 0.3 7.5 1.3 11.9 3.4 2.6 1.3 4 2.9 4.6 5.5 0.4 1.5 0.2 2.8 0 4q-0.3 1.9-0.6 3.7c-1.5 7.2-4 13.4-7.4 18.4-4.8 7-10.1 7.6-15.3 1.7q-0.3-0.3-0.6-0.7c-1.5-1.8-2.8-3.8-4-5.8-1.4-2.5-2.7-5.1-3.9-7.7-0.7-1.6-1.4-3.3-2.1-4.9q-0.5-1-0.7-2.1c-0.2-0.8-0.3-1.7-0.4-2.6zm18.4-11.8c-4.2-0.3-8.1 0.6-13.2 3q-0.3 0.1-0.6 0.3 6.6-1.6 13.1-1.6 5.8 0 11.4 1.2c-3.9-1.8-7.4-2.7-10.7-2.9zm-16.9 14.7c3.8 9.5 7.3 16 11 20.2q3.3 3.7 6.3 3.7c2.4 0 4.9-1.8 7.3-5.3 3.3-4.9 5.8-11 7.2-18q0.4-1.8 0.7-3.6c0.2-1.5 0.2-2.5 0-3.7q-0.3-1.1-0.8-1.9c-0.5-0.8-1.3-1.4-2.2-1.7-8.9-2.2-18-2.2-27.1 0.2-1.3 0.3-2.3 1.4-2.6 2.7-0.5 2.1-0.2 4.6 0 6.5q0.1 0.4 0.2 0.9z"
                />
                <path
                    id="81down-outline"
                    fillRule="evenodd"
                    className="s6"
                    d="m458 698.3c-0.4-1.4-0.5-2.2-0.6-2.9q0-0.5-0.1-1c-1.6-9.6-1.3-20.6 1-34.3q0-0.4 0.1-0.8c0.3-1.8 0.6-3.9 2.4-5.2 1.7-1.2 4.6-1.6 6.8-1.9q0.8-0.1 1.5-0.2c4.8-0.8 9.8-0.9 14.7-0.4 4.1 0.4 8.4 0.8 8.6 6.5l0.1 2.6c0.1 6.1 0.3 12.3 0 18.5-0.1 2.7-0.2 5.6-0.7 8.4-0.2 1.5-0.7 3-1.1 4.3-0.4 1.2-0.8 2.3-1 3.5-3 14.4-5.1 29.1-6.1 43.7-0.2 3-0.2 6.1-0.3 9.1 0 3.7 0 7.5-0.4 11.2-1 9.3-3 17.8-6.1 25.1-1.6 2.5-3.1 3.7-4.6 3.8q-0.1 0-0.2 0c-1.5-0.1-2.8-1.2-4-3.5l-0.1-0.1v-0.2c0.1-31.5-3.1-59.7-9.9-86.2zm1.3 0.8c6.6 26.2 9.8 54.1 9.7 85.3q1.5 2.7 3 2.8 1.7 0 3.8-3.2c3-7.2 5.1-15.5 6-24.7 0.4-3.7 0.4-7.5 0.4-11.1 0.1-3 0.1-6.1 0.3-9.2 1.1-14.7 3.1-29.4 6.1-43.8q0-0.1 0.1-0.1c-2 0.3-3.6 2.3-4.9 4.5l-0.1 0.2c-1.2 1.9-2.6 4.2-4.9 5.2q-1.1 0.5-2.4 0.5c-1.8 0.1-3.8-0.5-5.6-1.7-1.6-1-2.9-2.3-4.2-3.5q-0.9-0.9-1.8-1.8c-1.2-1-2.3-1.8-3.4-2.4-1.5-0.7-2.4-0.9-2.8-0.9l0.2 1.8q0.1 0.5 0.3 1.1c0.1 0.5 0.2 0.8 0.2 1zm29.4-4q0 0 0 0 0 0 0 0zm-27.3-40.1c-1.4 1-1.7 2.7-1.9 4.5q-0.1 0.4-0.1 0.8c-2.3 13.5-2.6 24.4-1 33.8 0.6 0 1.6 0.2 3.5 1.1 1.2 0.6 2.3 1.4 3.6 2.5q1 0.9 1.9 1.8c1.2 1.2 2.5 2.5 4 3.4 2.4 1.6 5 2 7 1.1 2-0.9 3.2-2.9 4.4-4.8l0.1-0.2c1-1.7 3-4.9 6.1-5.1q0.3-1.2 0.6-2.3c0.5-1.4 0.9-2.7 1.2-4.1 0.4-2.8 0.6-5.7 0.6-8.3 0.3-6.2 0.1-12.4 0-18.5l-0.1-2.5c-0.2-4.5-3-5.1-7.6-5.5-4.8-0.5-9.7-0.4-14.4 0.4q-0.7 0.1-1.5 0.2c-2.2 0.3-4.9 0.6-6.4 1.7zm27.3 40.1z"
                />
                <path
                    id={81}
                    fillRule="evenodd"
                    className="s5"
                    d="m466.3 522.7q-1.6-0.4-2.4-1.4-0.9-1.1-0.9-2.6 0-2.1 1.5-3.3 1.5-1.2 4.1-1.2 2.6 0 4.1 1.2 1.5 1.2 1.5 3.3 0 1.5-0.8 2.6-0.9 1-2.4 1.4 1.7 0.4 2.7 1.5 0.9 1.2 0.9 2.9 0 2.5-1.5 3.9-1.6 1.3-4.5 1.3-2.9 0-4.4-1.3-1.6-1.4-1.6-3.9 0-1.7 1-2.9 1-1.1 2.7-1.5zm2.3 1q-1.7 0-2.6 0.9-1 0.9-1 2.5 0 1.6 1 2.5 0.9 0.9 2.6 0.9 1.7 0 2.7-0.9 1-1 1-2.5 0-1.6-1-2.5-1-0.9-2.7-0.9zm-3.2-4.8q0 1.4 0.8 2.2 0.9 0.7 2.4 0.7 1.6 0 2.4-0.7 0.9-0.8 0.9-2.2 0-1.3-0.9-2.1-0.8-0.7-2.4-0.7-1.5 0-2.4 0.7-0.8 0.8-0.8 2.1zm13.8 13.1v-2h3.9v-13.3l-4.2 0.8v-2.2l4.2-0.8h2.4v15.5h3.8v2z"
                />
            </g>
            <g id="Group82" className={missingChecker(82) ? "g1-none" : "g1"}>
                <path
                    id="82up-fill2"
                    className="s0"
                    d="m410.3 560c5.8-4 11.8-5 18.2-2.9q4.2 1.4 8.7 4.5c-9.6-2.9-18.6-3.5-26.9-1.6z"
                />
                <path
                    id="82up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m443.1 571.6c-2.1 5.1-4.2 9.7-6.4 13.8l-0.1 0.2q-1.5 3-3.3 5.7c-0.7 1-1.4 2-2.2 2.8q-1.1 1.4-2.3 1.9c-2.2 1-4.4 1.2-6.5 0.5q-2.2-0.6-3.9-2.4-0.4-0.5-0.7-0.9c-4.8-6.6-8.5-14.3-11.2-22.7-0.1-3.4 0.3-6.1 1.5-8.1 0.3-0.5 0.8-0.9 1.4-1.1 8.9-2.3 18.8-1.7 29.4 1.9q1 0.3 1.8 1c2.5 1.7 3.3 4.1 2.5 7.4zm-33-4.4c8.9-1 17.9-0.9 26.9 0.5-9.4-3.1-18.4-3.2-26.9-0.5zm26.9 0.5q0.1 0 0.1 0 0 0 0.1 0-0.1 0-0.2 0z"
                />
                <path
                    id="82down-fill2"
                    className="s0"
                    d="m414.8 741.2c-0.4-16.3-1.6-29.2-3.6-41.2 0.8 0.8 1.7 1.5 2.6 2.1q0.4 0.3 0.9 0.6c1.8 1.4 3.5 3 5 4.7q0.3 0.3 0.5 0.6c0.6 0.7 1.2 1.4 2.2 1.7q1.6 0.6 3.5 0.6 0.2 0.1 0.3 0c3.4 0 6.2-2 8.6-3.7 2.6-1.9 4.9-3.7 6.9-5.7q-0.2 0.6-0.3 1.3-0.3 4.2-0.9 8.4c-0.6 5.5-1.2 11.1-1.5 16.7l-0.2 2.9c-0.3 7.3-0.7 15-1.7 22.2-2.2 15.8-5.9 29.1-11.3 40.6-0.6 1.5-1.3 2.8-2.4 3.9-1.1 1.1-2.5 1.7-3.8 1.5-3.1-0.4-3-4.4-2.6-8.8q0.2-1.3 0.2-2.2c0-4.5-0.1-9.5-0.5-15.5-0.2-3.9-0.5-7.8-0.7-11.6-0.5-6.2-1-12.7-1.2-19.1z"
                />
                <path
                    id="82down-fill"
                    className="s0"
                    d="m443.4 668.1c0.1 5.4 0.3 11 0.3 16.5 0 4-0.1 7.4-0.6 10.8-0.1 1-0.5 2.1-0.8 3.2q0 0.1 0 0.1c-2.2 2.4-4.9 4.7-8.2 7-2.3 1.7-4.8 3.5-7.9 3.6-1.3 0-2.4-0.2-3.4-0.6-0.8-0.2-1.2-0.8-1.7-1.4q-0.3-0.3-0.5-0.6c-1.6-1.8-3.4-3.4-5.3-4.8q-0.4-0.4-0.9-0.7c-1.3-1-2.6-1.9-3.5-3.3q-0.2-0.8-0.3-1.5c-0.9-5.1-1.8-9.9-2.2-15.1l-0.2-2.7c-0.3-4.3-0.5-7.6-0.8-12.5-0.1-1.6-0.2-3.4 0.4-4.9 1.3-3.1 5.1-5.2 7.8-5.2 3.9 0 8.1-0.1 12.9-0.3q4.5-0.2 9.1-0.4l0.6-0.1q0.6 0 1.3 0c1.2 0 2.2 0.2 2.9 1.4 0.7 1.3 0.8 2.9 0.8 4.3q0.1 3.6 0.2 7.2z"
                />
                <path
                    id="82caries-vesial"
                    className={cariesChecker(82) ? cariesChecker(82).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m413 701l1-35 4-1 12 1h8v37l-3 3-6 4-5-1zm0-114v-17h24v16l-6 9-5 3h-4l-5-5z"
                />
                <path
                    id="82implant-vesial"
                    className={implantChecker(82) ? implantChecker(82).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m413 701l1-35 4-1 12 1h8v37l-3 3-6 4-5-1zm0-114v-17h24v16l-6 9-5 3h-4l-5-5z"
                />
                <path
                    id="82caries-mesial"
                    className={cariesChecker(82) ? cariesChecker(82).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m436 560v29l2-7 4-6 2-7-1-3-3-3zm1 95v48l3-2 3-3 1-6v-23-8l-1-5-3-1z"
                />
                <path
                    id="82implant-mesial"
                    className={implantChecker(82) ? implantChecker(82).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m436 560v29l2-7 4-6 2-7-1-3-3-3zm1 95v48l3-2 3-3 1-6v-23-8l-1-5-3-1z"
                />
                <path
                    id="82caries-distal"
                    className={cariesChecker(82) ? cariesChecker(82).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m413 558l1 29-5-9-2-8v-6l2-3zm1 98v45l-2-2-2-5-1-8-1-10v-9-6l2-3z"
                />
                <path
                    id="82implant-distal"
                    className={implantChecker(82) ? implantChecker(82).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m413 558l1 29-5-9-2-8v-6l2-3zm1 98v45l-2-2-2-5-1-8-1-10v-9-6l2-3z"
                />
                <path
                    id="82caries-occlusal"
                    className={cariesChecker(82) ? cariesChecker(82).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m409 659l2 7 28 1 5-7v-3l-3-2-11-1h-9l-7 1zm-2-90l5 3h9 8l7-1 5-1 3-3-5-4-4-4-7-3-6-1h-5l-4 3-5 3-2 4z"
                />
                <path
                    id="82implant-occlusal"
                    className={implantChecker(82) ? implantChecker(82).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m409 659l2 7 28 1 5-7v-3l-3-2-11-1h-9l-7 1zm-2-90l5 3h9 8l7-1 5-1 3-3-5-4-4-4-7-3-6-1h-5l-4 3-5 3-2 4z"
                />
                <path
                    id="82endo1"
                    className={endoRootCheker(82) ? endoRootCheker(82).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m421 795c0 0 2.4-10 4.6-19.7 1.8-9.5 3.4-19.2 3.4-19.2l1-13.7-1-25.5-0.4-7.5-3.5-0.4 0.5 26-2.8 36-1.8 19.5 1.5-8.8z"
                />
                <path
                    id="82up-outline2"
                    className="s3"
                    d="m437.1 567.7q0 0 0.1 0c-9.1-1.4-18.1-1.5-27.1-0.5 8.5-2.7 17.6-2.6 27 0.5z"
                />
                <path
                    id="82down-outline"
                    fillRule="evenodd"
                    className="s6"
                    d="m413.8 741.3c-0.6-18-1.9-31.8-4.3-44.7-0.9-5.1-1.8-9.9-2.2-15.3l-0.2-2.6c-0.3-4.3-0.5-7.7-0.8-12.5-0.1-1.7-0.2-3.7 0.5-5.5 1.4-3.4 5.7-5.8 8.8-5.8 3.9 0 8.1-0.1 12.8-0.3q4.6-0.2 9.1-0.4h0.6q0.7-0.1 1.4-0.1c1.2 0 2.8 0.2 3.8 2 0.9 1.5 0.9 3.3 1 4.8q0.1 3.5 0.2 7.1c0.1 5.5 0.3 11.1 0.3 16.6-0.1 4.1-0.2 7.5-0.6 10.9-0.1 1.2-0.5 2.3-0.8 3.5-0.4 1.1-0.8 2.2-0.9 3.3q-0.4 4.2-0.9 8.5c-0.6 5.4-1.2 11-1.5 16.5l-0.2 2.9c-0.4 7.4-0.7 15.1-1.7 22.4-2.2 15.9-5.9 29.2-11.4 40.9-0.7 1.5-1.4 3-2.6 4.2-1.2 1.2-2.7 1.8-4.1 1.8q-0.3 0-0.6 0c-4.4-0.6-3.9-6.2-3.5-10 0-0.8 0.1-1.5 0.1-2.1 0-4.5-0.1-9.4-0.4-15.5-0.3-3.8-0.6-7.7-0.8-11.5-0.5-6.3-1-12.7-1.2-19.1zm2.3 19c0.2 3.8 0.5 7.7 0.7 11.6 0.4 6 0.5 11 0.5 15.5q0 0.9-0.2 2.2c-0.4 4.4-0.5 8.4 2.6 8.8 1.3 0.2 2.7-0.4 3.8-1.5 1.1-1.1 1.8-2.4 2.4-3.9 5.4-11.5 9.1-24.8 11.3-40.6 1-7.2 1.4-14.8 1.7-22.2l0.2-2.9c0.3-5.6 0.9-11.2 1.5-16.6 0.4-2.9 0.7-5.7 0.9-8.5q0.1-0.7 0.3-1.3c-2 2-4.3 3.8-6.9 5.7-2.4 1.7-5.2 3.7-8.6 3.8q-0.1 0-0.3 0-1.9 0-3.5-0.6c-1-0.4-1.6-1.1-2.2-1.8q-0.2-0.3-0.5-0.6c-1.5-1.7-3.2-3.3-5-4.6q-0.5-0.4-0.9-0.7c-0.9-0.6-1.8-1.3-2.6-2.1 2 12 3.2 24.9 3.6 41.2 0.2 6.4 0.7 12.9 1.2 19.1zm-0.4-104.3c-2.7 0-6.5 2.2-7.8 5.2-0.6 1.5-0.5 3.3-0.4 4.9 0.3 4.9 0.5 8.2 0.8 12.6l0.2 2.6c0.4 5.2 1.3 10 2.2 15.1q0.1 0.7 0.3 1.5c0.9 1.4 2.2 2.3 3.5 3.3q0.5 0.3 0.9 0.7c1.9 1.4 3.7 3 5.2 4.8q0.3 0.3 0.6 0.6c0.5 0.6 0.9 1.2 1.7 1.4 1 0.4 2.1 0.6 3.4 0.6 3-0.1 5.6-1.9 7.9-3.6 3.3-2.3 6-4.6 8.2-7q0 0 0-0.1c0.3-1 0.7-2.2 0.8-3.2 0.4-3.4 0.5-6.8 0.6-10.8 0-5.5-0.2-11.1-0.3-16.5q-0.1-3.6-0.2-7.2c0-1.4-0.1-3-0.8-4.3-0.7-1.2-1.7-1.4-2.9-1.4q-0.7 0-1.3 0l-0.6 0.1q-4.6 0.2-9.2 0.4c-4.7 0.2-8.9 0.3-12.8 0.3z"
                />
                <path
                    id="82up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m405.5 570.7v-0.1c-0.3-4.8 0.8-8.4 3.1-10.6 6.3-4.9 13.2-6.2 20.3-3.9 4 1.3 8 3.7 12.4 7.3 2.7 1.8 3.7 4.7 2.8 8.5-2.1 5.3-4.2 9.8-6.5 14l-0.1 0.2c-1 2-2.1 4-3.4 5.8-0.6 1-1.3 2-2.2 2.9-0.9 1-1.7 1.7-2.6 2.1-4.1 2-8.5 1.2-11.7-2.1q-0.4-0.5-0.8-0.9c-4.8-6.8-8.6-14.6-11.3-23.2zm23-13.6c-6.4-2.1-12.4-1.1-18.2 2.9 8.3-1.9 17.3-1.3 26.9 1.6q-4.5-3.1-8.7-4.5zm-20.5 5.3q-1.7 3-1.5 8.1c2.7 8.4 6.4 16.1 11.2 22.7q0.3 0.4 0.7 0.9 1.7 1.8 3.9 2.4c2.1 0.7 4.3 0.5 6.5-0.5q1.2-0.5 2.3-1.9c0.8-0.8 1.5-1.8 2.2-2.8q1.8-2.7 3.3-5.7l0.1-0.2c2.2-4.1 4.3-8.7 6.4-13.8 0.8-3.3 0-5.7-2.5-7.4q-0.8-0.7-1.8-1c-10.6-3.6-20.5-4.2-29.4-1.9-0.6 0.2-1.1 0.6-1.4 1.1z"
                />
                <path
                    id={82}
                    fillRule="evenodd"
                    className="s5"
                    d="m413.3 523.7q-1.6-0.4-2.4-1.4-0.9-1.1-0.9-2.6 0-2.1 1.5-3.3 1.5-1.2 4.1-1.2 2.6 0 4.1 1.2 1.5 1.2 1.5 3.3 0 1.5-0.8 2.6-0.9 1-2.4 1.4 1.7 0.4 2.7 1.5 0.9 1.2 0.9 2.9 0 2.5-1.5 3.9-1.6 1.3-4.5 1.3-2.9 0-4.4-1.3-1.6-1.4-1.6-3.9 0-1.7 1-2.9 1-1.1 2.7-1.5zm2.3 1q-1.7 0-2.6 0.9-1 0.9-1 2.5 0 1.6 1 2.5 0.9 0.9 2.6 0.9 1.7 0 2.7-0.9 1-1 1-2.5 0-1.6-1-2.5-1-0.9-2.7-0.9zm-3.2-4.8q0 1.4 0.8 2.2 0.9 0.7 2.4 0.7 1.6 0 2.4-0.7 0.9-0.8 0.9-2.2 0-1.3-0.9-2.1-0.8-0.7-2.4-0.7-1.5 0-2.4 0.7-0.8 0.8-0.8 2.1zm15.5 11.1h8.2v2h-11.1v-2q1.4-1.4 3.7-3.7 2.3-2.4 2.9-3.1 1.2-1.2 1.6-2.1 0.5-0.9 0.5-1.8 0-1.4-1-2.2-1-0.9-2.6-0.9-1.1 0-2.3 0.4-1.2 0.4-2.7 1.1v-2.4q1.5-0.5 2.7-0.8 1.3-0.3 2.3-0.3 2.7 0 4.3 1.3 1.6 1.4 1.6 3.7 0 1.1-0.4 2-0.4 1-1.4 2.3-0.3 0.3-1.9 2-1.6 1.6-4.4 4.5z"
                />
            </g>
            <g id="Group83" className={missingChecker(83) ? "g1-none" : "g1"}>
                <path
                    id="83down-fill2"
                    className="s0"
                    d="m392.6 700c-0.8 3.2-1.7 7.1-2.2 11-0.8 6-1.4 11.5-1.7 16.7-0.5 7.2-0.5 14.5-0.5 21.6 0.1 13.2 0.1 26.9-3.2 40.1-1.3 5.3-3.2 11.1-7.8 13.8q-1.2 0.6-2 0.6c-3.1 0-3.2-6.9-3.3-10.7 0-0.8 0-1.5-0.1-1.9-0.8-11-2.4-22-4.7-32.7-0.4-2.1-1-4.4-1.5-6.5-0.7-3-1.5-6.1-2-9-0.6-3.6-1-7.2-1.3-10.8q-0.2-2.6-0.5-5.2c-0.3-2.1-0.4-4.4-0.6-6.6-0.4-4.6-0.8-9.4-1.8-13.9 3.4 5.7 7.3 8.8 11.6 9.2q0.5 0 1 0c6.1 0 12.9-5.2 20.6-15.7z"
                />
                <path
                    id="83down-fill"
                    className="s0"
                    d="m363.1 662.8c2.3-1.1 4.4-2.6 6.9-4.9q0.4-0.4 0.9-0.8c1.1-1.1 2.2-2.2 3.6-2.7q1.2-0.5 2.5-0.5c1.5 0 3.1 0.5 4.6 1.3 4.1 2.4 8.8 7.4 11.3 12.1 2 3.6 3 7.9 3.1 12.9 0 5.6-1.3 11.2-2.5 16l-0.2 0.6q0 0.2 0 0.3c-8.5 12.2-15.9 18.1-22.2 17.5-5-0.5-9.4-5-13.3-13.5q-0.3-0.9-0.6-1.8c-0.6-1.6-1.2-3.3-1.7-5.1-1.5-5.7-1.8-12.1-0.9-20.7 0.2-2.3 0.6-4.7 1.9-6.5 1.4-1.9 3.6-2.8 5.6-3.8q0.5-0.2 1-0.4z"
                />
                <path
                    id="83up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m391.6 567.4c2 2.8 2.2 6.1 0.5 9.9-2.1 5.7-5.3 11.3-9.5 16.9-4.2 5-8.3 6.2-12.6 3.5-0.2-0.1-0.4-0.2-0.5-0.3-7.2-7.2-12.2-15.1-14.9-23.4-0.7-2.1-0.8-4.8-0.4-7.3 0.2-1.1 0.4-2.4 1-3.2 1.1-1.6 2.8-2.8 4.4-4l0.1-0.1q2.5-1.7 5-2.8c3.9-1.7 7.5-2.1 10.9-1.3 1.5 0.4 3.1 1.1 4.9 2.2 1 0.6 2 1.3 2.8 1.9 3.2 2.3 6 5 8.3 8zm-33.5 1.4c10.5-3.4 20.6-2.9 30.6 0.3-11.6-4.7-21.9-4.8-30.6-0.3z"
                />
                <path
                    id="83caries-vesial"
                    className={cariesChecker(83) ? cariesChecker(83).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m365 712l-1-50 9-1 16 1v42l-3 3-6 5-6 3-5-1zm-5-125v-18l8 1 12-1 6 1v20l-4 5-6 4-5-1-5-5z"
                />
                <path
                    id="83implant-vesial"
                    className={implantChecker(83) ? implantChecker(83).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m365 712l-1-50 9-1 16 1v42l-3 3-6 5-6 3-5-1zm-5-125v-18l8 1 12-1 6 1v20l-4 5-6 4-5-1-5-5z"
                />
                <path
                    id="83caries-mesial"
                    className={cariesChecker(83) ? cariesChecker(83).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m385 560v30l4-4 3-7 2-7-1-3-3-5-2-3zm3 100v44l3-3 3-6 2-9v-8-6l-4-6-2-4z"
                />
                <path
                    id="83implant-mesial"
                    className={implantChecker(83) ? implantChecker(83).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m385 560v30l4-4 3-7 2-7-1-3-3-5-2-3zm3 100v44l3-3 3-6 2-9v-8-6l-4-6-2-4z"
                />
                <path
                    id="83caries-distal"
                    className={cariesChecker(83) ? cariesChecker(83).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m361 559l1 30-3-6-3-5-2-5v-6l1-4 3-3zm4 103v50l-2-3-4-6-3-6-1-7-1-6v-7l1-5v-3l2-3 4-2z"
                />
                <path
                    id="83implant-distal"
                    className={implantChecker(83) ? implantChecker(83).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m361 559l1 30-3-6-3-5-2-5v-6l1-4 3-3zm4 103v50l-2-3-4-6-3-6-1-7-1-6v-7l1-5v-3l2-3 4-2z"
                />
                <path
                    id="83caries-occlusal"
                    className={cariesChecker(83) ? cariesChecker(83).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m363 662l28 1-4-5-6-4-5-1-4 2-4 4zm-10-96l6 3 11 2h10l10-1 3-1-3-6-6-4-7-5h-8l-7 3-6 4z"
                />
                <path
                    id="83implant-occlusal"
                    className={implantChecker(83) ? implantChecker(83).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m363 662l28 1-4-5-6-4-5-1-4 2-4 4zm-10-96l6 3 11 2h10l10-1 3-1-3-6-6-4-7-5h-8l-7 3-6 4z"
                />
                <path
                    id="83endo1"
                    className={endoRootCheker(83) ? endoRootCheker(83).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m376 800.8c0 0 1.6-10.2 3.2-20 1.2-9.6 2.1-19.4 2.1-19.4v-13.7l-2.7-25.4-1-7.5-3.5-0.1 2.3 25.9-0.3 36.1-0.4 19.5 0.8-8.9z"
                />
                <path
                    id="83up-outline2"
                    className="s3"
                    d="m388.7 569.1c-9.9-3.2-20.1-3.7-30.6-0.3 8.7-4.5 19-4.4 30.6 0.3z"
                />
                <path
                    id="83down-outline"
                    fillRule="evenodd"
                    className="s6"
                    d="m353.5 673.4c0.3-2.5 0.7-5 2.2-7.1 1.5-2 3.8-3.1 6-4.1q0.5-0.2 0.9-0.4c2.2-1 4.3-2.5 6.6-4.7q0.5-0.4 0.9-0.8c1.2-1.1 2.4-2.3 4-2.9 2.3-1 5.4-0.6 8 0.9 4.3 2.5 9.1 7.6 11.8 12.4 2.1 3.8 3.1 8.3 3.2 13.4 0 5.8-1.3 11.5-2.5 16.4l-0.2 0.6q-0.4 1.5-0.7 2.9c-0.8 3.3-1.7 7.2-2.2 11.1-0.8 6-1.4 11.5-1.7 16.7-0.5 7.1-0.5 14.4-0.5 21.5 0.1 13.3 0.1 27-3.2 40.4-1.4 5.5-3.4 11.6-8.4 14.4q-1.4 0.8-2.5 0.8c-4.2 0-4.3-7.4-4.4-11.8 0-0.7 0-1.4-0.1-1.8-0.8-10.9-2.4-21.9-4.6-32.6-0.5-2.1-1-4.3-1.5-6.4-0.8-3-1.6-6.2-2.1-9.1-0.6-3.6-0.9-7.3-1.3-10.9q-0.2-2.6-0.5-5.2c-0.2-2.1-0.4-4.4-0.6-6.6-0.4-5.4-0.9-11-2.4-16.2-0.4-1.5-1-3.1-1.5-4.6-0.6-1.7-1.2-3.4-1.7-5.2-1.6-5.8-1.9-12.3-1-21.1zm3.7 25.9q0.3 0.9 0.7 1.8 5.7 12.7 13.2 13.5c6.3 0.6 13.8-5.3 22.2-17.5q0-0.1 0.1-0.3l0.1-0.6c1.2-4.8 2.5-10.4 2.5-16-0.1-5-1.1-9.3-3.1-12.9-2.5-4.7-7.2-9.7-11.3-12.1-1.5-0.8-3.1-1.3-4.6-1.3q-1.3 0-2.5 0.5c-1.4 0.5-2.5 1.6-3.6 2.7q-0.5 0.4-0.9 0.8c-2.5 2.3-4.6 3.8-6.9 4.9q-0.5 0.2-1 0.4c-2 1-4.2 1.9-5.5 3.8-1.4 1.8-1.8 4.2-2 6.5-0.9 8.6-0.6 15 1 20.7 0.4 1.8 1 3.5 1.6 5.1zm5.1 32.9c0.3 3.6 0.7 7.2 1.3 10.8 0.5 2.9 1.3 6 2 9 0.5 2.2 1.1 4.4 1.5 6.5 2.3 10.7 3.9 21.7 4.7 32.7 0.1 0.5 0.1 1.1 0.1 1.9 0.1 3.8 0.2 10.7 3.3 10.7q0.8 0 2-0.6c4.6-2.7 6.5-8.5 7.8-13.8 3.3-13.2 3.3-26.9 3.2-40.1 0-7.1 0-14.4 0.5-21.6 0.4-5.2 0.9-10.7 1.7-16.7 0.5-3.9 1.4-7.8 2.2-11-7.7 10.5-14.5 15.7-20.6 15.7q-0.5 0-1 0c-4.3-0.4-8.2-3.5-11.6-9.2 1 4.5 1.4 9.3 1.8 13.9 0.2 2.2 0.4 4.5 0.6 6.6q0.3 2.6 0.5 5.2z"
                />
                <path
                    id="83up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m368.9 598.2c-7.5-7.4-12.6-15.4-15.3-23.8-0.7-2.3-0.9-5.2-0.4-7.9 0.2-1.2 0.4-2.6 1.1-3.6 1.3-1.8 3.1-3.1 4.7-4.3h0.1c1.7-1.2 3.5-2.3 5.2-3 4-1.8 7.9-2.2 11.6-1.3 1.6 0.4 3.3 1.1 5.1 2.2 1.1 0.7 2.1 1.4 3 2 3.2 2.4 6.1 5.2 8.4 8.3 2.3 3.1 2.5 6.7 0.7 10.9-2.1 5.7-5.4 11.5-9.7 17.1-4.7 5.7-9.6 6.9-14.5 3.4zm-13.7-34.7c-0.6 0.8-0.8 2.1-1 3.2-0.4 2.5-0.3 5.2 0.4 7.3 2.7 8.3 7.7 16.2 14.9 23.4 0.1 0.1 0.3 0.2 0.5 0.3 4.3 2.7 8.4 1.5 12.6-3.6 4.2-5.5 7.4-11.1 9.5-16.8 1.7-3.8 1.5-7.1-0.5-9.9-2.3-3-5.1-5.7-8.3-8-0.8-0.6-1.8-1.3-2.8-1.9-1.8-1.1-3.4-1.8-4.9-2.2-3.4-0.8-7-0.4-10.9 1.3q-2.5 1.1-4.9 2.8l-0.2 0.1c-1.6 1.2-3.3 2.4-4.4 4z"
                />
                <path
                    id={83}
                    fillRule="evenodd"
                    className="s5"
                    d="m363.3 524.7q-1.6-0.4-2.4-1.4-0.9-1.1-0.9-2.6 0-2.1 1.5-3.3 1.5-1.2 4.1-1.2 2.6 0 4.1 1.2 1.5 1.2 1.5 3.3 0 1.5-0.8 2.6-0.9 1-2.4 1.4 1.7 0.4 2.7 1.5 0.9 1.2 0.9 2.9 0 2.5-1.5 3.9-1.6 1.3-4.5 1.3-2.9 0-4.4-1.3-1.6-1.4-1.6-3.9 0-1.7 1-2.9 1-1.1 2.7-1.5zm2.3 1q-1.7 0-2.6 0.9-1 0.9-1 2.5 0 1.6 1 2.5 0.9 0.9 2.6 0.9 1.7 0 2.7-0.9 1-1 1-2.5 0-1.6-1-2.5-1-0.9-2.7-0.9zm-3.2-4.8q0 1.4 0.8 2.2 0.9 0.7 2.4 0.7 1.6 0 2.4-0.7 0.9-0.8 0.9-2.2 0-1.3-0.9-2.1-0.8-0.7-2.4-0.7-1.5 0-2.4 0.7-0.8 0.8-0.8 2.1zm20.6 3.7q1.7 0.3 2.7 1.5 0.9 1.1 0.9 2.8 0 2.6-1.8 4-1.7 1.4-5 1.4-1.1 0-2.3-0.2-1.2-0.2-2.4-0.6v-2.3q1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2.2 0 3.4-0.8 1.2-0.9 1.2-2.6 0-1.5-1.1-2.4-1.1-0.9-3-0.9h-2.1v-1.9h2.2q1.7 0 2.6-0.7 1-0.7 1-2 0-1.4-1-2.1-1-0.7-2.7-0.7-1 0-2.1 0.2-1.1 0.2-2.5 0.6v-2.1q1.4-0.3 2.5-0.5 1.2-0.2 2.3-0.2 2.7 0 4.2 1.2 1.6 1.2 1.6 3.3 0 1.5-0.8 2.5-0.9 1-2.4 1.4z"
                />
            </g>
            <g id="Group84" className={missingChecker(84) ? "g1-none" : "g1"}>
                <path
                    id="84up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m340.7 571.2c5.8 10.1 2.9 19.9-9.1 30.1-2.9 2.4-6.7 5.1-11.2 4.4-4-0.7-7.4-3.6-10.7-9.1l-0.2-0.3c-2.6-4.5-4.5-9.6-5.5-15.1-1.1-5.7-0.6-10.2 1.3-13.6 2.6-4.4 5.9-9.1 11.2-10.5 3.1-0.8 6.3-0.5 9.7 1 2 0.9 4.9 2.3 7 4.2 3.2 3 5.8 6 7.5 8.9zm-27 16.5c0.2-0.3 0.6-0.3 0.8 0q5.4 6.7 14.7 4.8c0.4-0.1 0.8 0.2 0.8 0.6q-0.1 1.7 0.6 3.1c0.5-5.1 2.3-10 5-14.8-2.7 3.5-4.5 6.6-5.2 9.2-0.1 0.3-0.4 0.6-0.7 0.7-6.5 1.5-11.4-0.1-14.8-4.9-0.3-0.3-0.3-0.7-0.3-1.1 0.5-3.1-0.7-7.7-2-12 0 0 1.6 9.8 0.8 12.6-0.3 1.3-2.2 3.2-2.2 3.2q1.6-0.2 2.5-1.4z"
                />
                <path
                    id="84down-fill2"
                    className="s0"
                    d="m331 700.7c2.4-1.5 4.7-1.9 7.1-1.4 0 17.7-0.3 33.1-1 47-0.5 8.7-0.9 16.9-3.9 25.1-2.8 7.6-7.4 14.9-13.6 21.6-0.7 0.7-1.2 1.3-2 1.5-1.2 0.3-2.7-0.7-3.2-2.2-0.6-1.4-0.4-3.2-0.2-5q0.1-0.9 0.1-1.7c0.2-3 0.3-5.6 0.2-8.1 0-5.2-0.4-10.4-1-15.4-0.5-3.6-1.2-8.3-2.6-12.9-1-3.4-1-7.1-1-10.7q0-0.9-0.1-1.7c-0.1-12.7-2.2-25.2-6.1-37.2q-0.5-1.4-1-2.9c3 0.1 5.5 1.6 7.7 4.6l0.4 0.6c1.5 2.2 3.1 4.5 5.7 5.4q1.1 0.4 2.2 0.4c2.4 0 4.7-1.2 5.9-2.1 1.1-0.7 2.2-1.6 3.2-2.4 1-0.9 2.1-1.8 3.2-2.5z"
                />
                <path
                    id="84down-fill"
                    className="s0"
                    d="m310.6 659.7c1.8-0.9 3.4-2 4.9-3q1.5-1 3-1.9 1.4-0.9 2.8-0.9c1.5 0 2.9 0.8 4.7 2 2.8 2 5.7 3.9 8.7 5.6l0.2 0.1c3.4 2 6.5 3.8 7.1 7.9 1.4 10.6 0.1 20.3-3.6 28.8-2.8-0.7-5.4-0.2-8 1.5-1.2 0.8-2.3 1.7-3.3 2.6-1 0.8-2 1.6-3.1 2.3-1.4 1-4.4 2.5-7.1 1.6-2.3-0.8-3.8-2.9-5.2-5l-0.4-0.6c-2.5-3.5-5.4-5.1-9-5.1-1.3-3.9-2.7-8-3.3-12.2-0.2-1.5-0.2-3-0.2-4.4 0-1.1 0-2.2-0.1-3.4-0.2-2.6-0.4-5.1 0.2-7.5 1-3.9 4.8-5.5 8.6-7.1 1.1-0.4 2.1-0.8 3.1-1.3z"
                />
                <path
                    id="84caries-oral"
                    className={cariesChecker(84) ? cariesChecker(84).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m304 586h1l13-8h8l9 4 9 3-1-11-6-9-8-6-6-3-8 1-7 5-3 6-1 6"
                />
                <path
                    id="84implant-oral"
                    className={implantChecker(84) ? implantChecker(84).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m304 586h1l13-8h8l9 4 9 3-1-11-6-9-8-6-6-3-8 1-7 5-3 6-1 6"
                />
                <path
                    id="84caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(84) ? cariesChecker(84).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m335 661v37l-5 3-6 4-4 2-4-1-8-8 1-38 3-2 5-3 5-1 6 3 5 3zm8-73l-2 4-11 11-6 3h-4l-5-2-5-6-3-6-3-10 4 3 7 5c0 0 5.6 3.4 7 3h10l11-7z"
                />
                <path
                    id="84implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(84) ? implantChecker(84).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m335 661v37l-5 3-6 4-4 2-4-1-8-8 1-38 3-2 5-3 5-1 6 3 5 3zm8-73l-2 4-11 11-6 3h-4l-5-2-5-6-3-6-3-10 4 3 7 5c0 0 5.6 3.4 7 3h10l11-7z"
                />
                <path
                    id="84caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(84) ? cariesChecker(84).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m335 585l-1 4-1 3-1 9 9-9 2-5 1-8-2-8-8-9v19zm-1 76v37h4l3-5 2-13-1-11-3-5z"
                />
                <path
                    id="84implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(84) ? implantChecker(84).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m335 585l-1 4-1 3-1 9 9-9 2-5 1-8-2-8-8-9v19zm-1 76v37h4l3-5 2-13-1-11-3-5z"
                />
                <path
                    id="84caries-distal"
                    fillRule="evenodd"
                    className={cariesChecker(84) ? cariesChecker(84).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m313 584v4l1 3v10l-3-3-3-4-2-4-1-5-2-8v-3l1-4 2-3 2-4 3-4 4-2v21zm-4 76v38l-5-2-2-1-1-6-2-6v-16l3-4 4-2z"
                />
                <path
                    id="84implant-distal"
                    fillRule="evenodd"
                    className={implantChecker(84) ? implantChecker(84).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m313 584v4l1 3v10l-3-3-3-4-2-4-1-5-2-8v-3l1-4 2-3 2-4 3-4 4-2v21zm-4 76v38l-5-2-2-1-1-6-2-6v-16l3-4 4-2z"
                />
                <path
                    id="84caries-occlusal"
                    className={cariesChecker(84) ? cariesChecker(84).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m311 579l10-3 10 3 5 5v6l-3 4-10 2-7-3-6-8z"
                />
                <path
                    id="84implant-occlusal"
                    className={implantChecker(84) ? implantChecker(84).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m311 579l10-3 10 3 5 5v6l-3 4-10 2-7-3-6-8z"
                />
                <path
                    id="84endo1"
                    className={endoRootCheker(84) ? endoRootCheker(84).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m319 791c0 0 2.4-10 4.6-19.7 1.8-9.5 3.4-19.2 3.4-19.2l1-13.7-1-25.5-0.4-7.5-3.5-0.4 0.5 26-2.8 36-1.8 19.5 1.5-8.8z"
                />
                <path
                    id="84up-outline"
                    className="s3"
                    d="m311.2 589.1c0 0 1.9-1.9 2.2-3.2 0.8-2.8-0.8-12.6-0.8-12.6 1.3 4.3 2.5 8.9 2 12 0 0.4 0 0.8 0.3 1.1 3.4 4.8 8.3 6.4 14.8 4.9 0.3-0.1 0.6-0.3 0.7-0.7 0.7-2.6 2.5-5.6 5.2-9.2-2.7 4.8-4.5 9.7-5 14.8q-0.7-1.4-0.7-3.1c0.1-0.4-0.3-0.7-0.7-0.6q-9.3 1.9-14.7-4.8c-0.2-0.2-0.6-0.3-0.8 0q-0.9 1.2-2.5 1.4z"
                />
                <path
                    id="84up-outline2"
                    fillRule="evenodd"
                    className="s3"
                    d="m303 581.4c-1.1-6-0.7-10.7 1.4-14.3 2.7-4.6 6.1-9.5 11.8-11 3.3-0.9 6.8-0.5 10.4 1 2.1 0.9 5.1 2.4 7.3 4.5 3.3 3 5.9 6 7.7 9.1 6.1 10.4 2.9 21-9.4 31.4-3 2.6-7.1 5.4-11.9 4.6-5.8-0.9-9.3-5.9-11.5-9.5l-0.2-0.3c-2.7-4.7-4.6-9.9-5.6-15.5zm13.5-24.3c-5.3 1.4-8.6 6.1-11.2 10.5-1.9 3.4-2.4 7.9-1.3 13.6 1 5.5 2.9 10.6 5.5 15.1l0.2 0.3c3.3 5.5 6.7 8.4 10.7 9.1 4.5 0.7 8.3-2 11.2-4.4 12-10.2 14.9-20 9.1-30.1-1.7-2.9-4.3-5.9-7.5-8.9-2.1-1.9-5-3.3-7-4.2-3.4-1.4-6.6-1.8-9.7-1z"
                />
                <path
                    id="84down-outline"
                    fillRule="evenodd"
                    className="s6"
                    d="m301.5 696.6c-1.5-4.2-3-8.6-3.6-13.1-0.2-1.5-0.2-3-0.2-4.5 0-1.1 0-2.2-0.1-3.3-0.2-2.7-0.4-5.3 0.2-7.8 1.2-4.5 5.5-6.3 9.2-7.9 1.1-0.4 2.2-0.8 3.1-1.3 1.7-0.9 3.3-1.9 4.8-2.9 1-0.7 2-1.3 3-1.9q1.7-1.1 3.4-1.1c2 0 3.7 1.1 5.3 2.2 2.8 2 5.7 3.8 8.6 5.5l0.3 0.2c3.4 2 6.9 4 7.6 8.7 1.4 10.9 0.1 20.8-3.9 29.6 0 17.8-0.3 33.3-1.1 47.4-0.4 8.7-0.8 17-3.9 25.4-2.8 7.7-7.5 15.1-13.8 22-0.7 0.7-1.5 1.5-2.5 1.7q-0.4 0.2-0.8 0.1c-1.6 0-3.2-1.2-3.8-2.9-0.6-1.7-0.4-3.6-0.2-5.5q0.1-0.8 0.2-1.7c0.1-2.9 0.2-5.5 0.1-8 0-5.1-0.3-10.3-1-15.3-0.5-3.5-1.2-8.2-2.5-12.6-1.1-3.6-1.1-7.4-1.1-11.1q0-0.9 0-1.7c-0.2-12.5-2.2-24.9-6.1-36.8q-0.6-1.7-1.2-3.4zm-2.7-17.6c0 1.4 0 2.9 0.2 4.4 0.6 4.2 2 8.3 3.3 12.2 3.6 0 6.5 1.6 9 5.1l0.4 0.6c1.4 2.1 2.9 4.2 5.2 5 2.7 0.9 5.7-0.6 7.1-1.6 1.1-0.7 2.1-1.5 3.1-2.3 1-0.9 2.2-1.8 3.3-2.6 2.6-1.7 5.2-2.2 8-1.5 3.7-8.5 5-18.2 3.6-28.8-0.6-4.1-3.7-5.9-7.1-7.9l-0.2-0.1c-3-1.7-5.9-3.6-8.7-5.6-1.8-1.2-3.2-2-4.7-2q-1.4 0-2.8 0.9-1.5 0.9-3 1.9c-1.5 1-3.1 2.1-4.9 3-1 0.5-2 0.9-3.1 1.3-3.8 1.6-7.6 3.2-8.6 7.1-0.6 2.4-0.4 4.9-0.2 7.5 0.1 1.2 0.1 2.3 0.1 3.4zm4.9 20.6c3.9 12 6 24.5 6.1 37.2q0.1 0.8 0.1 1.7c0 3.6 0 7.3 1 10.7 1.4 4.6 2.1 9.3 2.6 12.9 0.6 5 1 10.2 1 15.4 0.1 2.5 0 5.1-0.2 8.1q0 0.8-0.1 1.7c-0.2 1.8-0.4 3.6 0.2 5 0.5 1.5 2 2.5 3.2 2.2 0.8-0.2 1.3-0.8 2-1.5 6.2-6.7 10.8-14 13.6-21.6 3-8.2 3.4-16.4 3.9-25.1 0.7-13.9 1-29.3 1-47-2.4-0.5-4.7-0.1-7.1 1.4-1.1 0.7-2.2 1.6-3.2 2.5-1 0.8-2 1.7-3.2 2.4-1.2 0.9-3.5 2.1-5.9 2.1q-1.1 0-2.2-0.4c-2.6-0.9-4.2-3.2-5.7-5.4l-0.4-0.6c-2.2-3-4.7-4.5-7.7-4.6q0.5 1.5 1 2.9z"
                />
                <path
                    id={84}
                    fillRule="evenodd"
                    className="s5"
                    d="m310.3 524.7q-1.6-0.4-2.4-1.4-0.9-1.1-0.9-2.6 0-2.1 1.5-3.3 1.5-1.2 4.1-1.2 2.6 0 4.1 1.2 1.5 1.2 1.5 3.3 0 1.5-0.8 2.6-0.9 1-2.4 1.4 1.7 0.4 2.7 1.5 0.9 1.2 0.9 2.9 0 2.5-1.5 3.9-1.6 1.3-4.5 1.3-2.9 0-4.4-1.3-1.6-1.4-1.6-3.9 0-1.7 1-2.9 1-1.1 2.7-1.5zm2.3 1q-1.7 0-2.6 0.9-1 0.9-1 2.5 0 1.6 1 2.5 0.9 0.9 2.6 0.9 1.7 0 2.7-0.9 1-1 1-2.5 0-1.6-1-2.5-1-0.9-2.7-0.9zm-3.2-4.8q0 1.4 0.8 2.2 0.9 0.7 2.4 0.7 1.6 0 2.4-0.7 0.9-0.8 0.9-2.2 0-1.3-0.9-2.1-0.8-0.7-2.4-0.7-1.5 0-2.4 0.7-0.8 0.8-0.8 2.1zm12 6.7l7.3-11.1h3v11.4h2.5v2h-2.5v4.1h-2.4v-4.1h-7.9zm7.9 0.3v-9.3l-5.9 9.3z"
                />
            </g>
            <g id="Group85" className={missingChecker(85) ? "g1-none" : "g1"}>
                <path
                    id="85down-fill2"
                    className="s0"
                    d="m276.6 701.3c-3.2 9.6-4.2 19.6-2.7 29.9 0.5 8.7-0.1 17.1-1.7 25-1.2 6.4-3.2 12.6-5.7 18.5-1.4 3.1-2.9 6.1-4.5 8.8l-0.3 0.5c-1.3 2.3-2.5 4.3-4.8 5.6-0.7 0.3-1.6 0.8-2.6 0.8q-0.6 0-1-0.2c-0.8-0.4-1.5-1.2-1.8-2.3-0.3-1-0.2-2.1-0.2-3.1 0.1-1.4 0.3-2.8 0.5-4.2 0.2-1.5 0.4-3 0.5-4.5 0.1-3 0-6-0.2-8.8q-0.5-4.3-1.5-8.8c-0.3-1.3-0.7-2.6-1.1-3.9-0.5-1.5-1-3.1-1.3-4.6-3.4-19.7-5.1-36.1-5.1-50.2 4-1.1 8.6-0.4 12 2q0.4 0.3 0.8 0.6c1 0.7 2 1.5 3.2 1.8q0.6 0.1 1.3 0.2 1.5 0 3.1-0.8c0.7-0.3 1.4-0.6 2.1-1q0.8-0.4 1.7-0.8c2.4-1.1 6.9-2.1 9.3-0.5z"
                />
                <path
                    id="85down-fill"
                    className="s0"
                    d="m285.1 666.2c-0.2 15.2-2.8 26.4-7.9 34.2-2.8-1.9-7.7-0.9-10.4 0.4q-0.9 0.4-1.8 0.9c-0.6 0.3-1.2 0.6-1.9 0.9q-2.1 0.9-3.7 0.5c-1-0.2-1.9-0.9-2.8-1.6q-0.4-0.3-0.8-0.6c-3.7-2.5-8.5-3.3-12.8-2.2-0.2-0.4-0.5-0.9-1-1.5-0.2-0.3-0.4-0.6-0.5-0.8q-0.6-1.4-1.2-2.9c-0.7-2-1.2-4-1.6-6.2-0.9-5-0.7-10.4-0.5-15.2 0.1-1.6 0.3-3.2 0.9-4.7 0.6-1.2 1.8-1.9 3.2-2.5 3.5-1.6 7.6-4.6 12.5-9.2 3.4-2.3 6.2-3.3 8.8-3.3 1.9 0 3.7 0.5 5.5 1.6 0.8 0.5 1.5 1 2.3 1.5 1.4 1 2.9 2 4.5 2.7 1.2 0.5 2.6 0.8 4.4 1.1q0.3 0.1 0.6 0.1c1 0.1 1.6 0.2 2.3 0.9 1.3 1.2 2 3.2 1.9 5.9z"
                />
                <path
                    id="85up-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m277.2 562.5c9.5 14.2 9.6 28.6 1.5 43.1-5.5 10-20.1 8.8-28.1 2.8-4.6-3.5-7.5-8.6-8.8-14.2-1.5-6.7-0.9-14-0.2-20.8 0.7-5.3 3.6-10.4 7.4-14 3.9-3.6 9-5.7 14.3-5.2 2.2 0.2 4.3 0.9 6.2 1.9 2.7 1.5 6 3.8 7.7 6.4zm-27.3 27.1c1.8 3.1 4.2 5.1 7.1 5.9 0.2 0.1 0.5 0.1 0.7 0.2-1.6 2.3-2 5.2-1.7 8.5 0.2-2.9 0.9-5.6 2.4-7.8 0.2-0.3 0.6-0.4 1-0.4 3.9 0.3 8.5-1 14-4q0.1 3.9 1.4 7.6 0 0.2 0.1 0.4c-0.9-6.8-0.7-13.6 0.5-20.4q-2 5.9-2.1 11.2c-6.5 3.6-11.9 4.9-16 3.7-2.8-0.9-5.1-3-6.9-6.2 1-3.2 1-8.3 0.5-14.6-0.1 8-0.9 14.8-3.3 18 1-0.3 1.7-1.1 2.3-2.1z"
                />
                <path
                    id="85caries-oral"
                    className={cariesChecker(85) ? cariesChecker(85).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m241 583l15-6 13 1 15 4-4-15-6-8-8-4-6-1-7 3-8 7-3 8-1 8z"
                />
                <path
                    id="85implant-oral"
                    className={implantChecker(85) ? implantChecker(85).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m241 583l15-6 13 1 15 4-4-15-6-8-8-4-6-1-7 3-8 7-3 8-1 8z"
                />
                <path
                    id="85caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(85) ? cariesChecker(85).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m276 657v43h-6l-6 3h-3l-5-2-7-2v-39l3-2 3-3 4-2c0.9 0 4-1 4-1l5 1 3 2zm6-59l-4 7-6 7-6 1-6-1-6-2-5-3-6-8-1-5v-6l4 3 6 5 5 4h15l12-7z"
                />
                <path
                    id="85implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(85) ? implantChecker(85).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m276 657v43h-6l-6 3h-3l-5-2-7-2v-39l3-2 3-3 4-2c0.9 0 4-1 4-1l5 1 3 2zm6-59l-4 7-6 7-6 1-6-1-6-2-5-3-6-8-1-5v-6l4 3 6 5 5 4h15l12-7z"
                />
                <path
                    id="85caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(85) ? cariesChecker(85).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m273 580v-21l7 8 3 8 1 9v6l-3 9-4 8-3 4h-2l1-12 2-8v-7zm10 80l2 3v9l-1 10-2 8-3 8-2 2-2-1v-41z"
                />
                <path
                    id="85implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(85) ? implantChecker(85).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m273 580v-21l7 8 3 8 1 9v6l-3 9-4 8-3 4h-2l1-12 2-8v-7zm10 80l2 3v9l-1 10-2 8-3 8-2 2-2-1v-41z"
                />
                <path
                    id="85caries-distal"
                    fillRule="evenodd"
                    className={cariesChecker(85) ? cariesChecker(85).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m252 580l-1 3-1 4v3l1 4 2 3v12l-2-1-4-3-3-6-2-5v-4l-1-4v-4l1-5v-5l2-5 3-5 4-4 3-2v21zm-3 80l1 39-5-1h-2l-2-3-2-7-1-6v-11l1-3 2-2z"
                />
                <path
                    id="85implant-distal"
                    fillRule="evenodd"
                    className={implantChecker(85) ? implantChecker(85).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m252 580l-1 3-1 4v3l1 4 2 3v12l-2-1-4-3-3-6-2-5v-4l-1-4v-4l1-5v-5l2-5 3-5 4-4 3-2v21zm-3 80l1 39-5-1h-2l-2-3-2-7-1-6v-11l1-3 2-2z"
                />
                <path
                    id="85caries-occlusal"
                    className={cariesChecker(85) ? cariesChecker(85).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m251 578l8-1h10l7 5 2 9-3 8-6 5-10-1-7-6-5-7z"
                />
                <path
                    id="85implant-occlusal"
                    className={implantChecker(85) ? implantChecker(85).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m251 578l8-1h10l7 5 2 9-3 8-6 5-10-1-7-6-5-7z"
                />
                <path
                    id="85endo1"
                    className={endoRootCheker(85) ? endoRootCheker(85).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m255 789c0 0 2.4-10 4.6-19.7 1.8-9.5 3.4-19.2 3.4-19.2l1-13.7-1-25.5-0.4-7.5-3.5-0.4 0.5 26-2.8 36-1.8 19.5 1.5-8.8z"
                />
                <path
                    id="85up-outline"
                    className="s3"
                    d="m257 595.5c-2.9-0.8-5.3-2.8-7.1-5.9-0.6 1-1.3 1.8-2.3 2.1 2.4-3.2 3.2-10 3.3-18 0.5 6.3 0.5 11.4-0.5 14.6 1.8 3.2 4.1 5.3 6.9 6.2 4.1 1.2 9.5-0.1 16-3.7q0.1-5.3 2.1-11.2c-1.2 6.8-1.4 13.6-0.5 20.4q-0.1-0.2-0.1-0.4-1.3-3.7-1.4-7.6c-5.5 3-10.1 4.3-14 4-0.4 0-0.8 0.1-1 0.4-1.5 2.2-2.2 4.9-2.4 7.8-0.3-3.3 0.1-6.2 1.7-8.5-0.2-0.1-0.5-0.1-0.7-0.2z"
                />
                <path
                    id="85down-outline"
                    fillRule="evenodd"
                    className="s6"
                    d="m239.3 693.9q-1.1-3.1-1.7-6.4c-0.8-5.1-0.7-10.6-0.5-15.4 0.1-1.7 0.3-3.5 1-5.1 0.8-1.6 2.2-2.4 3.7-3.1 3.5-1.6 7.5-4.5 12.3-9 3.7-2.5 6.6-3.6 9.5-3.6 2.1 0 4.1 0.6 6.1 1.8q1.2 0.7 2.3 1.5c1.4 1 2.8 1.9 4.3 2.6 1.2 0.4 2.4 0.8 4.1 1q0.4 0.1 0.7 0.1c1 0.2 1.9 0.3 2.8 1.2 1.6 1.5 2.4 3.8 2.3 6.7-0.2 15.9-3 27.6-8.6 35.6-3.1 9.3-4 19.2-2.7 29.3 0.5 8.8 0 17.3-1.6 25.4-1.3 6.4-3.2 12.6-5.8 18.6-1.4 3.2-3 6.2-4.6 8.9l-0.3 0.5c-1.3 2.4-2.6 4.6-5.2 6-0.7 0.5-1.9 1-3.1 1q-0.8 0-1.5-0.3c-1.1-0.5-1.9-1.6-2.3-3-0.4-1.2-0.3-2.4-0.3-3.5 0.1-1.4 0.3-2.9 0.5-4.2 0.2-1.5 0.4-3 0.5-4.4 0.1-3 0-5.9-0.2-8.7-0.3-2.8-0.8-5.8-1.4-8.7-0.3-1.2-0.7-2.5-1.2-3.7-0.5-1.6-1-3.2-1.3-4.8-3.5-20-5.1-36.6-5.1-50.9 0-0.2-0.6-1.1-0.9-1.5-0.2-0.3-0.4-0.7-0.6-0.9q-0.7-1.4-1.2-3zm3.8 5.9c0 14.1 1.7 30.5 5.1 50.2 0.3 1.5 0.8 3.1 1.3 4.6 0.4 1.3 0.8 2.6 1.1 3.9q1 4.5 1.5 8.8c0.2 2.8 0.3 5.8 0.2 8.8-0.1 1.5-0.3 3-0.5 4.5-0.2 1.4-0.4 2.8-0.5 4.2 0 1-0.1 2.1 0.2 3.1 0.3 1.1 1 1.9 1.8 2.3q0.4 0.2 1 0.2c1 0 1.9-0.5 2.6-0.8 2.3-1.3 3.5-3.3 4.8-5.6l0.3-0.5c1.6-2.7 3.1-5.7 4.5-8.9 2.5-5.8 4.5-12 5.7-18.4 1.6-7.9 2.2-16.3 1.7-25-1.5-10.3-0.5-20.3 2.7-29.9-2.4-1.6-6.9-0.6-9.3 0.5q-0.9 0.4-1.7 0.8c-0.7 0.4-1.4 0.7-2.1 1q-1.6 0.8-3.1 0.8-0.7-0.1-1.3-0.2c-1.2-0.3-2.2-1.1-3.2-1.8q-0.4-0.3-0.8-0.6c-3.4-2.4-8-3.1-12-2zm-4-32.4c-0.6 1.5-0.8 3.1-0.9 4.7-0.2 4.8-0.4 10.2 0.5 15.2 0.4 2.2 0.9 4.2 1.6 6.2q0.6 1.5 1.2 2.9c0.1 0.2 0.3 0.5 0.6 0.8q0.6 0.9 0.9 1.5c4.3-1.1 9.1-0.3 12.8 2.2q0.4 0.3 0.8 0.6c0.9 0.7 1.8 1.4 2.8 1.6q1.6 0.4 3.7-0.5c0.7-0.2 1.3-0.6 1.9-0.9q0.9-0.5 1.8-0.9c2.7-1.3 7.6-2.3 10.4-0.4 5.1-7.8 7.7-19 7.9-34.2 0.1-2.7-0.6-4.7-1.9-5.9-0.7-0.7-1.3-0.8-2.3-0.9q-0.3 0-0.6-0.1c-1.8-0.3-3.2-0.6-4.4-1.1-1.6-0.7-3.1-1.7-4.5-2.7-0.8-0.5-1.5-1-2.3-1.5-1.8-1.1-3.6-1.6-5.5-1.6-2.6 0-5.4 1-8.8 3.3-4.9 4.6-9 7.6-12.5 9.2-1.4 0.6-2.6 1.3-3.2 2.5z"
                />
                <path
                    id={85}
                    fillRule="evenodd"
                    className="s5"
                    d="m254.3 524.7q-1.6-0.4-2.4-1.4-0.9-1.1-0.9-2.6 0-2.1 1.5-3.3 1.5-1.2 4.1-1.2 2.6 0 4.1 1.2 1.5 1.2 1.5 3.3 0 1.5-0.8 2.6-0.9 1-2.4 1.4 1.7 0.4 2.7 1.5 0.9 1.2 0.9 2.9 0 2.5-1.5 3.9-1.6 1.3-4.5 1.3-2.9 0-4.4-1.3-1.6-1.4-1.6-3.9 0-1.7 1-2.9 1-1.1 2.7-1.5zm2.3 1q-1.7 0-2.6 0.9-1 0.9-1 2.5 0 1.6 1 2.5 0.9 0.9 2.6 0.9 1.7 0 2.7-0.9 1-1 1-2.5 0-1.6-1-2.5-1-0.9-2.7-0.9zm-3.2-4.8q0 1.4 0.8 2.2 0.9 0.7 2.4 0.7 1.6 0 2.4-0.7 0.9-0.8 0.9-2.2 0-1.3-0.9-2.1-0.8-0.7-2.4-0.7-1.5 0-2.4 0.7-0.8 0.8-0.8 2.1zm13.5 4.4v-8.8h9.3v2h-7.2v4.3q0.5-0.2 1.1-0.3 0.5-0.1 1-0.1 2.9 0 4.6 1.6 1.7 1.6 1.7 4.4 0 2.8-1.7 4.4-1.8 1.5-5 1.5-1.1 0-2.2-0.1-1.2-0.2-2.4-0.6v-2.4q1.1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-1 0-1.9 0.2-1 0.2-1.9 0.7z"
                />
            </g>
            <g id="Group65" className={missingChecker(65) ? "g1-none" : "g1"}>
                <path
                    id="65up-fill"
                    className="s0"
                    d="m764.9 55.4c0.6-0.8 1.5-1.7 2.2-1.3 0.5 0.2 0.7 1 0.9 1.8 1.6 9 2.6 16.6 2.5 24.1 0 3.5-0.2 7-0.4 10.4-0.2 3.2-0.3 6.6-0.4 9.8-0.3 19 3.6 37.7 7.5 55.9-1.1 0-4.1 0.1-4.6 0-1.3-0.1-2.4-0.7-3.6-1.4q-0.8-0.6-1.8-1.1c-2.2-1-4.8-1.4-7.2-1.2-4.8 0.5-7.6 3.3-10.3 6.3-1.4 1.6-2.6 2-4 2.5-0.8 0.3-1.7 0.6-2.7 1.1l0.1-9.5h-0.1l0.1-16.4q0.2-3.3 0.4-6.5c0-2.5 0.2-5.1 0.3-7.7 0.5-7 2.8-13.6 4.5-18.1 1.1-2.7 2.3-5.4 3.4-8.1 2-4.6 4-9.3 5.5-14.2 1.7-5.5 2.7-10 3.1-14.1 0.5-4.7 2-8.9 4.6-12.3zm18.9 128.2c0.6 6.6-0.7 10.7-4.7 14.1-2 1.8-4.4 3.1-6.7 4.5l-0.8 0.5c-1.2 0.7-2.5 1.4-3.9 2.4q-0.2 0.1-0.5 0.3c-0.4 0.3-1.2 0.9-1.5 1-2.2 0.5-4.5 1-7.1 0q-1-0.3-2-0.7c-2.5-0.9-4.8-1.7-6.9-3.2-3.2-2.1-6.8-4.6-8.8-8.2-1.9-3.4-2.3-7.7-1.3-13.2 0.6-3.6 1.4-7.2 2.1-10.7q0.8-3.5 1.5-7c1-0.6 1.9-0.9 2.8-1.2 1.6-0.5 2.9-1 4.5-2.8 2.7-2.9 5.2-5.4 9.6-5.9 2.2-0.2 4.6 0.2 6.7 1.1 0.6 0.3 1.1 0.7 1.7 1 1.2 0.7 2.4 1.5 4 1.6 0 0 3.3 0.1 4.9-0.1l3.7 10.9c1 3 1.7 6.2 2 9.4z"
                />
                <path
                    id="65down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m789.3 271.7c1 7 1.6 16.1-2.8 24-1.9 3.5-4.6 6.7-7.9 9.1-2.5 1.9-5.4 3.5-9.1 3.1q-4.1-0.4-8.2-3.5c-4.3-3.1-8.3-7.4-11.3-11.9q-2.2-3.5-3.4-6.9c-1-2.8-1.4-5.7-1.3-8.4 0.4-7 3.3-13.8 5.4-18.3 0.8-1.8 2-4.1 3.3-6.4q0.3-0.7 0.8-1.5c0.6-1.2 1.2-2.4 2-3.5q0.5-0.7 1.2-1.4c2.2-2.3 5.2-3.7 8.3-4 6.8-0.5 14.3 3.7 17.2 9.5 0.7 1.6 1.5 3.4 2.2 5.5 0.5 1.4 1 3 1.6 5 0.8 2.9 1.5 6.1 2 9.6zm-26.8-0.2c4.2 2.8 8.5 3.5 12.5 2 0.1 4.5 2 9.3 5.6 14.5l-0.8-2.3q-0.1-0.4-0.4-0.9c-2.4-4.8-3.5-8.2-3.5-11.6 0-0.1 0-0.3 0-0.3 0-2.7 0.8-5.3 2.3-8.5l1.1-2.8q-4 5-4.3 10.7c-4 1.6-8 1-12.3-2q1-5.7-0.9-11.1-0.3-0.9-0.7-1.8l0.6 2.9c1.4 9.2-0.3 14.1-5.3 22.7l-1.2 2.3q5.7-7 7.3-13.8z"
                />
                <path
                    id="65caries-oral"
                    className={cariesChecker(65) ? cariesChecker(65).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m790 274l-13 9-7 3-8-1-11-6-6-4 1 9 6 13 13 10 8 1 7-4 8-9 3-10z"
                />
                <path
                    id="65implant-oral"
                    className={implantChecker(65) ? implantChecker(65).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m790 274l-13 9-7 3-8-1-11-6-6-4 1 9 6 13 13 10 8 1 7-4 8-9 3-10z"
                />
                <path
                    id="65caries-vesial"
                    className={cariesChecker(65) ? cariesChecker(65).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m750 159l2 45 5 3h5l5-2 7-4h1l-2-44-4-2-5-3-6 1-4 2zm-3 109l16-6 9 1 8 3 9 2-3-11-3-8-6-4-3-2-5-1h-4l-4 2-6 6-4 7-2 5z"
                />
                <path
                    id="65implant-vesial"
                    className={implantChecker(65) ? implantChecker(65).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m750 159l2 45 5 3h5l5-2 7-4h1l-2-44-4-2-5-3-6 1-4 2zm-3 109l16-6 9 1 8 3 9 2-3-11-3-8-6-4-3-2-5-1h-4l-4 2-6 6-4 7-2 5z"
                />
                <path
                    id="65caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(65) ? cariesChecker(65).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m772 157l2 45 5-4 4-5 2-7-2-12-2-9-3-5-1-3zm9 113v5l-2 6v24l3-3 3-4 4-7 1-6v-16l-3-7-2-7-3-7-3-2v19z"
                />
                <path
                    id="65implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(65) ? implantChecker(65).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m772 157l2 45 5-4 4-5 2-7-2-12-2-9-3-5-1-3zm9 113v5l-2 6v24l3-3 3-4 4-7 1-6v-16l-3-7-2-7-3-7-3-2v19z"
                />
                <path
                    id="65caries-distal"
                    className={cariesChecker(65) ? cariesChecker(65).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m764 263l-1-21-7 6-5 10-5 11-1 10 1 6 4 9 6 6 5 5 3 2v-20l-5-5-2-4v-5l2-5 3-2zm-11-59l-2-45-3 2-5 2-2 9-2 9v8l3 8 5 4z"
                />
                <path
                    id="65implant-distal"
                    className={implantChecker(65) ? implantChecker(65).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m764 263l-1-21-7 6-5 10-5 11-1 10 1 6 4 9 6 6 5 5 3 2v-20l-5-5-2-4v-5l2-5 3-2zm-11-59l-2-45-3 2-5 2-2 9-2 9v8l3 8 5 4z"
                />
                <path
                    id="65caries-occlusal"
                    className={cariesChecker(65) ? cariesChecker(65).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m779 266l-4-3-4-1h-4-5l-3 2-2 5-1 4-1 4 1 3 3 4 4 3 6 1 4-1 3-2 3-3 2-5 1-4-1-3-1-3z"
                />
                <path
                    id="65implant-occlusal"
                    className={implantChecker(65) ? implantChecker(65).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m779 266l-4-3-4-1h-4-5l-3 2-2 5-1 4-1 4 1 3 3 4 4 3 6 1 4-1 3-2 3-3 2-5 1-4-1-3-1-3z"
                />
                <path
                    id="65endo1"
                    className={endoRootCheker(65) ? endoRootCheker(65).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m764.7 58l-5.8 42.8-0.9 15 1.2 28 0.5 8.1 3.5 0.4-0.5-28.5 2.1-39.5 0.7-21-1.2 9.2z"
                />
                <path
                    id="65down-outline2"
                    className="s3"
                    d="m775 273.5c-4 1.5-8.3 0.8-12.5-2q-1.6 6.8-7.3 13.8l1.2-2.3c5-8.6 6.6-13.5 5.3-22.7l-0.6-2.9q0.4 0.9 0.7 1.8 1.9 5.4 0.9 11.1c4.3 3 8.3 3.6 12.3 2q0.3-5.7 4.3-10.7l-1.1 2.8c-1.5 3.2-2.3 5.8-2.3 8.5 0 0 0 0.2 0 0.3 0 3.4 1.1 6.8 3.5 11.6q0.3 0.5 0.4 0.9l0.8 2.3c-3.6-5.2-5.5-10-5.6-14.5z"
                />
                <path
                    id="65down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m749.1 293.1c-3.5-5.4-5.2-10.8-4.9-15.9 0.4-7.2 3.3-14.2 5.5-18.7 0.8-1.9 2-4.2 3.3-6.5q0.4-0.8 0.7-1.5c0.7-1.2 1.3-2.5 2.2-3.6q0.5-0.8 1.2-1.6c2.5-2.5 5.8-4 9.1-4.3 7.2-0.5 15.3 3.9 18.3 10.1 0.7 1.6 1.5 3.4 2.2 5.6 0.5 1.5 1 3 1.6 5.1 0.9 2.9 1.6 6.2 2.1 9.7 1 7.2 1.6 16.5-2.9 24.7-2 3.7-4.9 7-8.3 9.5-2.7 2-5.7 3.8-9.9 3.3-2.9-0.3-5.8-1.5-8.7-3.7-4.4-3.2-8.5-7.6-11.5-12.2zm1.6-34.2c-2.1 4.5-5 11.3-5.4 18.3-0.1 2.7 0.3 5.5 1.3 8.4q1.2 3.4 3.4 6.9c3 4.5 7 8.8 11.3 11.9q4.1 3.1 8.2 3.5c3.7 0.4 6.5-1.2 9.1-3.1 3.3-2.4 6-5.6 7.9-9.1 4.4-7.9 3.8-17 2.8-24-0.5-3.5-1.2-6.7-2-9.6-0.6-2-1.1-3.6-1.6-5-0.7-2.1-1.5-3.9-2.2-5.5-2.9-5.8-10.4-10-17.2-9.5-3.1 0.3-6.1 1.7-8.3 4q-0.7 0.7-1.3 1.4c-0.7 1.1-1.3 2.3-2 3.5q-0.4 0.8-0.7 1.5c-1.3 2.3-2.5 4.6-3.3 6.4z"
                />
                <path
                    id="65up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m749.1 203.5c-3.3-2.3-7-4.8-9.1-8.6-2-3.7-2.5-8.2-1.5-14 0.6-3.6 1.4-7.2 2.1-10.7q0.7-3.1 1.3-6.2l0.1-3h-0.1l0.1-24.6q0.2-3.3 0.4-6.6c0.1-2.5 0.2-5.1 0.3-7.7 0.5-7.1 2.9-13.9 4.6-18.4 1.1-2.7 2.2-5.4 3.4-8.1 1.9-4.6 3.9-9.3 5.4-14.1 1.7-5.5 2.7-9.9 3.1-14 0.5-4.9 2.1-9.2 4.8-12.8 1.1-1.5 2.5-2.1 3.6-1.6 1.1 0.5 1.3 1.9 1.4 2.5 1.7 9.2 2.7 16.8 2.6 24.4 0 3.5-0.2 7-0.4 10.5-0.2 3.2-0.4 6.5-0.4 9.8-0.3 19 3.7 37.9 7.6 56.2l0.1 0.5 3.6 10.6c1.1 3.2 1.8 6.4 2.1 9.7l0.6 6.2c0.6 5.1 0.3 10.5-5 15-2.1 1.8-4.5 3.2-6.8 4.6l-0.9 0.5c-1.2 0.7-2.4 1.4-3.8 2.4q-0.2 0.1-0.5 0.3c-0.7 0.6-1.4 1-1.8 1.1-1.4 0.4-2.8 0.7-4.3 0.7-1.1 0-2.3-0.2-3.5-0.6q-1-0.4-2-0.8c-2.4-0.9-4.9-1.8-7.1-3.2zm-7.4-33.1c-0.7 3.5-1.5 7.1-2.1 10.7-1 5.5-0.6 9.8 1.3 13.2 2 3.6 5.6 6.1 8.8 8.2 2.1 1.5 4.4 2.3 6.9 3.2q1 0.4 2 0.7c2.6 1 4.9 0.5 7.1 0 0.3-0.1 1.1-0.7 1.5-1q0.3-0.2 0.5-0.3c1.4-1 2.7-1.7 3.9-2.4l0.8-0.5c2.3-1.4 4.7-2.7 6.7-4.5 4-3.4 5.3-7.5 4.7-14.1l-0.7-6.2c-0.3-3.2-1-6.4-2-9.4l-3.8-10.9c-1.5 0.2-4.8 0.1-4.8 0.1-1.6-0.1-2.8-0.9-4-1.6-0.6-0.3-1.1-0.7-1.7-1-2.1-0.9-4.5-1.3-6.7-1.1-4.4 0.5-6.9 3-9.6 5.9-1.6 1.9-2.9 2.3-4.5 2.8-0.9 0.3-1.8 0.6-2.9 1.2q-0.6 3.5-1.4 7zm1.3-8.1c1-0.5 1.9-0.8 2.6-1.1 1.5-0.5 2.7-0.9 4.1-2.5 2.7-3 5.5-5.8 10.3-6.3 2.4-0.2 5 0.2 7.2 1.2q1 0.5 1.8 1.1c1.2 0.7 2.3 1.3 3.6 1.4 0.5 0.1 3.5 0 4.6 0-3.9-18.2-7.8-36.9-7.5-55.9 0-3.2 0.2-6.6 0.4-9.8 0.2-3.4 0.4-6.9 0.4-10.4 0.1-7.5-0.9-15.1-2.5-24.1-0.2-0.8-0.4-1.6-0.9-1.8-0.7-0.4-1.6 0.5-2.2 1.3-2.6 3.4-4.1 7.6-4.6 12.3-0.4 4.1-1.4 8.6-3.1 14.1-1.5 4.9-3.6 9.6-5.5 14.2-1.1 2.7-2.3 5.4-3.4 8.1-1.7 4.5-4 11.1-4.5 18.1-0.2 2.6-0.3 5.2-0.3 7.7q-0.2 3.2-0.4 6.5l-0.1 16.4h0.1z"
                />
                <path
                    id={65}
                    fillRule="evenodd"
                    className="s5"
                    d="m768.6 323.9v2.1q-0.9-0.4-1.8-0.6-0.9-0.2-1.8-0.2-2.3 0-3.6 1.6-1.2 1.5-1.4 4.7 0.7-1 1.8-1.5 1-0.6 2.3-0.6 2.6 0 4.1 1.6 1.6 1.6 1.6 4.4 0 2.7-1.6 4.3-1.6 1.6-4.3 1.6-3 0-4.6-2.3-1.6-2.3-1.6-6.7 0-4.2 1.9-6.6 2-2.5 5.3-2.5 0.9 0 1.8 0.2 0.9 0.1 1.9 0.5zm-4.7 7.4q-1.6 0-2.5 1.1-0.9 1.1-0.9 3 0 1.9 0.9 3 0.9 1.1 2.5 1.1 1.6 0 2.5-1.1 1-1.1 1-3 0-1.9-1-3-0.9-1.1-2.5-1.1zm10 1v-8.8h9.3v2h-7.2v4.3q0.5-0.2 1.1-0.3 0.5-0.1 1-0.1 2.9 0 4.6 1.6 1.7 1.6 1.7 4.4 0 2.8-1.7 4.4-1.8 1.5-5 1.5-1.1 0-2.2-0.1-1.2-0.2-2.4-0.6v-2.4q1.1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-1 0-1.9 0.2-1 0.2-1.9 0.7z"
                />
            </g>
            <g id="Group64" className={missingChecker(64) ? "g1-none" : "g1"}>
                <path
                    id="64up-fill"
                    className="s0"
                    d="m695.8 153c-2.8 1.6-6.3 4.7-10.7 5q0-0.1 0-0.1c0-1.2 0-5.6 0-6.8-0.2-4.8-0.4-9.7-0.5-14.4q-0.1-4.4-0.3-8.8l-0.6-19.3c-0.1-3-0.2-6.2 0.1-9.3 0.2-2.2 0.8-4.3 1.4-6.4q0.3-1.4 0.7-2.8l2.2-9.5q0.4-1.7 0.8-3.4 0.9-3.2 1.5-6.3l0.2-1c0.1-0.6 0.3-1.3 0.4-2 0.6-3.1 1.4-6.9 3.8-8.8 0.6-0.4 1-0.5 1.2-0.5 0.3 0 0.9 0.4 1 3.4v30.4q-0.2 1.1-0.4 2.1c-0.5 2.7-0.9 5.5-1.3 8.4-0.6 5.5-1.2 11.2-1.6 16.8l-0.4 21.1c0.4-6.9 0.9-14 1.4-21 0.5-5.6 1.1-11.3 1.7-16.8 0.3-2.8 0.8-5.6 1.3-8.3q0.1-0.5 0.2-1h0.1l2.1-11.3q0.6-3.6 1.2-7.2c0.7-4.3 1.5-8.8 2.5-13.1l0.1-0.9c0.8-3.2 1.6-6.9 4.6-8.7q0.5-0.3 0.8-0.3c0.7 0 1.6 0.8 2.1 4.7l1.3 24.5 2.1 22.6 6.2 48.5c-2.3 0.1-5.1-0.7-8.7-2.6-6.8-3.7-7.6-3-16.5 3.1zm16-2.1c3.9 2 6.8 2.8 9.3 2.6l0.2 1.1 4.3 18.5c0.3 1.1 0.6 2.3 0.9 3.5 1.5 5.4 3.2 11.4 0.7 16.1-0.8 1.7-2.3 3-3.7 4.3-1 1-2.4 1.6-3.8 2.3-0.9 0.5-1.8 0.9-2.6 1.5q-1 0.6-2.1 1.3c-1.3 1-2.7 2-4.3 2.7-2.5 1.1-5.6 1.3-9.2 0.7-4.7-0.8-9.2-2.8-12.6-5.7-3.1-2.6-6.3-5.3-7.2-9.5-0.8-3.3-0.3-6.8 0.3-10 1.5-8.2 2.6-15 3-22 0-0.1 0 1.4 0 0.8 4.7-0.3 8.4-3.5 11.4-5.2 8.5-5.8 8.9-6.5 15.4-3z"
                />
                <path
                    id="64down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m732.4 282c0.7 6.4 0.2 12.4-1.6 17.7-1 3.2-5.1 6.9-7.4 8.8q-3.7 3.1-7.8 3.9c-6.8 1.1-13-3.4-17-7.3-2.3-2.2-4.5-4.9-6.6-8l-0.7-1c-1.1-1.5-2.2-3.1-3-4.8q-0.1-0.2-0.2-0.5-0.5-1-0.7-2-0.3-1.4-0.2-2.8c0.2-3 0.6-5.9 1.5-9 1.2-4.2 2.9-8.7 5.3-13.7q1-2.1 2-4.2 0.7-1.2 1.3-2.4c1.2-2.4 2.7-3.9 5.4-5.3q2.3-1.2 4.9-1.4c3.3-0.3 6.9 0.6 11.1 2.6l0.5 0.2c1.9 0.9 3.8 1.8 4.7 3.6l0.4 1c3.5 7.5 7 15.7 8.1 24.6zm-29.9-5.3c5.9 4.2 11.5 4.9 16.7 2 0.3 4.4 2.3 8.8 5.9 13.4l-0.5-1.5c-3.9-7.7-5.5-12.6-3.3-20.6l0.8-2.5q-2.9 4.9-2.9 9.9c-5.2 3.2-10.6 2.5-16.7-2q0.1-6.1-1.8-12.1l-0.1 1.4c1.4 9.2 0.9 14.8-1.2 24l-0.5 3.2q3.2-7.7 3.6-15.2z"
                />
                <path
                    id="64caries-oral"
                    className={cariesChecker(64) ? cariesChecker(64).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m733 285l-8 6-7 1-9-2-6-3-7-5-7-5-2 6v6l3 6 8 10 6 4 4 3 8 1 5-2 7-7 4-8 1-5z"
                />
                <path
                    id="64implant-oral"
                    className={implantChecker(64) ? implantChecker(64).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m733 285l-8 6-7 1-9-2-6-3-7-5-7-5-2 6v6l3 6 8 10 6 4 4 3 8 1 5-2 7-7 4-8 1-5z"
                />
                <path
                    id="64caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(64) ? cariesChecker(64).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m717 152c0 0 0.2 12.4 0.4 24.7 0.2 12.4 0.5 24.7 0.5 24.7l-8.1 5.2-8.2-0.9-7.9-2.2c0 0 0-12.1 0-24.1 0.1-12.1 0.2-24.3 0.2-24.3l7.1-4.1 4-2 6 1zm-25 114.1l3-5.9 3-4.8 5-3.9 5-1.9c0 0 5.1 0.9 7.1 0.9l4 1.9 5 3.9 5.9 12.7-9.8-1.6-3-1h-11l-16.2 3.6z"
                />
                <path
                    id="64implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(64) ? implantChecker(64).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m717 152c0 0 0.2 12.4 0.4 24.7 0.2 12.4 0.5 24.7 0.5 24.7l-8.1 5.2-8.2-0.9-7.9-2.2c0 0 0-12.1 0-24.1 0.1-12.1 0.2-24.3 0.2-24.3l7.1-4.1 4-2 6 1zm-25 114.1l3-5.9 3-4.8 5-3.9 5-1.9c0 0 5.1 0.9 7.1 0.9l4 1.9 5 3.9 5.9 12.7-9.8-1.6-3-1h-11l-16.2 3.6z"
                />
                <path
                    id="64caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(64) ? cariesChecker(64).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m715 152l2 49 6-3 5-5 1-7-3-12-3-13-2-7zm7 121l1 9-1 5-3 4-1 21 6-3 5-6 3-7 2-6-1-6-1-9-2-6-3-8-4-6-4-3v15z"
                />
                <path
                    id="64implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(64) ? implantChecker(64).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m715 152l2 49 6-3 5-5 1-7-3-12-3-13-2-7zm7 121l1 9-1 5-3 4-1 21 6-3 5-6 3-7 2-6-1-6-1-9-2-6-3-8-4-6-4-3v15z"
                />
                <path
                    id="64caries-distal"
                    className={cariesChecker(64) ? cariesChecker(64).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m705.1 267.2l0.1-16.9-7.1 4-5.3 11.7-5.1 13.8v11.1l5 8 10.2 10.1 2.1-20-5.1-7 0.1-5.9 2.1-3.9zm-9.9-62.8c0 0-0.2-12.3-0.5-24.7-0.4-12.4-0.8-24.9-0.8-24.9l-3.3 2.9-5.7-0.2-2.2 6v9l-2.1 11 0.1 9 6.6 6.2z"
                />
                <path
                    id="64implant-distal"
                    className={implantChecker(64) ? implantChecker(64).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m705.1 267.2l0.1-16.9-7.1 4-5.3 11.7-5.1 13.8v11.1l5 8 10.2 10.1 2.1-20-5.1-7 0.1-5.9 2.1-3.9zm-9.9-62.8c0 0-0.2-12.3-0.5-24.7-0.4-12.4-0.8-24.9-0.8-24.9l-3.3 2.9-5.7-0.2-2.2 6v9l-2.1 11 0.1 9 6.6 6.2z"
                />
                <path
                    id="64caries-occlusal"
                    className={cariesChecker(64) ? cariesChecker(64).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m711 265h4l4 2 2 2 2 3 1 4 1 5-1 5-1 4-3 3h-6l-6-1-3-2-3-2-3-4-2-5v-5l3-4 3-3 4-1z"
                />
                <path
                    id="64implant-occlusal"
                    className={implantChecker(64) ? implantChecker(64).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m711 265h4l4 2 2 2 2 3 1 4 1 5-1 5-1 4-3 3h-6l-6-1-3-2-3-2-3-4-2-5v-5l3-4 3-3 4-1z"
                />
                <path
                    id="64endo2"
                    className={endoRootCheker(64) ? endoRootCheker(64).canalTwo ? "s2-red" : "s2" : "s2"}
                    d="m708.5 54.5l-5.8 42.7-0.9 15 1.2 28 0.5 8.2 3.5 0.4-0.5-28.6 2.2-39.5 0.6-20.9-1.1 9.2z"
                />
                <path
                    id="64endo1"
                    className={endoRootCheker(64) ? endoRootCheker(64).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m694.4 61c0 0-2.4 10.3-4.4 21-1.3 11.3-2.3 22.2-2.3 22.2l-0.7 14.5 1.1 28.9 0.5 8.4h3.1l-2-29.1 2.2-40.8 2.2-20.2-1.6 8.9z"
                />
                <path
                    id="64down-outline2"
                    className="s3"
                    d="m702.5 275.4c6.1 4.5 11.5 5.2 16.7 2q0-5 2.9-9.9l-0.8 2.5c-2.2 8-0.6 12.9 3.3 20.6l0.5 1.5c-3.6-4.6-5.6-9-5.9-13.4-5.2 2.9-10.8 2.2-16.7-2q-0.4 7.5-3.6 15.2l0.5-3.2c2.1-9.2 2.6-14.8 1.2-24l0.1-1.4q1.9 6 1.8 12.1z"
                />
                <path
                    id="64down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m690.4 296.8c-1.2-1.7-2.5-3.6-3.3-5.5q-0.5-1.2-0.7-2.3-0.4-1.5-0.3-3c0.2-3.1 0.7-6.1 1.6-9.3 1.2-4.2 2.9-8.8 5.3-13.9q1-2.1 2.1-4.2 0.6-1.2 1.2-2.4c1.3-2.6 3.1-4.3 5.9-5.7 1.7-0.9 3.5-1.4 5.4-1.6 3.4-0.3 7.2 0.6 11.5 2.7l0.6 0.3c2 0.9 4.1 1.9 5.1 4.1l0.5 0.9c1.8 3.8 3.5 7.8 5 11.9 1.4 4.2 2.6 8.6 3.1 13.1 0.8 6.5 0.3 12.6-1.5 18.2-1.2 3.4-5.4 7.3-7.8 9.3-2.7 2.2-5.5 3.6-8.3 4-7.3 1.2-13.8-3.4-18-7.5-2.3-2.3-4.5-5-6.7-8.2zm-3-7.9q0.2 0.9 0.6 2 0.1 0.2 0.2 0.4c0.8 1.7 2 3.4 3.1 4.9l0.6 1c2.2 3.1 4.4 5.7 6.7 8 3.9 3.9 10.1 8.3 17 7.2 2.6-0.4 5.3-1.7 7.8-3.8 2.3-1.9 6.3-5.6 7.4-8.8q2.7-8.1 1.5-17.7c-1.1-9-4.5-17.1-8-24.7l-0.5-0.9c-0.9-1.8-2.7-2.7-4.6-3.6l-0.6-0.3c-4.1-1.9-7.7-2.8-11-2.5q-2.6 0.2-4.9 1.4c-2.7 1.3-4.3 2.9-5.4 5.2q-0.6 1.3-1.3 2.5-1 2.1-2 4.1c-2.4 5.1-4.1 9.6-5.3 13.8q-1.4 4.5-1.5 9-0.1 1.4 0.2 2.8z"
                />
                <path
                    id="64up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m683.2 127.9l-0.6-19.3c-0.1-3-0.2-6.2 0.1-9.4 0.2-2.3 0.8-4.5 1.4-6.6q0.4-1.4 0.7-2.8l2.2-9.5q0.4-1.7 0.9-3.4c0.5-2 1-4.1 1.4-6.2 0.1-0.7 0.5-2.3 0.6-3 0.7-3.1 1.5-7.3 4.2-9.4q1.1-0.8 1.9-0.8c1.9 0 2 3.1 2.1 4.4v25.7l0.9-5.3q0.7-3.6 1.2-7.3c0.8-4.3 1.5-8.8 2.5-13.2l0.2-0.8c0.7-3.5 1.6-7.4 5-9.4q0.8-0.5 1.4-0.5c2.5 0 3 4.2 3.2 5.7l1.2 24.5 2.2 22.6 6.4 50.5 4.4 18.5c0.2 1.1 0.6 2.2 0.9 3.4 1.5 5.6 3.2 11.9 0.6 16.9-1 1.8-2.5 3.3-3.9 4.6-1.2 1.1-2.7 1.8-4.1 2.5-0.9 0.4-1.7 0.9-2.5 1.4q-1 0.6-2 1.3c-1.4 1-2.9 2.1-4.5 2.8-1.8 0.7-3.8 1.1-6 1.1q-1.8 0-3.8-0.3c-5-0.9-9.7-3-13.2-6-3.3-2.7-6.6-5.5-7.6-10-0.8-3.6-0.3-7.1 0.3-10.5 1.5-8.1 2.6-15 3-21.9 0-0.2 0.1-2.2 0.1-2.5 0-1.2 0-3.4 0-4.6-0.2-4.7-0.4-9.6-0.5-14.3q-0.1-4.4-0.3-8.9zm2-35c-0.6 2.1-1.2 4.2-1.4 6.4-0.3 3.1-0.2 6.3-0.1 9.3l0.6 19.3q0.2 4.4 0.3 8.8c0.1 4.7 0.3 9.6 0.5 14.4 0 1.2 0 5.6 0 6.8q0 0 0 0.1c4.4-0.3 7.9-3.4 10.7-5 8.8-6.1 9.7-6.8 16.5-3.1 3.6 1.9 6.4 2.7 8.7 2.6l-6.2-48.5-2.1-22.6-1.3-24.5c-0.5-3.9-1.4-4.7-2.1-4.7q-0.3 0-0.8 0.3c-3 1.8-3.8 5.5-4.6 8.7l-0.2 0.9c-0.9 4.3-1.7 8.8-2.4 13.1q-0.6 3.6-1.2 7.2l-2.1 11.3h-0.1q-0.1 0.5-0.2 1c-0.5 2.7-1 5.5-1.3 8.3-0.6 5.5-1.2 11.2-1.7 16.8-0.6 7-1 14.1-1.4 21l0.3-21.1c0.5-5.6 1.1-11.3 1.7-16.8 0.3-2.9 0.8-5.7 1.3-8.4q0.2-1 0.4-2.1v-30.4c-0.1-3-0.7-3.4-1-3.4-0.2 0-0.6 0.1-1.2 0.5-2.4 1.9-3.2 5.7-3.8 8.8-0.2 0.7-0.3 1.4-0.4 2l-0.2 1c-0.4 2.1-1 4.2-1.5 6.3q-0.4 1.7-0.8 3.4l-2.2 9.5q-0.4 1.4-0.7 2.8zm-0.2 65.4c-0.4 7-1.5 13.8-3 22-0.6 3.2-1.1 6.7-0.3 10 0.9 4.2 4.1 6.9 7.2 9.5 3.4 2.9 7.9 4.9 12.6 5.7 3.6 0.6 6.7 0.4 9.2-0.7 1.6-0.7 3-1.7 4.3-2.7q1.1-0.7 2.1-1.3c0.8-0.6 1.7-1 2.6-1.5 1.4-0.7 2.7-1.3 3.8-2.3 1.4-1.3 2.9-2.6 3.7-4.3 2.5-4.7 0.8-10.7-0.7-16.1-0.3-1.2-0.6-2.4-0.9-3.5l-4.3-18.5-0.2-1.1c-2.5 0.2-5.4-0.6-9.3-2.6-6.5-3.5-6.9-2.8-15.4 3-3 1.7-6.7 4.9-11.4 5.2 0 0.6 0-0.9 0-0.8z"
                />
                <path
                    id={64}
                    fillRule="evenodd"
                    className="s5"
                    d="m710.6 323.9v2.1q-0.9-0.4-1.8-0.6-0.9-0.2-1.8-0.2-2.3 0-3.6 1.6-1.2 1.5-1.4 4.7 0.7-1 1.8-1.5 1-0.6 2.3-0.6 2.6 0 4.1 1.6 1.6 1.6 1.6 4.4 0 2.7-1.6 4.3-1.6 1.6-4.3 1.6-3 0-4.6-2.3-1.6-2.3-1.6-6.7 0-4.2 1.9-6.6 2-2.5 5.3-2.5 0.9 0 1.8 0.2 0.9 0.1 1.9 0.5zm-4.7 7.4q-1.6 0-2.5 1.1-0.9 1.1-0.9 3 0 1.9 0.9 3 0.9 1.1 2.5 1.1 1.6 0 2.5-1.1 1-1.1 1-3 0-1.9-1-3-0.9-1.1-2.5-1.1zm8.5 3.3l7.3-11.1h3v11.4h2.5v2h-2.5v4.1h-2.4v-4.1h-7.9zm7.9 0.3v-9.3l-5.9 9.3z"
                />
            </g>
            <g id="Group63" className={missingChecker(63) ? "g1-none" : "g1"}>
                <path
                    id="63down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m670.4 288.4c0.1 0.9 0.2 1.9 0.1 2.7-0.2 1.3-0.8 2.6-1.4 3.8-0.7 1.4-1.7 2.8-2.9 4.2-5.6 6.4-14.6 12.2-22.9 9-2.8-1-5.4-2.9-8-5.6-1.4-1.4-2.6-2.8-3.6-4.1-1.7-2.1-3-4.2-3.7-6.1-5.1-12.3 2.6-24 7.6-29.8 2.8-3.2 6.1-6.7 10.5-7.6 4-0.8 7.5 1.3 9.7 3.1 1.7 1.5 3.2 3.9 4.6 6.1l0.5 0.9c1.7 2.9 3.3 5.9 4.8 8.9 2 4.1 4.1 8.9 4.7 13.9zm-5.9 2.4l0.3-0.8c-3.3 4-8.1 6.4-14.8 7.2l0.6 0.3c6.9-0.7 10.6-2.5 13.9-6.7zm-19.7 5.4l0.4-0.3c-5.6 0-10.4-2.8-14.3-8.3l-0.1 0.2c3.8 6.1 7.9 8.7 14 8.4z"
                />
                <path
                    id="63up-fill"
                    className="s0"
                    d="m665.9 182.5c0.6 4 1.1 7.8-0.6 10.8-1.5 2.9-4.5 4.5-7.7 6.2-1.1 0.6-2.3 1.2-3.4 1.9-1.4 1-2.8 2-4.1 3.1-1.2 0.9-2.4 1.9-3.8 2.8-2.2 1.4-4.4 2.1-6.8 2.1-5 0-9.9-3.4-14.1-6.4-1.8-1.2-3.6-2.6-4.7-4.5-1.2-2-0.9-4.5-0.6-6.9q0.1-0.7 0.2-1.3c0.3-3.2 0.8-6.4 1.4-9.5 0.8-4.4 2.2-8.7 3.4-12.9 1-3.1 2-6.4 2.8-9.8q0.2-0.5 0.4-1.1 0.8-0.5 1.7-1c1.2-0.7 2.5-1.4 3.6-2.3 1.2-0.8 2.2-1.8 3.2-2.7q0.8-0.7 1.6-1.4c3.2-2.8 6.1-4 8.7-3.7 2.5 0.3 4.6 2.1 6.6 3.8l0.5 0.3c2.5 2.1 4.6 3.3 7.2 4q0 0.7 0.1 1.3c0.7 6.5 1.9 13 3 19.2l0.5 2.8q0.4 2.6 0.9 5.2zm-32.9-29.6c-1.1 0.8-2.3 1.5-3.5 2.1q-0.4 0.3-0.8 0.5 0.2-0.5 0.2-1.1c0.9-6.8 1.4-13.8 1.8-20.6 0.4-6.4 0.8-13 1.6-19.5 2.8-22.3 6.6-45.7 18.4-64.2q0.2-0.3 0.4-0.8c0.7-1.1 1.6-2.7 2.7-2.7q0.1 0 0.2 0c0.8 0.2 1.3 0.9 1.6 2.5 0.2 1.6 0 3.2-0.2 4.9-0.1 0.9-0.3 1.9-0.3 2.9-0.4 11.5 0.6 23.2 1.6 34.5 0 4.9 0.5 9.9 1.1 14.8 0.3 2.4 0.6 4.8 0.8 7.2l1.9 22.5q0.2 1.9 0.4 3.8c0.2 2.4 0.5 5 0.6 7.4 0 1.2 0 2.3-0.1 3.4q0 1.2 0 2.4c-2.3-0.7-4.3-1.8-6.5-3.7l-0.5-0.4c-2-1.7-4.3-3.6-7.2-4-2.9-0.4-6.1 0.9-9.6 4q-0.7 0.7-1.5 1.4c-1 0.9-2 1.8-3.1 2.7z"
                />
                <path
                    id="63caries-vesial"
                    className={cariesChecker(63) ? cariesChecker(63).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m656 151l1 47-12 1-16-1 2-20 1-24 6-5 5-3 3-1 4 1 4 3zm4 110l4 32h-18l-13-1 4-27 1-5 5-5h6 4l4 3z"
                />
                <path
                    id="63implant-vesial"
                    className={implantChecker(63) ? implantChecker(63).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m656 151l1 47-12 1-16-1 2-20 1-24 6-5 5-3 3-1 4 1 4 3zm4 110l4 32h-18l-13-1 4-27 1-5 5-5h6 4l4 3z"
                />
                <path
                    id="63caries-mesial"
                    className={cariesChecker(63) ? cariesChecker(63).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m655 150l1 51 5-3 4-3 2-6-1-7-2-10-2-10-1-7-1-2zm3 110l6 41 6-6 1-7-2-8-6-12z"
                />
                <path
                    id="63implant-mesial"
                    className={implantChecker(63) ? implantChecker(63).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m655 150l1 51 5-3 4-3 2-6-1-7-2-10-2-10-1-7-1-2zm3 110l6 41 6-6 1-7-2-8-6-12z"
                />
                <path
                    id="63caries-distal"
                    className={cariesChecker(63) ? cariesChecker(63).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m634 301l4-41-5 4-4 6-2 6-1 8 2 7 2 6zm-4-95l3-52-4 2-3 8-4 14-2 11v8l3 5z"
                />
                <path
                    id="63implant-distal"
                    className={implantChecker(63) ? implantChecker(63).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m634 301l4-41-5 4-4 6-2 6-1 8 2 7 2 6zm-4-95l3-52-4 2-3 8-4 14-2 11v8l3 5z"
                />
                <path
                    id="63caries-occlusal"
                    className={cariesChecker(63) ? cariesChecker(63).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m620 198h40l-6 5-6 4-4 3-5 1-6-2-5-3-5-3-2-3zm8 95l6-2h8 9l9 1 6 1 3 2-1 4-6 5-6 4-5 2h-6l-5-2-5-4-4-5z"
                />
                <path
                    id="63implant-occlusal"
                    className={implantChecker(63) ? implantChecker(63).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m620 198h40l-6 5-6 4-4 3-5 1-6-2-5-3-5-3-2-3zm8 95l6-2h8 9l9 1 6 1 3 2-1 4-6 5-6 4-5 2h-6l-5-2-5-4-4-5z"
                />
                <path
                    id="63endo1"
                    className={endoRootCheker(63) ? endoRootCheker(63).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m652 50c0 0-2.4 11.1-4.6 21.7-1.8 10.6-3.4 21.2-3.4 21.2l-1 15.1 1 28.2 0.4 8.3 3.5 0.4-0.5-28.7 2.8-39.7 1.8-21.5-1.5 9.7z"
                />
                <path
                    id="63down-outline"
                    className="s3"
                    d="m645.3 295.9l-0.4 0.2c-6.1 0.3-10.2-2.2-14.1-8.3l0.2-0.3c3.8 5.6 8.6 8.4 14.3 8.4z"
                />
                <path
                    id="63down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m634.6 303.2c-3.7-3.8-6.3-7.4-7.6-10.5-5.3-12.8 2.7-24.8 7.9-30.9 2.8-3.3 6.3-6.9 11-7.8 4.4-0.9 8.2 1.3 10.5 3.3q1.1 0.9 2.1 2.1 1.5 2.1 2.7 4.2l0.5 0.9c1.8 2.9 3.4 5.9 4.9 8.9 2 4.2 4.2 9.1 4.7 14.3l0.1 0.6c0.1 0.9 0.2 2 0.1 3-0.3 1.4-0.9 2.8-1.5 4-0.8 1.5-1.8 3-3 4.4-5.9 6.7-15.3 12.7-24.1 9.3-2.9-1.1-5.6-3-8.3-5.8zm-6.6-10.9c0.7 1.9 2 4 3.7 6.1 1 1.3 2.2 2.7 3.6 4.1 2.6 2.7 5.2 4.6 8 5.6 8.3 3.2 17.3-2.6 22.9-9 1.2-1.4 2.2-2.8 2.9-4.2 0.6-1.2 1.2-2.5 1.4-3.8 0.1-0.8 0-1.8-0.1-2.7v-0.6c-0.6-5-2.7-9.8-4.7-13.9-1.5-3-3.1-6-4.8-8.9l-0.5-0.9c-1.4-2.2-2.9-4.6-4.6-6.1-2.2-1.8-5.7-3.9-9.7-3.1-4.4 0.9-7.7 4.4-10.5 7.6-5 5.8-12.8 17.5-7.6 29.8z"
                />
                <path
                    id="63up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m619.2 190.2c0.3-3.2 0.8-6.4 1.4-9.6 0.9-4.4 2.2-8.8 3.5-13 1-3.1 2-6.4 2.8-9.7q0.1-0.7 0.4-1.5c0.2-0.7 0.5-1.5 0.5-2.1 1-6.8 1.4-13.8 1.8-20.5 0.4-6.5 0.8-13.2 1.6-19.7 2.8-22.4 6.6-45.9 18.5-64.6q0.2-0.3 0.4-0.7c0.9-1.5 2.1-3.6 4.1-3.3 1.9 0.4 2.3 2.6 2.4 3.4 0.3 1.8 0.1 3.5-0.1 5.2-0.2 0.9-0.3 1.9-0.3 2.8-0.4 11.5 0.6 23.1 1.5 34.4 0 4.9 0.6 9.9 1.2 14.7 0.3 2.5 0.5 4.9 0.7 7.3l2 22.5q0.2 1.9 0.4 3.7c0.2 2.5 0.5 5.1 0.6 7.6 0 1.2 0 2.3-0.1 3.5 0 1.5-0.1 3 0.1 4.6 0.7 6.4 1.9 12.9 3 19.1l0.4 2.8q0.5 2.6 0.9 5.2c0.6 3.3 1.3 7.9-0.6 11.5-1.7 3.2-5 5-8.2 6.7-1.1 0.6-2.2 1.2-3.3 1.9-1.4 0.8-2.7 1.9-4 2.9-1.2 1-2.5 2-3.9 2.9-2.4 1.5-4.8 2.3-7.4 2.3-5.4 0-10.3-3.5-14.7-6.6-1.9-1.3-3.8-2.8-5.1-4.8-1.4-2.3-1-5.2-0.7-7.6q0.1-0.7 0.2-1.3zm11.5-56.4c-0.4 6.8-0.9 13.8-1.8 20.6q0 0.6-0.2 1.1 0.4-0.2 0.8-0.5c1.2-0.6 2.4-1.3 3.5-2.1 1.1-0.9 2.1-1.8 3.1-2.7q0.8-0.7 1.5-1.4c3.5-3.1 6.7-4.4 9.6-4 2.9 0.4 5.2 2.3 7.2 4l0.5 0.4c2.2 1.9 4.2 3 6.5 3.7q0-1.2 0-2.4c0.1-1.1 0.1-2.2 0.1-3.4-0.1-2.5-0.4-5-0.6-7.4q-0.2-1.9-0.4-3.8l-1.9-22.5c-0.2-2.4-0.5-4.8-0.8-7.2-0.6-4.9-1.1-9.9-1.1-14.8-1-11.3-2-23-1.6-34.5 0-1 0.1-2 0.3-2.9 0.2-1.7 0.4-3.3 0.2-4.9-0.3-1.6-0.8-2.3-1.6-2.5q-0.1 0-0.2 0c-1.1 0-2 1.6-2.7 2.7q-0.2 0.5-0.4 0.8c-11.8 18.5-15.6 41.9-18.4 64.2-0.8 6.5-1.2 13.1-1.6 19.5zm-10.6 57.8c-0.3 2.4-0.7 4.9 0.6 6.9 1.1 1.9 2.9 3.3 4.7 4.5 4.2 3 9.1 6.4 14.1 6.4 2.4 0 4.6-0.7 6.8-2.1 1.4-0.9 2.6-1.9 3.8-2.8 1.3-1.1 2.7-2.1 4.1-3.1 1.1-0.6 2.3-1.3 3.4-1.9 3.2-1.7 6.2-3.2 7.7-6.2 1.7-3 1.2-6.8 0.6-10.8q-0.5-2.6-0.9-5.2l-0.5-2.8c-1.1-6.2-2.3-12.7-3-19.2q-0.1-0.6-0.1-1.3c-2.6-0.7-4.8-1.9-7.2-4l-0.5-0.3c-2-1.7-4.1-3.5-6.6-3.8-2.7-0.3-5.5 0.9-8.7 3.7q-0.8 0.7-1.6 1.4c-1 0.9-2 1.9-3.2 2.7-1.1 0.9-2.4 1.6-3.6 2.3q-0.9 0.5-1.8 1-0.1 0.6-0.3 1.1c-0.8 3.4-1.8 6.7-2.8 9.8-1.3 4.2-2.6 8.5-3.4 12.9-0.6 3.1-1.1 6.3-1.5 9.5q0 0.6-0.1 1.3z"
                />
                <path
                    id="63down-outline"
                    className="s3"
                    d="m664.9 290l-0.4 0.8c-3.2 4.2-6.9 6-13.9 6.7l-0.5-0.3c6.6-0.8 11.5-3.2 14.8-7.2z"
                />
                <path
                    id={63}
                    fillRule="evenodd"
                    className="s5"
                    d="m648.6 324.9v2.1q-0.9-0.4-1.8-0.6-0.9-0.2-1.8-0.2-2.3 0-3.6 1.6-1.2 1.5-1.4 4.7 0.7-1 1.8-1.5 1-0.6 2.3-0.6 2.6 0 4.1 1.6 1.6 1.6 1.6 4.4 0 2.7-1.6 4.3-1.6 1.6-4.3 1.6-3 0-4.6-2.3-1.6-2.3-1.6-6.7 0-4.2 1.9-6.6 2-2.5 5.3-2.5 0.9 0 1.8 0.2 0.9 0.1 1.9 0.5zm-4.7 7.4q-1.6 0-2.5 1.1-0.9 1.1-0.9 3 0 1.9 0.9 3 0.9 1.1 2.5 1.1 1.6 0 2.5-1.1 1-1.1 1-3 0-1.9-1-3-0.9-1.1-2.5-1.1zm17.1 0.3q1.7 0.3 2.7 1.5 0.9 1.1 0.9 2.8 0 2.6-1.8 4-1.7 1.4-5 1.4-1.1 0-2.3-0.2-1.2-0.2-2.4-0.6v-2.3q1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2.2 0 3.4-0.8 1.2-0.9 1.2-2.6 0-1.5-1.1-2.4-1.1-0.9-3-0.9h-2.1v-1.9h2.2q1.7 0 2.6-0.7 1-0.7 1-2 0-1.4-1-2.1-1-0.7-2.7-0.7-1 0-2.1 0.2-1.1 0.2-2.5 0.6v-2.1q1.4-0.3 2.5-0.5 1.2-0.2 2.3-0.2 2.7 0 4.2 1.2 1.6 1.2 1.6 3.3 0 1.5-0.8 2.5-0.9 1-2.4 1.4z"
                />
            </g>
            <g id="Group62" className={missingChecker(62) ? "g1-none" : "g1"}>
                <path
                    id="62down-fill"
                    className="s0"
                    d="m609.5 307.1c-8 5.7-18.8 5.8-29.5 0.3q1.1 0.4 2.2 0.6c2.7 0.7 5.5 1.2 8.3 1.4 5.4 0.4 11-0.2 16.6-1.7l0.8-0.2q0.8-0.2 1.6-0.4z"
                />
                <path
                    id="62down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m613.8 296.8c0.6 2.3 1.1 5.4-0.6 7.2-1.4 1.4-3.6 2-5.6 2.5l-0.7 0.2c-5.6 1.5-11 2-16.4 1.6-2.7-0.2-5.4-0.6-8.1-1.3q-2-0.5-4-1.2-0.5-0.1-1-0.3c-0.5-0.1-1.1-0.3-1.6-0.5q-0.8-0.5-1.6-1.1-2.1-2.3-2.2-4.7 0-0.9 0-1.9c0.1-1.9 0.4-3.8 0.7-5.8 0.7-4.9 2-9.8 3.9-14.5q0.2-0.6 0.5-1.4c0.9-2.5 2-5.3 3.7-7.1 1.5-1.5 3.6-2.9 5.5-3.7 2.7-0.9 6.2-0.7 8.9 0.7q1.6 0.9 2.6 2c6.7 7.5 11.9 17.1 16 29.3zm-6.3 2.4c-8.4 4.1-17.7 4.2-27.9 0.7 9.8 4.8 19.2 4.8 27.9-0.7z"
                />
                <path
                    id="62up-fill"
                    className="s0"
                    d="m572.1 159.1q0.1-0.6 0.2-1.2c0.8-5.6 1.6-10.2 2.5-14.3 0.8-3.2 1.6-6.6 2.5-9.8 1.4-5.2 2.8-10.6 3.7-16 0.5-3.9 0.9-7.9 1.2-11.7 0.3-3.9 0.6-7.8 1.3-11.7 1.8-11.6 5.3-18.8 10.7-22 0.7-0.4 1.5-0.7 2.1-0.7q1.6 0 2.3 2c0.6 1.7 0.2 3.9-0.2 6-0.2 1-0.4 1.9-0.4 2.8-0.6 7-0.4 14 0.1 22.2 0.6 8.9 1.4 17.9 2.1 26.6 0 3.5 0.4 7.1 0.8 10.7 0.2 1.7 0.4 3.5 0.6 5.2q0.2 2.4 0.3 5c0.2 2.1 0.3 4.3 0.5 6.5l-7.8-6.7c-2.1-1.8-4.3-2.8-7.2-2.1-3.1 0.8-5 3-7.3 5.1-1 0.9-2.4 1.6-3.6 2.3-0.5 0.2-4.1 1.6-4.4 1.8zm34.7 34.8c0.5 5.3-1.1 8.7-4.9 10.4-1.7 0.1-31.2 3.2-31.4 3.2-1.5 0.2-2.6-0.1-3.4-1-1.7-1.9-1.2-6.3-0.8-9.8q0.1-1.5 0.3-2.7c0.3-5.8 1-11.8 1.9-17.6 0.4-2.7 1.1-5.4 1.7-8 0.6-2.4 1.2-4.8 1.6-7.3q0.1-0.3 0.1-0.7c4.5-1.4 8.3-3.7 11.3-6.8q0.8-0.9 1.9-1.5c3.5-2 6.3-1.7 8.8 0.7l8.7 7.5q0.2 1.4 0.4 2.8l0.2 0.8c1.8 10.1 3.5 19.8 3.6 30z"
                />
                <path
                    id="62caries-vesial"
                    className={cariesChecker(62) ? cariesChecker(62).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m600 157l1 39-21 1-7 1 1-21 1-19 5-2 4-3 4-2h4l5 3zm-1 111l7 29h-26l3-31 5-2 5-1 3 2z"
                />
                <path
                    id="62implant-vesial"
                    className={implantChecker(62) ? implantChecker(62).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m600 157l1 39-21 1-7 1 1-21 1-19 5-2 4-3 4-2h4l5 3zm-1 111l7 29h-26l3-31 5-2 5-1 3 2z"
                />
                <path
                    id="62caries-mesial"
                    className={cariesChecker(62) ? cariesChecker(62).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m599 156l1 49 6-3 2-11-4-24-1-8zm-1 110l9 42 4-1 2-2 2-4-1-7-5-11-6-10-3-5z"
                />
                <path
                    id="62implant-mesial"
                    className={implantChecker(62) ? implantChecker(62).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m599 156l1 49 6-3 2-11-4-24-1-8zm-1 110l9 42 4-1 2-2 2-4-1-7-5-11-6-10-3-5z"
                />
                <path
                    id="62caries-distal"
                    className={cariesChecker(62) ? cariesChecker(62).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m581 297l3-32-4 4-3 6-3 7-2 8v10l2-2 3-1zm-7-100l2-39-4 2-1 4-2 10-2 10-1 10v8 2l3-4 3-2z"
                />
                <path
                    id="62implant-distal"
                    className={implantChecker(62) ? implantChecker(62).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m581 297l3-32-4 4-3 6-3 7-2 8v10l2-2 3-1zm-7-100l2-39-4 2-1 4-2 10-2 10-1 10v8 2l3-4 3-2z"
                />
                <path
                    id="62caries-occlusal"
                    className={cariesChecker(62) ? cariesChecker(62).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m566 202l5-5 13-1h16 3l4 4-2 3-3 2-9 1-5 1-8 1-9 1-3-1-3-2zm5 96l10-2h5 6 6l6 1h4 6l1 3v3l-2 3-9 5-7 2h-7l-6-2-5-3-6-4-2-2z"
                />
                <path
                    id="62implant-occlusal"
                    className={implantChecker(62) ? implantChecker(62).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m566 202l5-5 13-1h16 3l4 4-2 3-3 2-9 1-5 1-8 1-9 1-3-1-3-2zm5 96l10-2h5 6 6l6 1h4 6l1 3v3l-2 3-9 5-7 2h-7l-6-2-5-3-6-4-2-2z"
                />
                <path
                    id="62endo1"
                    className={endoRootCheker(62) ? endoRootCheker(62).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m595.6 70.4c0 0-3.2 13.4-4.5 17.5-0.7 4.7-1.8 12.7-1.8 12.7l-1.2 14.7-0.2 26.6v7.5l3.4 0.4 0.3-24.9 1.8-27.3 2.2-21.7-2.2 12z"
                />
                <path
                    id="62down-outline2"
                    className="s3"
                    d="m579.6 299.9c10.2 3.5 19.5 3.4 27.9-0.7-8.7 5.5-18.1 5.5-27.9 0.7z"
                />
                <path
                    id="62down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m573.3 304.5q-2.3-2.5-2.4-5.2 0-1 0.1-2.1c0-1.9 0.3-3.8 0.6-5.8 0.8-5.1 2.1-10 4-14.8q0.2-0.6 0.5-1.3c1-2.6 2.1-5.6 3.9-7.5 1.6-1.7 3.9-3.2 6-4 2.9-1 6.7-0.7 9.7 0.8q1.7 0.9 2.9 2.2c6.8 7.6 12.1 17.3 16.2 29.7 0.7 2.6 1.2 6.1-0.8 8.2q-0.3 0.2-0.6 0.5c-9.5 9.5-24.2 9.8-38.1 0.7-0.6-0.2-1.1-0.5-1.4-0.8q-0.2-0.2-0.4-0.4-0.1-0.1-0.3-0.2zm0.9-0.6q0.9 0.6 1.7 1.2c0.5 0.2 1 0.3 1.5 0.5q0.6 0.1 1 0.3 2.1 0.7 4.1 1.2c2.7 0.7 5.4 1.1 8.1 1.3 5.3 0.4 10.8-0.2 16.3-1.6l0.8-0.2c1.9-0.5 4.2-1.1 5.6-2.6 1.6-1.7 1.2-4.8 0.6-7.1-4.1-12.3-9.4-21.9-16-29.3q-1.1-1.2-2.6-2c-2.8-1.4-6.3-1.7-8.9-0.7-2 0.7-4.1 2.1-5.6 3.7-1.6 1.7-2.7 4.6-3.6 7.1q-0.3 0.7-0.6 1.4c-1.8 4.6-3.1 9.5-3.9 14.5q-0.5 3-0.6 5.7-0.1 1.1-0.1 2 0.1 2.4 2.2 4.6zm5.9 3.6c10.7 5.5 21.5 5.3 29.5-0.4q-0.8 0.3-1.7 0.5l-0.7 0.2c-5.6 1.5-11.2 2-16.7 1.6-2.7-0.2-5.5-0.6-8.3-1.3q-1-0.3-2.1-0.6z"
                />
                <path
                    id="62up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m569.8 208.6q-2.2 0-3.5-1.4c-2-2.3-1.5-6.9-1.1-10.6 0.1-1 0.3-1.9 0.3-2.6 0.4-5.9 1-11.9 1.9-17.8 0.5-2.7 1.1-5.5 1.8-8.1 0.5-2.3 1.1-4.8 1.5-7.2q0.3-1.5 0.5-3.2c0.9-5.6 1.7-10.2 2.6-14.3 0.7-3.3 1.6-6.6 2.4-9.8 1.4-5.2 2.8-10.6 3.7-16 0.6-3.8 0.9-7.8 1.2-11.6 0.3-3.9 0.7-7.9 1.3-11.8 1.9-12 5.6-19.4 11.2-22.7 2.7-1.6 5.1-0.8 6.1 1.8 0.7 2 0.2 4.4-0.2 6.6-0.2 1-0.4 1.9-0.4 2.7-0.6 7-0.4 13.9 0.1 22.1 0.6 8.8 1.3 17.8 2.1 26.5 0 3.5 0.4 7.2 0.8 10.6 0.2 1.8 0.4 3.6 0.6 5.3q0.2 2.5 0.3 5c0.3 3.6 0.5 7.3 1.1 10.8l0.1 0.8c1.9 10.2 3.6 19.8 3.7 30.2 0.6 5.7-1.3 9.6-5.6 11.4h-0.2c0 0-31.3 3.3-31.4 3.3q-0.5 0-0.9 0zm2.5-50.7q-0.1 0.6-0.2 1.2c0.3-0.2 3.9-1.6 4.4-1.8 1.2-0.7 2.6-1.4 3.6-2.3 2.3-2.1 4.2-4.3 7.3-5.1 2.9-0.7 5.1 0.3 7.2 2.1l7.8 6.7c-0.2-2.2-0.3-4.4-0.5-6.5q-0.1-2.6-0.3-5c-0.2-1.7-0.4-3.5-0.6-5.2-0.4-3.6-0.8-7.2-0.8-10.7-0.7-8.7-1.5-17.7-2.1-26.6-0.5-8.2-0.7-15.2-0.1-22.2 0-0.9 0.2-1.8 0.4-2.8 0.4-2.1 0.8-4.3 0.2-6q-0.7-2-2.3-2c-0.6 0-1.4 0.3-2.1 0.7-5.4 3.2-8.9 10.4-10.7 22-0.6 3.9-1 7.8-1.3 11.7-0.3 3.8-0.7 7.8-1.2 11.7-0.9 5.4-2.3 10.8-3.7 16.1-0.9 3.1-1.7 6.5-2.5 9.7-0.9 4.1-1.7 8.7-2.5 14.3zm-3.8 18.5c-0.9 5.8-1.6 11.8-1.9 17.6q-0.2 1.2-0.3 2.7c-0.4 3.5-0.9 7.9 0.8 9.8 0.8 0.9 1.9 1.2 3.4 1 0.2 0 29.7-3.1 31.4-3.3 3.8-1.6 5.4-5 4.9-10.3-0.1-10.3-1.8-19.9-3.6-30l-0.2-0.8q-0.2-1.4-0.4-2.9l-8.7-7.4c-2.5-2.4-5.3-2.7-8.8-0.7q-1.1 0.6-1.9 1.5c-3 3.1-6.8 5.4-11.3 6.8q0 0.4-0.1 0.7c-0.4 2.5-1 4.9-1.6 7.3-0.6 2.6-1.3 5.3-1.7 8z"
                />
                <path
                    id={62}
                    fillRule="evenodd"
                    className="s5"
                    d="m594.6 325.9v2.1q-0.9-0.4-1.8-0.6-0.9-0.2-1.8-0.2-2.3 0-3.6 1.6-1.2 1.5-1.4 4.7 0.7-1 1.8-1.5 1-0.6 2.3-0.6 2.6 0 4.1 1.6 1.6 1.6 1.6 4.4 0 2.7-1.6 4.3-1.6 1.6-4.3 1.6-3 0-4.6-2.3-1.6-2.3-1.6-6.7 0-4.2 1.9-6.6 2-2.5 5.3-2.5 0.9 0 1.8 0.2 0.9 0.1 1.9 0.5zm-4.7 7.4q-1.6 0-2.5 1.1-0.9 1.1-0.9 3 0 1.9 0.9 3 0.9 1.1 2.5 1.1 1.6 0 2.5-1.1 1-1.1 1-3 0-1.9-1-3-0.9-1.1-2.5-1.1zm12 7.7h8.2v2h-11.1v-2q1.4-1.4 3.7-3.7 2.3-2.4 2.9-3.1 1.2-1.2 1.6-2.1 0.5-0.9 0.5-1.8 0-1.4-1-2.2-1-0.9-2.6-0.9-1.1 0-2.3 0.4-1.2 0.4-2.7 1.1v-2.4q1.5-0.5 2.7-0.8 1.3-0.3 2.3-0.3 2.7 0 4.3 1.3 1.6 1.4 1.6 3.7 0 1.1-0.4 2-0.4 1-1.4 2.3-0.3 0.3-1.9 2-1.6 1.6-4.4 4.5z"
                />
            </g>
            <g id="Group61" className={missingChecker(61) ? "g1-none" : "g1"}>
                <path
                    id="61down-fill2"
                    className="s0"
                    d="m513.9 307.9c3.1 0.5 5.7 0.9 8.4 1.2 4.2 0.5 8.1 0.6 12.4 0.4 3.8-0.1 7.8-0.5 11.8-1.3q3.1-0.5 6.2-1.3 0.7-0.1 1.6-0.2-9.4 6.3-20.1 6.3-9.7 0.1-20.3-5.1z"
                />
                <path
                    id="61down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m558.2 292.1c0.9 2.5 1.7 4.8 1.3 7.7-0.3 1.6-0.7 3.4-1.8 4.6-0.9 0.9-2.2 1.1-3.6 1.3q-0.8 0.1-1.7 0.3-3 0.7-6 1.2c-4.1 0.8-8 1.2-11.7 1.3-4.3 0.2-8.2 0.1-12.3-0.3-3.3-0.4-6.5-0.9-10.5-1.7-0.6-0.1-1.3-0.2-1.8-0.6-1.1-0.9-1.3-3.9-1.4-5.3-0.2-2.5 0.4-4.7 1-6.6 3.4-11.2 7.8-20.1 13.6-27.3 7.1-5.2 13.7-5.1 20 0.4 6.4 6.1 11.3 13.9 14.3 23.2q0.3 0.9 0.6 1.8zm-5 6.7l0.6-0.3c-13.3 5-26.7 5.2-38.9 0.7l-0.9-0.4c12.1 6.6 25.3 7 39.2 0z"
                />
                <path
                    id="61up-fill"
                    className="s0"
                    d="m518.5 153.1c-2.6 3.2-5.1 6.3-8.2 8.8q0-0.2 0.1-0.5c0.2-1.9 0.3-3.8 0.4-5.7q0-1.3 0.1-2.5l0.2-2.3c0.6-9.9 1.2-20.2 2.9-30.2 1.1-5.9 2.6-11.8 4-17.6l0.8-3c0.9-3.8 2.2-7.7 3.5-11.4 1.1-3 2.2-6.2 3.1-9.4 1.2-4.5 2.1-9.2 2.6-13.9q0.2-1.5 0.2-3c0.1-3.6 0.3-7.3 2.8-9.6 0.4-0.4 1-0.6 1.5-0.6q0.3 0 0.5 0.1c0.7 0.3 1 1.2 1.3 2 5.7 17.2 7.6 35.6 9.3 53.4q0.6 5.4 1.1 10.7c0.4 3.7 0.8 7.4 1.1 11 0.9 9.9 1.9 20.1 4 29.9q-0.9-0.4-1.8-0.6c-0.6-0.1-1.2-0.3-1.8-0.5q-2-0.9-4.2-2.7c-0.9-0.8-1.8-1.6-2.6-2.4-1.5-1.4-3-2.7-4.7-3.9-4-2.6-9.8-3.7-13.2 0.3-1.1 1.2-2 2.4-3 3.6zm31.6 7.6c2.7 13.3 3.6 23.3 2.8 34.3 0 0.8 0 1.7 0 2.6 0 1.7 0 3.3-0.4 4.8-0.9 3-3 4.9-5.5 5.2-1.8 0.2-3.4 0.6-5 0.9-2.1 0.5-4 0.9-6.2 1.1-6 0.3-11 0.5-15.7 0.5-3.2 0-6.2-0.1-9.1-0.2q-5-0.3-5.4-6c-1.3-13.8 1.8-28.6 4.4-40.5h0.1c3.6-2.7 6.5-6.2 9.2-9.6q1.5-1.8 3-3.6c3-3.5 8.2-2.5 11.8-0.1 1.6 1.1 3.1 2.5 4.5 3.8q1.3 1.2 2.7 2.4c1.6 1.3 3 2.2 4.5 2.9 0.6 0.2 1.3 0.4 1.9 0.6 0.9 0.2 1.7 0.4 2.4 0.9z"
                />
                <path
                    id="61caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(61) ? cariesChecker(61).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m520 153l4-5h4l5 1 5 3 6 5 1 39-23 1-8-1 2-39zm24 114l7 30-8-1-7-1-8 1h-6l-5 2 1-6 2-10 4-9 2-8 4-2h4l5 1 3 2z"
                />
                <path
                    id="61implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(61) ? implantChecker(61).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m520 153l4-5h4l5 1 5 3 6 5 1 39-23 1-8-1 2-39zm24 114l7 30-8-1-7-1-8 1h-6l-5 2 1-6 2-10 4-9 2-8 4-2h4l5 1 3 2z"
                />
                <path
                    id="61caries-mesial"
                    className={cariesChecker(61) ? cariesChecker(61).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m543 157l1 53 6-4 3-5v-18l-1-16-2-7h-4zm0 109l9 43 5-4 2-4 1-3v-5l-7-15-6-8z"
                />
                <path
                    id="61implant-mesial"
                    className={implantChecker(61) ? implantChecker(61).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m543 157l1 53 6-4 3-5v-18l-1-16-2-7h-4zm0 109l9 43 5-4 2-4 1-3v-5l-7-15-6-8z"
                />
                <path
                    id="61caries-distal"
                    className={cariesChecker(61) ? cariesChecker(61).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m518 297l9-33-4 2-5 6-2 6-3 6-3 6-1 6v5l2-2 3-1zm-3-101l2-39-5 4-2 3-2 9-1 8-1 8-1 10 1 6 4-4z"
                />
                <path
                    id="61implant-distal"
                    className={implantChecker(61) ? implantChecker(61).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m518 297l9-33-4 2-5 6-2 6-3 6-3 6-1 6v5l2-2 3-1zm-3-101l2-39-5 4-2 3-2 9-1 8-1 8-1 10 1 6 4-4z"
                />
                <path
                    id="61caries-occlusal"
                    className={cariesChecker(61) ? cariesChecker(61).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m505 206l9-10 12-1h22l6 6-1 4-3 3-7 2-9 1h-14l-10-1h-3zm4 98v-4l5-2 6-2 6-1 6-1 8 1 6 1 7 1 5 1h3l-1 5-4 3-6 4-8 3-5 1h-8l-6-1-6-3-5-3-3-1z"
                />
                <path
                    id="61caries-occlusal"
                    className={implantChecker(61) ? implantChecker(61).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m505 206l9-10 12-1h22l6 6-1 4-3 3-7 2-9 1h-14l-10-1h-3zm4 98v-4l5-2 6-2 6-1 6-1 8 1 6 1 7 1 5 1h3l-1 5-4 3-6 4-8 3-5 1h-8l-6-1-6-3-5-3-3-1z"
                />
                <path
                    id="61endo1"
                    className={endoRootCheker(61) ? endoRootCheker(61).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m534.6 68.4c0 0-3.2 13.4-4.5 17.5-0.7 4.7-1.8 12.7-1.8 12.7l-1.2 14.7-0.2 26.6v7.5l3.4 0.4 0.3-24.9 1.8-27.3 2.2-21.7-2.2 12z"
                />
                <path
                    id="61down-outline"
                    className="s3"
                    d="m553.9 298.5l-0.7 0.3c-13.9 7-27.1 6.6-39.2 0l0.9 0.4c12.3 4.5 25.6 4.3 39-0.7z"
                />
                <path
                    id="61down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m534.2 314.1q-11.4 0-24.1-7.1-0.3-0.1-0.6-0.3c-1.5-1.2-1.7-4.3-1.8-6-0.2-2.7 0.4-5 1-7 3.5-11.4 8-20.4 13.9-27.6v-0.1c3.7-2.8 7.3-4.1 10.8-4.1q0.7 0 1.4 0c3.1 0.4 6.2 1.9 9.2 4.5 6.5 6.2 11.5 14.1 14.6 23.6q0.3 0.9 0.6 1.7c0.9 2.6 1.7 5.1 1.2 8.2-0.3 1.8-0.7 3.8-2 5.2-0.5 0.5-1.1 0.8-1.7 1q-10.4 8-22.5 8zm-10.9-47.4c-5.8 7.2-10.2 16.1-13.6 27.3-0.6 1.9-1.2 4.1-1 6.6 0.1 1.4 0.3 4.4 1.4 5.3 0.5 0.4 1.2 0.5 1.8 0.6 4 0.8 7.2 1.3 10.5 1.7 4.1 0.4 8 0.5 12.3 0.3 3.7-0.1 7.6-0.5 11.7-1.3q3-0.5 6-1.2 0.9-0.2 1.7-0.3c1.4-0.2 2.7-0.4 3.6-1.3 1.1-1.2 1.5-3 1.8-4.6 0.4-2.9-0.4-5.2-1.3-7.7q-0.3-0.9-0.6-1.8c-3-9.3-7.9-17.1-14.3-23.2-6.3-5.5-12.9-5.6-20-0.4zm10.9 46.3q10.7 0 20.1-6.3-0.9 0.1-1.6 0.2-3.1 0.8-6.2 1.3c-4 0.8-8 1.2-11.8 1.3-4.3 0.2-8.2 0.1-12.4-0.4-2.7-0.3-5.3-0.7-8.4-1.2q10.6 5.2 20.3 5.1z"
                />
                <path
                    id="61up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m504.5 204c-1.3-14 1.9-29 4.5-41.1q0.2-0.8 0.3-1.6c0.2-1.9 0.3-3.8 0.4-5.6q0.1-1.3 0.1-2.6l0.2-2.2c0.6-10 1.3-20.3 3-30.3 1-6 2.5-12 4-17.8l0.7-2.9c1-3.9 2.3-7.8 3.6-11.5 1.1-3.1 2.1-6.2 3-9.4 1.3-4.5 2.2-9.1 2.6-13.7q0.2-1.4 0.2-3c0.1-3.6 0.3-7.7 3.2-10.3 0.9-0.9 2.2-1.1 3.2-0.7 1 0.5 1.5 1.7 1.8 2.7 5.8 17.3 7.6 35.7 9.4 53.6q0.6 5.4 1.1 10.7c0.4 3.7 0.8 7.4 1.1 11 0.9 9.8 1.9 20 4 29.8 3 14.1 3.9 24.5 3.1 35.9 0 0.9 0 1.7 0 2.6 0 1.7 0 3.4-0.5 5.1-1 3.4-3.4 5.6-6.3 6-1.8 0.2-3.4 0.6-5 0.9-2 0.4-4 0.9-6.4 1-6 0.4-11 0.6-15.7 0.6-3.2 0-6.2-0.1-9.2-0.2-3.8-0.3-6-2.6-6.4-7zm13.5-100.9c-1.4 5.8-2.9 11.7-4 17.7-1.7 9.9-2.3 20.2-2.9 30.1l-0.2 2.3q-0.1 1.2-0.1 2.5c-0.1 1.9-0.2 3.8-0.4 5.7q-0.1 0.3-0.1 0.5c3.1-2.5 5.6-5.6 8.2-8.8 1-1.2 1.9-2.4 3-3.6 3.4-4 9.2-2.9 13.2-0.3 1.7 1.2 3.2 2.6 4.7 3.9 0.8 0.8 1.7 1.6 2.6 2.4q2.2 1.8 4.2 2.7c0.6 0.2 1.2 0.4 1.8 0.5q0.9 0.2 1.8 0.6c-2.1-9.8-3.1-20-4-29.9-0.3-3.6-0.7-7.3-1.1-11q-0.5-5.3-1.1-10.7c-1.7-17.8-3.6-36.2-9.3-53.4-0.3-0.8-0.6-1.7-1.3-2q-0.2-0.1-0.5-0.1c-0.5 0-1.1 0.2-1.5 0.6-2.5 2.3-2.7 6-2.8 9.6q0 1.5-0.2 3c-0.5 4.7-1.4 9.4-2.6 13.9-0.9 3.2-2 6.4-3.1 9.4-1.3 3.7-2.6 7.6-3.5 11.4zm-12.5 100.9c0.4 3.8 2.1 5.7 5.4 5.9 2.9 0.2 5.9 0.2 9.1 0.2 4.7 0 9.7-0.1 15.7-0.5 2.3-0.1 4.2-0.6 6.2-1 1.6-0.4 3.3-0.7 5.1-1 2.5-0.3 4.5-2.2 5.4-5.2 0.5-1.5 0.4-3.1 0.4-4.8 0-0.8 0-1.7 0.1-2.6 0.7-10.9-0.1-20.9-2.9-34.3-0.7-0.4-1.5-0.6-2.4-0.9-0.6-0.1-1.3-0.3-1.9-0.6-1.4-0.6-2.9-1.6-4.4-2.9-1-0.7-1.9-1.6-2.7-2.4-1.5-1.3-3-2.6-4.6-3.7-3.6-2.4-8.8-3.4-11.8 0.1q-1.5 1.7-2.9 3.5c-2.8 3.4-5.6 7-9.3 9.7v-0.1c-2.6 12-5.8 26.8-4.5 40.6z"
                />
                <path
                    id={61}
                    fillRule="evenodd"
                    className="s5"
                    d="m533.6 326.9v2.1q-0.9-0.4-1.8-0.6-0.9-0.2-1.8-0.2-2.3 0-3.6 1.6-1.2 1.5-1.4 4.7 0.7-1 1.8-1.5 1-0.6 2.3-0.6 2.6 0 4.1 1.6 1.6 1.6 1.6 4.4 0 2.7-1.6 4.3-1.6 1.6-4.3 1.6-3 0-4.6-2.3-1.6-2.3-1.6-6.7 0-4.2 1.9-6.6 2-2.5 5.3-2.5 0.9 0 1.8 0.2 0.9 0.1 1.9 0.5zm-4.7 7.4q-1.6 0-2.5 1.1-0.9 1.1-0.9 3 0 1.9 0.9 3 0.9 1.1 2.5 1.1 1.6 0 2.5-1.1 1-1.1 1-3 0-1.9-1-3-0.9-1.1-2.5-1.1zm10.3 9.7v-2h3.9v-13.3l-4.2 0.8v-2.2l4.2-0.8h2.4v15.5h3.8v2z"
                />
            </g>
            <g id="Group51" className={missingChecker(51) ? "g1-none" : "g1"}>
                <path
                    id="51down-fill2"
                    className="s0"
                    d="m447.5 306.9q0.9 0.1 1.6 0.2 3.1 0.8 6.2 1.3c4 0.8 8 1.2 11.8 1.3 4.3 0.2 8.3 0.1 12.4-0.4 2.7-0.3 5.4-0.7 8.4-1.2-15 7.3-28.5 6.8-40.4-1.2z"
                />
                <path
                    id="51down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m492.1 294.2c0.6 1.9 1.2 4.2 1 6.7-0.1 1.4-0.3 4.3-1.5 5.2-0.4 0.4-1.1 0.5-1.7 0.6-4 0.8-7.2 1.3-10.5 1.7-4.1 0.4-8 0.5-12.3 0.4-3.7-0.2-7.6-0.6-11.7-1.3q-3-0.6-6-1.3c-0.6-0.2-1.2-0.2-1.7-0.3-1.4-0.2-2.7-0.4-3.6-1.3-1.1-1.2-1.5-3-1.8-4.6-0.4-2.9 0.4-5.1 1.3-7.7q0.3-0.9 0.6-1.8c3-9.3 7.9-17.1 14.3-23.2 6.3-5.5 12.9-5.7 20-0.3 5.8 7.1 10.2 16 13.6 27.2zm-4.3 4.8l-0.9 0.4c-12.3 4.6-25.6 4.3-38.9-0.8l0.6 0.4c13.9 7 27.1 6.6 39.2 0z"
                />
                <path
                    id="51up-fill"
                    className="s0"
                    d="m452 158.3q-0.9 0.3-1.8 0.6c2.1-9.8 3-20 4-29.9 0.3-3.6 0.7-7.3 1.1-11q0.5-5.3 1.1-10.7c1.7-17.8 3.6-36.2 9.3-53.4 0.3-0.8 0.6-1.7 1.3-2q0.2-0.1 0.6-0.1c0.4 0 1 0.2 1.4 0.6 2.5 2.3 2.7 6 2.8 9.6 0 1 0.1 2 0.2 3 0.4 4.7 1.4 9.4 2.6 13.9 0.9 3.2 2 6.4 3.1 9.4 1.2 3.7 2.6 7.6 3.5 11.4l0.8 3c1.4 5.8 2.9 11.8 3.9 17.7 1.8 9.9 2.4 20.2 3 30.1l0.2 2.3q0.1 1.3 0.1 2.5c0.1 1.9 0.2 3.8 0.4 5.7q0.1 0.3 0.1 0.5c-3.1-2.5-5.6-5.6-8.2-8.8q-1.4-1.8-3-3.6c-3.4-4-9.2-2.9-13.2-0.3-1.7 1.2-3.2 2.6-4.7 3.9-0.8 0.8-1.7 1.6-2.6 2.4q-2.2 1.8-4.2 2.7c-0.6 0.2-1.2 0.4-1.8 0.5zm38 4.7c2.6 11.9 5.7 26.8 4.4 40.5q-0.5 5.7-5.4 6c-2.9 0.1-5.9 0.2-9.1 0.2-4.7 0-9.7-0.2-15.7-0.5-2.2-0.2-4.1-0.6-6.2-1.1-1.6-0.3-3.2-0.7-5-0.9-2.6-0.3-4.6-2.2-5.5-5.2-0.4-1.5-0.4-3.1-0.4-4.8 0-0.9 0-1.7 0-2.6-0.8-11 0.1-21 2.8-34.3 0.7-0.5 1.5-0.7 2.4-0.9 0.6-0.2 1.3-0.4 1.9-0.6 1.4-0.7 2.9-1.6 4.5-2.9q1.3-1.2 2.7-2.4c1.4-1.3 2.9-2.7 4.5-3.8 3.6-2.4 8.8-3.4 11.8 0.1q1.5 1.8 3 3.6c2.7 3.4 5.6 6.9 9.2 9.6z"
                />
                <path
                    id="51caries-vesial"
                    className={cariesChecker(51) ? cariesChecker(51).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m484 156l1 40-31 1 2-39 4-4 5-4 4-2h4l4 1 3 3zm-4 111l6 32-37-2 4-10 6-18 1-4 4-2 5-1 5 2 5 2z"
                />
                <path
                    id="51implant-vesial"
                    className={implantChecker(51) ? implantChecker(51).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m484 156l1 40-31 1 2-39 4-4 5-4 4-2h4l4 1 3 3zm-4 111l6 32-37-2 4-10 6-18 1-4 4-2 5-1 5 2 5 2z"
                />
                <path
                    id="51caries-mesial"
                    className={cariesChecker(51) ? cariesChecker(51).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m483 157l1 54 5-1 4-3 2-2 1-11-2-12-2-12-2-6-1-2-3-3zm-4 110l8 42 4-2 3-3-1-6-1-7-3-9-4-9-3-4z"
                />
                <path
                    id="51implant-mesial"
                    className={implantChecker(51) ? implantChecker(51).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m483 157l1 54 5-1 4-3 2-2 1-11-2-12-2-12-2-6-1-2-3-3zm-4 110l8 42 4-2 3-3-1-6-1-7-3-9-4-9-3-4z"
                />
                <path
                    id="51caries-distal"
                    className={cariesChecker(51) ? cariesChecker(51).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m451 296l10-31-5 4-6 7-3 7-4 8-1 8 1 3zm4-101l2-38-4 2h-3l-2 11-1 12v9 10l1 3z"
                />
                <path
                    id="51implant-distal"
                    className={implantChecker(51) ? implantChecker(51).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m451 296l10-31-5 4-6 7-3 7-4 8-1 8 1 3zm4-101l2-38-4 2h-3l-2 11-1 12v9 10l1 3z"
                />
                <path
                    id="51caries-occlusal"
                    className={cariesChecker(51) ? cariesChecker(51).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m448 203l7-8h31l8 12-3 4h-13l-17-1-8-2-4-1zm-6 97l8-4h10 13l9 1 8 2 4 2v5l-5 3-8 3-8 3-11-1-8-2-8-5-4-3z"
                />
                <path
                    id="51caries-occlusal"
                    className={implantChecker(51) ? implantChecker(51).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m448 203l7-8h31l8 12-3 4h-13l-17-1-8-2-4-1zm-6 97l8-4h10 13l9 1 8 2 4 2v5l-5 3-8 3-8 3-11-1-8-2-8-5-4-3z"
                />
                <path
                    id="51endo1"
                    className={endoRootCheker(51) ? endoRootCheker(51).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m464.9 67.4c0 0 3.2 13.4 4.4 17.5 0.8 4.7 1.9 12.7 1.9 12.7l1.2 14.7 0.2 26.6v7.5l-3.5 0.4-0.2-24.9-1.9-27.3-2.1-21.7 2.1 12z"
                />
                <path
                    id="51down-outline"
                    className="s3"
                    d="m486.9 299.4l0.9-0.4c-12.1 6.6-25.3 7-39.2 0l-0.6-0.3c13.3 5 26.7 5.3 38.9 0.7z"
                />
                <path
                    id="51up-outline3"
                    fillRule="evenodd"
                    className="s3"
                    d="m449.1 158.7c2.1-9.8 3.1-20 4-29.8 0.3-3.6 0.7-7.3 1.1-11q0.5-5.3 1.1-10.7c1.8-17.9 3.6-36.3 9.4-53.6 0.3-1.1 0.7-2.2 1.8-2.7 1-0.4 2.3-0.1 3.2 0.7 2.9 2.6 3.1 6.7 3.2 10.3q0 1.6 0.2 3c0.4 4.6 1.3 9.2 2.6 13.7 0.9 3.2 1.9 6.3 3 9.4 1.3 3.7 2.6 7.5 3.6 11.5l0.7 2.9c1.5 5.8 3 11.8 4 17.8 1.7 10 2.4 20.3 3 30.3l0.2 2.2q0 1.3 0.1 2.6c0.1 1.8 0.2 3.7 0.4 5.6q0.1 0.8 0.3 1.6c2.6 12.1 5.8 27.1 4.5 41.1-0.4 4.4-2.6 6.7-6.4 6.9-3 0.2-6 0.3-9.2 0.3-4.7 0-9.7-0.2-15.7-0.5-2.4-0.2-4.4-0.7-6.4-1.1-1.6-0.3-3.2-0.7-5-0.9-2.9-0.4-5.3-2.6-6.3-6-0.5-1.7-0.5-3.4-0.5-5.1 0-0.9 0-1.7 0-2.6-0.8-11.4 0.1-21.8 3.1-35.9zm3.2 0.7c-0.9 0.2-1.7 0.4-2.4 0.9-2.7 13.3-3.6 23.3-2.8 34.3 0 0.9 0 1.7 0 2.6 0 1.7 0 3.3 0.4 4.8 0.9 3 2.9 4.9 5.5 5.2 1.8 0.2 3.4 0.6 5 0.9 2.1 0.5 4 0.9 6.2 1.1 6 0.3 11 0.5 15.7 0.5 3.2 0 6.2-0.1 9.1-0.2 3.3-0.2 5-2.2 5.4-6 1.3-13.7-1.9-28.6-4.4-40.5h-0.1c-3.6-2.7-6.5-6.2-9.2-9.6q-1.5-1.8-3-3.6c-3-3.5-8.2-2.5-11.8-0.1-1.6 1.1-3.1 2.5-4.5 3.8q-1.4 1.2-2.7 2.4c-1.6 1.3-3.1 2.2-4.5 2.9-0.6 0.2-1.3 0.4-1.9 0.6zm13.4-105.5c-5.7 17.2-7.6 35.6-9.3 53.4q-0.6 5.4-1.1 10.7c-0.4 3.7-0.8 7.4-1.1 11-1 9.9-1.9 20.1-4 29.9q0.9-0.3 1.8-0.6c0.6-0.1 1.2-0.3 1.8-0.5q2-0.9 4.2-2.7c0.9-0.8 1.8-1.6 2.6-2.4 1.5-1.3 3-2.7 4.7-3.9 4-2.6 9.8-3.7 13.2 0.3q1.6 1.8 3 3.6c2.6 3.2 5.1 6.3 8.2 8.8q0-0.2-0.1-0.5c-0.2-1.9-0.3-3.8-0.4-5.7q0-1.2-0.1-2.5l-0.2-2.3c-0.6-9.9-1.2-20.2-3-30.1-1-5.9-2.5-11.9-3.9-17.7l-0.8-3c-0.9-3.8-2.3-7.7-3.5-11.4-1.1-3-2.2-6.2-3.1-9.4-1.3-4.5-2.2-9.2-2.6-13.9-0.1-1-0.2-2-0.2-3-0.1-3.6-0.3-7.3-2.8-9.6-0.4-0.4-1-0.6-1.5-0.6q-0.3 0-0.5 0.1c-0.7 0.3-1 1.2-1.3 2z"
                />
                <path
                    id="51down-outline2"
                    fillRule="evenodd"
                    className="s3"
                    d="m443.2 290.2c3.2-9.5 8.1-17.4 14.6-23.6q2.3-2 4.6-3.1 2.3-1.1 4.6-1.4c3.9-0.4 8 1 12.1 4.1l0.1 0.1c5.9 7.2 10.4 16.2 13.9 27.6 0.6 2 1.2 4.4 1 7-0.1 1.7-0.3 4.8-1.8 6q-0.3 0.2-0.6 0.4c-17.5 9.6-33.1 9.3-46.6-0.9-0.6-0.3-1.2-0.6-1.7-1.1-1.3-1.4-1.8-3.4-2-5.2-0.5-3.1 0.3-5.6 1.2-8.1q0.3-0.9 0.6-1.8zm1 0.3q-0.3 0.9-0.6 1.8c-0.9 2.6-1.7 4.8-1.3 7.7 0.3 1.6 0.7 3.4 1.8 4.6 0.9 0.9 2.2 1.1 3.6 1.3 0.5 0.1 1.1 0.1 1.7 0.3q3 0.7 6 1.3c4.1 0.7 8 1.1 11.7 1.3 4.3 0.1 8.2 0 12.3-0.4 3.3-0.4 6.5-0.9 10.5-1.7 0.6-0.1 1.3-0.2 1.7-0.6 1.2-0.9 1.4-3.8 1.5-5.2 0.2-2.5-0.4-4.8-1-6.7-3.3-11.2-7.8-20.1-13.6-27.2-7.1-5.4-13.7-5.2-20 0.3-6.4 6.1-11.3 13.9-14.3 23.2zm11.1 17.9q-3.1-0.5-6.2-1.3-0.7-0.1-1.6-0.2c11.9 8 25.4 8.5 40.4 1.2-3 0.5-5.7 0.9-8.4 1.2-4.1 0.5-8.1 0.6-12.4 0.4-3.8-0.1-7.8-0.6-11.8-1.3z"
                />
                <path
                    id={51}
                    className="s5"
                    d="m454.6 336.3v-8.8h9.3v2h-7.1v4.3q0.5-0.2 1-0.3 0.5-0.1 1-0.1 2.9 0 4.7 1.6 1.7 1.6 1.7 4.4 0 2.8-1.8 4.4-1.7 1.5-4.9 1.5-1.1 0-2.3-0.1-1.1-0.2-2.3-0.6v-2.4q1 0.6 2.1 0.9 1.2 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-0.9 0-1.9 0.2-0.9 0.2-1.9 0.7zm15.6 8.7v-2h3.9v-13.3l-4.2 0.8v-2.2l4.2-0.8h2.4v15.5h3.8v2z"
                />
            </g>
            <g id="Group52" className={missingChecker(52) ? "g1-none" : "g1"}>
                <path
                    id="52down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m429.8 297.5q0 1 0 2 0 2.3-2.2 4.6-0.8 0.6-1.6 1.1c-0.5 0.2-1.1 0.4-1.6 0.5q-0.5 0.2-1 0.3-2 0.7-4 1.2c-2.7 0.7-5.4 1.1-8.1 1.3-5.4 0.4-10.8-0.1-16.4-1.6l-0.7-0.2c-2-0.5-4.2-1.1-5.6-2.5-1.7-1.8-1.2-4.9-0.6-7.2 4.1-12.2 9.3-21.8 16-29.3q1-1.1 2.6-2c2.8-1.4 6.2-1.6 8.9-0.7 1.9 0.8 4 2.1 5.5 3.8 1.7 1.7 2.8 4.5 3.7 7.1q0.3 0.7 0.5 1.3c1.9 4.7 3.2 9.6 3.9 14.6 0.3 1.9 0.6 3.8 0.7 5.7zm-7.6 2.6c-10.2 3.5-19.5 3.4-27.9-0.7 8.7 5.5 18.1 5.5 27.9 0.7z"
                />
                <path
                    id="52up-fill"
                    className="s0"
                    d="m397.6 158.3c0.2-2.2 0.3-4.4 0.5-6.5q0.1-2.5 0.3-5c0.2-1.7 0.4-3.5 0.6-5.2 0.4-3.6 0.8-7.2 0.8-10.7 0.7-8.7 1.5-17.7 2.1-26.5 0.5-8.3 0.7-15.3 0.1-22.3 0-0.9-0.2-1.8-0.4-2.8-0.4-2.1-0.8-4.3-0.2-6q0.7-2 2.3-2c0.6 0 1.4 0.3 2.1 0.7 5.4 3.2 8.9 10.4 10.7 22 0.6 3.9 1 7.8 1.3 11.7 0.3 3.8 0.6 7.8 1.2 11.7 0.9 5.4 2.3 10.8 3.7 16.1 0.9 3.2 1.7 6.5 2.5 9.7 0.9 4.2 1.7 8.7 2.5 14.3q0.1 0.6 0.2 1.2c-0.3-0.2-4-1.5-4.4-1.8-1.2-0.7-2.6-1.4-3.6-2.3-2.3-2.1-4.2-4.3-7.3-5.1-2.9-0.7-5.1 0.3-7.2 2.1zm36.1 38c0.4 3.5 0.9 7.9-0.8 9.8-0.8 0.9-1.9 1.2-3.4 1-0.2 0-29.7-3.1-31.4-3.2-3.8-1.7-5.4-5.1-4.9-10.4 0.1-10.3 1.8-19.9 3.6-30l0.2-0.8q0.2-1.4 0.4-2.8l8.7-7.5c2.5-2.4 5.3-2.7 8.8-0.7 0.7 0.4 1.4 0.9 1.9 1.5 3 3.1 6.8 5.4 11.3 6.8q0 0.4 0.1 0.7c0.4 2.5 1 4.9 1.6 7.3 0.6 2.6 1.3 5.3 1.7 8 0.9 5.8 1.6 11.8 1.9 17.6q0.1 1.2 0.3 2.7z"
                />
                <path
                    id="52down-fill2"
                    className="s0"
                    d="m421.8 307.6c-10.7 5.5-21.5 5.4-29.6-0.3q0.9 0.2 1.7 0.4l0.8 0.2c5.6 1.5 11.2 2.1 16.6 1.7q4.2-0.3 8.3-1.4 1.1-0.2 2.2-0.6z"
                />
                <path
                    id="52caries-vesial"
                    fillRule="evenodd"
                    className={cariesChecker(52) ? cariesChecker(52).cariesVesial ? "s1-blue" : "s1" : "s1"}
                    d="m403 154l4-3 5-1 3 1 4 4 5 4 1 37-27-1 3-39zm17 113l3 28h-4l-22-1 2-5 5-14 2-6 1-5h9z"
                />
                <path
                    id="52implant-vesial"
                    fillRule="evenodd"
                    className={implantChecker(52) ? implantChecker(52).implantVesial ? "s1-red" : "s1" : "s1"}
                    d="m403 154l4-3 5-1 3 1 4 4 5 4 1 37-27-1 3-39zm17 113l3 28h-4l-22-1 2-5 5-14 2-6 1-5h9z"
                />
                <path
                    id="52caries-mesial"
                    className={cariesChecker(52) ? cariesChecker(52).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m423 158l1 49h7l3-2v-10l-1-13-3-14-2-7v-2h-2zm-4 109l4 39 4-1 3-5v-5l-1-7-2-9-3-6-2-3z"
                />
                <path
                    id="52implant-mesial"
                    className={implantChecker(52) ? implantChecker(52).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m423 158l1 49h7l3-2v-10l-1-13-3-14-2-7v-2h-2zm-4 109l4 39 4-1 3-5v-5l-1-7-2-9-3-6-2-3z"
                />
                <path
                    id="52caries-distal"
                    className={cariesChecker(52) ? cariesChecker(52).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m398 295l7-20 3-10-5 4-4 5-3 5-3 5-3 6-2 7v3l4-2zm1-101l3-37-4 2-1 5-2 9-1 11-1 11 1 5z"
                />
                <path
                    id="52implant-distal"
                    className={implantChecker(52) ? implantChecker(52).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m398 295l7-20 3-10-5 4-4 5-3 5-3 5-3 6-2 7v3l4-2zm1-101l3-37-4 2-1 5-2 9-1 11-1 11 1 5z"
                />
                <path
                    id="52caries-occlusal"
                    className={cariesChecker(52) ? cariesChecker(52).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m394 199l5-5 2-2h8 10 9l7 5 1 7-2 3-3 1-11-1-13-1-7-1-4-2-1-3zm-6 100l3-3 4-1 3-1h5 5 5 4l5 1 3 1 2 1 4 1 1 1-1 3-3 3-5 3-6 4h-6-8l-6-2-4-3-3-1-2-3z"
                />
                <path
                    id="52implant-occlusal"
                    className={implantChecker(52) ? implantChecker(52).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m394 199l5-5 2-2h8 10 9l7 5 1 7-2 3-3 1-11-1-13-1-7-1-4-2-1-3zm-6 100l3-3 4-1 3-1h5 5 5 4l5 1 3 1 2 1 4 1 1 1-1 3-3 3-5 3-6 4h-6-8l-6-2-4-3-3-1-2-3z"
                />
                <path
                    id="52endo1"
                    className={endoRootCheker(52) ? endoRootCheker(52).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m404.9 69.4c0 0 3.2 13.4 4.4 17.5 0.8 4.7 1.9 12.7 1.9 12.7l1.2 14.7 0.2 26.6v7.5l-3.5 0.4-0.2-24.9-1.9-27.3-2.1-21.7 2.1 12z"
                />
                <path
                    id="52down-outline2"
                    className="s3"
                    d="m394.3 299.4c8.4 4.1 17.7 4.2 27.9 0.7-9.8 4.8-19.2 4.8-27.9-0.7z"
                />
                <path
                    id="52down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m406.3 312.7c-6.8 0-13.1-2.4-17.9-7.3q-0.3-0.2-0.6-0.5c-1.9-2.1-1.5-5.6-0.8-8.2 4.1-12.4 9.4-22.1 16.2-29.7q1.2-1.3 2.9-2.2c3-1.5 6.8-1.8 9.7-0.8 2.2 0.8 4.4 2.3 6 4 1.8 1.9 2.9 4.9 3.9 7.5q0.3 0.7 0.5 1.3c1.9 4.8 3.2 9.7 4 14.8 0.3 2 0.5 3.9 0.7 5.8q0 1.1 0 2.1-0.1 2.7-2.4 5.2h0.1q-0.2 0.1-0.3 0.2-0.2 0.2-0.4 0.4c-0.3 0.3-0.8 0.6-1.4 0.8-6.8 4.4-13.7 6.6-20.2 6.6zm-2.3-45c-6.7 7.5-11.9 17.1-16 29.3-0.6 2.3-1.1 5.4 0.6 7.2 1.4 1.4 3.6 2 5.6 2.5l0.7 0.2c5.6 1.5 11 2 16.4 1.6 2.7-0.2 5.4-0.6 8.1-1.3q2-0.5 4-1.2 0.5-0.1 1-0.3c0.5-0.1 1.1-0.3 1.6-0.5q0.8-0.5 1.6-1.1 2.2-2.3 2.2-4.6 0-1 0-2c-0.1-1.9-0.4-3.8-0.7-5.7-0.7-5-2-9.9-3.9-14.6q-0.2-0.6-0.5-1.3c-0.9-2.6-2-5.4-3.7-7.1-1.5-1.7-3.6-3-5.5-3.8-2.7-0.9-6.1-0.7-8.9 0.7q-1.6 0.9-2.6 2z"
                />
                <path
                    id="52up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m397.7 204.9c-4.3-1.8-6.1-5.7-5.6-11.4 0.1-10.3 1.8-20 3.7-30.2l0.1-0.8c0.6-3.5 0.9-7.2 1.1-10.8q0.1-2.5 0.3-5c0.2-1.7 0.4-3.5 0.6-5.3 0.4-3.4 0.8-7 0.8-10.6 0.8-8.7 1.5-17.6 2.1-26.5 0.5-8.2 0.7-15.1 0.1-22.1 0-0.8-0.2-1.7-0.4-2.7-0.4-2.2-0.9-4.6-0.2-6.5 1-2.7 3.4-3.5 6.1-1.9 5.6 3.3 9.3 10.8 11.2 22.7 0.6 3.9 1 7.9 1.3 11.8 0.3 3.8 0.6 7.8 1.2 11.6 0.9 5.4 2.3 10.8 3.7 16 0.8 3.2 1.7 6.5 2.4 9.8 0.9 4.1 1.8 8.7 2.6 14.3q0.2 1.7 0.5 3.2c0.4 2.5 1 4.9 1.5 7.2 0.7 2.7 1.3 5.4 1.8 8.2 0.9 5.8 1.5 11.8 1.9 17.7 0.1 0.7 0.2 1.6 0.3 2.6 0.4 3.7 0.9 8.3-1.1 10.7q-1.3 1.3-3.5 1.4-0.4-0.1-0.8-0.1c-0.2 0-31.5-3.3-31.5-3.3zm0.7-58.1q-0.2 2.5-0.4 5c-0.1 2.2-0.3 4.4-0.5 6.5l7.8-6.6c2.2-1.9 4.4-2.8 7.2-2.1 3.2 0.8 5.1 3 7.3 5 1.1 0.9 2.4 1.7 3.6 2.3 0.5 0.3 4.2 1.6 4.4 1.9q-0.1-0.6-0.2-1.3c-0.8-5.6-1.6-10.1-2.5-14.2-0.7-3.3-1.6-6.6-2.4-9.8-1.4-5.2-2.8-10.6-3.7-16.1-0.6-3.9-0.9-7.8-1.2-11.7-0.4-3.8-0.7-7.8-1.3-11.7-1.9-11.6-5.4-18.8-10.7-22q-1.2-0.6-2.1-0.6-1.7 0-2.4 2c-0.6 1.6-0.2 3.8 0.2 5.9 0.2 1 0.4 2 0.5 2.9 0.6 7 0.3 13.9-0.2 22.2-0.5 8.9-1.3 17.9-2.1 26.5 0 3.6-0.4 7.2-0.8 10.7-0.2 1.7-0.4 3.5-0.5 5.2zm-1.6 16.7c-1.8 10.2-3.5 19.8-3.6 30.1-0.6 5.3 1 8.7 4.8 10.3 1.8 0.2 31.3 3.2 31.4 3.3 1.6 0.1 2.7-0.2 3.4-1 1.7-2 1.2-6.4 0.8-9.9-0.1-0.9-0.2-1.8-0.2-2.6-0.4-5.9-1-11.8-2-17.6-0.4-2.8-1-5.5-1.7-8.1-0.5-2.3-1.1-4.8-1.6-7.2q0-0.4-0.1-0.8c-4.5-1.3-8.3-3.6-11.2-6.8-0.6-0.6-1.2-1.1-1.9-1.5-3.5-1.9-6.4-1.7-8.8 0.8l-8.8 7.4q-0.1 1.4-0.4 2.8z"
                />
                <path
                    id={52}
                    className="s5"
                    d="m395.6 336.3v-8.8h9.3v2h-7.1v4.3q0.5-0.2 1-0.3 0.5-0.1 1-0.1 2.9 0 4.7 1.6 1.7 1.6 1.7 4.4 0 2.8-1.8 4.4-1.7 1.5-4.9 1.5-1.1 0-2.3-0.1-1.1-0.2-2.3-0.6v-2.4q1 0.6 2.1 0.9 1.2 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-0.9 0-1.9 0.2-0.9 0.2-1.9 0.7zm17.3 6.7h8.2v2h-11.1v-2q1.4-1.4 3.7-3.7 2.3-2.4 2.9-3.1 1.2-1.2 1.6-2.1 0.5-0.9 0.5-1.8 0-1.4-1-2.2-1-0.9-2.6-0.9-1.1 0-2.3 0.4-1.2 0.4-2.7 1.1v-2.4q1.5-0.5 2.7-0.8 1.3-0.3 2.3-0.3 2.7 0 4.3 1.3 1.6 1.4 1.6 3.7 0 1.1-0.4 2-0.4 1-1.4 2.3-0.3 0.3-1.9 2-1.6 1.6-4.4 4.5z"
                />
            </g>
            <g id="Group53" className={missingChecker(53) ? "g1-none" : "g1"}>
                <path
                    id="53down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m366.2 262.7c5 5.8 12.8 17.5 7.7 29.8-0.8 1.9-2.1 4-3.8 6.1q-1.5 2-3.6 4.1c-2.6 2.7-5.2 4.6-8 5.6-8.3 3.2-17.3-2.6-22.9-9-1.2-1.4-2.1-2.8-2.9-4.2-0.6-1.2-1.2-2.5-1.4-3.8-0.1-0.8 0-1.8 0.1-2.7l0.1-0.6c0.5-5 2.6-9.8 4.6-13.9 1.5-3 3.1-6 4.8-8.9l0.5-0.9c1.4-2.2 2.9-4.6 4.6-6.1 2.2-1.8 5.7-3.9 9.7-3.1 4.4 0.9 7.7 4.4 10.5 7.6zm4.8 25.3l-0.2-0.3c-3.8 5.6-8.6 8.4-14.3 8.4l0.4 0.2c6.1 0.3 10.2-2.2 14.1-8.3zm-19.8 9.7l0.5-0.3c-6.6-0.8-11.4-3.2-14.8-7.2l0.4 0.8c3.3 4.2 6.9 6 13.9 6.7z"
                />
                <path
                    id="53up-fill"
                    className="s0"
                    d="m370.5 154.6c-1.2-0.6-2.4-1.3-3.5-2.1-1.1-0.9-2.1-1.8-3.1-2.7q-0.8-0.7-1.5-1.4c-3.5-3.1-6.7-4.4-9.6-4-2.9 0.4-5.2 2.3-7.2 4l-0.5 0.4c-2.2 1.9-4.2 3-6.5 3.7q0-1.2 0-2.4c-0.1-1.1-0.1-2.2-0.1-3.4 0.1-2.5 0.4-5 0.6-7.4q0.2-1.9 0.4-3.8l1.9-22.5c0.2-2.4 0.5-4.8 0.8-7.2 0.6-4.9 1.1-9.9 1.1-14.8 1-11.3 2-23 1.6-34.5 0-1-0.2-2-0.3-2.9-0.2-1.7-0.4-3.3-0.2-4.9 0.3-1.6 0.8-2.3 1.6-2.5q0.1 0 0.2 0c1.1 0 2 1.6 2.7 2.8q0.2 0.3 0.4 0.7c11.8 18.5 15.6 41.9 18.4 64.2 0.8 6.5 1.2 13.1 1.6 19.5 0.4 6.8 0.9 13.8 1.8 20.6q0 0.6 0.2 1.1-0.4-0.2-0.8-0.5zm9.4 36.6c0.3 2.4 0.7 4.9-0.6 6.9-1.1 1.9-2.9 3.3-4.7 4.5-4.2 3-9.1 6.4-14.1 6.4-2.4 0-4.6-0.7-6.8-2.1-1.4-0.9-2.6-1.9-3.8-2.9-1.3-1-2.7-2-4.1-3-1.1-0.6-2.3-1.3-3.4-1.9-3.2-1.7-6.2-3.2-7.7-6.2-1.7-3-1.2-6.8-0.6-10.8q0.5-2.6 0.9-5.2l0.5-2.8c1.1-6.2 2.3-12.7 3-19.2q0.1-0.6 0.1-1.3c2.6-0.7 4.8-1.8 7.2-3.9l0.5-0.4c2-1.7 4.1-3.5 6.6-3.8 2.7-0.3 5.5 0.9 8.7 3.7q0.8 0.7 1.6 1.4c1 0.9 2 1.9 3.2 2.7 1.1 0.9 2.4 1.6 3.6 2.3q0.9 0.5 1.8 1 0.1 0.6 0.3 1.2c0.8 3.3 1.8 6.6 2.8 9.7 1.2 4.2 2.6 8.5 3.4 12.9 0.6 3.1 1.1 6.3 1.5 9.5q0 0.6 0.1 1.3z"
                />
                <path
                    id="53caries-vesial"
                    className={cariesChecker(53) ? cariesChecker(53).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m343 151l-1 47h27l-1-44-5-5-5-3h-4-4l-3 3-2 1zm2 107l-6 31 5 1h19 6l-1-26-3-4-4-3-4-2-5-1-3 2z"
                />
                <path
                    id="53implant-vesial"
                    className={implantChecker(53) ? implantChecker(53).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m343 151l-1 47h27l-1-44-5-5-5-3h-4-4l-3 3-2 1zm2 107l-6 31 5 1h19 6l-1-26-3-4-4-3-4-2-5-1-3 2z"
                />
                <path
                    id="53caries-mesial"
                    className={cariesChecker(53) ? cariesChecker(53).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m367 153l2 53 6-4 6-6-3-14-2-12-4-12-1-3zm0 109l1 40 7-12v-10l-1-8-4-6z"
                />
                <path
                    id="53implant-mesial"
                    className={implantChecker(53) ? implantChecker(53).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m367 153l2 53 6-4 6-6-3-14-2-12-4-12-1-3zm0 109l1 40 7-12v-10l-1-8-4-6z"
                />
                <path
                    id="53caries-distal"
                    className={cariesChecker(53) ? cariesChecker(53).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m340 289l6-31-5 5-5 9-3 7-2 7 1 5v3zm3-89l1-49-5 2-1 4-1 9-2 7-1 7-1 8 1 5 5 5z"
                />
                <path
                    id="53implant-distal"
                    className={implantChecker(53) ? implantChecker(53).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m340 289l6-31-5 5-5 9-3 7-2 7 1 5v3zm3-89l1-49-5 2-1 4-1 9-2 7-1 7-1 8 1 5 5 5z"
                />
                <path
                    id="53caries-occlusal"
                    className={cariesChecker(53) ? cariesChecker(53).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m331 292l6-3h12l23 1 3 1-2 6-7 7-6 5-6 1h-5l-7-4-6-5-4-7zm8-94h16 14 12l-4 4-6 4-5 3-4 1h-4l-8-4-5-4z"
                />
                <path
                    id="53implant-occlusal"
                    className={implantChecker(53) ? implantChecker(53).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m331 292l6-3h12l23 1 3 1-2 6-7 7-6 5-6 1h-5l-7-4-6-5-4-7zm8-94h16 14 12l-4 4-6 4-5 3-4 1h-4l-8-4-5-4z"
                />
                <path
                    id="53endo1"
                    className={endoRootCheker(53) ? endoRootCheker(53).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m348 50.9c0 0 2.4 11 4.6 21.7 1.8 10.5 3.4 21.2 3.4 21.2l1 15.1-1 28.2-0.4 8.3-3.5 0.4 0.5-28.7-2.8-39.7-1.8-21.5 1.5 9.7z"
                />
                <path
                    id="53down-outline3"
                    className="s3"
                    d="m370.8 287.7l0.2 0.3c-3.9 6.1-8 8.6-14.1 8.3l-0.4-0.2c5.7 0 10.5-2.8 14.3-8.4z"
                />
                <path
                    id="53down-outline2"
                    className="s3"
                    d="m351.7 297.4l-0.5 0.3c-7-0.7-10.6-2.5-13.9-6.7l-0.4-0.8c3.4 4 8.2 6.4 14.8 7.2z"
                />
                <path
                    id="53up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m341.9 200.1c-3.2-1.7-6.5-3.4-8.2-6.7-1.9-3.6-1.2-8.2-0.6-11.5q0.4-2.6 0.9-5.2l0.4-2.8c1.1-6.2 2.3-12.7 3-19.1 0.2-1.6 0.1-3.1 0.1-4.6-0.1-1.2-0.1-2.3-0.1-3.5 0.1-2.5 0.4-5.1 0.6-7.6q0.2-1.8 0.4-3.7l2-22.4c0.2-2.5 0.4-5 0.7-7.4 0.6-4.8 1.2-9.8 1.2-14.7 0.9-11.3 1.9-22.9 1.5-34.4 0-0.9-0.1-1.9-0.3-2.8-0.2-1.7-0.4-3.4-0.1-5.2 0.1-0.8 0.5-3 2.4-3.4 2-0.3 3.2 1.8 4.1 3.3q0.2 0.4 0.4 0.7c11.9 18.7 15.7 42.2 18.5 64.6 0.8 6.6 1.2 13.2 1.6 19.7 0.4 6.7 0.8 13.7 1.8 20.5 0 0.6 0.3 1.4 0.5 2.1q0.3 0.8 0.4 1.5c0.8 3.3 1.8 6.6 2.8 9.7 1.3 4.2 2.6 8.6 3.5 13 0.6 3.2 1.1 6.4 1.4 9.6q0.1 0.6 0.2 1.3c0.3 2.4 0.7 5.3-0.7 7.6-1.3 2-3.2 3.5-5.1 4.8-4.4 3.1-9.3 6.6-14.7 6.6-2.6 0-5-0.8-7.4-2.3-1.4-0.9-2.7-1.9-3.9-2.9-1.3-1-2.6-2.1-4-2.9-1.1-0.7-2.2-1.3-3.3-1.9zm-3.3-47.6c2.3-0.7 4.3-1.8 6.5-3.7l0.5-0.4c2-1.7 4.3-3.6 7.2-4 2.9-0.4 6.1 0.9 9.6 4q0.7 0.7 1.5 1.4c1 0.9 2 1.8 3.1 2.7 1.1 0.8 2.3 1.5 3.5 2.1q0.4 0.3 0.8 0.5-0.2-0.5-0.2-1.1c-0.9-6.8-1.4-13.8-1.8-20.6-0.4-6.4-0.8-13-1.6-19.5-2.8-22.3-6.6-45.7-18.4-64.2q-0.2-0.4-0.4-0.7c-0.7-1.2-1.6-2.8-2.7-2.8q-0.1 0-0.2 0c-0.8 0.2-1.3 0.9-1.6 2.5-0.2 1.6 0 3.2 0.2 4.9 0.2 0.9 0.3 1.9 0.3 2.9 0.4 11.5-0.6 23.2-1.6 34.5 0 4.9-0.5 9.9-1.1 14.8-0.3 2.4-0.6 4.8-0.8 7.2l-1.9 22.5q-0.2 1.9-0.4 3.8c-0.2 2.4-0.5 4.9-0.6 7.4 0 1.2 0 2.3 0.1 3.4q0 1.2 0 2.4zm-0.1 2.4c-0.7 6.5-1.9 13-3 19.2l-0.5 2.8q-0.4 2.6-0.9 5.2c-0.6 4-1.1 7.8 0.6 10.8 1.5 3 4.5 4.5 7.7 6.2 1.1 0.6 2.3 1.3 3.4 1.9 1.4 1 2.8 2 4.1 3 1.2 1 2.4 2 3.8 2.9 2.2 1.4 4.4 2.1 6.8 2.1 5 0 9.9-3.4 14.1-6.4 1.8-1.2 3.6-2.6 4.7-4.5 1.3-2 0.9-4.5 0.6-6.9q-0.1-0.7-0.1-1.3c-0.4-3.2-0.9-6.4-1.5-9.5-0.8-4.4-2.2-8.7-3.4-12.9-1-3.1-2-6.4-2.8-9.8q-0.2-0.5-0.3-1.1-0.9-0.5-1.8-1c-1.2-0.7-2.5-1.4-3.6-2.3-1.2-0.8-2.2-1.8-3.2-2.7q-0.8-0.7-1.6-1.4c-3.2-2.8-6-4-8.7-3.7-2.5 0.3-4.6 2.1-6.6 3.8l-0.5 0.4c-2.4 2-4.6 3.2-7.2 3.9q0 0.7-0.1 1.3z"
                />
                <path
                    id="53down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m330.5 287.9c0.5-5.2 2.7-10.1 4.7-14.3 1.5-3 3.1-6 4.9-8.9l0.5-0.9q1.2-2.1 2.7-4.2 1-1.2 2.1-2.1c2.3-2 6.1-4.2 10.5-3.3 4.7 0.9 8.2 4.5 11 7.8 5.2 6 13.2 18.1 7.9 30.9-1.3 3.1-3.9 6.7-7.6 10.5-2.7 2.8-5.4 4.8-8.3 5.9-8.8 3.3-18.2-2.7-24-9.4-1.3-1.4-2.3-2.9-3.1-4.4-0.6-1.2-1.2-2.6-1.5-4-0.1-1 0-2.1 0.1-3zm1 0.1l-0.1 0.6c-0.1 0.9-0.2 1.9 0 2.7 0.2 1.3 0.8 2.6 1.4 3.7 0.7 1.5 1.6 2.9 2.8 4.2 5.7 6.5 14.7 12.3 23 9.1 2.7-1.1 5.3-2.9 7.9-5.6q2.1-2.1 3.6-4.1c1.7-2.2 3-4.2 3.8-6.1 5.1-12.4-2.6-24-7.7-29.8-2.8-3.2-6.1-6.7-10.4-7.6-4.1-0.8-7.5 1.2-9.7 3.1-1.8 1.4-3.3 3.8-4.6 6.1l-0.5 0.9c-1.8 2.9-3.4 5.8-4.8 8.8-2 4.2-4.2 9-4.7 14z"
                />
                <path
                    id={53}
                    className="s5"
                    d="m340.6 337.3v-8.8h9.3v2h-7.1v4.3q0.5-0.2 1-0.3 0.5-0.1 1-0.1 2.9 0 4.7 1.6 1.7 1.6 1.7 4.4 0 2.8-1.8 4.4-1.7 1.5-4.9 1.5-1.1 0-2.3-0.1-1.1-0.2-2.3-0.6v-2.4q1 0.6 2.1 0.9 1.2 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-0.9 0-1.9 0.2-0.9 0.2-1.9 0.7zm22.4-0.7q1.7 0.3 2.7 1.5 0.9 1.1 0.9 2.8 0 2.6-1.8 4-1.7 1.4-5 1.4-1.1 0-2.3-0.2-1.2-0.2-2.4-0.6v-2.3q1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2.2 0 3.4-0.8 1.2-0.9 1.2-2.6 0-1.5-1.1-2.4-1.1-0.9-3-0.9h-2.1v-1.9h2.2q1.7 0 2.6-0.7 1-0.7 1-2 0-1.4-1-2.1-1-0.7-2.7-0.7-1 0-2.1 0.2-1.1 0.2-2.5 0.6v-2.1q1.4-0.3 2.5-0.5 1.2-0.2 2.3-0.2 2.7 0 4.2 1.2 1.6 1.2 1.6 3.3 0 1.5-0.8 2.5-0.9 1-2.4 1.4z"
                />
            </g>
            <g id="Group54" className={missingChecker(54) ? "g1-none" : "g1"}>
                <path
                    id="54up-fill"
                    className="s0"
                    d="m304 58.2c0.2 0 0.6 0.1 1.2 0.6 2.4 1.8 3.2 5.6 3.8 8.7 0.1 0.7 0.3 1.4 0.4 2l0.2 1c0.4 2.1 0.9 4.3 1.5 6.3q0.4 1.7 0.8 3.4l2.2 9.5q0.4 1.4 0.7 2.8c0.6 2.1 1.2 4.2 1.4 6.4 0.3 3.1 0.2 6.3 0.1 9.3l-0.6 19.3q-0.2 4.4-0.3 8.8c-0.1 4.7-0.3 9.6-0.5 14.4 0 1.2 0 5.6 0 6.8q0 0 0 0.1c-4.4-0.3-7.9-3.4-10.7-5.1-8.8-6-9.7-6.7-16.5-3-3.6 1.9-6.4 2.7-8.7 2.6l6.2-48.5 2.1-22.6 1.3-24.5c0.5-3.9 1.4-4.7 2.1-4.7q0.3 0 0.8 0.3c3 1.8 3.8 5.5 4.6 8.7l0.1 0.9c1 4.3 1.8 8.8 2.5 13.1q0.6 3.6 1.2 7.2l2.1 11.3h0.1q0.1 0.5 0.2 1c0.5 2.7 1 5.5 1.3 8.3 0.6 5.5 1.2 11.2 1.7 16.8 0.6 7 1 14.1 1.4 21l-0.3-21.1c-0.5-5.6-1.1-11.3-1.7-16.8-0.3-2.9-0.8-5.6-1.3-8.4q-0.2-1-0.4-2.1v-30.4c0.1-3 0.7-3.4 1-3.4zm11 99.7c0.4 7 1.5 13.8 3 22 0.6 3.2 1.1 6.7 0.3 10-0.9 4.2-4.1 6.9-7.2 9.5-3.4 2.9-7.9 4.9-12.7 5.7-3.5 0.6-6.6 0.4-9.1-0.7-1.6-0.7-3-1.7-4.3-2.6q-1.1-0.8-2.1-1.4c-0.8-0.6-1.7-1-2.6-1.5-1.4-0.7-2.7-1.3-3.9-2.3-1.3-1.3-2.8-2.6-3.6-4.3-2.5-4.7-0.8-10.8 0.7-16.1q0.5-1.8 0.9-3.5l4.3-18.5 0.2-1c2.5 0.1 5.4-0.7 9.3-2.7 6.5-3.5 6.9-2.8 15.4 3 3 1.7 6.7 4.9 11.3 5.2 0.1 0.6 0.1-0.9 0.1-0.8z"
                />
                <path
                    id="54down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m304.5 256.9q0.6 1.2 1.3 2.5 1 2 2 4.1c2.4 5 4.1 9.5 5.3 13.7 0.9 3.1 1.4 6 1.5 9.1q0.1 1.3-0.2 2.7-0.2 1-0.7 2-0.1 0.3-0.2 0.5c-0.8 1.7-1.9 3.3-3 4.9l-0.7 0.9c-2.1 3.2-4.3 5.8-6.6 8-4 3.9-10.2 8.4-17 7.3-2.7-0.5-5.3-1.7-7.8-3.8-2.3-2-6.3-5.6-7.4-8.9-1.8-5.4-2.3-11.3-1.6-17.7 0.6-4.4 1.7-8.7 3.2-12.8 1.4-4.1 3.1-8.1 4.9-11.8l0.5-1c0.8-1.8 2.6-2.6 4.6-3.6l0.5-0.2c4.2-2 7.8-2.8 11-2.6q2.7 0.2 5 1.4c2.7 1.4 4.2 2.9 5.4 5.3zm-21.9 22c5.2 2.9 10.8 2.2 16.7-2q0.4 7.5 3.7 15.1l-0.6-3c-2.1-9.3-2.6-14.9-1.2-24.1l-0.1-1.4q-1.9 6-1.9 12.1c-6 4.5-11.4 5.2-16.6 2 0-3.3-1-6.6-2.9-9.9l0.9 2.5c2.1 8 0.5 12.9-3.4 20.6l-0.5 1.6c3.6-4.6 5.6-9.1 5.9-13.5z"
                />
                <path
                    id="54caries-oral"
                    className={cariesChecker(54) ? cariesChecker(54).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m315 280l-15 8-10 5h-8l-13-8 1 12 6 9 9 7 10-1 11-9 9-13v-7z"
                />
                <path
                    id="54implant-oral"
                    className={implantChecker(54) ? implantChecker(54).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m315 280l-15 8-10 5h-8l-13-8 1 12 6 9 9 7 10-1 11-9 9-13v-7z"
                />
                <path
                    id="54caries-vesial"
                    className={cariesChecker(54) ? cariesChecker(54).cariesVesial ? "s1-blue" : "s1" : "s1"}
                    d="m284 152l-1 49 5 3 4 2h5l3-1 5-2 3-2v-45l-3-3-6-3-3-2h-3l-3 1-3 2zm-12 118l14-3 9 1 8 3 10 3-4-10-4-8-2-4-5-2-2-1c-1.8 0.3-5 0-5 0l-4 1-4 2-3 2-2 3-3 5-1 4-2 3"
                />
                <path
                    id="54implant-vesial"
                    className={implantChecker(54) ? implantChecker(54).implantVesial ? "s1-red" : "s1" : "s1"}
                    d="m284 152l-1 49 5 3 4 2h5l3-1 5-2 3-2v-45l-3-3-6-3-3-2h-3l-3 1-3 2zm-12 118l14-3 9 1 8 3 10 3-4-10-4-8-2-4-5-2-2-1c-1.8 0.3-5 0-5 0l-4 1-4 2-3 2-2 3-3 5-1 4-2 3"
                />
                <path
                    id="54caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(54) ? cariesChecker(54).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m311 158l3 1 1 2 2 9 2 11v8l-3 5-5 5-4 3v-46zm-8 113v-18l5 8 6 20v7l-1 4-4 7-6 6v-20l1-4v-5z"
                />
                <path
                    id="54implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(54) ? implantChecker(54).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m311 158l3 1 1 2 2 9 2 11v8l-3 5-5 5-4 3v-46zm-8 113v-18l5 8 6 20v7l-1 4-4 7-6 6v-20l1-4v-5z"
                />
                <path
                    id="54caries-distal"
                    className={cariesChecker(54) ? cariesChecker(54).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m286 267v-16l-6 2-3 5-5 11-2 9-1 9v7l3 8 4 6 4 2 2 1 1-18-3-4-2-5 1-6 2-5 2-4zm-2-66l1-49-5 1-2 1-1 7-2 7-2 9-1 6v5l1 4 2 3 4 3z"
                />
                <path
                    id="54implant-distal"
                    className={implantChecker(54) ? implantChecker(54).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m286 267v-16l-6 2-3 5-5 11-2 9-1 9v7l3 8 4 6 4 2 2 1 1-18-3-4-2-5 1-6 2-5 2-4zm-2-66l1-49-5 1-2 1-1 7-2 7-2 9-1 6v5l1 4 2 3 4 3z"
                />
                <path
                    id="54caries-occlusal"
                    className={cariesChecker(54) ? cariesChecker(54).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m291.2 265.8l-4.1 0.1-4 2-2.1 2.1-2 3-1 4-1 5 1 5 1 4 3 3h6l6.1-0.7 3.2-1.5 3.1-1.7 3.2-3.9 2.1-5.5v-5.6l-3.1-4.4-3.1-3.1-4.2-0.9z"
                />
                <path
                    id="54implant-occlusal"
                    className={implantChecker(54) ? implantChecker(54).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m291.2 265.8l-4.1 0.1-4 2-2.1 2.1-2 3-1 4-1 5 1 5 1 4 3 3h6l6.1-0.7 3.2-1.5 3.1-1.7 3.2-3.9 2.1-5.5v-5.6l-3.1-4.4-3.1-3.1-4.2-0.9z"
                />
                <path
                    id="54down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m268.4 282.1c1.1-9.1 4.6-17.4 8.1-25l0.5-0.9c1-2.2 3.1-3.2 5.1-4.1l0.6-0.3c4.3-2.1 8.1-2.9 11.5-2.7q2.9 0.3 5.4 1.6c2.9 1.4 4.6 3.1 5.9 5.7q0.6 1.2 1.2 2.4 1 2.1 2.1 4.2c2.4 5.1 4.1 9.7 5.3 13.9 0.9 3.2 1.4 6.2 1.6 9.3q0.1 1.5-0.2 3-0.3 1.1-0.8 2.3c-0.8 1.9-2.1 3.8-3.3 5.5l-0.7 0.9c-2.2 3.2-4.4 5.9-6.7 8.2-4.2 4.1-10.7 8.7-18 7.5-2.8-0.4-5.6-1.8-8.3-4-2.4-2-6.6-5.9-7.7-9.3-1.9-5.6-2.4-11.7-1.6-18.2zm9.1-24.5c-1.8 3.7-3.5 7.7-4.9 11.8-1.5 4.1-2.6 8.4-3.2 12.8-0.7 6.4-0.2 12.3 1.6 17.7 1.1 3.2 5.1 6.9 7.4 8.9 2.5 2.1 5.1 3.3 7.8 3.8 6.8 1.1 13-3.4 17-7.3 2.3-2.2 4.5-4.8 6.6-8l0.7-1c1.1-1.5 2.2-3.1 3-4.8q0.1-0.2 0.2-0.5 0.5-1 0.7-2 0.3-1.4 0.2-2.8c-0.1-3-0.6-5.9-1.5-9-1.2-4.2-2.9-8.7-5.3-13.7q-1-2.1-2-4.2-0.7-1.2-1.3-2.4c-1.2-2.4-2.7-3.9-5.4-5.3q-2.3-1.2-4.9-1.4c-3.3-0.2-6.9 0.6-11.1 2.6l-0.5 0.2c-1.9 1-3.8 1.8-4.6 3.6z"
                />
                <path
                    id="54down-outline2"
                    className="s3"
                    d="m282.6 278.9c-0.3 4.4-2.3 8.9-5.9 13.5l0.5-1.6c3.9-7.7 5.5-12.6 3.3-20.6l-0.8-2.5c1.9 3.3 2.9 6.6 2.9 9.9 5.2 3.2 10.6 2.5 16.6-2q0-6.1 1.9-12.1l0.1 1.4c-1.4 9.2-0.9 14.8 1.2 24.1l0.6 3q-3.3-7.6-3.7-15.1c-5.9 4.2-11.5 4.9-16.7 2z"
                />
                <path
                    id="54up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m272.4 175.9c0.3-1.2 0.7-2.3 0.9-3.4l4.4-18.5 6.4-50.5 2.2-22.6 1.2-24.4c0.2-1.6 0.7-5.8 3.2-5.8q0.6 0 1.4 0.5c3.4 2 4.3 5.9 5 9.4l0.2 0.9c1 4.3 1.7 8.8 2.5 13.2q0.5 3.6 1.2 7.2l0.9 5.3v-25.7c0.1-1.3 0.2-4.4 2.1-4.4q0.8 0 1.9 0.8c2.7 2.1 3.5 6.3 4.2 9.4q0.2 1 0.4 2l0.2 1c0.4 2.1 0.9 4.2 1.4 6.2q0.4 1.7 0.9 3.4l2.2 9.5q0.3 1.4 0.7 2.8c0.6 2.1 1.2 4.3 1.4 6.6 0.3 3.2 0.2 6.4 0.1 9.5l-0.6 19.2q-0.2 4.5-0.3 8.9c-0.1 4.7-0.3 9.6-0.5 14.3 0 1.2 0 3.4 0 4.6 0 0.3 0.1 2.3 0.1 2.5 0.4 6.9 1.5 13.8 3 21.9 0.6 3.3 1.1 7 0.3 10.5-1 4.5-4.3 7.3-7.6 10-3.5 3-8.2 5.2-13.2 6q-2 0.3-3.8 0.3c-2.2 0-4.2-0.4-6-1.1-1.6-0.7-3.1-1.8-4.5-2.8q-1-0.7-2-1.3c-0.8-0.5-1.6-1-2.5-1.4-1.4-0.7-2.9-1.4-4.1-2.5-1.4-1.3-2.9-2.8-3.9-4.6-2.6-5-0.9-11.3 0.6-16.9zm6.6-23.8c2.3 0.1 5.1-0.7 8.7-2.6 6.8-3.7 7.7-3 16.5 3 2.8 1.7 6.3 4.8 10.7 5.1q0-0.1 0-0.1c0-1.2 0-5.6 0-6.8 0.2-4.8 0.4-9.7 0.5-14.4q0.1-4.4 0.3-8.8l0.6-19.3c0.1-3 0.2-6.2-0.1-9.3-0.2-2.2-0.8-4.3-1.4-6.4q-0.3-1.4-0.7-2.8l-2.2-9.5q-0.4-1.7-0.8-3.4c-0.5-2-1.1-4.2-1.5-6.3l-0.2-1c-0.1-0.6-0.3-1.3-0.4-2-0.6-3.1-1.4-6.9-3.8-8.7-0.6-0.5-1-0.6-1.2-0.6-0.3 0-0.9 0.4-1 3.4v30.4q0.2 1.1 0.4 2.1c0.5 2.8 1 5.6 1.3 8.4 0.6 5.5 1.2 11.2 1.7 16.8l0.3 21.1c-0.4-6.9-0.8-14-1.4-21-0.5-5.6-1.1-11.3-1.7-16.8-0.3-2.8-0.8-5.6-1.3-8.3q-0.1-0.5-0.2-1h-0.1l-2.1-11.3q-0.6-3.6-1.2-7.2c-0.7-4.3-1.5-8.8-2.4-13.1l-0.2-0.9c-0.8-3.2-1.6-6.9-4.6-8.7q-0.5-0.3-0.8-0.3c-0.7 0-1.6 0.9-2.1 4.7l-1.3 24.5-2.1 22.6zm-6.3 40.3c0.9 1.6 2.3 2.9 3.7 4.2 1.1 1.1 2.4 1.7 3.9 2.4 0.8 0.4 1.7 0.9 2.6 1.4q1 0.7 2 1.4c1.4 1 2.8 2 4.3 2.6 2.6 1.1 5.7 1.4 9.2 0.8 4.8-0.8 9.3-2.9 12.7-5.7 3.1-2.6 6.3-5.3 7.2-9.5 0.7-3.3 0.3-6.8-0.3-10.1-1.5-8.1-2.6-15-3-22-0.1 0-0.1 1.5-0.1 0.9-4.7-0.3-8.4-3.5-11.3-5.3-8.5-5.8-9-6.4-15.4-2.9-3.9 2-6.8 2.8-9.4 2.6l-0.1 1-4.4 18.6q-0.4 1.7-0.9 3.4c-1.4 5.4-3.1 11.5-0.7 16.2z"
                />
                <path
                    id="54endo2"
                    className={endoRootCheker(54) ? endoRootCheker(54).canalTwo ? "s2-red" : "s2" : "s2"}
                    d="m305.3 60.9c0 0 2.3 10.2 4.3 21 1.3 11.2 2.3 22.1 2.3 22.1l0.7 14.5-1.1 29-0.4 8.4h-3.1l2-29.1-2.2-40.8-2.2-20.3 1.6 9z"
                />
                <path
                    id="54endo1"
                    className={endoRootCheker(54) ? endoRootCheker(54).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m291.6 53.5l5.9 42.7 0.8 15-1.1 28-0.6 8.2-3.5 0.4 0.5-28.6-2.1-39.5-0.7-20.9 1.2 9.2z"
                />
                <path
                    id={54}
                    fillRule="evenodd"
                    className="s5"
                    d="m278.6 337.3v-8.8h9.3v2h-7.1v4.3q0.5-0.2 1-0.3 0.5-0.1 1-0.1 2.9 0 4.7 1.6 1.7 1.6 1.7 4.4 0 2.8-1.8 4.4-1.7 1.5-4.9 1.5-1.1 0-2.3-0.1-1.1-0.2-2.3-0.6v-2.4q1 0.6 2.1 0.9 1.2 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-0.9 0-1.9 0.2-0.9 0.2-1.9 0.7zm13.8 2.3l7.3-11.1h3v11.4h2.5v2h-2.5v4.1h-2.4v-4.1h-7.9zm7.9 0.3v-9.3l-5.9 9.3z"
                />
            </g>
            <g id="Group55" className={missingChecker(55) ? "g1-none" : "g1"}>
                <path
                    id="55down-fill"
                    fillRule="evenodd"
                    className="s0"
                    d="m256.5 277.4c0.1 2.7-0.3 5.6-1.3 8.4-0.8 2.3-1.9 4.6-3.4 6.8-3 4.6-7 8.9-11.3 12-2.7 2-5.5 3.2-8.1 3.5-3.8 0.4-6.6-1.2-9.2-3.1-3.2-2.4-6-5.6-7.9-9.1-4.4-7.9-3.8-17-2.8-24 0.5-3.5 1.2-6.7 2-9.6 0.6-2 1.1-3.6 1.6-5 0.7-2.1 1.5-3.9 2.2-5.5 2.9-5.8 10.4-10 17.2-9.5 3.1 0.3 6.1 1.7 8.3 4q0.7 0.7 1.3 1.5c0.7 1 1.3 2.2 2 3.4q0.3 0.8 0.7 1.5c1.3 2.3 2.5 4.7 3.3 6.4 2.1 4.5 5 11.3 5.4 18.3zm-29.7-3.7c4 1.5 8.3 0.8 12.5-2q1.6 6.8 7.3 13.8l-1.2-2.3c-5-8.6-6.6-13.5-5.3-22.7l0.6-2.9q-0.4 0.9-0.7 1.8-1.9 5.4-0.9 11.1c-4.3 3-8.3 3.6-12.3 2q-0.3-5.7-4.3-10.7l1.1 2.8c1.5 3.2 2.3 5.8 2.3 8.5v0.3c0 3.4-1.1 6.8-3.5 11.6q-0.2 0.5-0.4 0.9l-0.8 2.3c3.6-5.2 5.5-10 5.6-14.5z"
                />
                <path
                    id="55up-fill"
                    className="s0"
                    d="m257 161.9c-1-0.5-1.9-0.8-2.7-1.1-1.4-0.5-2.6-0.9-4-2.5-2.7-3-5.5-5.8-10.2-6.3-2.5-0.2-5.1 0.2-7.3 1.3-0.7 0.2-1.3 0.6-1.9 1-1.1 0.7-2.2 1.3-3.5 1.5-0.5 0-3.5 0-4.6-0.1 3.9-18.2 7.8-36.9 7.5-55.8-0.1-3.3-0.2-6.7-0.4-9.9-0.2-3.4-0.4-6.9-0.4-10.4-0.1-7.6 0.9-15.1 2.5-24.1 0.2-0.8 0.3-1.5 0.9-1.8 0.7-0.3 1.6 0.5 2.2 1.3 2.6 3.4 4.1 7.6 4.6 12.3 0.4 4.1 1.4 8.6 3.1 14.1 1.5 4.9 3.5 9.6 5.5 14.2 1.1 2.7 2.3 5.4 3.4 8.2 1.7 4.4 4 11.1 4.5 18 0.2 2.6 0.2 5.2 0.4 7.7q0.1 3.3 0.3 6.5l0.1 16.4h-0.1zm3.4 18.8c1 5.5 0.6 9.8-1.3 13.2-2 3.6-5.6 6.1-8.8 8.3-2.1 1.4-4.4 2.2-6.9 3.1q-1 0.3-2 0.7c-2.6 1-4.9 0.5-7.1 0-0.3-0.1-1.1-0.7-1.5-1q-0.3-0.2-0.5-0.3c-1.4-1-2.7-1.7-3.9-2.4l-0.8-0.5c-2.3-1.4-4.7-2.8-6.7-4.5-4-3.4-5.3-7.5-4.7-14.1l0.7-6.2c0.3-3.2 1-6.4 2-9.4l3.8-10.9c1.5 0.3 4.8 0.1 4.8 0.1 1.6-0.1 2.8-0.9 4-1.6 0.6-0.3 1.1-0.7 1.7-1 2.1-0.9 4.5-1.3 6.7-1.1 4.4 0.5 6.9 3 9.6 5.9 1.6 1.9 2.9 2.3 4.5 2.8 0.9 0.3 1.8 0.6 2.9 1.2q0.6 3.5 1.4 7c0.8 3.5 1.5 7.1 2.1 10.7z"
                />
                <path
                    id="55caries-oral"
                    className={cariesChecker(55) ? cariesChecker(55).cariesOral ? "s1-blue" : "s1" : "s1"}
                    d="m257 275l-17 9-13 2-5-4-10-4v8l2 9 8 9 4 4 9 1 10-7 10-15 3-9z"
                />
                <path
                    id="55implant-oral"
                    className={implantChecker(55) ? implantChecker(55).implantOral ? "s1-red" : "s1" : "s1"}
                    d="m257 275l-17 9-13 2-5-4-10-4v8l2 9 8 9 4 4 9 1 10-7 10-15 3-9z"
                />
                <path
                    id="55caries-vesial"
                    className={cariesChecker(55) ? cariesChecker(55).cariesFacial ? "s1-blue" : "s1" : "s1"}
                    d="m228 157l-3 43 3 2 4 3 4 2h2l4-1 3-1 4-2 2-1v-42l-1-2-3-3-3-1-3-1h-3-3l-3 2-2 1zm-15 110l11-4 4-1h8l5 2 4 4 7 1 4 1-2-7-3-7-4-7-2-3-4-3-5-1h-3l-4 1-5 3-3 3-3 3-2 5-1 4-1 3z"
                />
                <path
                    id="55implant-vesial"
                    className={implantChecker(55) ? implantChecker(55).implantFacial ? "s1-red" : "s1" : "s1"}
                    d="m228 157l-3 43 3 2 4 3 4 2h2l4-1 3-1 4-2 2-1v-42l-1-2-3-3-3-1-3-1h-3-3l-3 2-2 1zm-15 110l11-4 4-1h8l5 2 4 4 7 1 4 1-2-7-3-7-4-7-2-3-4-3-5-1h-3l-4 1-5 3-3 3-3 3-2 5-1 4-1 3z"
                />
                <path
                    id="55caries-mesial"
                    fillRule="evenodd"
                    className={cariesChecker(55) ? cariesChecker(55).cariesMesial ? "s1-blue" : "s1" : "s1"}
                    d="m250 159v43l5-3 4-5 3-7-2-8-1-7-2-7v-2l-4-1zm-5 112v4l-2 7v21l4-4 4-5 4-9 2-5-1-7-1-5-3-7-4-9-4-6v22z"
                />
                <path
                    id="55implant-mesial"
                    fillRule="evenodd"
                    className={implantChecker(55) ? implantChecker(55).implantMesial ? "s1-red" : "s1" : "s1"}
                    d="m250 159v43l5-3 4-5 3-7-2-8-1-7-2-7v-2l-4-1zm-5 112v4l-2 7v21l4-4 4-5 4-9 2-5-1-7-1-5-3-7-4-9-4-6v22z"
                />
                <path
                    id="55caries-distal"
                    className={cariesChecker(55) ? cariesChecker(55).cariesDistal ? "s1-blue" : "s1" : "s1"}
                    d="m226 264l1-20-6 3-5 8-3 12-2 8 1 8 1 9 4 7 6 7 1-22-3-5-1-6 2-4zm0-63l3-45h-7l-3 10-2 9-1 11 1 6 4 6z"
                />
                <path
                    id="55implant-distal"
                    className={implantChecker(55) ? implantChecker(55).implantDistal ? "s1-red" : "s1" : "s1"}
                    d="m226 264l1-20-6 3-5 8-3 12-2 8 1 8 1 9 4 7 6 7 1-22-3-5-1-6 2-4zm0-63l3-45h-7l-3 10-2 9-1 11 1 6 4 6z"
                />
                <path
                    id="55caries-occlusal"
                    className={cariesChecker(55) ? cariesChecker(55).cariesOcculsal ? "s1-blue" : "s1" : "s1"}
                    d="m221.9 265.4l4-3.2 4.2-1 4.2 0.2 5.2 0.3 3.3 2.2 2.3 5.1 0.8 4 0.7 4-1.2 3-3.3 4-4.1 3-6 1-4-1-3-2-3-3.1-2-5-1-4 0.9-3.1 1-3.2z"
                />
                <path
                    id="55implant-occlusal"
                    className={implantChecker(55) ? implantChecker(55).implantOcculsal ? "s1-red" : "s1" : "s1"}
                    d="m221.9 265.4l4-3.2 4.2-1 4.2 0.2 5.2 0.3 3.3 2.2 2.3 5.1 0.8 4 0.7 4-1.2 3-3.3 4-4.1 3-6 1-4-1-3-2-3-3.1-2-5-1-4 0.9-3.1 1-3.2z"
                />
                <path
                    id="55-down-outline"
                    className="s3"
                    d="m239.3 271.7c-4.2 2.8-8.5 3.5-12.5 2-0.1 4.5-2 9.3-5.6 14.5l0.8-2.3q0.2-0.4 0.4-0.9c2.4-4.8 3.5-8.2 3.5-11.6v-0.3c0-2.7-0.8-5.3-2.3-8.5l-1.1-2.8q4 5 4.3 10.7c4 1.6 8 1 12.3-2q-1-5.7 0.9-11.1 0.3-0.9 0.7-1.8l-0.6 2.9c-1.3 9.2 0.3 14.1 5.3 22.7l1.2 2.3q-5.7-7-7.3-13.8z"
                />
                <path
                    id="55up-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m215.8 176.9c0.3-3.3 1-6.5 2.1-9.7l3.6-10.6 0.1-0.5c3.9-18.3 8-37.2 7.6-56.2 0-3.3-0.2-6.6-0.4-9.8-0.2-3.5-0.4-7-0.4-10.5-0.1-7.6 0.9-15.2 2.5-24.3 0.2-0.7 0.4-2 1.5-2.6 1.1-0.5 2.5 0.1 3.6 1.6 2.7 3.6 4.3 8 4.8 12.9 0.4 4 1.4 8.4 3.1 13.9 1.4 4.8 3.5 9.5 5.4 14.1 1.1 2.7 2.3 5.4 3.4 8.2 1.7 4.4 4.1 11.2 4.6 18.4 0.1 2.5 0.2 5.1 0.3 7.6q0.2 3.3 0.3 6.6l0.2 24.6v3.1q0.6 3 1.3 6.1c0.7 3.5 1.5 7.1 2.1 10.7 1 5.8 0.5 10.4-1.5 14-2.1 3.8-5.8 6.3-9.1 8.6-2.2 1.4-4.7 2.3-7.1 3.2q-1 0.4-2 0.8c-1.2 0.4-2.4 0.6-3.5 0.6-1.5 0-3-0.3-4.3-0.7-0.4-0.1-1.1-0.5-1.8-1.1q-0.3-0.2-0.5-0.3c-1.4-0.9-2.6-1.7-3.8-2.4l-0.9-0.5c-2.3-1.3-4.7-2.7-6.8-4.6-5.3-4.5-5.6-9.9-5-15zm11.6-21.2c1.3-0.1 2.4-0.8 3.5-1.5 0.6-0.3 1.2-0.7 1.8-1 2.3-1 4.8-1.5 7.3-1.2 4.8 0.5 7.6 3.2 10.3 6.3 1.4 1.6 2.6 2 4 2.5 0.8 0.2 1.7 0.5 2.7 1l-0.2-9.4h0.1l-0.1-16.4q-0.1-3.3-0.3-6.6c-0.1-2.5-0.2-5.1-0.4-7.6-0.4-7-2.8-13.7-4.5-18.1-1-2.7-2.2-5.5-3.3-8.1-2-4.6-4-9.4-5.5-14.2-1.7-5.6-2.7-10-3.1-14.2-0.5-4.7-2.1-8.8-4.6-12.3-0.6-0.8-1.5-1.6-2.3-1.2-0.5 0.2-0.7 0.9-0.8 1.7-1.6 9-2.6 16.6-2.6 24.1 0.1 3.5 0.3 7 0.5 10.5 0.1 3.2 0.3 6.5 0.4 9.8 0.3 19-3.6 37.7-7.5 55.8 1.1 0.1 4.1 0.1 4.6 0.1zm-8.5 11.8c-1 3.1-1.7 6.3-2.1 9.5l-0.6 6.1c-0.7 6.6 0.7 10.7 4.7 14.2 2 1.7 4.4 3.1 6.7 4.4l0.8 0.5c1.2 0.7 2.4 1.5 3.9 2.5q0.2 0.1 0.5 0.3c0.4 0.3 1.2 0.9 1.4 0.9 2.3 0.6 4.6 1.1 7.2 0.1q1-0.4 2-0.8c2.5-0.8 4.8-1.7 6.8-3.1 3.2-2.2 6.9-4.6 8.9-8.2 1.8-3.4 2.3-7.7 1.3-13.2-0.6-3.6-1.4-7.2-2.1-10.7q-0.8-3.5-1.5-7c-1.1-0.6-2-0.9-2.8-1.2-1.6-0.5-2.9-1-4.6-2.8-2.6-3-5.1-5.5-9.5-5.9-2.3-0.3-4.7 0.1-6.7 1.1-0.6 0.3-1.2 0.6-1.7 1-1.2 0.7-2.4 1.4-4 1.6-0.1 0-3.3 0.1-4.9-0.1z"
                />
                <path
                    id="55down-outline"
                    fillRule="evenodd"
                    className="s3"
                    d="m214.3 296.4c-4.5-8.1-3.9-17.5-2.9-24.7 0.5-3.5 1.2-6.7 2-9.7 0.7-2.1 1.2-3.6 1.7-5.1 0.7-2.1 1.4-4 2.2-5.6 3.1-6.2 11.1-10.6 18.3-10.1 3.3 0.3 6.6 1.9 9 4.4q0.8 0.7 1.4 1.5c0.8 1.1 1.4 2.4 2 3.6q0.4 0.7 0.8 1.4c1.3 2.4 2.5 4.8 3.3 6.5 2.2 4.6 5.1 11.6 5.5 18.8 0.3 5.1-1.4 10.6-4.9 15.9-3 4.6-7.1 9-11.5 12.2-2.9 2.2-5.8 3.4-8.7 3.7-4.2 0.5-7.2-1.3-9.9-3.3-3.4-2.5-6.3-5.8-8.3-9.5zm-1.8-24.5c-1 7-1.6 16.1 2.8 24 1.9 3.5 4.7 6.7 7.9 9.1 2.5 1.9 5.4 3.5 9.2 3.1 2.6-0.3 5.4-1.5 8.1-3.5 4.3-3.1 8.3-7.4 11.3-12 1.5-2.2 2.6-4.5 3.4-6.8 1-2.8 1.4-5.7 1.3-8.4-0.4-7-3.3-13.8-5.4-18.3-0.8-1.7-2-4.1-3.3-6.4q-0.4-0.7-0.7-1.5c-0.7-1.2-1.3-2.4-2-3.4q-0.6-0.8-1.3-1.5c-2.2-2.3-5.2-3.7-8.3-4-6.8-0.5-14.3 3.7-17.2 9.5-0.7 1.6-1.5 3.4-2.2 5.5-0.5 1.4-1 3-1.6 5-0.8 2.9-1.5 6.1-2 9.6z"
                />
                <path
                    id="55endo1"
                    className={endoRootCheker(55) ? endoRootCheker(55).canalOne ? "s2-red" : "s2" : "s2"}
                    d="m234.6 58.5l5.9 42.7 0.8 15-1.1 28-0.6 8.2-3.5 0.4 0.5-28.6-2.1-39.5-0.7-20.9 1.2 9.2z"
                />
                <path
                    id={55}
                    className="s5"
                    d="m220.6 337.3v-8.8h9.3v2h-7.1v4.3q0.5-0.2 1-0.3 0.5-0.1 1-0.1 3 0 4.7 1.6 1.7 1.6 1.7 4.4 0 2.8-1.8 4.4-1.7 1.5-4.9 1.5-1.1 0-2.3-0.1-1.1-0.2-2.3-0.6v-2.4q1 0.6 2.1 0.9 1.2 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-0.9 0-1.9 0.2-0.9 0.2-1.9 0.7zm15.3 0v-8.8h9.3v2h-7.2v4.3q0.5-0.2 1.1-0.3 0.5-0.1 1-0.1 2.9 0 4.6 1.6 1.7 1.6 1.7 4.4 0 2.8-1.7 4.4-1.8 1.5-5 1.5-1.1 0-2.2-0.1-1.2-0.2-2.4-0.6v-2.4q1.1 0.6 2.2 0.9 1.1 0.2 2.4 0.2 2 0 3.2-1 1.2-1.1 1.2-2.9 0-1.8-1.2-2.9-1.2-1.1-3.2-1.1-1 0-1.9 0.2-1 0.2-1.9 0.7z"
                />
            </g>
        </g>
    </svg>
    )
}
export default MilkToothChart;
