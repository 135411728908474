import './toothSelector.css';

export default function ToothSelctor({ tooths, toothsIds, setToothsIds }) {

    const handleSelect = (id) => {
        setToothsIds(old => old.includes(id) ? old.filter(el => el !== id) : [...old, id]);
    }

    return (
        <div className="tooth_selector_main_div">
            {tooths.map(tooth => <div className={`single_tooth_selector  ${toothsIds.includes(tooth.toothId) ? "blue" : ""}`} onClick={() => handleSelect(tooth.toothId)}>{tooth.toothNum}</div>)}
        </div>
    )
}