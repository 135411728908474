import { FaUser } from "react-icons/fa";
import { PiRadioactiveFill } from "react-icons/pi";
import { MdCalendarViewMonth } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { PatientTabsEnum } from "../types";
import "./header.css";

export default function Header({ handler, number }) {
  const { t } = useTranslation();
  return (
    <section className="header">
      <div className="header__container">
        <div className="header__content">

          <div className="header__content__left">
            <FaUser />
            <p>{`${t('patient')}: ${number}`}</p>
          </div>

          <div className="header__content__right">

            <button className="btn  patient" onClick={() => handler(PatientTabsEnum.PATIENT)}>
              <FaUser />
              <p>{t('patient')}</p>
            </button>

            <button className="btn  patient" onClick={() => handler(PatientTabsEnum.FILES)}>
              <FaUser />
              <p>{t('files')}</p>
            </button>

            <button className="btn  patient" onClick={() => handler(PatientTabsEnum.RTG)}>
              <PiRadioactiveFill />
              <p>RTG</p>
            </button>

            <button className="btn  patient" onClick={() => handler(PatientTabsEnum.RECORD)}>
              <MdCalendarViewMonth />
              <p>{t('medicalRecord')}</p>
            </button>

          </div>
        </div>
      </div>
    </section>
  )
}