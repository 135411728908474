import { TiStarburst } from "react-icons/ti";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./rtgaddedit.css";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import DatePicker from "react-datepicker";

export default function RTGAdd() {
  const { id } = useParams();

  const { createRtgRecords, tooths, message, setMessage } = useContext(DataContext);

  const { t } = useTranslation();
  const [toothId, setToothId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [document, setDocument] = useState(null);
  const [note, setNote] = useState('');
  const [toothNumber, setToothNumber] = useState('');

  useEffect(() => { setMessage("") }, []);

  const rtgAddHandle = (e) => {
    e.preventDefault();
    createRtgRecords(id, toothId, date, document, note, toothNumber);
  }

  const handleDate = (date) => {
    setDate(date)
  }

  return (
    <section className="container rtgadd__container">
      <div className="rtgadd__top">
        <h3>{t('addRTGRecord')}</h3>
      </div>
      <form className="rtgAdd__form" id="rtg_form" onSubmit={rtgAddHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input36">{t('date')} </label>
          <DatePicker
            selected={date}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input37">{t('document')} </label>
          <input type="file" accept="image/png, image/jpeg" name="document" id="input37" onChange={e => setDocument(e.target.files[e.target.files.length - 1])} />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="textarea1">{t('note')} </label>
          <textarea name="textarea1" id="textarea1" cols="40" rows="6" value={note} onChange={e => setNote(e.target.value)} />
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input38">{t('toothNumber')} </label>
          <select name="toothNumber" id="input38" value={toothNumber} onChange={e => { setToothId(e.target.value); setToothNumber(e.target.value) }}>
            <option value="">-- {t('chooseTooth')} --</option>
            {tooths.map(({ toothId, toothNum }, i) => <option key={i} value={toothId}>{toothNum}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>

      </form>
    </section>
  )
}