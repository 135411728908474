import { useContext, useState, useEffect } from 'react';
import './doctorAddEdit.css';
import { DataContext } from '../../../DataContext';
import { useTranslation } from 'react-i18next';
import DoctorEdit from './DoctorEdit';

export default function DoctorAdd(){
  const {t} = useTranslation();
  const {doctors, createDoctor, deleteDoctor, message, setMessage} = useContext(DataContext);


  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [editToggle, setEditToggle] = useState(false);

  useEffect(() => {setMessage("")}, []);

  const doctorSubmitHandler = (e) => {
    e.preventDefault();
    createDoctor(username, password, passwordConfirm, name, lastName);
  }


  return(
    <section>
        <div className="container doctor__container">
          <div className="all__doctors__view">
            <div className="all__doctors__table__top">
              <h2>{t('allDoctors')}</h2>
            </div>
            <div className="all__doctors__table__body__top">
              <p>{t('name').toUpperCase()}</p>
              <p>{t('lastName').toUpperCase()}</p>
            </div>
            <div>
              {doctors.map(({doctorId, doctorName, doctorLastName}, i)=> {
                  return <div className='all__doctors__table__body__top  data' key={i}>
                    <p>{doctorName}</p>
                    <p>{doctorLastName}</p> 
                    <button className='btn edit' onClick={() => {
                      setDoctorId(doctorId);
                      setEditToggle(old => !old);
                    }}>{t('edit')}</button>
                    <button className='btn delete' onClick={() => deleteDoctor(doctorId)}>{t('delete')}</button>
            </div>
              })}
            </div>
          </div>
          
          <div className="doctor__add__container">
            <form onSubmit={doctorSubmitHandler} className="doctor__form">
                <div className="doctor__form__top">
                  <h3>{t('addDoctor')}</h3>
                </div>

                <div className="doctor__form__body">

                    <div className="radioButton__grid__twoCollumns doctor">
                      <label htmlFor="doctorUsername">{t('username')}: </label>
                      <input type="text" name="doctorUsername" id="doctorUsername" value={username} onChange={e => setUsername(e.target.value)}/>
                    </div>

                    <div className="radioButton__grid__twoCollumns doctor">
                      <label htmlFor="doctorPassword">{t('password')}: </label>
                      <input type="password" name="doctorPassword" id="doctorPassword" value={password} onChange={e => setPassword(e.target.value)}/>
                    </div>

                    <div className="radioButton__grid__twoCollumns doctor">
                      <label htmlFor="doctorPasswordConfirm">{t('passwordConfirm')}: </label>
                      <input type="password" name="doctorPasswordConfirm" id="doctorPasswordConfirm" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)}/>
                    </div>

                    <div className="radioButton__grid__twoCollumns doctor">
                      <label htmlFor="doctorName">{t('name')}: </label>
                      <input type="text" name="doctorName" id="doctorName" value={name} onChange={e => setName(e.target.value)}/>
                    </div>

                    <div className="radioButton__grid__twoCollumns doctor">
                      <label htmlFor="doctorLastName">{t('lastName')}: </label>
                      <input type="text" name="doctorLastName" id="doctorLastName" value={lastName} onChange={e => setLastName(e.target.value)}/>
                    </div>

                    <div className="newReservationFormFooter">
                        <input className="btn save" type="submit" value={t('add')} />
                        <div className="message__div">{message}</div>
                    </div>
                </div>
            </form>
                {editToggle && <DoctorEdit doctorId={doctorId}/>}
          </div>
        </div>
    </section>
  )
}