import axios from 'axios';
import { t } from 'i18next';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status === 401) {
            localStorage.clear();
            window.location.href = '/';
        }
        if (error.response.status === 412) {
            alert(t(error.response.data.message));
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
