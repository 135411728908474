import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import Odontogram from "./patientTabs/medicalRecord/Odontogram";
import PatientInfo from "./patientTabs/patientInfo/PatientInfo";
import MedicalData from "./patientTabs/medicalData/MedicalData";
import RTG from "./patientTabs/rtg/RTG";
import { DataContext } from "../../DataContext";
import { PatientTabsEnum } from "../../types";

export default function Patient() {
    const { id } = useParams();

    const { patient, fetchPatient, fetchTooths, fetchToothInterventions, fetchPlannedInterventions, fetchNotes, fetchAnamnesis, fetchAppointments, fetchFilesAndOpinions, fetchRtgRecords } = useContext(DataContext);
    const [selectedTab, setSelectedTab] = useState(PatientTabsEnum.PATIENT);
    const [plannedInterventionsPage, setPlannedInterventionsPage] = useState(1);
    const [notesPage, setNotesPage] = useState(1);
    const [appointmentsPage, setAppointmentsPage] = useState(1);
    const [toothInterventionsPage, setToothInterventionsPage] = useState(1);
    const [filesAndOpinionsPage, setFilesAndOpinionsPage] = useState(1);
    const [rtgRecordsPage, setRtgRecordsPage] = useState(1);

    useEffect(() => {
        fetchPatient(id);
        fetchTooths(id);
        fetchToothInterventions(id, toothInterventionsPage);
        fetchPlannedInterventions(id, plannedInterventionsPage);
        fetchNotes(id, notesPage);
        fetchAnamnesis(id);
        fetchAppointments(id, appointmentsPage);
        fetchFilesAndOpinions(id, filesAndOpinionsPage);
        fetchRtgRecords(id, rtgRecordsPage);
    }, [plannedInterventionsPage, notesPage, appointmentsPage, toothInterventionsPage, filesAndOpinionsPage, rtgRecordsPage])

    const handleSelection = (tab) => {
        setSelectedTab(tab);
    }

    return (
        <section className="patient__section">
            <div className="container patient__container">
                <Header handler={handleSelection} number={patient.name + " " + patient.lastName} />
                {selectedTab === PatientTabsEnum.RECORD && <Odontogram id={id} />}
                {selectedTab === PatientTabsEnum.PATIENT && <PatientInfo plannedInterventionsPage={plannedInterventionsPage} setPlannedInterventionsPage={setPlannedInterventionsPage} notesPage={notesPage} setNotesPage={setNotesPage} appointmentsPage={appointmentsPage} setAppointmentsPage={setAppointmentsPage} toothInterventionsPage={toothInterventionsPage} setToothInterventionsPage={setToothInterventionsPage} />}
                {selectedTab === PatientTabsEnum.FILES && <MedicalData id={id} filesAndOpinionsPage={filesAndOpinionsPage} setFilesAndOpinionsPage={setFilesAndOpinionsPage} />}
                {selectedTab === PatientTabsEnum.RTG && <RTG id={id} rtgRecordsPage={rtgRecordsPage} setRtgRecordsPage={setRtgRecordsPage} />}
            </div>
        </section>
    )
}
