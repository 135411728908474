import "./passwordReset.css";
import axios from 'axios';
import { useState } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function PasswordReset() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const passwordResetHandle = (e) => {
        e.preventDefault();

        const targetId = Number(localStorage.getItem('userId'));

        const passwordResetData = {
            targetId: targetId,
            password: password,
            passwordConfirm: passwordConfirm
        }

        const accessToken = localStorage.getItem('access_token');
        const requestConfig = { headers: { 'Authorization': "Bearer " + accessToken } };

        axios.patch(
            process.env.REACT_APP_LOCAL_URL + "auth/password-change",
            passwordResetData,
            requestConfig
        )
            .then((_response) => {
                alert("Success");
                navigate('/');
            })
            .catch(error => {
                alert(error.response.data.message);
            });
    }


    return (
        <section>
            <div className="container password_reset__container">
                <div className="password_reset__info">
                    <h2 className="password_reset__h2">{t('password_reset')}: </h2>

                    <form onSubmit={passwordResetHandle} className='log__form'>

                        <div className='input__div'>
                            <p>{t('password')}:</p>
                            <input
                                type="password"
                                name="password"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>

                        <div className='input__div'>
                            <p>{t('passwordConfirm')}:</p>
                            <input
                                type="password"
                                name="passwordConfirm"
                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}
                            />
                        </div>

                        <div className="log__div">
                            <input
                                className='btn password_reset__btn'
                                type="submit"
                                value={t('edit')}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}