import "./workersAddEdit.css";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next"
import { DataContext } from "../../../DataContext";

export default function WorkerEdit({ workerId }) {
  const { t } = useTranslation();
  const { fetchWorker, editWorker, worker, setWorker } = useContext(DataContext);

  useEffect(() => {
    fetchWorker(workerId);
  }, [])

  const workerEditSubmitHandler = (e) => {
    e.preventDefault();
    editWorker(workerId, worker);
  }

  if (worker) {
    return (
      <form onSubmit={workerEditSubmitHandler} className="worker__form">
        <div className="worker__form__top">
          <h3>{t('editWorker')}</h3>
        </div>

        <div className="worker__form__body">
          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="workerName">{t('name')}: </label>
            <input type="text" name="workerName" id="workerName" value={worker.medicalWorkerName} onChange={e => setWorker(old => ({ ...old, medicalWorkerName: e.target.value }))} />
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="workerLastName">{t('lastName')}: </label>
            <input type="text" name="workerLastName" id="workerLastName" value={worker.medicalWorkerLastName} onChange={e => setWorker(old => ({ ...old, medicalWorkerLastName: e.target.value }))} />
          </div>

          <div className="newReservationFormFooter">
            <input className="btn save" type="submit" value={t('edit')} />
          </div>
        </div>

      </form>
    )
  } else return <div>Loading</div>
}