import { useContext, useState } from "react";
import "./anamneza.css";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../DataContext";
import { useParams } from "react-router-dom";


export default function AnamnezaEdit(){
  const {id} = useParams();
  const {t} = useTranslation();
  const {anamnesis, editAnamnesis} = useContext(DataContext);
  const {warning, bloodType, alergicTo, hadPainWeeklyFaceJaw, hadPainWeeklyChewing, jawBlockWeekly, chronicDiseases, infectiveDiseases, otherDiseases, permanentTherapy, hadGumSurgery, hadGumBleed, hadOtherOperations, toothSensitivity, isSmoker, wasInHospital, additionalInfo} = anamnesis;


  const [alert, setAlert] = useState(warning);
  const [bloodGroup, setBloodGroup] = useState(bloodType);
  const [alergies, setAlergies] = useState(alergicTo);
  const [hadPainWeeklyFaceJawState, setHadPainWeeklyFaceJawState] = useState(hadPainWeeklyFaceJaw);
  const [hadPainWeeklyChewingState, setHadPainWeeklyChewingState] = useState(hadPainWeeklyChewing);
  const [jawBlockWeeklyState, setJawBlockWeeklyState] = useState(jawBlockWeekly)
  const [chronicDiseasesState, setChronicDiseasesState] = useState(chronicDiseases);
  const [infectiveDiseasesState, setInfectiveDiseasesState] = useState(infectiveDiseases);
  const [otherDiseasesState, setOtherDiseasesState] = useState(otherDiseases);
  const [permanentTherapyState, setPermanentTherapyState] = useState(permanentTherapy);
  const [hadGumSurgeryState, setHadGumSurgeryState] = useState(hadGumSurgery);
  const [hadGumBleeding, setHadGumBleeding] = useState(hadGumBleed);
  const [operation, setOperation] = useState(hadOtherOperations);
  const [toothWeakPoints, setToothWeakPoints] = useState(toothSensitivity);
  const [isSmokerState, setIsSmokerState] = useState(isSmoker);
  const [hospitalizedPatient, setHospitalizedPatient] = useState(wasInHospital);
  const [additionalInformation, setAdditionalInformation] = useState(additionalInfo);

  const anamnezaEditHandle = (e) => {
      e.preventDefault();
      editAnamnesis(id, alert, bloodGroup, alergies, hadPainWeeklyFaceJawState, hadPainWeeklyChewingState, jawBlockWeeklyState, chronicDiseasesState, infectiveDiseasesState, otherDiseasesState, permanentTherapyState, hadGumSurgeryState, hadGumBleeding, operation, toothWeakPoints, isSmokerState, hospitalizedPatient, additionalInformation)
  }

  return(
    <section>
      <div className="container anamnezaForm__container">
        <div className="anamneza__header">
          <h3>{t('anamnesisChange')}:</h3> 
        </div>
        <form className="anamneza__form" onSubmit={anamnezaEditHandle}>

          <div className="anamneza__form__body">

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input1">{t('warning')}: </label>
            <input type="text" name="alert" id="input1" value={alert} onChange={e => setAlert(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input2">{t('bloodGroup')}: </label>
            <input type="text" name="bloodGroup" id="input2" value={bloodGroup} onChange={e => setBloodGroup(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input3">{t('patientAlergicTo')}: </label>
            <input type="text" name="alergies" id="input3" value={alergies} onChange={e => setAlergies(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input3.1">{t('hadPainWeeklyFaceJaw')}: </label>
            <input type="text" name="alergies" id="input3.1" value={hadPainWeeklyFaceJawState} onChange={e => setHadPainWeeklyFaceJawState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input3.2">{t('hadPainWeeklyChewing')}: </label>
            <input type="text" name="alergies" id="input3.2" value={hadPainWeeklyChewingState} onChange={e => setHadPainWeeklyChewingState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input3.3">{t('jawBlockWeekly')}: </label>
            <input type="text" name="alergies" id="input3.3" value={jawBlockWeeklyState} onChange={e => setJawBlockWeeklyState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input4">{t('chronicDiseases')}: </label>
            <input type="text" name="chronicDiseases" id="input4" value={chronicDiseasesState} onChange={e => setChronicDiseasesState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input5">{t('infectiveDiseases')}: </label>
            <input type="text" name="infectiveDiseases" id="input5" value={infectiveDiseasesState} onChange={e => setInfectiveDiseasesState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input6">{t('otherDiseases')}: </label>
            <input type="text" name="otherDiseases" id="input6" value={otherDiseasesState} onChange={e => setOtherDiseasesState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input7">{t('permanentTherapy')}: </label>
            <input type="text" name="permanentTherapy" id="input7" value={permanentTherapyState} onChange={e => setPermanentTherapyState(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input8">{t('hadGumSurgery')}? </label>
            <input className="chk__button" type="checkbox" name="hadGumSurgery" id="input8" checked={hadGumSurgeryState} onChange={e => setHadGumSurgeryState(e.target.checked)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input9">{t('hadGumBleed')}? </label>
            <input className="chk__button" type="checkbox" name="hadGumBleeding" id="input9" checked={hadGumBleeding} onChange={e => setHadGumBleeding(e.target.checked)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input10">{t('hadOtherSurgeries')}? </label>
            <input type="text" name="operation" id="input10" value={operation} onChange={e => setOperation(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input11">{t('toothSensitivity')}? </label>
            <input type="text" name="toothWeakPoints" id="input11" value={toothWeakPoints} onChange={e => setToothWeakPoints(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input12">{t('isSmoker')}? </label>
            <input className="chk__button" type="checkbox" name="isSmoker" id="input12" checked={isSmokerState} onChange={e => setIsSmokerState(e.target.checked)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input13">{t('lastTwoYearsHospital')}? </label>
            <input type="text" name="hospitalizedPatient" id="input13" value={hospitalizedPatient} onChange={e => setHospitalizedPatient(e.target.value)}/>
          </div>

          <div className="radioButton__grid__twoCollumns">
            <label htmlFor="input14">{t('additionalInfo')}: </label>
            <input type="text" name="additionalInformation" id="input14" value={additionalInformation} onChange={e => setAdditionalInformation(e.target.value)}/>
          </div>

          <div className="anamnezaFormFooter">
                <input className="btn save" type="submit" value={t('save')} />
                <button className="btn sm">{t('discard')}</button>
            </div>

          </div>


        
        </form>
      </div>
    </section>
  )
}