import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import 'moment/locale/sr';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";
import { useCallback, useContext, useState, useEffect } from "react";
import { DataContext } from "../../DataContext.js";
import AppointmentModal from "./AppointmentModal.jsx";
import { useTranslation } from "react-i18next";

export default function MyCalendar() {
    const {t} = useTranslation();
    const {appointmentsForCalendar, fetchAppointmentsForCalendar, filterAppointmentsForCalendar, doctors, chairs} = useContext(DataContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [appointmentIdState, setAppointmentIdState] = useState('');
    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()+1, 1));
    const [doctorId, setDoctorId] = useState('all');
    const [chairId, setChairId] = useState(0);
    const onNavigate = useCallback((newDate) => setDate(newDate), [setDate]);

    useEffect(() => {
      fetchAppointmentsForCalendar(startDate, endDate);
    },[])

    const onRangeChange = useCallback((range) => {
        setStartDate(range.start);
        setEndDate(range.end);
      if(doctorId !== "all" || chairId !== 0){
        filterAppointmentsForCalendar(doctorId, chairId, range.start, range.end);
      } 
      else fetchAppointmentsForCalendar(range.start, range.end);
    }, []);

    const localizer = momentLocalizer(moment);
    const eventStyleGetter = (event) => {
        
        const backgroundColor = event.chair === 1 ? "#005996" : "#ff2424";
    
        const style = {
          backgroundColor,
          borderRadius: '5px',
          color: 'white',
          border: '0',
          display: 'block',
        };
    
        return {style};
      };

    const translationData = {
        today: t('today'),
        previous: t('previous'),
        next: t('next'),
        agenda: t('agenda'),
        month: t('month'),
        week: t('week'),
        day: t('day'),

        showMore: total => `+${total} ${t('appointments')}`
    }
    

    return (
        <div className="calendar__container">

              <div className="my__calendar__navigation">
                <select className="my__calendar__select" value={doctorId} onChange={e => setDoctorId(e.target.value)}>
                  <option value="all">-- {t('doctor')} --</option>
                  {doctors.map(({doctorId, doctorName, doctorLastName}, i) => <option key={i} value={doctorId}>{doctorName} {doctorLastName}</option>)}
                </select>
                <select className="my__calendar__select" value={chairId} onChange={e => setChairId(e.target.value)}>
                  <option value={0}>--{t('chooseChair')}--</option>
                  {chairs.map(({chairId, chairName}, i) => <option key={i} value={chairId}>{chairName}</option>)}
                </select>
                <button className="btn sm variant" onClick={() => filterAppointmentsForCalendar(doctorId, chairId, startDate, endDate)}>{t('filter')}</button>
              </div>
              
            <AppointmentModal open={isModalOpen} setOpen={() => setIsModalOpen(false)} appointmentId={appointmentIdState} />
            <Calendar 
            localizer={localizer}
            culture="sr"
            events={appointmentsForCalendar}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, color: "#000000", backgroundColor: "#ffffff"}}
            min={new Date(1972, 0, 1, 8, 0, 0)}
            max={new Date(1972, 0, 1, 20, 59, 59)}
            messages={translationData}
            slotGroupPropGetter={() => ({style: {minHeight:80}})}
            onSelectEvent={(event)=> {
                setAppointmentIdState(event.appointmentId);
                setIsModalOpen(true)
            }}
            eventPropGetter={eventStyleGetter}
            date={date}
            onNavigate={onNavigate}
            onRangeChange={onRangeChange}
            />
        </div>
    );
}