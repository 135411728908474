import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./filesandopinionsadd.css";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import DatePicker from "react-datepicker";

export default function FilesAndOpinionsAdd() {
  const { id } = useParams();
  const { t } = useTranslation();

  const { createFilesAndOpinions, doctors, fetchDoctors, message, setMessage } = useContext(DataContext);

  const [date, setDate] = useState(new Date());
  const [diagnose, setDiagnose] = useState('');
  const [therapy, setTherapy] = useState('');
  const [doctor, setDoctor] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        fetchDoctors();
        setMessage('');
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, [])

  const filesAndOpinionsAddEditHandle = (e) => {
    e.preventDefault();
    createFilesAndOpinions(id, date, diagnose, therapy, doctor);
  }

  const handleDate = (date) => {
    setDate(date)
  }

  return (
    <section className="container filesAndOpinionsAddEdit__container">
      <div className="filesAndOpinionsAddEdit__top">
        <h3>{t('filesAndOpinionsAdd/Edit')}</h3>
      </div>
      <form className="filesAndOpinionsForm" onSubmit={filesAndOpinionsAddEditHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input15">{t('date')}: </label>
          <DatePicker
            selected={date}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="diagnoseInput">{t('diagnose')}: </label>
          <textarea rows="8" type="text" name="diagnose" id="diagnoseInput" value={diagnose} onChange={e => setDiagnose(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="therapyInput">{t('therapy')}: </label>
          <textarea rows="8" type="text" name="therapy" id="therapyInput" value={therapy} onChange={e => setTherapy(e.target.value)} />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="doctorInput">{t('doctor')}: </label>
          <select name="doctor" id="doctorInput" value={doctor} onChange={e => setDoctor(e.target.value)}>
            <option value="">-- {t('chooseDoctor')} --</option>
            {doctors.map(({ doctorId, doctorName, doctorLastName }) => <option value={doctorId}>{doctorName} {doctorLastName}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>
      </form>
    </section>
  )
}