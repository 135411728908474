import { createContext } from "react";
import { useState } from "react";

export const AuthContext = createContext({
    logged: false,
    setLogged: () => {},
})

export default function AuthProvider({children}){
    const [logged, setLogged] = useState(false);

    const contextValue = {
        logged: logged,
        setLogged
    }

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
