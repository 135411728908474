import { useTranslation } from "react-i18next";


export default function Footer(){
    const {t} = useTranslation();
    return (
        <footer>
            <div className="footer__copyright">
                <small>&copy; {t('dentalOffice')}, {t('allRightsReserved')}</small>
            </div>
        </footer>
    )
}