import { TiStarburst } from "react-icons/ti";
import { useContext, useEffect, useState } from "react";
import "./appointmentAddEdit.css"
import { BsCalendar3 } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../../DataContext";
import DatePicker from 'react-datepicker';

export default function AppointmentAdd() {
  const { t } = useTranslation();
  const { searchPatients, createAppointment, fetchInterventions, fetchChairs, fetchDoctors, patients, interventions, doctors, chairs, message, setMessage } = useContext(DataContext);

  const [patient, setPatient] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [lastNameQuery, setLastNameQuery] = useState('')
  const [patientId, setPatientId] = useState('');
  const [chairId, setChairId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [service, setService] = useState('');
  const [start, setStart] = useState('');
  const [note, setNote] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    setMessage("");
    fetchInterventions();
    fetchChairs();
    fetchDoctors();
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    createAppointment(patientId, doctorId, chairId, service, start, note, end);
  }

  const handleStart = (date) => {
    setStart(date)
  }

  const handleEnd = (date) => {
    setEnd(date)
  }

  const searchHandle = (query) => {
    setPatient(query)
    if (query === '') return;
    searchPatients(query);
  }


  return (
    <section className="container  newReservation__container">
      <div className="newReservation__top">
        <BsCalendar3 />
        <h3>{t('create/edit_appointment')}</h3>
      </div>

      <form className="newReservation__form" onSubmit={handleSubmit}>

        <div className="radioButton__flex">
          <label htmlFor="patients">{t('patient')}: </label>
          <select id="patients" value={patientId} onChange={e => setPatientId(e.target.value)}>
            <option value="">-- {t('choosePatient')} --</option>
            {patients.map(({ patientId, name, lastName }, i) => {
              return <option key={i} value={patientId}>{name} {lastName}</option>
            })}
          </select>
          <input type="text" name="patient" placeholder={'Pretražite pacijente'} value={patient} onChange={(e) => searchHandle(e.target.value)} />

          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__flex">
          <label htmlFor="chairs">{t('chair')}: </label>
          <select id="chairs" value={chairId} onChange={e => setChairId(e.target.value)}>
            <option value="">-- {t('chooseChair')} --</option>
            {chairs.map(({ chairId, chairName }, i) => {
              return <option key={i} value={chairId}>{chairName}</option>
            })}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__flex">
          <label htmlFor="input17">{t('intervention')}: </label>
          <select name="service" id="serviceSelect" value={service} onChange={e => setService(e.target.value)}>
            <option value="">-- {t('chooseIntervention')} --</option>
            {interventions.map(({ interventionTitle }, i) => <option key={i} value={interventionTitle}>{interventionTitle}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__flex">
          <label htmlFor="input18">{t('start')}: </label>
          <DatePicker
            selected={start}
            onChange={handleStart}
            dateFormat="dd.MM.yyyy - HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__flex">
          <label htmlFor="input19">{t('note')}: </label>
          <textarea id="input19" rows="4" cols="50" name="note" value={note} onChange={e => setNote(e.target.value)}></textarea>
        </div>

        <div className="radioButton__flex">
          <label htmlFor="input20">{t('end')}: </label>
          <DatePicker
            selected={end}
            onChange={handleEnd}
            dateFormat="dd.MM.yyyy - HH:mm"
            showTimeSelect
            timeFormat="HH:mm"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>


        <div className="radioButton__flex">
          <label htmlFor="doctorPickerInput">{t('doctor')}: </label>
          <select id="doctorPickerInput" value={doctorId} onChange={e => setDoctorId(e.target.value)}>
            <option value="placeholder">--{t('chooseDoctor')}--</option>
            {doctors.map(({ doctorId, doctorName, doctorLastName }, i) => <option key={i} value={doctorId}>{doctorName} {doctorLastName}</option>)}
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="newReservationFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>
      </form>
    </section>
  )
}