import React, { useContext } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Tooltip, Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DataContext } from '../DataContext';
import { useTranslation } from 'react-i18next';

ChartJS.register(CategoryScale,LinearScale,BarElement,Tooltip,Legend);

export const options = {
  indexAxis: 'y' ,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'right' ,
    },
    title: {
      display: true,
      text: 'Chart.js Horizontal Bar Chart',
    },
  },
};


export function DemographicHorizontalBarChart() {
  const {t} = useTranslation();
  const {malePatients, femalePatients} = useContext(DataContext);
  const labels = [t('gender')];
  const data = {
  labels,
  datasets: [
    {
      label: t('male'),
      data: labels.map(() => malePatients),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
    {
      label: t('female'),
      data: labels.map(() => femalePatients),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};
  return <Bar options={options} data={data} />;
}