import { TiStarburst } from "react-icons/ti";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import "./noteaddedit.css";
import DatePicker from "react-datepicker";

export default function NoteAdd() {
  const id = useParams().id

  const { t } = useTranslation();
  const { createNotes, message, setMessage } = useContext(DataContext);

  const [date, setDate] = useState(new Date());
  const [noteType, setNoteType] = useState('');
  const [noteText, setNoteText] = useState('');
  const [document, setDocument] = useState('');

  useEffect(() => { setMessage("") }, [])

  const noteAddHandle = (e) => {
    e.preventDefault();
    createNotes(id, date, noteType, noteText, document);
  }

  const handleDate = (date) => {
    setDate(date)
  }

  return (
    <section className="container noteAdd__container">
      <div className="noteAdd__container__top">
        <h3>{t('addNote')}</h3>
      </div>
      <form className="noteAddForm" onSubmit={noteAddHandle}>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input21">{t('date')}: </label>
          <DatePicker
            selected={date}
            onChange={handleDate}
            dateFormat="dd.MM.yyyy"
            id="input26"
            placeholderText="DD.MM.YYYY"
            className="date__input"
          />
          <div className="red__star"><TiStarburst /></div>
        </div>

        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="noteType1">{t('noteType')}: </label>
          <select name="noteType" id="noteType1" value={noteType} onChange={e => setNoteType(e.target.value)}>
            <option value="placeholder">-- {t('chooseNoteType')} --</option>
            <option value="informacija">{t('information')}</option>
            <option value="anamneza">{t('anamnesis')}</option>
            <option value="alergija">{t('alergie')}</option>
          </select>
          <div className="red__star"><TiStarburst /></div>
        </div>


        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="noteText">{t('noteText')}:</label>
          <textarea name="noteText" id="noteText" cols="30" rows="10" value={noteText} onChange={e => setNoteText(e.target.value)}></textarea>
          <div className="red__star"><TiStarburst /></div>
        </div>


        <div className="radioButton__grid__twoCollumns">
          <label htmlFor="input22">{t('document')}:</label>
          <input type="file" name="document"
            accept="application/msword, .docx , .xlsx ,application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
            id="input22" onChange={e => setDocument(e.target.files[e.target.files.length - 1])} />
        </div>


        <div className="anamnezaFormFooter">
          <input className="btn save" type="submit" value={t('save')} />
          <button className="btn sm">{t('discard')}</button>
          <div className="message__div">{message}</div>
        </div>

      </form>
    </section>
  )
}