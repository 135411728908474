import React, { useContext } from 'react';
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Tooltip,Legend} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { DataContext } from '../DataContext';

ChartJS.register(CategoryScale,LinearScale,BarElement,Tooltip,Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
};

export function InterventionThisMonthVerticalBarChart() {
const {partialInterventionsSelectedMonth} = useContext(DataContext);
const labels = ['1-7', '8-14', '15-21','22-28', '29-31'];

const data = {
  labels,
  datasets: [
    {
      label: 'Intervencije',
      data: partialInterventionsSelectedMonth,
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};
  return <Bar options={options} data={data}/>;
}
