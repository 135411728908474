import ReactDOM from "react-dom/client";
import App from "./App";
import DataProvider from "./DataContext";
import AuthProvider from "./AuthContext";
import "./index.css"
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
    <BrowserRouter>
        <AuthProvider>
            <DataProvider>
                <App />
            </DataProvider>
        </AuthProvider>
    </BrowserRouter>
)