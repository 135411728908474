import { useContext, useState, useEffect } from 'react';
import './workersAddEdit.css';
import { DataContext } from '../../../DataContext';
import { useTranslation } from 'react-i18next';
import WorkersEdit from './WorkersEdit';

export default function WorkerAdd() {
  const { t } = useTranslation();
  const { workers, createWorker, deleteWorker, message, setMessage } = useContext(DataContext);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [workerId, setWorkerId] = useState('');
  const [editToggle, setEditToggle] = useState(false);

  useEffect(() => { setMessage("") }, []);

  const workerSubmitHandler = (e) => {
    e.preventDefault();
    createWorker(username, password, passwordConfirm, name, lastName);
  }


  return (
    <section>
      <div className="container worker__container">
        <div className="all__workers__view">
          <div className="all__workers__table__top">
            <h2>{t('allWorkers')}</h2>
          </div>
          <div className="all__workers__table__body__top">
            <p>{t('name').toUpperCase()}</p>
            <p>{t('lastName').toUpperCase()}</p>
          </div>
          <div>
            {workers.map(({ medicalWorkerId, medicalWorkerName, medicalWorkerLastName }, i) => {
              return <div className='all__workers__table__body__top  data' key={i}>
                <p>{medicalWorkerName}</p>
                <p>{medicalWorkerLastName}</p>
                <button className='btn edit' onClick={() => {
                  setWorkerId(medicalWorkerId);
                  setEditToggle(old => !old);
                }}>{t('edit')}</button>
                <button className='btn delete' onClick={() => deleteWorker(medicalWorkerId)}>{t('delete')}</button>
              </div>
            })}
          </div>
        </div>

        <div className="worker__add__container">
          <form onSubmit={workerSubmitHandler} className="worker__form">
            <div className="worker__form__top">
              <h3>{t('addWorker')}</h3>
            </div>

            <div className="worker__form__body">

              <div className="radioButton__grid__twoCollumns worker">
                <label htmlFor="workerUsername">{t('username')}: </label>
                <input type="text" name="workerUsername" id="workerUsername" value={username} onChange={e => setUsername(e.target.value)} />
              </div>

              <div className="radioButton__grid__twoCollumns worker">
                <label htmlFor="workerPassword">{t('password')}: </label>
                <input type="password" name="workerPassword" id="workerPassword" value={password} onChange={e => setPassword(e.target.value)} />
              </div>

              <div className="radioButton__grid__twoCollumns worker">
                <label htmlFor="workerPasswordConfirm">{t('passwordConfirm')}: </label>
                <input type="password" name="workerPasswordConfirm" id="workerPasswordConfirm" value={passwordConfirm} onChange={e => setPasswordConfirm(e.target.value)} />
              </div>

              <div className="radioButton__grid__twoCollumns worker">
                <label htmlFor="workerName">{t('name')}: </label>
                <input type="text" name="workerName" id="workerName" value={name} onChange={e => setName(e.target.value)} />
              </div>

              <div className="radioButton__grid__twoCollumns worker">
                <label htmlFor="workerLastName">{t('lastName')}: </label>
                <input type="text" name="workerLastName" id="workerLastName" value={lastName} onChange={e => setLastName(e.target.value)} />
              </div>

              <div className="newReservationFormFooter">
                <input className="btn save" type="submit" value={t('add')} />
                <div className="message__div">{message}</div>
              </div>
            </div>
          </form>
          {editToggle && <WorkersEdit workerId={workerId} />}
        </div>
      </div>
    </section>
  )
}