import "./lengthInputs.css";

export default function LengthInputs({title, handler }){
  return(
    <div className="radioButton__grid">
        <label htmlFor={title}>{title}</label>
        <input type="text" className="length__code__input" id={title} onChange={(e) => handler(e.target.id, e.target.value)}/>
        <label htmlFor={title}>mm</label>
    </div>
  )
}