import "./chairsAddEdit.css";
import { useContext, useEffect} from "react";
import { useTranslation } from "react-i18next"
import { DataContext } from "../../../DataContext";

export default function ChairEdit({chairId}) {
  const {t} = useTranslation();
  const {fetchChair, editChair, chair, setChair} = useContext(DataContext);

  useEffect(() => {
    fetchChair(chairId);
  }, [])
  
  const chairEditSubmitHandler = (e) => {
    e.preventDefault();
    editChair(chairId, chair);
  }

  if (chair) {return (
            <form onSubmit={chairEditSubmitHandler} className="chair__form">
                <div className="chair__form__top">
                  <h3>{t('editChair')}</h3>
                </div>

                <div className="chair__form__body">
                    <div className="radioButton__grid__twoCollumns">
                      <label htmlFor="chairName">{t('name')}: </label>
                      <input type="text" name="chairName" id="chairName" value={chair.chairName} onChange={e => setChair(old => ({...old, chairName: e.target.value}))}/>
                    </div>


                    <div className="newReservationFormFooter">
                        <input className="btn save" type="submit" value={t('edit')} />
                    </div>
                </div>

            </form>
  )} else return <div>Loading</div>
}