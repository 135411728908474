import "./patientaddedit.css";
import { TiStarburst } from "react-icons/ti";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DataContext } from "../../../DataContext";
import DatePicker from "react-datepicker";

export default function PatientEdit() {
      const id = useParams().id;
      const { t } = useTranslation();
      const { patients, editPatient, message, setMessage } = useContext(DataContext);
      const currentPatient = patients.find(obj => obj.patientId === id);
      const { name, lastName, birthday, gender, jmbg, parentName, adress, verbalAgreement, notedAgreement, mobilePhone, housePhone, email, image } = currentPatient;

      const [firstName, setFirstName] = useState(name);
      const [lastNameState, setLastNameState] = useState(lastName);
      const [bornDate, setBornDate] = useState(new Date(birthday));
      const [genderState, setGenderState] = useState(gender);
      const [jmbgState, setJmbgState] = useState(jmbg);
      const [parentNameState, setParentNameState] = useState(parentName);
      const [address, setAddress] = useState(adress);
      const [verbalAprooval, setVerbalAprooval] = useState(verbalAgreement);
      const [writtenAprooval, setWrittenAprooval] = useState(notedAgreement);
      const [mobilePhoneNumber, setMobilePhoneNumber] = useState(mobilePhone);
      const [phoneNumber, setPhoneNumber] = useState(housePhone);
      const [emailState, setEmailState] = useState(email);
      const [imageState, setImageState] = useState('');

      useEffect(() => { setMessage("") }, [])

      const patientAddEditHandle = (e) => {
            e.preventDefault();
            editPatient(id, firstName, lastNameState, bornDate, genderState, jmbgState, parentNameState, address, verbalAprooval, writtenAprooval, mobilePhoneNumber, phoneNumber, emailState, imageState);
      }

      const handleBornDate = (date) => {
            setBornDate(date)
      }

      return (
            <section className="container patientAddEdit__container">
                  <div className="patientAddEdit__top">
                        <h3>{t('create/edit_patients')}</h3>
                  </div>

                  <form className="patientAddEdit__form" onSubmit={patientAddEditHandle}>
                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input24">{t('name')}:</label>
                              <input type="text" name="firstName" id="input24" value={firstName} onChange={e => setFirstName(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input25">{t('lastName')}: </label>
                              <input type="text" name="lastName" id="input25" value={lastNameState} onChange={e => setLastNameState(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input26">{t('dateOfBirth')}:</label>
                              <DatePicker
                                    selected={bornDate}
                                    onChange={handleBornDate}
                                    dateFormat="dd.MM.yyyy"
                                    id="input26"
                                    placeholderText="DD.MM.YYYY"
                                    className="date__input"
                              />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="gender1">{t('gender')}:</label>
                              <select name="gender" id="gender1" value={genderState} onChange={e => setGenderState(e.target.value)}>
                                    <option value="placeholder">-- {t('chooseGender')} --</option>
                                    <option value={t('male')}>{t('male')}</option>
                                    <option value={t('female')}>{t('female')}</option>
                              </select>
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input27">JMBG: </label>
                              <input type="text" name="jmbg" id="input27" value={jmbgState} onChange={e => setJmbgState(e.target.value)} />
                        </div>


                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input28">{t('parentName')}:</label>
                              <input type="text" name="parentName" id="input28" value={parentNameState} onChange={e => setParentNameState(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input29">{t('address')}: </label>
                              <input type="text" name="address" id="input29" value={address} onChange={e => setAddress(e.target.value)} />
                              <div className="red__star"><TiStarburst /></div>
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input30">{t('verbalAgreement')}: </label>
                              <input className="chk__button" type="checkbox" name="verbalAprooval" id="input30" checked={verbalAprooval} onChange={e => setVerbalAprooval(e.target.checked)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input31">{t('notedAgreement')}:</label>
                              <input className="chk__button" type="checkbox" name="writtenAprooval" id="input31" checked={writtenAprooval} onChange={e => setWrittenAprooval(e.target.checked)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input32">{t('mobilePhone')}:</label>
                              <input type="text" name="mobilePhoneNumber" id="input32" value={mobilePhoneNumber} onChange={e => setMobilePhoneNumber(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input33">{t('housePhone')}:</label>
                              <input type="text" name="phoneNumber" id="input33" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                        </div>

                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input34">{t('email')}:</label>
                              <input type="text" name="email" id="input34" value={emailState} onChange={e => setEmailState(e.target.value)} />
                        </div>


                        <div className="radioButton__grid__twoCollumns">
                              <label htmlFor="input35">{t('image')}: </label>
                              <input type="file" accept="image/*" name="image" id="input35" value={imageState} onChange={e => setImageState(e.target.value)} />
                        </div>

                        <div className="anamnezaFormFooter">
                              <input className="btn save" type="submit" value={t('save')} />
                              <button className="btn sm">{t('discard')}</button>
                              <div className="message__div">{message}</div>
                        </div>

                  </form>
            </section>
      )
}