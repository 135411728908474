import "./notFound.css"
import  {Link}  from "react-router-dom"
import { useTranslation } from "react-i18next"
export default function NotFound(){
    const {t} = useTranslation('')
    return (
        <section>
            <div className="container notfound__container">
                <h1>{t('error404')}</h1>
                <Link to="/" className="btn">{t('backToHome')}</Link>
            </div>
        </section>
    )
}